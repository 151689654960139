import {CHANGE_MAP_ZOOM, CHANGE_MAP_BOUNCE, CHANGE_MAP_ZOOM_TO_ACTION} from '../constants'

export const changeMapZoom = (zoom) => ({
    type: CHANGE_MAP_ZOOM,
    payload: zoom,
})
export const changeMapZoomToAction = (zoomToAction) => ({
    type: CHANGE_MAP_ZOOM_TO_ACTION,
    payload: zoomToAction,
})

export const changeMapBounce = (bounce) => ({
    type: CHANGE_MAP_BOUNCE,
    payload: bounce,
})

export const setMapZoomIn = () => (dispatch, getState) => {
    const state = getState()
    const initialZoom = state.mapZoom.data
    dispatch(changeMapZoom(Number(initialZoom) + 1))
    dispatch(changeMapZoomToAction(true))
}

export const setMapZoomOut = () => (dispatch, getState) => {
    const state = getState()
    const initialZoom = state.mapZoom.data
    dispatch(changeMapZoom(Number(initialZoom) - 1))
    dispatch(changeMapZoomToAction(true))
}

export const setMapZoom = (zoom) => (dispatch) => {
    dispatch(changeMapZoom(zoom))
    dispatch(changeMapZoomToAction(false))
}


export const setMapBounce = (bounce) => (dispatch) => {
    dispatch(changeMapBounce(bounce))
}
