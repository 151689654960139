import { useEffect, useRef, useState } from 'react';

function useCountdownTimer(initialTime, callBack,autostart,) {
    const timerRef = useRef(null);
    const startTimeRef = useRef(null);
    const pausedRef = useRef(false);
    const initialTimeRef = useRef(initialTime);
    const nextTimeRef = useRef(initialTime);

    const startCountdown = () => {

        if (initialTimeRef.current < 0 || pausedRef.current) return;


        startTimeRef.current = Date.now();
        if (timerRef.current === null) {
            const animate = () => {
                if(pausedRef.current) return
                const currentTime = Date.now();
                const elapsedTime = currentTime - startTimeRef.current;
                const remainingTime = Math.max(0, initialTimeRef.current - elapsedTime);
                if (remainingTime > 0 && !pausedRef.current) {
                    timerRef.current = requestAnimationFrame(animate);
                } else {
                    timerRef.current = null;
                    // Use the callback from the ref
                    if (typeof callBack === 'function' && initialTimeRef.current > 0) {
                        callBack();
                        initialTimeRef.current = nextTimeRef.current
                    }
                }
            };

            timerRef.current = requestAnimationFrame(animate);
        }
    };

    const stopCountdown = () => {
        if (timerRef.current !== null) {
            cancelAnimationFrame(timerRef.current);
            timerRef.current = null;
        }
    };

    const pauseCountdown = () => {
        pausedRef.current = true;
        timerRef.current = null;

    };

    const playCountdown = () => {
        pausedRef.current = false;
        startCountdown();
    };
    const changeInitialTime = (duration) =>{
        initialTimeRef.current = duration
    }
   const changeNextTime = (duration) =>{
         nextTimeRef.current = duration
    }

    const restartCountdown = () => {
        stopCountdown();
        startCountdown(); // Use the callback from the ref
    };

    // useEffect(() => {
    //     if (autostart) {
    //         startCountdown(); // Use the callback from the ref
    //     }
    //     return () => {
    //         stopCountdown();
    //     };
    // }, []);

    return { changeNextTime,startCountdown, stopCountdown, restartCountdown, pauseCountdown, playCountdown, isPaused:pausedRef.current , changeInitialTime};
}

export default useCountdownTimer;
