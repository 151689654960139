import React from "react";

const SuccessCheck = () => {
  return (
      <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_28_690)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M62 0C27.8031 0 0 27.8031 0 62C0 96.1969 27.8031 124 62 124C96.1969 124 124 96.1969 124 62C124 27.8031 96.1969 0 62 0Z" fill="#42D3B0"/>
          <path d="M50.0602 90.3601C42.1406 82.4406 34.2695 74.4484 26.3258 66.5289C25.4539 65.657 25.4539 64.2039 26.3258 63.332L35.4563 54.2015C36.3281 53.3297 37.7813 53.3297 38.6531 54.2015L51.707 67.2554L85.2742 33.664C86.1703 32.7922 87.5992 32.7922 88.4953 33.664L97.65 42.8187C98.5461 43.7148 98.5461 45.1437 97.65 46.0156L53.257 90.3601C52.3852 91.2562 50.9563 91.2562 50.0602 90.3601Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_28_690">
            <rect width="124" height="124" fill="white"/>
          </clipPath>
        </defs>
      </svg>
  );
};
export default SuccessCheck;
