import {
  SET_COLOR_PICKER_SHOW_STATE,
  SET_ERASER_CLICKED_STATE,
  SET_PAINTER_COLOR,
  SET_PAINTER_COLOR_DATA,
  SET_PAINTER_SIZE,
  SET_PAINTER_SIZE_DATA,
  SET_PAINTER_START,
  SET_RULER_CLICKED_STATE,
  SET_START_POINTER_CLICK_STATE,
} from "../constants";
import { setHintsData } from "./hints";
import { setMapRequirementData } from "./mapRequirement";
import { setMapStyleData } from "./mapStyle";
import { setMenuData } from "./menu";
import { setQuizPopupData } from "./quizPopup";
import { setSearchData } from "./search";

export const setPainterStartData = (state) => ({
  type: SET_PAINTER_START,
  payload: state,
});

export const setStartPaintClickState = (state) => ({
  type: SET_START_POINTER_CLICK_STATE,
  payload: state,
});

export const setPointerColor = (color) => ({
  type: SET_PAINTER_COLOR,
  payload: color,
});

export const setPointerColorData = (data) => ({
  type: SET_PAINTER_COLOR_DATA,
  payload: data,
});

export const setPointerSize = (size) => ({
  type: SET_PAINTER_SIZE,
  payload: size,
});

export const setPointerSizeData = (data) => ({
  type: SET_PAINTER_SIZE_DATA,
  payload: data,
});

export const setColorPickerShowState = (state) => ({
  type: SET_COLOR_PICKER_SHOW_STATE,
  payload: state,
});

export const setRulerClickedState = (state) => ({
  type: SET_RULER_CLICKED_STATE,
  payload: state,
});

export const setEraserClickState = (state) => ({
  type: SET_ERASER_CLICKED_STATE,
  payload: state,
});

export const setPainterStart = (state) => (dispatch) => {
  dispatch(setMapRequirementData(false));
  dispatch(setMenuData(false));
  dispatch(setMapStyleData(false));
  dispatch(setHintsData(false));
  dispatch(setQuizPopupData(false));
  dispatch(setSearchData(false));
  dispatch(setPainterStartData(state));
};
