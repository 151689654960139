import { connect } from "react-redux";
import "./style.css";
import Line from "../../CustomCardComponents/Line";

const DynamicAction = (props) => {
  const { edit = () => {}, remove = () => {} } = props;
  return (
    <div className="card-body__dynamic-actions dynamic-actions">
      <h6 onClick={edit}>Խմբագրել</h6>
      <Line color={"#fff"} />
      <h6 onClick={remove}>Ջնջել</h6>
    </div>
  );
};
export default connect(null, null)(DynamicAction);
