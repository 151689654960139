import {SET_MAP_LOADING, SET_MAP_SELECTED_CLUSTER} from '../constants'

const initialState = {
    data: null,
    selectedCluster: null,
    isLoading: false,
}


export const mapReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MAP_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
            case SET_MAP_SELECTED_CLUSTER:
            return {
                ...state,
                selectedCluster: action.payload,
            };
        default:
            return state;
    }
};
