import { connect } from "react-redux";
import "./style.css";
import React from "react";
const DateInfo = (props) => {
  const { content = {} } = props;
  return (
    <div className="card-body__date-info date-info">
      <h5>{content?.dateType} &nbsp;</h5>
      <p>{content?.dateTime}</p>
    </div>
  );
};
export default connect(null, null)(DateInfo);
