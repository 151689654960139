import { SET_QUIZ_POPUP } from "../constants";
import { setSearchData } from "./search";
import { setMenuData } from "./menu";
import { setMapStyleData } from "./mapStyle";
import { setMapRequirementData } from "./mapRequirement";

export const setQuizPopupData = (bool) => ({
  type: SET_QUIZ_POPUP,
  payload: bool,
});

export const setQuizPopup = (bool) => (dispatch) => {
  dispatch(setSearchData(false));
  dispatch(setMenuData(false));
  dispatch(setMapStyleData(false));
  dispatch(setMapRequirementData(false));
  dispatch(setQuizPopupData(bool));
};
