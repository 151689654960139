import { connect } from "react-redux";
import DynamicActionBtn from "../../DynamicScreenshotCard/DynamicActionBtn";
import "./style.css";
const DynamicTitle = (props) => {
    const { content, children, style={}} = props;

    return (
        <div style={style} className="card-body__dynamic-title dynamic-title style">
            <h4>{content}</h4>
            {children}
        </div>
    );
};
export default connect(null, null)(DynamicTitle);

