import React from 'react'

const CloseDark = () => {
    return (
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.584 11.584L34.7456 34.7456" stroke="#292D32" stroke-width="4.095" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.5811 34.7487L34.749 11.5808" stroke="#292D32" stroke-width="4.095" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
export default CloseDark
