import axios from "axios";
import IdentityAPI from "../server/IdentityAPI";
import {relatedArticlesAPIRequest} from "./article";

const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
        data.data[0].items.__proto__.totalLength = data.data[0].filteredTotal
      return data.data[0].items;
    }
  }
  return [];
};

export const getBookmarksAPI = (page) => {
  const body = {
      "page": page,
      "perPage": 10
  }
  return axios.post(`${process.env.REACT_APP_IDENTITY_URL}/api/FavoriteArticle/DataProvider`, body)
        .then((response) => extractItems(response))
        .then((response)=> {
          if(!!response.length) {
              const idsArray = response.map(el => el.articleId)
              return relatedArticlesAPIRequest(idsArray)
                  .then((res)=>{
                      const arr = res.items;
                      const reference = idsArray;
                      const sortedObjects = arr.sort((a, b) => {
                          const aIndex = reference.indexOf(a.id);
                          const bIndex = reference.indexOf(b.id);
                          if (aIndex !== -1 && bIndex !== -1) {
                              return aIndex - bIndex;
                          }
                          if (aIndex !== -1) {
                              return -1;
                          }
                          if (bIndex !== -1) {
                              return 1;
                          }
                          return 0;
                      });
                       res.items = sortedObjects
                      return res
                  })
          }
          return {items:[]}
        })
        .then((response)=> response?.items)
        .catch(console.log);
};
