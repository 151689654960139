import {
  SET_QUIZ,
  LOADING_QUIZ,
  SET_SORTED_TOOLTIP_SELECT_ITAM_QUIZ,
  SET_QUIZ_MODAL_STATE,
  SET_QUIZ_MODAL_DATA,
  SET_SELECTED_QUIZ_MODAL_DATA
} from "../constants";
import {getQuizAPI} from '../../service/quiz'

export const loadingQuiz = () => ({
  type: LOADING_QUIZ,
});

export const setQuiz = (quiz) => ({
  type: SET_QUIZ,
  payload: quiz,
});

export const setSortedTooltipSelectItemQuiz = (quiz) => ({
  type: SET_SORTED_TOOLTIP_SELECT_ITAM_QUIZ,
  payload: quiz,
});

export const setQuizModalState = (quiz) => {
  return {
    type: SET_QUIZ_MODAL_STATE,
    payload: quiz,
  };
};

export const setQuizModalData = (quiz) => ({
  type: SET_QUIZ_MODAL_DATA,
  payload: quiz,
});

export const setSelectedQuizData = (quiz) => ({
  type: SET_SELECTED_QUIZ_MODAL_DATA,
  payload: quiz,
});


export const getQuizInfo = (type,id) => (dispatch) => {
  dispatch(loadingQuiz());
  getQuizAPI(type,id).then(resp=>{
    let quiz = resp || [];
    const quizData = quiz && quiz.length >0?JSON.parse(quiz):[];
    dispatch(setQuiz(quizData));
  })
};

export const getSelectedQuizDataFromApi = (id) => (dispatch, getState) => {
  dispatch(loadingQuiz());
  const selectedQuizData = getState().quiz.data?.filter(el=>el.id === id)[0]
  dispatch(setSelectedQuizData(selectedQuizData));
}
