import {memo} from "react";

const QuizItem = ({
  data,
  getSelectedQuizDataFromApi,
}) => {
  return (
    <li
      className={`main__subitem-item main__subitem-item_quiz`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        getSelectedQuizDataFromApi(data.id);
      }}
    >
      <div className="hints__sub item_item hints__subitem_item_quiz">
        <div className="hints__sub item_item_quiz_content">
          <div
            className="main__subitem-btn main__subitem-btn_quiz"
            type="button"
          >
            {data.name}
          </div>
        </div>
      </div>
    </li>
  );
};

export default memo(QuizItem);
