import React, {Component, useRef} from "react";
import ReactDOM from "react-dom";
import ArticleModeHeader from "../../components/articleModeHeader";
import LeftSide from "../../components/leftSide";
import MapHeader from "../../components/mapHeader";
import RightSide from "../../components/rightSide";
import Timeline from "../../components/timeline";
import "./style.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl";
import {connect} from "react-redux";
import PageLoader from "../../components/UI/PageLoader";
import {HandleToken, LogIn} from "../../helpers";
import {getGeoJsonDataFromStorage} from "../../service/geoJsonData";
import {getArticleFromAPI} from "../../store/actions/articles";
import {parse, stringify} from "flatted";
import {
    getCategoriesFromAPI,
    getFilteredCategoriesFromAPI,
    updateCategoriesToggle,
} from "../../store/actions/categories";
// import {getTimeGeoJsonDataFromStorage} from "../../store/actions/geoJSONData";
import {
    setNextLessonID,
    setPrevLessonID,
    setSelectedLesson,
} from "../../store/actions/handleLesson";
import {
    getLampInfo,
    setLampModalData,
    setLampModalState, setLampModalStateIndex,
} from "../../store/actions/lamp";
import {getLessonsFromAPI, setLessonData} from "../../store/actions/lessons";
import {loadingMap} from "../../store/actions/map";
import {getScreenShotSingleDataAPI, setMapStylesIdST} from "../../store/actions/mapStateAction";
import {getQuizInfo} from "../../store/actions/quiz";
import {
    setMiddleTimeLineLessonsData,
    setMiddleMaxTimeLineLessonsData,
    setTimeLineArticlesData,
    setTimeLineItems,
    setTimeLineLessonData,
    setTimeLineTopicsData,
    setMiddleTimeLineTopicsData,
    timeLineLoadingReaction,
    updateTimeLineGroupToggle,
} from "../../store/actions/timeLine";
import {setTimeLineExpend} from "../../store/actions/timeLineExpend";
import {setMapBounce, setMapZoom} from "../../store/actions/zoom";
import {
    getCategoriesData,
    getFilteredCategoriesData,
    getIsTimeLineLoading,
    getLampData,
    getLampModalData,
    getLampModalState,
    getMapLoading,
    getMapStyledId,
    getMapStylesData,
    getMapZoomData,
    getNextLessonIDData,
    getPrevLessonIDData,
    getScreenShotLoadingST,
    getSelectedArticleData,
    getSelectedLessonId,
    getSelectedMapRequirements,
    getSelectedMapStyle,
    getTimeLineClickState,
    getTimeLineGroupsData,
    getTimeLineItemsData,
    getTimeLineExpendData,
    getTopicId,
    getSelectedMapStyleDark,
    getLegends,
    getFilteredLegendsData,
    getTimeLineZoomST,
    getMiddleTimeLineItemsData,
    getMiddleMaxTimeLineItemsData,
    getLegendsData,
    getTopicsData,
    getIsTopicLoading,
    getLessons,
    getIsLessonLoading,
    getMapSelectedCluster,
    getMapZoomToActionData,
    getMapStateSingleData,
    getMapStylesListData,
    getUser,
    getShowNotificationST,
    getPainterGeoJsonDataST,
    getRulerClickedState, getResetCompass, getSlidesSelectedSlideData
} from "../../store/selectors";
import {getTopicsFromAPI, setTopicId} from "./../../store/actions/topics";
import {
    addAndPositionLayer,
    addArticlePoint,
    addPoint,
    addSymbol,
    determinateURLParams,
    determineCatIds,
    extractId,
    formatLessonTime,
    formatServerResponseTime,
    generateQueryString,
    generateTimeLineQueryString,
    getData,
    getSelectedYear,
    getShowByType,
    isPoint,
    isSymbol,
    mapUtilities,
    subCatFilterLogic,
    _filterByFeatureId,
    MAX_ZOOM_MAP
} from "./utils";
import {
    getMapChangeRequest,
    mapLessonAPIRequest,
    mapLiveAPIRequest,
    mapPreviewAPIRequest,
} from "./utils/mapPreviewMode";


import lampIcon from "../../assets/imgs/lamp-icon.svg";
import PaintBar from "../../components/PaintBar/PaintBar";
import {constructSelectedItemApiParams} from "../accountPage/utils";
import * as props from "../../store/actions/geoJSONData";
import * as turf from "@turf/turf";
import {
    setMapCenterPositionST,
    setTimeLineCursorStatusST,
    setTimeLineEventDataST,
    setTimeLIneClickState,
} from "../../store/actions/mapStateAction";
import {
    dispatchFilteredLegends,
    getLegendsFromAPI,
    setFilteredLegends,
    updateLegendToggle
} from "../../store/actions/legends";
import {setMapSelectedCluster} from "../../store/actions/map";

import {changeMapStyles, setMapStylesList} from "../../store/actions/mapStyles";
import Supercluster from "supercluster";
import MapElementsPopup from "../../components/UI/MapElementsPopup";
import ReactDOMServer from 'react-dom/server';
import MapElementsPopupRadius from "../../components/UI/MapElementsPopupRadius";
import "./style.css";
import TimeLineClock from "../../assets/imgs/timeline_clock";
import {sanitizeResult} from "../../service/legends";
import axios from "axios";
import {getSpriteURLFromAPI, getSpriteFromAPI} from "../../store/actions/sprite";
import {mapItemAdapter, MapSlider} from "../../components/mapSlider";
import {
    getHeightByLngLat,
    getMapScale,
    MAP_TOOLBOX_ITEMS,
    MAP_TOOLBOX_KEYS,
    Toolbox
} from "../../components/mapToolbox";

import {setSubjectsSettings} from "../../store/actions/subjects";

import {NavLink} from "react-router-dom";
import WorldMapLoader from "../../components/UI/WorldMapLoader/WorldMapLoader";
import {
    setCompassNewAngle,
    setCompassRotate,
    setDisableCompass,
    setResetCompass
} from "../../store/actions/compassStateAction";

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN;
const queryParams = new URLSearchParams(window.location.search);

const zeroingItems = {
    toggle: true,
    menuExpanded: true,
    bounds: true,
    activeCatId: true,
    activeSubCatId: true,
    categories: true,
    allCategories: true,
    zoom: true,
    articlePopup: true,
    articleCollaps: true,
    timeLineSelectedDataItems: true,
    timeLineSelectedDataGroup: true,
    runScreenShotStates:null,
};
let initialState = {};
const debounce = (fn, wait) => {
    let timeoutID;
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...arg), wait);
    };
};
const debeounced2000 = debounce((fn) => fn(), 1000);
const debeounced500 = debounce((fn) => fn(), 500);
const debeounced10 = debounce((fn) => fn(), 10);
const debeounced1 = debounce((fn) => fn(), 1);
const debeounced100 = debounce((fn) => fn(), 100);
class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalLoading: true,
            selectedElement: null,
            markerAnimation: null,
            url: {
                lessonID: queryParams.get("lessonID"),
                topicID: queryParams.get("topicID"),
                gradeID: queryParams.get("gradeID"),
                subjectID: queryParams.get("subjectID"),
                articleID: queryParams.get("articleID"),
                preview: queryParams.get("preview"),
                type: queryParams.get("type"),
                articleReadMode: queryParams.get("articleReadMode"),
                bookmarkID: queryParams.get("bookmarkID"),
                screenShot: queryParams.get("screenShot"),
                userId: queryParams.get("userId"),
            },
            screenShotFirstLoad: true,
            superCluster: null,
            time: null,
            bounds: {},
            toggleMapMode: "all",
            // Map
            firstLayer: null,
            mapData: null,
            geoJsonData: null,
            loadFirst: true,
            defaultLayers: [],
            addedLayers: [],
            addedFeautures: [],
            firstGeoJsonPath: null,
            selectedStyleId: 1,
            timeLineChanged: false,
            lessonData: [],
            geoJsonZipData: null,
            hiddenLayarsID: {},
            nextLessonId: null,
            prevLessonId: null,
            customTimeLastData: null,
            subjectID: null,
            gradeID: null,
            currentDataIndex: 0,
            metadata:null
        };
        this.map = React.createRef();
        this.mapContainer = React.createRef();
        this.timeLine = React.createRef();
        this.timeLineRelated = React.createRef();
        this.mapUtilities = mapUtilities(this.map, this.timeLine);
        this.mapUtilities = mapUtilities(this.map, this.timeLineRelated);
        this.customLineRef = React.createRef();
        this.geojsonRef =  React.createRef();
    }
  async componentDidMount() {
    window.scrrenShot = false
    // await LogIn();
    this.props.getSpriteURLFromAPI()
        .then(url=>{
          this.props.getSpriteFromAPI(url)
        })
    const query = `?${this.state.url.type}Id=${this.state.url[`${this.state.url.type}ID`]}`
      if (!isNaN(+this.state.url.screenShot) && this.state.url.screenShot && this.state.screenShotFirstLoad && !!this.state.url.userId) {
          window.scrrenShot = true
          await this.props.getScreenShotSingleDataAPI(+this.state.url.screenShot,'MapState')
                  .then((runScreenShotStates)=>{
                      this.setState(()=>({runScreenShotStates}))
                  })
          this.setupMapFirstView(this.props.getMapStyledId || 1);
      }else{
          this.setupMapFirstView();
      }
    await HandleToken();
    this.map.current?.on('load', e =>{
      const { id, type } = determinateURLParams(this.state.url);
       const metadata = mapLiveAPIRequest(id, type)
       const legendsData = this.props.getLegendsFromAPI(query)

          Promise.all([metadata,legendsData])
              .then(values=>{
                  this.setState(()=>({metadata: values[0], legends: values[1]}))
              })
              .then(()=>{
            // if (this.state.url.preview && this.state.url.type === "article") {
            //   this.processArticlePreview(id, type, resData);
            // }else{
              this.mapLiveView(id, type,this.state.legends, null, null,null,null,this.state.metadata);
            // }
          })

    })


    }

    async componentDidUpdate() {
        if (
            !this.state.timeLineLoading &&
            !this.state.articleLoading &&
            !this.state.loadFirst &&
            this.state.globalLoading &&
            !initialState &&
            Object.keys(this.state.bounds).length > 0
        ) {
            if (!!this.state.url.lessonID) {
                if (
                    this.state.timeLineSelectedDataItems.length > 0 &&
                    this.state.timeLineSelectedDataGroup.length > 0
                )
                    // this.setState( {initialState:this.handleSaveIntial(this.state)})
                    initialState = JSON.parse(JSON.stringify(this.state));
                this.setState({globalLoading: false});

                this.timeLine.current.on("mouseDown", (event) => {
                    if (event.y > 130 || (this.state.timelineExtend && event.y > 36)) {
                        this.timeLine.current.removeCustomTime("t1");
                        this.timeLine.current.addCustomTime(event.time, "t1");
                        this.handleTimeChange(event.time);
                    } else {
                    }
                });
            } else {
                this.setState({globalLoading: false});
            }

        }

    }

    stopPinAnimation = () => {
        this.map.current?.fire('closeAnimationMarker');
        const popupELems = document.getElementsByClassName('elements-popup-radius-content')
        if (popupELems && popupELems.length > 0) {
            [...popupELems].forEach(el => {
                el.dataset.active = false
            })
        }
        this.props.setMapSelectedCluster({
            ...this.props.mapSelectedCluster,
            elementProps:null
        })
    };

  mapLiveView = async (id, type,legends,stopFly=false,onlymap=false,time,loadFeatures,resData) => {
    //petqa
      const catIds = determineCatIds(resData, type);
      if(!onlymap){
          this.setState(()=>({
              mapData: resData,
              subjectID: resData.subjectId,
              gradeID: resData.gradeId,
          }));
          if (this.state.url?.type === "lesson" && (!resData?.subjectId || !this.state.mapData?.showTimeline) ) {
              await axios.get(`${process.env.REACT_APP_HEADING_URL}/api/Topic/${resData?.topicIds?.[0]}/Language/1`)
                  .then(async (res) => {
                      this.setState({topic: res.data.data[0]})
                      await this.getSubject(resData?.subjectId || res.data.data[0].subjectId)
                      return true
                  }).then(async ()=>{
                      await this.props.setMapStylesList(this.state.subject?.setting?.mapTypes);
                  })
          }else{
              await this.getSubject(resData?.subjectId)
                  .then(async ()=>{
                      await this.props.setMapStylesList(this.state.subject?.setting?.mapTypes);
                  })
          }


          await this.props.getCategoriesFromAPI()
              .then(async(res)=> await this.props.getFilteredCategoriesFromAPI(resData, catIds, type,res))
      }
      // this.props.loadingMap(true) getById
      const subCat = {};
      const cat = {}
      this.props.categories?.forEach?.((el)=> {
        cat[el.id] = el
        el?.subCategories?.forEach?.((item)=> {
          subCat[item.id] = item
        })
      })
      const features =[]
      features.push(...resData.clusterElements.reduce((acum,el)=>{
        acum.push(
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [el.location.longitude, el.location.latitude]
              },
              properties: {
                id:el.id,
                relationType:el.lessonRelationType,
                articleIds:el.articleIds,
                name: el.displayName || 'no name ',
                yearStart:+`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                yearEnd:+`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear()}`,
                startTime:0,
                endTime:0,
                visible:'visible',
                relationFilter:'visible',
                // iconPath:subCat?.iconPath,
                iconPath:'https://cdn-icons-png.flaticon.com/512/3201/3201299.png',
                // color:subCat?.color || '#fff'
                key:  legends?.getKeyById(el?.layerId)?.key,
                subId:this.props.filteredCategories.getById(el?.articleIds[0])?.subcategory[0]?.subCategoryId,
                catId:this.props.filteredCategories.getById(el?.articleIds[0])?.categoryId,
                color: cat[this.props.filteredCategories.getById(el?.articleIds[0])?.categoryId]?.color,
                catColor:cat[this.props.filteredCategories.getById(el?.articleIds[0])?.categoryId]?.color,
                element:true,
              }
            }
        )
          return acum
      },[]))

      features.push(...resData.clusterArticles.reduce((acum,el,idx)=>{
        acum.push(
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [el.location.longitude, el.location.latitude]
              },
              properties: {
                visible:'visible',
                relationFilter:'visible',
                id:el.id,
                relationType:el.lessonRelationType,
                articleIds:[el.id],
                name: el.content[0]?.shortTitle ? el.content[0]?.shortTitle : (el.content[0]?.mainTitle || 'no name '),
                yearStart:+`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                yearEnd:+`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear() }`,
                startTime:0,
                endTime:0,
                catId:cat[el?.subcategory?.[0]?.categoryId]?.id,
                subId:subCat[`${el?.subcategory?.[0]?.subCategoryId}`]?.id,
                iconPath:subCat[`${el?.subcategory?.[0]?.subCategoryId}`]?.iconPath,
                color:subCat[`${el?.subcategory?.[0]?.subCategoryId}`]?.color || '#fff',
                key:subCat[`${el?.subcategory?.[0]?.subCategoryId}`]?.key,
                catColor:cat[el?.subcategory?.[0]?.categoryId]?.color,
                isArticle:true,
                active:idx === 0
              }
            }
        )
        return acum
      },[]))
      const index = new Supercluster({
        radius: 30,
        maxZoom: MAX_ZOOM_MAP
      }).load(features);
      this.setState({'superCluster':index})
      const sourceOptions = {
          type: 'geojson',
          data: {
              "type": "FeatureCollection",
              "features": features
          },
          cluster: true,
          clusterMaxZoom: MAX_ZOOM_MAP, // Max zoom to cluster points on
          clusterRadius: 30, // Radius of each cluster when clustering points (defaults to 50)

      }
      if(this.state.url.type !== 'article'){
          sourceOptions.filter = ['any',
              ['all',
                  ["==", ['get', 'relationFilter'], 'visible'],
                  ["==", ['get', 'visible'], 'visible'],
                  // ["==", ['get','active'], true],
                  ['any',
                      ["!=", ['get', 'startTime'], ['get', 'endTime']],
                      ['all', [">=", ['get', 'startTime'], ['get', 'yearStart']], ["<=", ['get', 'startTime'], ['get', 'yearEnd']]],
                      ['all', [">=", ['get', 'endTime'], ['get', 'yearStart']], ["<=", ['get', 'endTime'], ['get', 'yearEnd']]]
                  ]
              ],
              ["==", ['get', 'cluster'], true],
          ];
      }
        ['unclustered-text','unclustered-point','clusters','cluster-count'].forEach(layer=>{
            if(this.map.current.getLayer(layer)){
                this.map.current.removeLayer(layer)
            }
        })
        if (this.map.current.getSource('Brainograph PIN GIS API')) {
            this.map.current.removeSource('Brainograph PIN GIS API')
    }
      this.map.current.addSource('Brainograph PIN GIS API', sourceOptions);
      this.map.current.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'Brainograph PIN GIS API',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': ['get','catColor'],
          'circle-radius': 15,
          'circle-stroke-width': 1,
          'circle-stroke-color': ['get','catColor'],
        },
      });

      this.map.current.addLayer({
        id: 'unclustered-text',
        type: 'symbol',
        source: 'Brainograph PIN GIS API',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field':['get', 'name'],
          'text-font': ['GHEA Grapalat Regular'],
          'text-offset': [0, 0.9],
          'text-anchor': 'top',
          "text-size": this.props.selectedMapStyle['size'],
          "text-allow-overlap" : false,
          "text-letter-spacing":this.props.selectedMapStyle['letter-spacing'],
          // "text-allow-overlap" : true,
          // 'icon-allow-overlap': false,
          'icon-image': ['get', 'key'],
          'icon-size': 1,
          'icon-anchor': this.props.selectedMapStyle['anchor'],
          'icon-offset': [0, 0]
        },
      "paint": {
          'icon-halo-blur':1,
          'icon-halo-width':1.3,
          "text-color":this.props.selectedMapStyle['color'],
          'text-halo-blur': 1,
          'text-halo-color': this.props.selectedMapStyle['halo-color'],
          'text-halo-width': 1.3,
        }
      });

      this.map.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'Brainograph PIN GIS API',
        filter: ['all', ['has', 'point_count']],
        paint: {
          'circle-color': '#2C476C',
          //     [
          //   'step',
          //   ['get', 'point_count'],
          //   '#2C476C',
          //   10,
          //   '#f1f075',
          //   100,
          //   '#f28cb1'
          // ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15,
            10,
            20,
            40,
            30,
            100,
            35
          ]
        },
      });
      this.map.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'Brainograph PIN GIS API',
        filter: ['all',
            ['has', 'point_count'],
        ],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['GHEA Grapalat Regular'],
          'text-size': 12,
          "text-allow-overlap" : true,
        },
        paint: {
          "text-color": "#ffffff"
        }
      });
        if(!onlymap) {
        const getAllClusters = () => {
            var bounds = this.map.current.getBounds();
            var zoom = Math.floor(this.map.current.getZoom());
            var clusters = this.state.superCluster.getClusters([-180, -90, 180, 90], zoom);
            return clusters;
        }
        // #725a4f

        let _thisMapCurrent = this.map.current
        const layers = this.map.current
            .getStyle()
            .layers
            .map((l) => l.id)
            layers.forEach(el => {
            this.map.current.on('click', el, e => debeounced10(()=>{
                if (!e.point || this.props.getRulerClickedState) return;
                const allFeatures = _thisMapCurrent.queryRenderedFeatures(e.point)
                const features = _thisMapCurrent.queryRenderedFeatures(e.point, {layers: [el]});
                if (allFeatures[0].properties.cluster) {
                    const clusters = this.map.current.queryRenderedFeatures(e.point, {layers: ["clusters"]});
                    const lng = e.lng || e.lngLat.lng;
                    const lat = e.lat || e.lngLat.lat;
                    const cluster = clusters.find(item => item.geometry?.coordinates?.[0] === lng && item.geometry?.coordinates?.[1] === lat) || clusters[1]
                    const clusterId = cluster?.properties.cluster_id;


                    this.map.current.getSource("Brainograph PIN GIS API")
                        .getClusterExpansionZoom(
                            clusterId,
                            (err, zoom) => {
                                this.props.setMapSelectedCluster({
                                    lng,
                                    lat,
                                })
                                if (err) {
                                    this.map.current.getSource("Brainograph PIN GIS API")
                                        .getClusterChildren(clusterId, (error, features) => {
                                            if (!error) {
                                                this.generMarker(cluster.geometry.coordinates, features, zoom,clusterId)
                                            }
                                        });

                                    return
                                }
                                ;
                                if (zoom <= MAX_ZOOM_MAP) {
                                    return this.map.current.easeTo({
                                        center: {lng, lat},
                                        zoom: zoom + 0.1
                                    });
                                }
                                this.map.current.getSource("Brainograph PIN GIS API")
                                    .getClusterChildren(clusterId, (error, features) => {
                                        if (!error) {
                                            this.generMarker(cluster.geometry.coordinates, features, zoom,clusterId)
                                        }
                                    });

                                return
                            });
                    return
                }
                if (allFeatures[0].layer.id === "unclustered-text" || features[features.length - 1].layer.id === "unclustered-point") {
                    const unclusterPoint = this.map.current.queryRenderedFeatures(e?.point, {layers: ["unclustered-point"]});

                    const markerInfo = {
                        id: unclusterPoint[1].properties.id,
                        cordinates: unclusterPoint[1].geometry.coordinates,
                        pointCount: 0,
                        articleIds: JSON.parse(unclusterPoint[1].properties.articleIds),
                        catColor: unclusterPoint[1].properties?.catColor
                    }
                this.generAnimationMarker(markerInfo)
                this.setState({'selectedElement': unclusterPoint[1]})
                return
            }
                const feature = allFeatures.find(el=> 'articleids' in el?.properties)
                if (feature) {
                    this.props.getArticleFromAPI(feature?.properties.articleids)
                }
            })
        )
      })
      this.map.current.on("zoom", (e) => {
          const maxPitch = this.map.current.getMaxPitch()
          if (this.map.current.getZoom() >= 5) {
              if(!maxPitch){
                  this.map.current.setMaxPitch(45)
                  this.props.setDisableCompass(false)
              }

          } else {
              if (this.map.current.getPitch() !== 0) {
                  if(maxPitch){
                      this.map.current.setPitch(0)
                      this.map.current.compassTilt3D()
                  }
              }
              if(maxPitch) {
                  this.map.current.setMaxPitch(0)
                  this.props.setDisableCompass(true)
              }
          }

          if (this.state.selectedElement) {
            debeounced10(() => {
                const unclusterPoint = this.map.current.querySourceFeatures("Brainograph PIN GIS API", {
                    filter: ['==', ['get', 'id'], this.state.selectedElement.properties?.id]
                })
                if (unclusterPoint?.length > 0) {
                    if (
                        (+this.state.selectedElement.geometry.coordinates[0].toFixed(0) === +unclusterPoint[1].geometry.coordinates[0].toFixed(0))
                        && (+this.state.selectedElement.geometry.coordinates[1].toFixed(0) === +unclusterPoint[1].geometry.coordinates[1].toFixed(0))
                    ) {
                        const markerInfo = {
                            id: unclusterPoint[1].properties.id,
                            cordinates: unclusterPoint[1].geometry.coordinates,
                            pointCount: 0,
                            articleIds: unclusterPoint[1].properties.articleIds,
                            catColor: unclusterPoint[1].properties.catColor
                        }
                        this.generAnimationMarker(markerInfo)
                    }
                } else {
                    const features = getAllClusters()
                    let dx = this.state.selectedElement?.geometry?.coordinates[0]
                    let dy = this.state.selectedElement?.geometry?.coordinates[1]
                    const markerInfo = features
                        ?.reduce((acum, el) => {
                            if (!acum?.id) {
                                acum.id = el.id
                                acum.cordinates = el.geometry.coordinates
                                acum.pointCount = el.properties.point_count || 0
                            } else {
                                let acumX = (dx - acum?.cordinates[0]);
                                let acumy = (dy - acum?.cordinates[1]);
                                let elX = (dx - el.geometry?.coordinates[0]);
                                let ely = (dy - el.geometry?.coordinates[1]);
                                let acumRadius = Math.sqrt(acumX * acumX + acumy * acumy);
                                let elRadius = Math.sqrt(elX * elX + ely * ely);
                                if (acumRadius > elRadius) {
                                    acum.id = el.id
                                    acum.cordinates = el.geometry.coordinates
                                    acum.pointCount = el.properties.point_count || 0
                                }
                            }
                            return acum
                        }, {})
                    this.generAnimationMarker(markerInfo)
                }
            });
        }
        const currentZoom = this.map.current.getZoom();
        debeounced500(() => {
            this.props.setMapZoom(currentZoom);
        });
    })
      this.props.getLampInfo(resData, type);
      const {startFrom, endTo} = formatServerResponseTime(resData);
            this.setState({

                        mapData: resData,
                        subjectID: resData.subjectId,
                        gradeID: resData.gradeId,
                    });

              this.processTimeLineRendering(resData, type, false);
              if(this.state.url.type === 'article') {
                  this.props.getArticleFromAPI(this.state.url.articleID, false)
              }
              if(this.state.url.type !== 'article') {
                this.props.getQuizInfo(type, id)
                }
                  if (resData?.clusterBounds?.coordinates) {
                      setTimeout(()=>{
                          const padding = 0.1
                          const bboxMap = turf.bbox(turf?.[resData?.clusterBounds?.type.toLowerCase()](resData?.clusterBounds?.coordinates))

              // Calculate the dimensions of the bbox
              const width = bboxMap[2] - bboxMap[0];
              const height = bboxMap[3] - bboxMap[1];
              const bbox1 = bboxMap[0] - (width * padding)
              const bbox2 = bboxMap[1] - (height * padding)
              const bbox3 = bboxMap[2] + (width * padding)
              const bbox4 = bboxMap[3] + (height * padding)
              const paddedBounds = [
                  bbox1 > 180 || bbox1 < -180 ? bboxMap[0] : bbox1,
                  bbox2 > 90 || bbox2 < -90 ? bboxMap[1] : bbox2,
                  bbox3 > 180 || bbox3 < -180 ? bboxMap[2] : bbox3,
                  bbox4 > 90 || bbox4 < -90 ? bboxMap[3] : bbox4
              ];
              this.map.current.fitBounds(paddedBounds);
              this.props.setMapBounce(paddedBounds);
          },700)
          }
    }
        setTimeout(()=> {
            this.setState({globalLoading: false});
        },500)
        if(onlymap) {
            // console.log(time,'time')
            // this.changeClusterItemsTime(time)
            // if (this.props.filteredCategories?.length) {
            //     this.props.filteredCategories.forEach((item) => {
            //         if (!item.show) {
            //             this.toggleItemFromMap(item.id, "category", item.show);
            //         }
            //     })
            // }
            const gisApiSources = this.map.current.getStyle()?.sources['Brainograph PIN GIS API']
            if (!gisApiSources) return;
            gisApiSources.data.features = loadFeatures
            this.map.current.getSource('Brainograph PIN GIS API')._updateWorkerData()

                }
                if (this.state.url.screenShot && this.state.screenShotFirstLoad && !!this.state.url.userId) {
            const statesST = ['mapSTF','toolBarSTF','compassSTF',]
            this.state?.runScreenShotStates?.(statesST)
        }
  };

    setLampModalDataAndState = (time, isScreenShot) => {
        const timeYear = Math.abs(new Date(time).getFullYear());

        let data = this.props.lampData.filter(
            (item) => {
                if (!item?.timeEnd?.isBc &&
                    !item?.timeStart?.isBc &&
                    timeYear - 5 <= Math.abs(item.timeEnd.year) &&
                    timeYear + 5 >= Math.abs(item.timeStart.year)) {
                    return true
                } else if (
                    item?.timeEnd?.isBc &&
                    item?.timeStart?.isBc &&
                    timeYear + 5 >= Math.abs(item.timeEnd.year) &&
                    timeYear - 5 <= Math.abs(item.timeStart.year)
                ) {
                    return true
                } else {
                    return false
                }
            }
        );
        this.props.setLampModalData(data);

        if (isScreenShot) {
            this.props.setLampModalState(this.props.lampModalState);
            return;
        }
        this.props.setLampModalState(data.length > 0);
        this.props.setLampModalStateIndex(0)

    };

    handleTimeChange = (time, isScreenShot) => {
        this.map.current?.fire('closeAllPopups');
        this.map.current?.fire('closeAnimationMarker');
        let _setTime = time.toString().split('-')
        if (_setTime.length > 1) {
            _setTime = _setTime.join('B.C. ')
        } else {
            _setTime = _setTime.join('')
        }
        this.timeLine.current.setCustomTimeTitle(_setTime, "t1");
        this.setState({
            time: time,
        });
        this.setLampModalDataAndState(time, isScreenShot);
        const year = getSelectedYear(time, this.timeLine);
        const newObj = generateTimeLineQueryString(
            year - 1,
            this.state.url,
            this.props.selectedLesson,
            this.state.mapData?.id
        );
        const _doesExist = (item) => this.map.current.getLayer(item.id);
        const _removeLayersFromMap = (item) => {
            this.map.current.removeLayer(item.id);
            if (document.getElementById(item.id)) {
                document.getElementById(item.id).style.visibility = "hidden";
            }
        };

        if (Object.keys(newObj).length !== 0) {
            const queryString = Object.keys(newObj)
                .map((key) => key + "=" + newObj[key])
                .join("&");
            const tileUrl = new URL(this.map.current.getStyle()
                .sources["Brainograph GIS API"].tiles[0]);
            this.map.current.getSource("Brainograph GIS API")
                .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?${queryString}`]);
        }
    };

    // Start
    // TimeLine rendering
    processTimeLineRendering = async (data, type, fromTopic) => {
        if (type === "lesson") {
            if (fromTopic === false && this.props.selectedLesson === null) {
                this.props.setSelectedLesson(this.state.url.lessonID);
            }
            this.props.setMiddleMaxTimeLineLessonsData(data);
            this.props.setMiddleTimeLineLessonsData(data);
            this.props.setTimeLineLessonData(
                data,
                this.state.mapData.id,
                data.id,
                data.articles,
                fromTopic,
                this.props.setTopicId
            );

        }
        if (type === "topic") {
            await this.props.setTimeLineTopicsData(data, type);
            await this.props.setMiddleTimeLineTopicsData(data);
        }
        if (type === "article") {
            this.props.setTimeLineArticlesData(data, type);
            this.props.setMiddleTimeLineLessonsData(data);
        }
    };

    onMouseEnterScrollZone = () => {
        const scrollZone = document.getElementsByClassName('scroll_and_line_change')[0];
        this.timeLine.current.on("timechange", (properties) => {
            const eventPopover = document.getElementById('event-popover')
            const eventIcon = document.getElementById('event-icon')
            if (eventIcon) eventIcon.remove()
            if (eventPopover) {
                eventPopover.dataset.timeYear = properties.time.getFullYear() < 0 ? `Ք.ա. ${Math.abs(properties.time.getFullYear())}` : properties.time.getFullYear()
            } else {
                const popover = document.createElement('span')
                popover.className = 'event-popover'
                popover.id = 'event-popover'
                popover.dataset.timeYear = properties.time.getFullYear() < 0 ? `Ք.ա. ${Math.abs(properties.time.getFullYear())}` : properties.time.getFullYear()
                document.getElementsByClassName('t1')[0].appendChild(popover)
            }
            const scrollZoneParams = scrollZone.getBoundingClientRect();
            const customTimeLineParams = this.customLineRef?.current?.getBoundingClientRect();
            if (customTimeLineParams?.x + customTimeLineParams?.width >= scrollZoneParams.x) {
                const ms = new Date(properties.time).getTime();
                const changeMs = new Date(ms - 4.154e10);
                this.timeLine.current.removeCustomTime("t1");
                this.timeLine.current.addCustomTime(changeMs, "t1");
                this.timeLine.current.redraw();
                this.handleTimeChange(properties.time);
                const customBar = document.querySelector(".t1");
                this.customLineRef.current = customBar;
            }
        });
        // this.timeLine.current.on("panmove", () => {
        //     const {start, end} = this.timeLine.current.getWindow();
        //     if (start) {
        //         this.timeLineRelated.current.setWindow(start, end, {animation: false});
        //     }
        // });
        this.timeLine.current.on("rangechange", () => {
            const {start, end} = this.timeLine.current.getWindow();
            if (start) {
                this.timeLineRelated.current.setWindow(start, end, {animation: false});
            }
        });
        this.timeLineRelated.current.on("rangechange", () => {
            const {start, end} = this.timeLineRelated.current.getWindow();
            if (start) {
                this.timeLine.current.setWindow(start, end, {animation: false});
            }
        });
        // this.timeLineRelated.current.on("panmove", () => {
        //
        //     const {start, end} = this.timeLineRelated.current.getWindow();
        //     if (start) {
        //         this.timeLine.current.setWindow(start, end, {animation: false});
        //     }
        // });

        // this.timeLine.current.on("mousewheel", () => {
        //     const {start, end} = this.timeLine.current.getWindow();
        //     if (start) {
        //         this.timeLineRelated.current.setWindow(start, end, {animation: false});
        //         this.timeLine.current.setWindow(start, end, {animation: false});
        //     }
        // })
        this.timeLine.current.on("mouseUp", () => {
            const customBar = document.querySelector(".t1");
            this.customLineRef.current = customBar;
        });
    };

     processSelectedWhateverView = (data) => {
    this.onMouseEnterScrollZone();
    this.timeLine.current.on("click", this.handleTimeLineClickAction);
    this.timeLineRelated.current.on("click", this.handleTimeLineClickAction);
    this.setCurrentOnTimeline(data);
    this.setTimeLineOptions(data);
    this.reactToTimeChange();

    // this.props.getMapStyledId === 2 &&
    //   setTimeout(() => {
    //     // this.props.changeMapStyles(1);
    //     setTimeout(() => {
    //       this.props.changeMapStyles(2);
    //     }, 0);
    //   }, 0);
  };

    setTimeLineOptions = (data) => {
        this.timeLine.current.setOptions?.({
            start: data.start,
            end: data.end,
            min: data.start,
            max: data.end,
        });
        this.timeLineRelated.current.setOptions?.({
            start: data.start,
            end: data.end,
            min: data.start,
            max: data.end,
        });
    };
    filterLegend = () =>{
        this.filterFeatuers()
        debeounced500(()=>this.filtredLegendsByViewport(this.props.legendsData))
    }
    onMapRenderComplete  = (map, fn,i=0) => {
        if(i >= 500) return new Error('RENDER COUNT IS MUCH')
        if (map.isStyleLoaded()) {
            debeounced100(() => process.nextTick(fn))
            if(i !== -1) debeounced500(() => this.onMapRenderComplete(map, fn, -1))
            return
        }
        map.once('data', () => this.onMapRenderComplete(map, fn, ++i))
    }
    filterLegendsViewport = ()=> this.filtredLegendsByViewport(this.props.legendsData)

    reactToTimeChange = () => {

        this.timeLine.current.redraw();
        this.timeLineRelated.current.redraw();
        this.timeLine.current.on("timechanged", (properties) => {
            let time = properties.time;
            const activeEvent = this.props.timeLineItems?.getItemByDateRange?.(this.timeLine.current?.timeAxis?.step?.step || 0, time)
            if (activeEvent.length > 0) {
                time = activeEvent[0].start
            }
            this.timeLine.current.removeCustomTime('t1')
            this.timeLine.current.addCustomTime(time, 't1')

            this.setLampModalDataAndState(time);
            this.handleTimeChange(time);
            setTimeout(() => {
                const eventIcon = document.getElementById('event-icon')
                const customTimeLine = document.querySelector(".t1")
                const timeLineClock = document.querySelector(".time-line-clock")
                if (!timeLineClock) {
                    const rootElement = document.createElement("div")
                    rootElement.className = 'time-line-clock'
                    ReactDOM.render(<TimeLineClock/>, rootElement);
                    customTimeLine?.appendChild(rootElement)
                }
                const customTimeLineParams = customTimeLine?.getBoundingClientRect();
                document.querySelector('.iconic-clock-minute-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left * 6},192,192)`);
                document.querySelector('.iconic-clock-hour-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left},192,192)`);

                if (eventIcon) {
                    eventIcon.remove()
                }
                if (activeEvent.length > 0) {
                    const icon = document.createElement('i')
                    icon.className = 'event-icon' + ' ' + activeEvent[0]?.elementType
                    icon.id = 'event-icon'
                    customTimeLine.appendChild(icon)
                }

                debeounced100(()=>this.onMapRenderComplete(this.map.current,this.filterLegend))

            }, 0)

        });
    };

  setCurrentOnTimeline = (data) => {
    // if (this.state.url.type !== 'article' && !this.state.mapData?.showTimeline) return;

    const customDate = data.start
    const customBar = document.querySelector('.t1')
    this.customLineRef.current = customBar
    if(!this.props.selectedLesson){
      customBar === null &&  this.timeLine.current.addCustomTime(null, 't1')
      customBar !== null && this.timeLine.current.setCustomTime(customDate, 't1')
    }else{
      customBar === null
          ? this.timeLine.current.addCustomTime(customDate, 't1')
          : this.timeLine.current.setCustomTime(customDate, 't1')
    }
  }
  // End

    // Start
    // Process layers to the map
    addArticleLayer = (resData) => {
        getData(resData.cordinates[0].path).then((data) => {
            this.setState({defaultLayers: data.layers});
            this.map.current.addSource(resData.id, {
                type: "geojson",
                data: resData.cordinates[0].path,
            });
            const _addLayer = (layer) => this.map.current.addLayer(layer);

            const _addSymbol = addSymbol(
                this.props.categories,
                this.map.current,
                this.props.getArticleFromAPI
            );

            data.layers.forEach(_addLayer);
            data.layers.forEach(_addSymbol);

            if (data.features[0].geometry.type === "Point") {
                addArticlePoint(this.map.current, data);
            }
            const center = data?.center
                ? data?.center[0] !== "NaN"
                    ? data?.center
                    : [0, 0]
                : [0, 0];
            let zoom = this.state.mapData?.zoom ? this.state.mapData?.zoom : null;
            this.map.current.flyTo({
                center: center,
                zoom: zoom,
            });
        });


    };

    addAllOnMap = async (sources) => {
        const hiddItemsByCategory = [];
        getGeoJsonDataFromStorage(
            sources.geoJsonPath,
            sources.type,
            sources.id
        ).then(async (data) => {
            this.props.loadingMap(false);
            this.props.setTimeLIneClickState("all");

            setTimeout(async () => {
                if (data.layers.length > 0) {
                    this.setState({
                        firstGeoJsonPath: sources.geoJsonPath,
                        addedFeautures: data.features,
                        geoJsonZipData: data,
                    });
                    await this.addSourcesToMap(sources, data);

                    const firstLayerID = data.layers.find((x) => x.id.includes("point"));
                    this.setState({firstLayer: firstLayerID?.id});

                    const _existOnlyPins = (item) => item.id.includes("point");
                    const _doesNotExistIn = (layers) => (item) => {
                        let findDataInMap = layers?.find((x) => x.id === item.id);
                        return findDataInMap === undefined;
                    };

                    const addToMap = data.layers
                    addToMap.forEach(el => {
                        if (
                            document.getElementById(`category_filters_icon_${el.ArticleId}`)?.dataset?.show === 'false'
                            || document.getElementById(`main__subitem-label_lamp_${el.ArticleId}`)?.dataset?.show === 'false'
                        ) hiddItemsByCategory.push(el.id)
                    })
                    // .filter(_existOnlyPins)
                    // .filter(_doesNotExistIn(this.state.geoJsonData?.layers));

                    const removeFromMap = this.map.current
                        .getStyle()
                        .layers.filter((x) => x.type !== "background")
                        .filter((x) => x.source !== "composite");

                    if (this.props.timeLineItems.length >= 3) {
                        document.querySelector(".vis-content").style.paddingBottom = "40px";
                    }

                    if (removeFromMap.length > 0) {
                        //edit for GIS
                        // await this.removeLayerFromMap(removeFromMap);
                    }

                    if (addToMap.length > 0) {
                        this.setState({defaultLayers: addToMap});
                        //edit for GIS
                        // this.addLayerToMap(addToMap, data);
                    }
                    this.handleRequirements()
                    this.makeMapChanges(hiddItemsByCategory, false, true)
                    if (this.state.url.type !== "article") {
                        setTimeout(() => {
                            const bounds = new mapboxgl.LngLatBounds(
                                data.centers[0],
                                data.centers[0]
                            );
                            for (const cord of data.centers) {
                                bounds.extend(cord);
                            }
                            this.props.setMapBounce(bounds);
                            this.map.current.fitBounds(bounds, {
                                maxZoom: MAX_ZOOM_MAP-1,
                                padding: {top: 40, bottom: 200, left: 40, right: 40},
                                duration: 3000
                            });
                        }, 500);
                    }
                    this.setState({
                        geoJsonData: data,
                        loadFirst: false,
                    });
                }
            }, 200)
        });
    };

    // addOnlyThisTimeLayers = async (sources, time) => {
    //     const type =
    //         this.props.selectedLesson !== null ? "lesson" : this.state.url.type;
    //     const id =
    //         this.props.selectedLesson !== null
    //             ? this.props.selectedLesson
    //             : this.state.mapData.id;
    //     const hiddItemsByCategory = [];
    //     this.props
    //         .getTimeGeoJsonDataFromStorage(sources.geoJsonPath, type, id, time)
    //         .then(async (data) => {
    //             this.props.setTimeLIneClickState(!this.props.getTimeLineClickState);
    //             if (data.layers.length > 0) {
    //                 if (this.map.current.getSource(sources.id)) {
    //                     this.map.current.getSource(sources.id).setData(data);
    //                 } else {
    //                     this.map.current.addSource(sources.id, {
    //                         type: "geojson",
    //                         data: data,
    //                     });
    //                 }
    //                 const doesNotExistIn = (layers) => (oldItem) => {
    //                     let findDataInMap = layers.find((x) => x.id === oldItem.id);
    //                     return findDataInMap === undefined;
    //                 };
    //                 const initialStyles = {
    //                     "line-color": "#10A378",
    //                     "line-opacity": 1,
    //                     "text-color": "#2C476C",
    //                     "fill-color": "#10A378",
    //                     "fill-opacity": 1,
    //                 };
    //
    //                 function _setPaintStyles(styles) {
    //                     return Object.keys(styles).reduce((acum, el) => {
    //                         acum[el] = styles[el] !== null ? styles[el] : initialStyles[el];
    //                         return acum;
    //                     }, {});
    //                 }
    //
    //                 const addToMap = data.layers.map(el => {
    //                     el.paint = _setPaintStyles(el.paint)
    //                     if (document.getElementById(`category_filters_icon_${el.ArticleId}`)?.dataset?.show === 'false'
    //                         || document.getElementById(`main__subitem-label_lamp_${el.ArticleId}`)?.dataset?.show === 'false') hiddItemsByCategory.push(el.id)
    //                     return el
    //                 })
    //                 const removeFromMap =
    //                     this.map.current.getStyle().layers.filter(x => x.source === `${sources.id}`)
    //
    //
    //                 if (removeFromMap.length > 0) {
    //                     await this.removeLayerFromMap(removeFromMap);
    //                 }
    //
    //                 if (addToMap.length > 0) {
    //                     this.setState({addedLayers: addToMap});
    //                     await this.addLayerToMap(addToMap, data);
    //                 }
    //             }
    //             this.props.loadingMap(false);
    //             this.handleRequirements();
    //             this.makeMapChanges(hiddItemsByCategory, false, true);
    //         });
    // };
    addSourcesToMap = async (id, data) => {
        if (this.map.current?.getSource(id)) {
            this.map.current.getSource(id).setData(`${process.env.REACT_APP_GIS_URL}/MapboxStyles/1?${this.state.url.type}Id=${this.state.url[`${this.state.url.type}ID`]}`);

        } else {
            this.map.current.addSource(id, {
                type: "geojson",
                url: `${process.env.REACT_APP_GIS_URL}/MapboxStyles/1?${this.state.url.type}Id=${this.state.url[`${this.state.url.type}ID`]}`,
            });
        }
    };

    removeLayerFromMap = async (data) => {
        data.forEach((item) => {
            if (this.map.current.getLayer(item.id)) {
                this.map.current.removeLayer(item.id);
            }
            if (document.getElementById(item.id)) {
                document.getElementById(item.id).remove();
            }
        });
    };

    addLayerToMap = async (data, response) => {
        const _addPoint = addPoint(
            this.map.current,
            this.props.getArticleFromAPI,
            response,
            this.props.lampData
        );
        const _addSymbol = addSymbol(
            this.props.categories,
            this.map.current,
            this.props.getArticleFromAPI
        );
        const _addAndPositionLayer = addAndPositionLayer(
            this.map.current,
            this.state.loadFirst,
            this.state.firstLayer
        );
        const _getExistingLayers = (item) => document.getElementById(item.id);
        data.forEach(_addAndPositionLayer);
        data.filter(isSymbol).forEach(_addSymbol);
        data.filter(isPoint).forEach(_addPoint);

        this.map.current.on("zoom", () => {

            const currentZoom = this.map.current.getZoom();
            debeounced2000(() => {
                this.props.setMapZoom(currentZoom);
            });
            data.filter(_getExistingLayers).forEach((item) => {
                if (currentZoom < item.minzoom) {
                    document.getElementById(item.id).style.visibility = "hidden";
                } else {
                    document.getElementById(item.id).style.visibility =
                        this.map.current.getLayer(item.id)?.visibility;
                }
            });
        });
    };
    // End

    // Start
    // Functions where component did update
    componentDidUpdate(prevProps, prevState) {
        const customBar = document.querySelector(".t1");
        this.customLineRef.current = customBar;
        if (
            !this.state.timeLineLoading
            && !this.state.articleLoading
            && this.state.loadFirst
            && !this.state.globalLoading
            && !initialState
            // && Object.keys(this.state.bounds).length > 0
        ) {
            // if (
            //     this.state.timeLineSelectedDataItems?.length > 0 &&
            //     this.state.timeLineSelectedDataGroup?.length > 0
            // ){
                this.setState({loadFirst: false})

                // this.setState( {initialState:this.handleSaveIntial(this.state)})
                initialState = JSON.parse(JSON.stringify(this.state));
                // this.setState({globalLoading: false});

                // this.timeLine.current.on("mouseDown", (event) => {
                //     if (event.y > 130 || (this.state.timelineExtend && event.y > 36)) {
                //         this.timeLine.current.removeCustomTime("t1");
                //         this.timeLine.current.addCustomTime(event.time, "t1");
                //         this.handleTimeChange(event.time);
                //     } else {
                //     }
                // });
            // }

        }
        if(prevProps.getScreenShotLoadingST !== this.props.getScreenShotLoadingST && this.state.url.screenShot){
            this.setState({globalLoading: this.props.getScreenShotLoadingST});
        }

        if(+this.state.url.userId === +this.props.user?.data?.id){
            if(!this.props.getShowNotificationST && !this.props.getScreenShotLoadingST && this.state.url.screenShot && this.state.screenShotFirstLoad){
                const statesST = ['checkSettingsSTF']
                this.state?.runScreenShotStates?.(statesST)
            }
        }
        if (this.props.getSlidesSelectedSlideData?.id !== prevProps.getSlidesSelectedSlideData?.id) {
            this.handleChangeToInitialState()
        }
        if (
            prevState.timeLineChanged === true &&
            this.state.timeLineChanged === false
        ) {
            let body = {
                type: this.state.url.type,
                id: this.state.mapData.id,
                geoJsonPath: `${this.state.mapData.geoJsonPath}.gz`,
            };

            this.setState({
                time: null,
            });
            // this.addAllOnMap(body);
            let type = this.props.selectedLesson !== null ? 'lesson' : this.state.url.type
            const newObj = {}
            if (type === 'lesson') {
                newObj.lessonId = this.props.selectedLesson !== null ? this.props.selectedLesson : this.state.mapData.id
            }
            if (type === 'topic') {
                newObj.topicId = this.state.mapData.id
            }
            if (type === 'article') {
                newObj.articleId = this.state.mapData.id
            }
            const queryString = Object.keys(newObj)
                .map((key) => key + "=" + newObj[key])
                .join("&");
            const tileUrl = new URL(this.map.current.getStyle()
                .sources["Brainograph GIS API"].tiles[0]);
            this.map.current.getSource("Brainograph GIS API")
                .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?&${queryString}`]);
        }
        if (
            prevProps.selectedMapStyle &&
            prevProps.selectedMapStyle !== this.props.selectedMapStyle
        ) {
            this.handleMapStyleChange();
        }
        if (
            prevProps.selectedMapStyleDark &&
            prevProps.selectedMapStyleDark !== this.props.selectedMapStyleDark
        ) {
            this.handleMapStyleChange();
        }
        if (
            prevProps.selectedArticle &&
            prevProps.selectedArticle !== this.props.selectedArticle &&
            !this.props.selectedArticle?.fly
        ) {
            // this.startPinAnimation(`article_${this.props.selectedArticle.id}`);
            this.handleFlyToObject();
        }
        if (this.state.url.screenShot && prevProps.mapZoom !== this.props.mapZoom && this.state.loadFirst
            || prevProps.mapZoom !== this.props.mapZoom && this.props.mapZoomToAction) {
            this.changeMapZoom(this.props.mapZoom);
            if (this.props.mapSelectedCluster) {
                this.props.setMapSelectedCluster({
                    elementProps:this.props.mapSelectedCluster.elementProps
                })
            }
        }
        if (this.props.mapSelectedCluster?.forScreenShoot && !prevProps.mapSelectedCluster && JSON.stringify(prevProps.mapSelectedCluster) !== JSON.stringify(this.props.mapSelectedCluster)) {
            this.triggerClickMapCluster(this.props.mapSelectedCluster);
            this.setState({
                loadFirst: false,
            });
        }
        if (
            prevProps.selectedMapRequirement &&
            prevProps.selectedMapRequirement !== this.props.selectedMapRequirement
        ) {
            this.handleRequirements();
        }
        if (
            prevProps.timeLineLoading !== this.props.timeLineLoading
            && this.props.timeLineItems.length > 0
        ) {
            this.processSelectedWhateverView(this.props.timeLineItems[0]);
        }
        // if (
        //     prevProps.selectedLesson === null &&
        //     this.props.selectedLesson !== null
        // ) {
        //     this.props.loadingMap(true);
        //     this.processLessonMode(this.props.selectedLesson);
        // }
        if (
            !this.props.mapIsLoading &&
            !this.props.timeLineLoading &&
            Object.keys(this.props.selectedArticle).length === 0 &&
            this.state.url?.bookmarkID
        ) {
            const _this = this;
            setTimeout(() => {
                _this.showArticleReadMoreSection();
            }, 2000);
        }

        if (this.state.mapData?.subjectId && prevState.mapData?.subjectId !== this.state.mapData?.subjectId) {
            if (this.state.url?.type === 'topic') {
                this.getTopicsData(this.state.mapData?.gradeId);
            }
        }

        if (this.state.mapData?.topicId && prevState.mapData?.topicId !== this.state.mapData?.topicId) {
            if (this.state.url?.type === 'lesson') {
                this.getLessonsData(this.state.mapData?.topicIds?.[0]);
            }
        }

        if (this.state.url?.type === 'topic' && prevProps.topicsIsLoading && prevProps.topicsIsLoading !== this.props.topicsIsLoading) {
            this.setState({
                currentDataIndex: this.props.topics.findIndex((x) => x.id === this.state.mapData?.id),
            })
        }

        if (this.state.url?.type === 'lesson' && prevProps.lessonsIsLoading && prevProps.lessonsIsLoading !== this.props.lessonsIsLoading) {
            this.setState({
                currentDataIndex: this.props.lessons?.data?.findIndex((x) => x.id === this.state.mapData?.id),
            })
        }




    }

    triggerClickMapCluster = (selectedCluster) => {

       setTimeout(() => {
           const coords = {
               lat: selectedCluster.lat,
               lng: selectedCluster.lng,
           }
           if(selectedCluster.lat && selectedCluster.lng){
               this.map.current.fire("click", {
                   ...coords,
                   point:this.map.current.project(coords)
               })
           }

           if (selectedCluster.elementProps) {
               setTimeout(() => {
                   const {item, cords, count} = selectedCluster.elementProps
                   this.selectElement(item, cords, count, true);
               }, 500)
           }
       },2500)
    }

    selectElement = (item, cords, count, isScreenShoot) => {
        const popupELems = document.getElementsByClassName('elements-popup-radius-content')
        if (popupELems && popupELems.length > 0) {
            [...popupELems].forEach(el => {
                el.dataset.active = false
            })
        }
        const markerInfo = {
            id: item.properties.id,
            cordinates: cords,
            pointCount: count,
            articleIds: item.properties.articleIds,
            catColor:null,
            flyTo:false
        }
        this.generAnimationMarker(markerInfo)
        this.setState({'selectedElement': item})
        document.getElementById(`element-${item.id || item?.properties?.id}`).dataset.active = true
    }


    handleRequirements = () => {
        this.map.current.fire('closeAllPopups');
        this.map.current.fire('closeAnimationMarker');
        const type = this.props.selectedMapRequirement;
        const data = this.map.current.getStyle()?.layers
        const types = {
            'Mandatory': 1,
            'Additional': 2,
            'All': 3
        }

        function getOptionsFromBitmask(bitmask) {
            let options = [];

            if ((bitmask & 1) !== 0) {
                options.push("Mandatory");
            }

            if ((bitmask & 2) !== 0) {
                options.push("Additional");
            }
            return options;
        }

        let source = [...(this.map.current.getStyle().sources['Brainograph PIN GIS API']?.data.features || [])]
        source.map(el => {
            if (el.properties.relationType) {
                el.properties.relationFilter = 'visible'
                if (types[type] && !getOptionsFromBitmask(el.properties.relationType).includes(type)) {
                    el.properties.relationFilter = 'none'
                }

            }
            return el
        });
        this.map.current.getSource('Brainograph PIN GIS API')?._updateWorkerData(source)
        // if (data?.length > 0) {
        //     data.forEach((layer) => {
        //         if (layer.source === "Brainograph GIS API") {
        //             const filterOptions = []
        //             let flag = true
        //             this.map.current.getFilter(layer.id).forEach(filter => {
        //                 if (filter[1] !== 'relationType') filterOptions.push(filter)
        //                 if (filter[1] === 'point_count') flag = false
        //             });
        //             if (types[type] && flag) filterOptions.push(['any', ['==', 'relationType', types[type]], ['!', ['has', 'relationType']]])
        //             this.map.current.setFilter(layer?.id, filterOptions)
        //         }
        //     });
        // }

    };

    handleFlyToObject = async () => {
        let fly = true;
        const flyingObject = this.props.selectedArticle;
        const url = `${process.env.REACT_APP_GIS_URL}/Articles/${flyingObject.id}/Elements/Geometries/Bounds`;
        axios.get(url)
            .then(resp => turf.bbox(resp.data))
            .then(resp => {
                this.map.current.syncMapAndCompassStart()
                this.map.current.on('idle',this.map.current.syncMapAndCompassEnd)
                this.map.current.fitBounds(resp, {padding: 2})
            })
    }

    handleMapStyleChange = async () => {
        this.setState({globalLoading: true});
        this.geojsonRef.current =  {
            type: "FeatureCollection",
            features: [],
        }
        const time = this.state.time
        const gisApiSources = this.map.current.getStyle()?.sources['Brainograph PIN GIS API']
        let type = this.props.selectedLesson !== null ? 'lesson' : this.state.url.type
        // const newObj = {}
        // if (type === 'lesson') {
        //     newObj.lessonId = this.props.selectedLesson !== null ? this.props.selectedLesson : this.state.mapData.id
        // }
        // if (type === 'topic') {
        //     newObj.topicId = this.state.mapData.id
        // }
        // if (type === 'article') {
        //     newObj.articleId = this.state.mapData.id
        // }
        // const queryString = Object.keys(newObj)
        //     .map((key) => key + "=" + newObj[key])
        //     .join("&");
        // const tileUrl = new URL(this.map.current.getStyle().sources["Brainograph GIS API"].tiles[0]);
        // this.map.current.getSource("Brainograph GIS API")
        //     .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}${tileUrl.search}&${queryString}`]);

        const selectedMapStyle = this.props.selectedMapStyle.active
        const styleId = this.props.selectedMapStyle[selectedMapStyle]
        this.props.getSpriteURLFromAPI(styleId)
            .then(url => {
                this.props.getSpriteFromAPI(url)
            })
        this.props.setMapStylesIdST(styleId)
        this.map.current.setStyle(`${process.env.REACT_APP_GIS_URL}/MapboxStyles/${styleId}?${this.state.url.type}Id=${this.state.url[`${this.state.url.type}ID`]}`);
        this.map.current.once('styledata', () => {
            const year = getSelectedYear(time, this.timeLine);
            const newObj = generateTimeLineQueryString(
                year - 1,
                this.state.url,
                this.props.selectedLesson,
                this.state.mapData?.id
            );

            if (Object.keys(newObj).length !== 0) {
                const queryString = Object.keys(newObj)
                    .map((key) => key + "=" + newObj[key])
                    .join("&");
                const tileUrl = new URL(this.map.current.getStyle()
                    .sources["Brainograph GIS API"].tiles[0]);
                this.map.current.getSource("Brainograph GIS API")
                    .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?${queryString}`]);
            }
        })
        this.map.current.once("idle", () => {

            const {id, type} = determinateURLParams(this.state.url);
            if (type === "article") {
                window.location.href = `/`;
            }
            ['unclustered-text','unclustered-point','clusters','cluster-count'].forEach(layer=>{
                if(this.map.current.getLayer(layer)){
                    this.map.current.removeLayer(layer)
                }
            })
            if (this.map.current.getSource('Brainograph PIN GIS API')) {
                this.map.current.removeSource('Brainograph PIN GIS API')
            }

            if(Object.keys(this.props.getPainterGeoJsonDataST).length > 0){
                for (let i = 0; i < this.props.getPainterGeoJsonDataST.features.length; i++) {
                    this.geojsonRef.current.features.push({
                        id: this.props.getPainterGeoJsonDataST.features[i].id,
                        type: "Feature",
                        properties: {
                            ...this.props.getPainterGeoJsonDataST.features[i].properties,
                        },
                        geometry: {
                            ...this.props.getPainterGeoJsonDataST.features[i].geometry,
                        },
                    });

                    this.map.current.addLayer({
                        id: this.props.getPainterGeoJsonDataST.features[i].id,
                        type: "line",
                        source: {
                            type: "geojson",
                            data: this.geojsonRef?.current,
                        },

                        paint: {
                            "line-width": ["get", "width"],
                            "line-color": ["get", "color"],
                        },
                    });
                }

                this.map.current
                    ?.getSource(
                        `${
                            this.geojsonRef.current.features[this.geojsonRef.current.features.length - 1]
                                ?.id
                        }`
                    )
                    ?.setData(this.geojsonRef?.current);
            }

            this.mapLiveView(id, type, null, true, true,time,gisApiSources?.data?.features,this.state.metadata)
        })
    };
    // End
    handleGetPopupAnchor = () =>{
        return window.innerWidth < 512
                ?'bottom'
                :'left'
    };
    handleGetPopupWidth = () => {
        return window.innerWidth < 512
            ?'55vw'
            :'30vw'
    }
    handleGetPopupOffset = () => {
        return window.innerWidth < 512
            ? 50
            : 20
    }
    handleSetSuperCluster = (newFeatures) => {
        const index = new Supercluster({
            radius: 30,
            maxZoom: 8
        }).load(newFeatures);
        this.setState({'superCluster': index})
    }
    changeClusterItemsTime = (date) => {
        const newFeatures = []
        const year = new Date(date).getFullYear()
        const source = this.map.current.getStyle().sources['Brainograph PIN GIS API']?.data.features?.map(el => {
            const {yearStart, yearEnd, visible} = el.properties
            el.properties.startTime = year
            el.properties.endTime = year
            if (
                (visible === 'visible') &&
                ((year !== year) ||
                    (year >= yearStart && year <= yearEnd) ||
                    (year >= yearStart && year <= yearEnd))
            ) {
                newFeatures.push(el)
            }
            return el
        });
        this.handleSetSuperCluster(newFeatures)

    this.map.current.getSource('Brainograph PIN GIS API')?._updateWorkerData(source)
  }
    generAnimationMarker ({id,cordinates,pointCount,articleIds,catColor,flyTo = true}) {
        if(Array.isArray(articleIds) && !Number.isNaN(+articleIds[0])) this.props.getArticleFromAPI(articleIds[0],!flyTo)
        if(!('closeAnimationMarker' in this.map.current._listeners)){
            this.map.current.on('closeAnimationMarker', (e) => {
                if(!('ids' in e) || e.ids.includes(id)){
                    this.state.markerAnimation.remove();
                    this.setState({'selectedElement':null})
                }else if(this.state.selectedElement?.properties?.id && e.ids.includes(this.state.selectedElement?.properties?.id)){
                    this.state.markerAnimation.remove();
                    this.setState({'selectedElement':null})
                }
            });
        }
        const generSize  = (count) =>{
            const sizes = [40,40,45,60,70]
            const elemCount = [1,10,40,100]
            const elemCountIndex = elemCount.findIndex(elCount=>elCount > count)
            if(elemCountIndex === -1) return sizes[sizes.length -1];
            return sizes[elemCountIndex];
        }
        let mapMarker = document.getElementById("markerAnimation");

        const circleSize = generSize(pointCount)
        if (mapMarker && +this.state.markerAnimation.getElement().dataset.id === +id) return

        if (mapMarker) {
            this.state.markerAnimation.setLngLat(cordinates);
            this.state.markerAnimation.getElement().dataset.id = id;
            this.state.markerAnimation.getElement().style.cssText = `--size:${circleSize}px;--catColor:${catColor}`;
        } else {
            const el = document.createElement('div');
            el.id = 'markerAnimation';
            el.classList.add("tic_animation");
            el.dataset.id = id
            el.style.cssText = `--size:${circleSize}px;--catColor:${catColor}`;
            el.style.zIndex = "1";
            const markerAnimation = new mapboxgl.Marker(el).setLngLat(cordinates).addTo(this.map.current);
            this.setState({'markerAnimation': markerAnimation})
        }
    }
    selectELements (item, cords, count) {
        const popupELems = document.getElementsByClassName('elements-popup-radius-content')
        if (popupELems && popupELems.length > 0) {
            [...popupELems].forEach(el => {
                el.dataset.active = false
            })
        }
        const markerInfo = {
            id: item.properties.id,
            cordinates: cords,
            pointCount: count,
            articleIds: item.properties.articleIds,
            flyTo:false
        }
        this.props.setMapSelectedCluster({
            ...this.props.mapSelectedCluster,
            elementProps: {
                item, cords, count
            }
        })

        this.generAnimationMarker(markerInfo)

        this.setState({'selectedElement': item})
        if (document.getElementById(`element-${item.id || item?.properties?.id}`)) {
            document.getElementById(`element-${item.id || item?.properties?.id}`).dataset.active = true
        }
    }
    generMarker (coordinates, child, zoomInfo,clusterId) {
        const currentZoom = this.map.current.getZoom();
        const zoom = currentZoom <= 8 ? 8 : zoomInfo
        this.map.current.flyTo({
            center: coordinates,
            zoom: zoom && zoom > 16 ? 16 : zoom || (currentZoom < 6 ? 6 : currentZoom),
        });


        let popup = null
        this.map.current.once('moveend', () => {
            const cluster = this.map.current.queryRenderedFeatures(null, {layers: ["clusters"]}).find(el=>el.id===clusterId);
            if(zoom > 17){
                this.map.current.flyTo({
                    center: cluster?.geometry.coordinates,
                    zoom: zoom || (currentZoom < 6 ? 6 : currentZoom),
                })
            }else{
                this.map.current.easeTo({
                    center: cluster?.geometry.coordinates,
                    zoom: zoom || (currentZoom < 6 ? 6 : currentZoom),
                })
            }

            setTimeout(()=> {
                this.map.current.once('moveend', () => {

                    if (child.length <= 6) {
                        const circleRadius = 85; // Adjust the radius as needed
                        child.forEach((item, index) => {
                            const angle = (360 / child.length) * index;
                            let radians = (angle * Math.PI) / 180;
                            const x = circleRadius * Math.cos(radians);
                            const y = circleRadius * Math.sin(radians);
                            child[index].x = x * -1
                            child[index].y = y
                            child[index].radians = radians
                        });
                        const div = document.createElement("div")
                        ReactDOM.render(<MapElementsPopupRadius activeStyle={this.props.selectedMapStyle} child={child}
                                                                coordinates={cluster?.geometry.coordinates}
                                                                selectELements={this.selectELements.bind(this)}/>, div)
                        popup = new mapboxgl.Popup({
                            closeOnClick: false,
                            closeOnMove: true,
                            focusAfterOpen: true,
                            anchor: 'center',
                            maxWidth: '30vw',
                            className: 'elements-popup-radius'
                        }).setLngLat(cluster?.geometry.coordinates)
                            .setDOMContent(div)
                            .addTo(this.map.current);
                        if (this.state.selectedElement) {
                            const elem = document.getElementById(`element-${this.state.selectedElement?.id || this.state.selectedElement?.properties?.id}`)
                            if (elem) {
                                elem.dataset.active = true
                            }
                        }
                    } else {
                        const sortChiled = Object.values(child.reduce((acum, el) => {
                            if (!acum[el?.properties?.catId]) acum[el?.properties?.catId] = []
                            acum[el?.properties?.catId].push(el)
                            return acum
                        }, {}))
                            ?.map(sublist => sublist.sort((a, b) => a?.properties?.name - b?.properties?.name))
                            ?.flat(1)
                        const div = document.createElement("div")
                        ReactDOM.render(<MapElementsPopup child={sortChiled} coordinates={cluster?.geometry.coordinates}
                                                          selectELements={this.selectELements.bind(this)}/>, div)
                        popup = new mapboxgl.Popup({
                            closeOnClick: false,
                            closeOnMove: true,
                            focusAfterOpen: true,
                            anchor: this.handleGetPopupAnchor(),
                            offset: this.handleGetPopupOffset(),
                            maxWidth: this.handleGetPopupWidth(),
                            className: 'elements-popup-modal'
                        }).setLngLat(cluster?.geometry.coordinates)
                            .setDOMContent(div)
                            .addTo(this.map.current);

                    }
                    popup.on('close', () => {
                        if (typeof this.props.mapSelectedCluster === 'object') {
                            this.props.setMapSelectedCluster({
                                ...this.props.mapSelectedCluster,
                                lat: null,
                                lng: null,
                            })
                        } else {
                            this.props.setMapSelectedCluster(null)
                        }
                    })
                    this.map.current.on('closeAllPopups', () => {
                        popup.remove();
                    });
                })
            },300)
        });
    }

    handleTimeLineClickAction = (e) => {
    if (e.event.target.id === "group_content") return;
    if (e.event.target.id === "lesson-item") return;
    let time = e.time
    const customTimeLine = document.querySelector(".t1")
    const timeLineClock = document.querySelector(".time-line-clock")
    if(!timeLineClock){
      const rootElement = document.createElement("div")
      rootElement.className = 'time-line-clock'
      ReactDOM.render(<TimeLineClock/>, rootElement);
      customTimeLine?.appendChild(rootElement)
    }
    const customTimeLineParams = customTimeLine?.getBoundingClientRect();
    document.querySelector('.iconic-clock-minute-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left * 6},192,192)`);
    document.querySelector('.iconic-clock-hour-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left},192,192)`);
    const activeEvent = this.props.timeLineItems?.getItemByDateRange?.(this.timeLine.current?.timeAxis?.step?.step || 1, time)
    const eventIcon = document.getElementById('event-icon')
        if(activeEvent[0]?.elementType === "Lamp"){
            this.map.current.once('moveend', () => {
                const {lng,lat} = {lng:activeEvent[0].bounds.coordinates[0],lat:activeEvent[0].bounds.coordinates[1]}
                const cords = this.map.current.project(activeEvent[0].bounds.coordinates)
                const elements = this.map.current.queryRenderedFeatures(cords, {layers: ["clusters","unclustered-point"]})
                const element = elements.find(item => item.geometry?.coordinates?.[0] === lng && item.geometry?.coordinates?.[1] === lat) || elements[1]
                if(element?.layer.id === "clusters"){
                    const clusterId = element?.properties.cluster_id;
                    this.map.current.getSource("Brainograph PIN GIS API")
                        .getClusterExpansionZoom(
                            clusterId,
                            (err, zoom) => {
                                this.props.setMapSelectedCluster({
                                    lng,
                                    lat,
                                })
                                if (err) {
                                    this.map.current.getSource("Brainograph PIN GIS API")
                                        .getClusterChildren(clusterId, (error, features) => {
                                            if (!error) {
                                                this.generMarker(element.geometry.coordinates, features, zoom,clusterId)
                                                const activeChild = features.find(el=>el.properties.articleIds.includes(activeEvent[0].articleId))
                                                this.selectELements(activeChild,activeEvent[0].bounds.coordinates,features?.length || 0)
                                            }
                                        });

                                    return
                                };
                                if (zoom <= 8) {
                                    return this.map.current.easeTo({
                                        center: {lng,lat},
                                        zoom: zoom + 0.1
                                    });
                                }
                                this.map.current.getSource("Brainograph PIN GIS API")
                                    .getClusterChildren(clusterId, (error, features) => {
                                        if (!error) {
                                            this.generMarker(element.geometry.coordinates, features, zoom,clusterId)
                                            const activeChild = features.find(el=>el.properties.articleIds.includes(activeEvent[0].articleId))
                                            this.selectELements(activeChild,activeEvent[0].bounds.coordinates,features?.length || 0)
                                        }
                                    });

                                return
                            });
                }
                if(element?.layer.id === "unclustered-point") {
                        const markerInfo = {
                            id: element.properties.id,
                            cordinates: element.geometry.coordinates,
                            pointCount: 0,
                            articleIds: JSON.parse(element.properties.articleIds),
                            catColor: element.properties?.catColor
                        }
                        this.generAnimationMarker(markerInfo)
                        this.setState({'selectedElement':element})
                    }
            })
        }

        if (eventIcon) {
            eventIcon.remove()
        }

        if (activeEvent?.length === 1) {
            time = activeEvent[0].start
            const icon = document.createElement('i')
            icon.className = 'event-icon' + ' ' + activeEvent[0]?.elementType
            icon.id = 'event-icon'
            document.getElementsByClassName('t1')[0].appendChild(icon)
        }
        this.props.setTimeLineCursorStatusST(true);

        this.props.setTimeLineEventDataST({
            targetId: e.event.target.id,
            item: e.item,
            time: time,
        });

    // if (e.y > 130 || !this.props.timelineExtend && e.y > 11) {
    // if (!e.item) {
      this.setState({ timeLineChanged: true });
      const customBar = document.querySelector(".t1");
      if (customBar === null) {
        this.timeLine.current.addCustomTime(time, "t1");
      } else {
        this.timeLine.current.setCustomTime(time, "t1");
      }
      this.handleTimeChange(time);
      debeounced100(()=>this.onMapRenderComplete(this.map.current,this.filterLegend))
      this.changeClusterItemsTime(time)
    // } else {
    //   if (e.item !== null) {
        let data = this.props.timeLineItems.find((x) => x.id === e.item);
        if (data) {
          if(data?.articleId) this.props.getArticleFromAPI(data.articleId, false)
            // console.log(e.item,'e.item')
          // this.props.setSelectedLesson(Number(e.item.split("-")[2]));
          // this.props.setNextLessonID(e.item);
          // this.props.setPrevLessonID(e.item);
        }
      // }
    // }

    };

  handleTimeLineClickActionST = (e,isScreenShot) => {
    if (e.targetId === "group_content") return;
    if (e.targetId === "lesson-item") return;

    // if (!e.item) {
      this.setState({ timeLineChanged: true });
      const customBar = document.querySelector(".t1");
      if (customBar === null) {
        this.timeLine.current.addCustomTime(e.time, "t1");
      } else {
        this.timeLine.current.setCustomTime(e.time, "t1");
      }
      this.handleTimeChange (e.time,isScreenShot);
      this.changeClusterItemsTime(e.time)
      const layers = this.map.current.getStyle();

    // } else {
    //   if (e.item !== null) {
    //     let data = this.props.timeLineItems.find((x) => x.id === e.item);
    //     if (data !== "undefined" || data !== null) {
    //       this.props.setSelectedLesson(Number(e.item.split("-")[1]));
    //       this.props.setNextLessonID(e.item);
    //       this.props.setPrevLessonID(e.item);
    //     }
    //   }
    // }
  };

    processLessonMode = (id) => {
        mapLessonAPIRequest(
            id,
            this.state.url.preview,
            this.props.setLessonData
        ).then((resData) => {
            this.setState({lessonData: resData});
            this.setState({nextLessonId: resData.next});
            this.setState({nextLessonId: resData.previous});
            const type = "lesson";
            const catIds = determineCatIds(resData, type);
            this.props.getFilteredCategoriesFromAPI(resData, catIds, type);
            this.props.getLampInfo(resData, type);
            const {startFrom, endTo} = formatServerResponseTime(resData);
            this.setState({mapData: resData});
            if (this.state.toggleMapMode === "all") {
                let type = this.props.selectedLesson !== null ? 'lesson' : this.state.url.type
                const newObj = {}
                if (type === 'lesson') {
                    newObj.lessonId = this.props.selectedLesson !== null ? this.props.selectedLesson : this.state.mapData.id
                }
                if (type === 'topic') {
                    newObj.topicId = this.state.mapData.id
                }
                if (type === 'article') {
                    newObj.articleId = this.state.mapData.id
                }
                const queryString = Object.keys(newObj)
                    .map((key) => key + "=" + newObj[key])
                    .join("&");
                const tileUrl = new URL(this.map.current.getStyle()
                    .sources["Brainograph GIS API"].tiles[0]);
                this.map.current.getSource("Brainograph GIS API")
                    .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?&${queryString}`]);

                //Remove layar filters
                const layers = this.map.current.getStyle().layers;
                layers.forEach((layer) => {
                    if (layer && layer.source === "Brainograph GIS API" && layer['source-layer'] === "brainograph") {
                        const filterOptions = [];
                        this.map.current.getFilter(layer.id)
                            .forEach(filter => {
                                if (filter && !(filter[0] == '!=' && filter[1] == 'id')) {
                                    filterOptions.push(filter);
                                }
                            })
                        this.map.current.setFilter(layer.id, filterOptions);
                    }
                });
                this.setState({
                    loadFirst: false,
                });
                this.props.loadingMap(false)
            }
            this.processTimeLineRendering(resData, type, false);
            this.props.getQuizInfo(this.state.url.type, id)
            //  this.handleTimeChange(startFrom);
        });
    };

    goToTopics = async () => {
        if (
            window.location.href.includes("preview=true") &&
            window.location.href.includes("type=lesson")
        ) {
            window.location.href = `/map?preview=true&type=topic&topicID=${this.props.topicId}`;
        } else if (
            !window.location.href.includes("preview=true") &&
            window.location.href.includes("type=lesson")
        )
            window.location.href = `/map?type=topic&topicID=${this.props.topicId}`;
        else {
            window.location.reload();
        }
    };
    filterFeatuers = () => {
        const features = this.map.current.querySourceFeatures('Brainograph GIS API', {
            'sourceLayer': "brainograph"
        })
        //     .filter(layer => !(`${this.state.url.type}ids` in layer.properties
        //     && layer.properties[`${this.state.url.type}ids`]?.split(',').includes(this.state.url[`${this.state.url.type}ID`])
        // ))
        const layers = this.map.current.getStyle().layers.filter(x => x['source-layer'] == 'brainograph' || x['sourceLayer'] == 'brainograph')
        const filterOptions = {}
        features.forEach(el => {
            if (!Array.isArray(filterOptions[el.properties.layer])) {
                filterOptions[el.properties.layer] = layers.filter(x => x.filter.find(f => f[0] === "==" && f[1] === "layer" && f[2] == el.properties.layer))
                filterOptions[el.properties.layer].show = true
                filterOptions[el.properties.layer].textShow = true
                if (this.props.filteredLegends?.checkIsHideItem?.(el.properties.layer)) {
                    filterOptions[el.properties.layer].show = false
                }
                if (this.props.filteredLegends?.checkIsHideItem?.(el.properties.layer) || this.props.filteredLegends?.checkIsHideItemText?.(el.properties.layer)) filterOptions[el.properties.layer].textShow = false
            }
        });

        Object.keys(filterOptions).forEach(layer => {
            filterOptions[layer].forEach(targetLayer => {
                if (filterOptions[layer].show) {
                    this.map.current.setLayoutProperty(targetLayer.id, 'visibility', 'visible');
                } else {
                    this.map.current.setLayoutProperty(targetLayer.id, 'visibility', 'none');
                }
                if (targetLayer?.layout && 'text-field' in targetLayer?.layout) {
                    if (filterOptions[layer].textShow) {
                        this.map.current.setLayoutProperty(targetLayer.id, 'text-field', '{name}');
                    } else {
                        this.map.current.setLayoutProperty(targetLayer.id, 'text-field', '');
                    }
                }
            })
        })
    }
    toggleLegendsFromMapByID = async (layerId, layerKey, subLayerId, key, type, action) => {
        await this.props.updateLegendToggle(layerId, subLayerId, key, type, action)
        this.filterFeatuers()

        this.onMapRenderComplete(this.map.current,this.filterLegendsViewport)
    }
    handleGetItemVisibility = (data) => {
        const newItems = data.filter(el => {
            if (this.props.filteredCategories?.checkIsHideFromTimeLineItem?.(el.articleId)) return el
        });
        return newItems
    }
    toggleItemsFromTimeLineByID = (categoryId, subCategoryId, type, articleItemId, elementsIds, action, show) => {
        let newItems;
        this.props.updateCategoriesToggle(
            categoryId,
            subCategoryId,
            type,
            articleItemId,
            action,
            show
        );

        if (this.props.timelineExtend === 1) return
        if (this.props.timelineExtend === 2) {
            newItems = this.handleGetItemVisibility(this.props.middleTimeLineItems)
        } else if (this.props.timelineExtend === 3) {
            newItems = this.handleGetItemVisibility(this.props.middleMaxTimeLineItems)
        }
        this.timeLineRelated.current.setItems(newItems);
        this.timeLineRelated.current.redraw();

    }
    toggleItemsFromMapByID = (categoryId, subCategoryId, type, articleItemId, elementsIds, action, show) => {
        // TODO improve this logic

        if (false && this.state.url.screenShot && this.state.screenShotFirstLoad) {
            this.props.updateTimeLineGroupToggle(categoryId);
            const isShow = getShowByType(
                this.props.filteredCategories,
                categoryId,
                subCategoryId,
                type,
                articleItemId
            );
            // this.toggleItemFromMap(categoryId, type, isShow)
            this.toggleItemFromMap(
                categoryId,
                type,
                isShow,
                subCategoryId,
                articleItemId,
                elementsIds
            );

            // this.toggleItemsFromTimeLineByID(categoryId, subCategoryId, type, articleItemId, elementsIds, action, show)
                this.setState({screenShotFirstLoad: false});
        } else {
            this.props.updateCategoriesToggle(
                categoryId,
                subCategoryId,
                type,
                articleItemId,
                action,
                show
            );
            this.props.updateTimeLineGroupToggle(categoryId);
            const isShow = getShowByType(
                this.props.filteredCategories,
                categoryId,
                subCategoryId,
                type,
                articleItemId
            );
            // this.toggleItemFromMap(categoryId, type, isShow)
            this.toggleItemFromMap(
                categoryId,
                type,
                isShow,
                subCategoryId,
                articleItemId,
                elementsIds
            );
        }
    };

    toggleLampFromMap = (id, isShow) => {
        const data = this.state.geoJsonData
        let layerIds;
        const getFilteredLayerIds = (feature) => {
            return data?.layers?.filter(_filterByFeatureId(feature)).map(extractId);
        };
        layerIds = (data?.features || [])
            .filter((feature) => feature.properties.articleId === id)
            .map(getFilteredLayerIds)
            .flat();
        this.makeMapChanges(layerIds, isShow);
    };
    toggleItemFromMap = (id, type, isShow, subID, articleItemId, elementsIds = [], onlyHide) => {
        const mapCurrent = this.map.current
        const data = mapCurrent.getStyle()
        let features = [];
        // Query for all features within the bounding box
        const categories = this.props.filteredCategories
        if (!data || !data.layers.length) {
            return
        }
        const _subCatFilterIfNeeded = (feature) => {
            if (type === 'category') {
                return true
            } else {
                return subCatFilterLogic(categories, feature, id, isShow, subID)
            }
        }
        const getFilteredLayerIds = (feature) => {
            return data.layers
                .filter(_filterByFeatureId(feature))
                .map(extractId)
        }
        const toggleItemFromMapFUnc = (isShow, mapCurrent, elementsIds, articleItemIds) => {
            mapCurrent.fire('closeAllPopups');
            mapCurrent.fire('closeAnimationMarker', {ids: [...elementsIds, ...articleItemIds]});
            if (!isShow || onlyHide) {
                let sourceBrainographPINGISAPI = [...(this.map.current.getStyle().sources['Brainograph PIN GIS API']?.data.features || [] )]
                sourceBrainographPINGISAPI.map(el => {
                    if (el.properties.visible && el.properties.articleIds.some(item => articleItemIds.includes(item))) {
                        el.properties.visible = 'visible'

                        if (elementsIds.includes(el.properties.id)) {
                            el.properties.visible = 'none'
                        }
                        if (el?.properties?.isArticle) {
                            el.properties.visible = 'none'
                        }
                    }
                    return el
                });
                this.map.current.getSource('Brainograph PIN GIS API')?._updateWorkerData(sourceBrainographPINGISAPI)
            }
            else {
                const layers = mapCurrent.getStyle().layers;
                layers.forEach((layer) => {
                    if (layer && layer.source === "Brainograph GIS API" && layer['source-layer'] === "brainograph") {
                        const filterOptions = [];
                        mapCurrent.getFilter(layer.id)
                            .forEach(filter => {
                                if (filter && !(filter[0] == '!=' && filter[1] == 'id' && elementsIds.includes(filter[2]))) {
                                    filterOptions.push(filter);
                                }
                            })
                        mapCurrent.setFilter(layer.id, filterOptions);
                    }
                });
                const source = [...(this.map.current.getStyle().sources['Brainograph PIN GIS API']?.data.features || [])]

                source.map(el => {
                    if (el.properties.visible && el.properties.articleIds.some(item => articleItemIds.includes(item))) {
                        if (elementsIds.includes(el.properties.id)) {
                            el.properties.visible = 'visible'
                        }
                        if (el?.properties?.isArticle) {
                            el.properties.visible = 'visible'
                        }
                    }
                    return el
                });
                this.map.current.getSource('Brainograph PIN GIS API')?._updateWorkerData(source)
            }
        }
        if (type === 'article' || type === 'lamp') {
            toggleItemFromMapFUnc(isShow, mapCurrent, elementsIds, [articleItemId])
        } else if (type === 'layar' || type === 'category') {
            const articleItemIds = []
            const elementsIdsFromCat = categories
                .filter(categorie => categorie.show === isShow)
                .reduce((acum, item) => {
                    item.subCategories.forEach(subCategorie => {
                        subCategorie.articles.forEach(article => {
                            articleItemIds.push(article.id)
                            acum.push(...article.elementsIds)
                        })
                    })
                    return acum
                }, [])
            toggleItemFromMapFUnc(isShow, mapCurrent, elementsIdsFromCat, articleItemIds)
            this.props.filteredCategories.updateItems()
        }
    }

    makeMapChanges = (layerID, show, checkLamp) => {
        layerID.forEach((item) => {
            const pin = document.getElementById(item);
            const datasetFilter = pin?.dataset?.filter;
            if (!datasetFilter || datasetFilter === "visible") {
                if (pin) {
                    this.map.current.setLayoutProperty(
                        item,
                        "visibility",
                        show ? "visible" : "none"
                    );
                    if (pin.classList.contains(`lamp_pin`) && checkLamp) {
                        if (!show) {
                            pin.classList.remove("show_lamp_article");
                            pin.classList.add("hide_lamp_article");
                        } else {
                            pin.classList.remove("hide_lamp_article");
                            pin.classList.add("show_lamp_article");
                        }
                    } else {
                        pin.style.visibility = show ? "visible" : "hidden";
                    }
                }
            }
        });
    };
    // End

    // not movable functions
    startPinAnimation(id) {
        let mapMarkers = document.getElementsByClassName(
            "mapboxgl-marker-anchor-center"
        );

        for (let i = 0; i < mapMarkers.length; i++) {
            if (mapMarkers[i].getAttribute("class").includes(id)) {
                mapMarkers[i].classList.add("tic_animation");
                mapMarkers[i].style.zIndex = "11";

                if (window.innerWidth < 1024) {
                    setTimeout(() => {
                        var markerRect = mapMarkers[i].getBoundingClientRect();
                        var markerPos = {
                            x: markerRect.left + markerRect.width / 2,
                            y: markerRect.top + markerRect.height / 2,
                        };

                        var coords = this.map.current.unproject(markerPos);

                        this.map.current.flyTo({
                            center: [coords.lng, coords.lat + 0.1],
                        });
                    }, 3000);
                }

                continue;
            }
            mapMarkers[i].classList.remove("tic_animation");
            mapMarkers[i].style.zIndex = "1";
        }
    }

    filtredLegendsByViewport = (data) => {
        const features = this.map.current.queryRenderedFeatures().filter(feature => (
            feature.source === 'Brainograph GIS API'
            || feature.source === "Brainograph"
            || feature.source === "Brainograph PIN GIS API"
        ));
        const featureLayerKeys = new Set(features.reduce((acum,feature)=>{
            if(this.props.filteredLegends.hasElement(feature.properties.layer)) acum.push(feature.properties.layer)
                return acum
            },[])
        )
        const layerKey = {}
        const filterLayers = data.reduce((acum, layer) => {
            layerKey[layer.key] = false
            if (layer.parentId) {
                if (featureLayerKeys.has(layer.key)) acum.layers.push(layer)
            } else {
                acum.parents[layer.id] = layer
            }
            return acum
        }, {layers: [], parents: {}})
        const uniqueIds = []
        const result = filterLayers.layers.reduce((acum, el) => {
            layerKey[el.key] = true
            acum.push(el)
            if (!uniqueIds.includes(el.parentId)) {
                layerKey[filterLayers.parents[el.parentId].key] = true
                acum.push(filterLayers.parents[el.parentId])
            }
            uniqueIds.push(el.parentId)
            return acum
        }, [])
        Object.keys(layerKey).forEach(key=>{
            this.props.filteredLegends.updateId(key, layerKey[key])
        })
        this.props.dispatchFilteredLegends(sanitizeResult(result))
    }
    findWord = (word, str, splitBy = '&') => {
        return str.split(splitBy).some(function (w) {
            return w.startsWith(word)
        })
    }
    setupMapFirstView = async (styleId = 1) => {

        const genQueryString = (articleIdsArr) => {
            return articleIdsArr.join('&articleIds=')
        }

        this.map.current = await new mapboxgl.Map({
            container: this.mapContainer.current,
            style: `${process.env.REACT_APP_GIS_URL}/MapboxStyles/${styleId}?${this.state.url.type}Id=${this.state.url[`${this.state.url.type}ID`]}`,
            center: [11, 42],
            maxZoom: MAX_ZOOM_MAP + 0.1,
            zoom: 0.865,
            maxPitch:45,
                preserveDrawingBuffer: true,
            transformRequest: (url, resourceType) => {
                let sendUrl = url
                if (url.startsWith(process.env.REACT_APP_GIS_URL)) {
                    return {
                        url: sendUrl,
                        headers: {'Authorization': 'Bearer ' + localStorage.getItem('accessToken')}
                    }
                }
            }
            // maxPitch:60,
        })
        let _thisMapCurrent = this.map.current
        this.map.current.on('idle', () => {
            setTimeout(() => {
                this.map.current.resize()
            }, 10)
        })
        this.map.current.on("moveend", () => {
            this.filterFeatuers()
            debeounced100(()=>this.filtredLegendsByViewport(this.props.legendsData))

        });
    }

    moveLeft = () => this.move(0.2);

    moveRight = () => this.move(-0.2);
    changeMapZoom = (zoom) => {
        this.map.current.zoomTo(zoom, {duration: 1000});
    };

  // Timeline
  move = (percentage) => {
    const currentTime = this.timeLine.current.current_time;
    let range = this.timeLine.current.getWindow();
    let interval = range.end - range.start;

        this.timeLine.current.setWindow({
            start: range.start.valueOf() - interval * percentage,
            end: range.end.valueOf() - interval * percentage,
        })
        this.timeLine.current.setCurrentTime(currentTime);
        this.timeLineRelated.current.setCurrentTime(currentTime);
    }
    onTimeLineShowChange = () => {
        this.map.current?.fire('closeAllPopups');
        this.map.current?.fire('closeAnimationMarker');
        this.setState({timeLineChanged: false})
    }
    showArticleReadMoreSection = () => {
        this.props.getArticleFromAPI(this.state.url?.bookmarkID);
    };

    getSubject = async (id=1) => {
      await axios.get(`${process.env.REACT_APP_DICTIONARY_URL}/api/Subject/${id}/Language/1`).then(async (res) => {
      const subject = res.data.data[0];
      if (!subject?.settingId) return;
          {
              const res = await axios.get(`${process.env.REACT_APP_DICTIONARY_URL}/api/SubjectSetting/${subject?.settingId}/Language/1`)
              const setting = res.data.data[0];
              const toolbox = {};
              MAP_TOOLBOX_ITEMS.forEach(item => {
                  if (!!(setting.toolboxSetting & (2 ** item.id))) {
                      toolbox[item.key] = item;
                  }
              })
              this.setState(() => ({
                  subject: {
                      ...subject,
                      setting,
                      toolbox
                  }
              }), () => {
                  this.initMapToolboxListeners()
              })
              return this.props.setSubjectsSettings({...setting, ...toolbox})
          }
        })
    }

    initMapToolboxListeners = () => {
        const toolbox = this.state.subject?.toolbox;
        const listenMouseMove = toolbox[MAP_TOOLBOX_KEYS.HEIGHT] || toolbox[MAP_TOOLBOX_KEYS.COORDS]
        const listenZoomEnd = !!toolbox[MAP_TOOLBOX_KEYS.SCALE]

        if (!toolbox) return;

        if (listenMouseMove) {
            let heightTimer;
            let coordsTimer;
            this.map.current.on('mousemove', (e) => {
                if (toolbox[MAP_TOOLBOX_KEYS.HEIGHT]) {
                    clearTimeout(heightTimer)
                    heightTimer = setTimeout(async () => {
                      const coordinates = {lng: e.lngLat.wrap().lng, lat: e.lngLat.wrap().lat};
                      const heightResult = Math.floor(+(await this.map.current.queryTerrainElevation(coordinates)));
                      this.setState({
                        toolboxValues: {
                          ...this.state.toolboxValues,
                          [MAP_TOOLBOX_KEYS.HEIGHT]: heightResult
                        }
                      })
                    }, 200)
                }

                if (toolbox[MAP_TOOLBOX_KEYS.COORDS]) {
                    clearTimeout(coordsTimer)
                    coordsTimer = setTimeout(() => {
                        this.setState({
                            toolboxValues: {
                                ...this.state.toolboxValues,
                                [MAP_TOOLBOX_KEYS.COORDS]: {
                                    lng: e.lngLat.wrap().lng,
                                    lat: e.lngLat.wrap().lat,
                                }
                            }
                        })
                    }, 200)
                }

            });

        }

        if (listenZoomEnd) {
            const handleCalculateScale = () => {
                if (toolbox[MAP_TOOLBOX_KEYS.SCALE]) {
                    const zoom = this.map.current.getZoom();
                    const center = this.map.current.getCenter();
                    const latitude = center.lat;

                    const scaleValue = getMapScale(this.map.current);
                    this.setState({
                        toolboxValues: {
                            ...this.state.toolboxValues,
                            [MAP_TOOLBOX_KEYS.SCALE]: scaleValue
                        }
                    })
                }
            }
            this.map.current.on('zoomend', handleCalculateScale);

            handleCalculateScale()
        }
    }


    getTopicsData = (gradeID) => {
        const {url, body} = constructSelectedItemApiParams(
            "grade",
            gradeID
        );
        getTopicsFromAPI(url, body);
    };

    getLessonsData = (topicId) => {
        const {url, body} = constructSelectedItemApiParams("topic", topicId);
        topicId && this.props.getLessonsFromAPI(url, body);
    };

    navigateTo = (to) => () => {
        const {topics} = this.props;
        const {currentDataIndex, url} = this.state;
        const isTopicMode = url?.type === 'topic';
        const baseUrl = isTopicMode ? "/map?type=topic&topicID=" : "/map?type=lesson&lessonID=";
        const dataByType = isTopicMode ? topics : this.props.lessons?.data;
        if (to === 'next') {
            window.location.href = `${baseUrl}${
                dataByType[currentDataIndex + 1].id
            }`;
        } else {
            window.location.href = `${baseUrl}${dataByType[currentDataIndex - 1].id}`;
            window.location.href = `${baseUrl}${dataByType[currentDataIndex - 1].id}`;
        }
    }

    clickSliderItemHandler = (item) => {
        const {url} = this.state;

        if (url.type === "topic") {
            window.location.href = `/map?type=lesson&lessonID=${item.id}`
        }
    }
    handleChangeToInitialState = () => {
        Object.keys(initialState).forEach((e) => {
            if (zeroingItems[e]) {
                if (
                    JSON.stringify(this.state[e]) !==
                    JSON.stringify(initialState[e])
                ) {
                    this.setState({
                        [e]: JSON.parse(JSON.stringify(initialState[e])),
                    })
                }
            }
        })
        // this.handleShowAllCategory()
        // if (this.state.selectedStyleId !== this.state.mapStyles[0].id)
        //     this.handleMapStyleChange(initialState.mapStyles[0].id)
        if (initialState.zoom !== this.state.zoom) {
            this.map.current.fitBounds(this.state.bounds, {
                padding: 250,
            })
        }
        // this.handleMapRequirements(this.state.toggleMapMode)
        // this.handleTimeChange(this.state.timeLineSelectedDataItems[0].start)
        // this.timeLine.current.removeCustomTime('t1')
        // this.timeLine.current.addCustomTime(
        //     this.state.timeLineSelectedDataItems[0].start,
        //     't1'
        // )
        // this.timeLine.current.redraw()
        // this.timeLine.current.setOptions({
        //     start: new Date(
        //         this.state.timeLineSelectedDataItems[0].startFrom.setFullYear(
        //             this.state.timeLineSelectedDataItems[0].startFrom.getFullYear() -
        //             0
        //         )
        //     ),
        //     end: new Date(
        //         this.state.timeLineSelectedDataItems[0].endTo.setFullYear(
        //             this.state.timeLineSelectedDataItems[0].endTo.getFullYear() +
        //             0
        //         )
        //     ),
        //     min: new Date(
        //         this.state.timeLineSelectedDataItems[0].startFrom.setFullYear(
        //             this.state.timeLineSelectedDataItems[0].startFrom.getFullYear() -
        //             0
        //         )
        //     ),
        //     max: new Date(
        //         this.state.timeLineSelectedDataItems[0].endTo.setFullYear(
        //             this.state.timeLineSelectedDataItems[0].endTo.getFullYear() +
        //             0
        //         )
        //     ),
        // })
    }

    render() {
        const dataByType = this.state.url === 'topic' ? this.props.topics : this.props.lessons?.data;
        const sidebarHeader = this.state.url.type === 'topic' ? `ԹԵՄԱ - ${this.state.mapData?.language?.[0]?.title}` :
            <div className={'lesson__name'}>
                <p id={'lesson_title'}>ԴԱՍ - {this.state.mapData?.language?.[0]?.title}</p>
                <p class="lesson_slash">|</p>
                <a href={`/map?type=topic&topicID=${this.state.topic?.id}`}>
                   <p id={'topic_title'}>ԹԵՄԱ - {this.state.topic?.title}</p>
                </a>
            </div>
        return (
            <div className="map-boby">
                {/*{this.props.mapIsLoading ||*/}
                {/*    (this.props.getScreenShotLoadingST && this.state.url.screenShot && (*/}
                {/*        <div*/}
                {/*            style={{*/}
                {/*                position: "absolute",*/}
                {/*                width: "100%",*/}
                {/*                height: "100%",*/}
                {/*                display: "flex",*/}
                {/*                justifyContent: "center",*/}
                {/*                alignItems: "center",*/}
                {/*                zIndex: 999999,*/}
                {/*                background: "rgb(0 0 0 / 44%)",*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <PageLoader/>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {this.state.url.articleReadMode && (
                    <ArticleModeHeader id={this.props.selectedArticle.id}/>
                )}
                {!this.state.url.articleReadMode && (
                    <MapHeader tools={this.state.subject?.toolbox} map={this.map.current}
                               mapTypes={this.state.subject?.setting?.mapTypes}
                               globalLoading={this.state.globalLoading}/>
                )}
                <div ref={this.mapContainer} className="map" id={"map"}/>
                {this.state.globalLoading &&
                    <WorldMapLoader/>
                }
                <main className="main">
                    {!this.state.url.articleReadMode && (
                        <LeftSide
                            leftBarSTF={this.state.leftBarSTF}
                            toggleItemFromMap={this.toggleItemFromMap}
                            globalLoading={this.state.globalLoading}
                            toggleItemsFromMap={(
                                categoryID,
                                subCategoryID,
                                type,
                                articleItemId,
                                elementsIds,
                                actions,
                                show
                            ) =>
                                this.toggleItemsFromMapByID(
                                    categoryID,
                                    subCategoryID,
                                    type,
                                    articleItemId,
                                    elementsIds,
                                    actions,
                                    show
                                )
                            }
                            toggleItemsFromTimeLine={(
                                categoryID,
                                subCategoryID,
                                type,
                                articleItemId,
                                elementsIds,
                                actions,
                                show
                            ) =>
                                this.toggleItemsFromTimeLineByID(
                                    categoryID,
                                    subCategoryID,
                                    type,
                                    articleItemId,
                                    elementsIds,
                                    actions,
                                    show
                                )
                            }
                            stopPinAnimation={this.stopPinAnimation}
                            toggleLegendsFromMapByID={(layerId, layerKey, subLayerId, key, type, action) => this.toggleLegendsFromMapByID(layerId, layerKey, subLayerId, key, type, action)}
                            toggleLampFromMap={(id, isShow) => this.toggleLampFromMap(id, isShow)}
                            timeLine={this.timeLine}
                        />
                    )}
                    <RightSide mapState={this.map.current}
                               stopPinAnimation={this.stopPinAnimation}
                               globalLoading={this.state.globalLoading}/>
                </main>
                <Toolbox attachTimeline={this.state.mapData?.showTimeline} coords={this.state.toolboxValues?.coords}
                         height={this.state.toolboxValues?.height}
                         scale={this.state.toolboxValues?.scale}/>
                {
                    this.state.url.type !== "article" && this.state.mapData && !this.state.mapData?.showTimeline &&
                    <MapSlider hasNext={!!dataByType[this.state.currentDataIndex + 1]}
                               hasPrev={!!dataByType[this.state.currentDataIndex - 1]}
                               onClickItem={this.clickSliderItemHandler}
                               onNext={this.navigateTo('next')}
                               onPrev={this.navigateTo('prev')}
                               noBody={this.state.url?.type === 'lesson'}
                               items={this.state.mapData?.lessons?.map(mapItemAdapter) || []}
                               header={sidebarHeader}/>
                }
                {!this.state.url.articleReadMode && (
                <div
                    style={{display: this.state.url?.type !== 'article' && this.state.mapData && !this.state.mapData?.showTimeline ? 'none' : 'block',zIndex:'99'}}
                    data-is-windows={navigator.appVersion.indexOf("Windows") !== -1}
                >
                    <Timeline
                        runScreenShotStates={this.state.runScreenShotStates}
                        handleTimeChange={(time) => this.handleTimeChange(time)}
                        dataType={this.state.url.type}
                        timeLine={this.timeLine}
                        timeLineRelated={this.timeLineRelated}
                        goToTopics={() => this.goToTopics()}
                        moveLeft={() => this.moveLeft()}
                        moveRight={() => this.moveRight()}
                        toggleItemsFromMap={(categoryID, subCategoryID, type) =>
                            this.toggleItemsFromMapByID(categoryID, subCategoryID, type)
                        }
                        onMapRenderComplete={this.onMapRenderComplete}
                        handleGetItemVisibility={this.handleGetItemVisibility}
                        filterLegend={this.filterLegend}
                        subjectID={this.state.subjectID}
                        gradeID={this.state.gradeID}
                        timeLineChanged={this.state.timeLineChanged}
                        onTimeLineShowChange={this.onTimeLineShowChange}
                        map={this.map}
                        processLessonMode={this.processLessonMode}
                        nextLessonId={this.state.nextLessonId}
                        prevLessonId={this.state.prevLessonId}
                        handleTimeLineClickActionST={this.handleTimeLineClickActionST}
                        handleSetSuperCluster={this.handleSetSuperCluster}
                        globalLoading={this.state.globalLoading}
                    />
                </div>)}
            </div>
        );
    }
}

const mapStateTopProps = (state) => ({
    selectedMapStyleDark: getSelectedMapStyleDark(state),
    selectedMapStyle: getSelectedMapStyle(state),
    mapStyles: getMapStylesData(state),
    mapStylesList: getMapStylesListData(state),
    categories: getCategoriesData(state),
    filteredCategories: getFilteredCategoriesData(state),
    selectedArticle: getSelectedArticleData(state),
    mapZoom: getMapZoomData(state),
    mapZoomToAction: getMapZoomToActionData(state),
    selectedMapRequirement: getSelectedMapRequirements(state),
    prevLessonID: getPrevLessonIDData(state),
    nextLessonID: getNextLessonIDData(state),
    timeLineItems: getTimeLineItemsData(state),
    middleTimeLineItems: getMiddleTimeLineItemsData(state),
    middleMaxTimeLineItems: getMiddleMaxTimeLineItemsData(state),
    timeLineGroups: getTimeLineGroupsData(state),
    timeLineLoading: getIsTimeLineLoading(state),
    selectedLesson: getSelectedLessonId(state),
    lampData: getLampData(state),
    lampModalState: getLampModalState(state),
    lampModalData: getLampModalData(state),
    timelineExtend: getTimeLineExpendData(state),
    topicId: getTopicId(state),
    mapIsLoading: getMapLoading(state),
    getScreenShotLoadingST: getScreenShotLoadingST(state),
    getMapStyledId: getMapStyledId(state),
    getTimeLineClickState: getTimeLineClickState(state),
    legends: getLegends(state),
    legendsData: getLegendsData(state),
    filteredLegends: getFilteredLegendsData(state),
    timeLineZoomST: getTimeLineZoomST(state),
    topics: getTopicsData(state),
    topicsIsLoading: getIsTopicLoading(state),
    lessons: getLessons(state),
    lessonsIsLoading: getIsLessonLoading(state),
    mapSelectedCluster: getMapSelectedCluster(state),
    screenShotSingleData:getMapStateSingleData(state),
    user:getUser(state),
    getRulerClickedState: getRulerClickedState(state),
    getShowNotificationST:getShowNotificationST(state),
    getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
    getResetCompass: getResetCompass(state),
    getSlidesSelectedSlideData: getSlidesSelectedSlideData(state),

});

const mapDispatchToProps = {
    setResetCompass,
    setCompassNewAngle,
    setCompassRotate,
    getCategoriesFromAPI,
    getLegendsFromAPI,
    getSpriteURLFromAPI,
    getSpriteFromAPI,
    dispatchFilteredLegends,
    getLampInfo,
    getFilteredCategoriesFromAPI,
    setMapZoom,
    setMapBounce,
    setTimeLineExpend,
    updateCategoriesToggle,
    updateLegendToggle,
    getArticleFromAPI,
    setNextLessonID,
    setTimeLineTopicsData,
    setMiddleTimeLineTopicsData,
    setMiddleTimeLineLessonsData,
    setMiddleMaxTimeLineLessonsData,
    setTimeLineLessonData,
    setPrevLessonID,
    setSelectedLesson,
    updateTimeLineGroupToggle,
    setLampModalState,
    setLampModalData,
    setFilteredLegends,
    setTopicId,
    setLessonData,
    getLessonsFromAPI,
    // getTimeGeoJsonDataFromStorage,
    timeLineLoadingReaction,
    setTimeLineArticlesData,
    loadingMap,
    setTimeLineItems,
    getQuizInfo,
    getScreenShotSingleDataAPI,
    setTimeLineCursorStatusST,
    setTimeLineEventDataST,
    setMapCenterPositionST,
    changeMapStyles,
    setTimeLIneClickState,
    getTopicsFromAPI,
    setMapSelectedCluster,
    setSubjectsSettings,
    setLampModalStateIndex,
    setMapStylesList,
    setMapStylesIdST,
    setDisableCompass
};

export default connect(mapStateTopProps, mapDispatchToProps)(Map);
