import {SET_CATEGORIES, SET_FILTERED_CATEGORIES, SET_FILTERED_CATEGORIES_ST} from "../constants";

const initialState = {
  data: [],
  dataST: [],
  isLoading: false,
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const filteredCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERED_CATEGORIES:
      return {
        ...state,
        data: action.payload,
      };
      case SET_FILTERED_CATEGORIES_ST:
      return {
        ...state,
        dataST: action.payload,
      };
    default:
      return state;
  }
};
