/**
 *
 * @param item
 * @returns {{id, title: (*|string)}}
 */
export const mapItemAdapter = (item) => {
    return {
        id: item.id,
        title: item.language[0]?.title || item.title || '',
    }
}
