import React, { Component } from "react";
import { Button } from "../../../components/UI/Button";
import Loader from "../../../components/UI/Loader";
import { TextInput } from "../../../components/UI/TextInput";
import ErrorAlert from "../../../components/errorAlert";
import { getErrorMessage } from "../../../helpers";
import { changeUserPasswordURI } from "../../../shared/mockData";
import { checkResetPassValidationErrors } from "../form-validation";
import { changeUserPassword } from "../utils";
import SuccessAlert from "../../../components/successAlert";
import AccountPageModal from "../../../components/UI/AccountPageModal/AccountPageModal";
class PasswordSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPass: false,
      deleteUserAccountModal: false,
      loading: false,
      resetFields: {},
      resetErrors: {},
      apiErrorMsg: "",
      showError: false,
    };
  }

  resetPassSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();
    const resetErrors = checkResetPassValidationErrors(this.state.resetFields);
    if (resetErrors) {
      this.setState({
        loading: false,
        // editPass: false,
        resetErrors,
      });
    } else {
      this.resetPass();
    }
  }

  handlePassChange(field, e) {
    let fields = this.state.resetFields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  resetPass = () => {
    const { resetFields } = this.state;
    const body = {
      currentPassword: resetFields["oldPassword"],
      newPassword: resetFields["password"],
    };
    const errorState = {
      showError: false,
    };
    changeUserPassword(changeUserPasswordURI, body)
      .then((res) => {
        if (res.data.accepted === false) {
          return Promise.reject(res.data.errorMessages);
        }
        return res.data;
      })
      .then((data) => {
        this.setState({
          editPass: false,
          loading: false,
          showError: false,
          resetFields: {},
        });
        this.props.handleChangeSuccessAlert(true,'Գաղտնաբառը հաջողությամբ փոփոխված է')
        return;
      })
      .catch((error) => {
        errorState.showError = true;
        errorState.apiErrorMsg = getErrorMessage(error);
        errorState.loading = false;
        this.props.handleChangeSuccessAlert(false,'')
        this.setState(errorState);
      });
  };
  handleDeleteUserAccountModal = (bool) =>{
    this.setState(()=>({deleteUserAccountModal:bool}))
  }

  render() {
    const {
      editPass,
      loading,
      showError,
      apiErrorMsg,
      resetFields,
      resetErrors,
    } = this.state;
    const { resetPassSubmit, handlePassChange } = this;
    return (
      <>
        <div className="user-pass user_pass_zone">
          {editPass ? (
            <>
              <form onSubmit={resetPassSubmit.bind(this)}>
                <div className="user-detail passwords-detail">
                  <span>Ընթացիկ գաղտնաբառ</span>
                  <TextInput
                    black={true}
                    contentClassName="user-input input-wrapper"
                    className={""}
                    hasError={apiErrorMsg === 'Խնդրում ենք մուտքագրել վավեր գաղտնաբառ'&&
                    apiErrorMsg || resetErrors["oldPassword"]}
                    type="password"
                    onChange={handlePassChange.bind(this, "oldPassword")}
                    value={resetFields["oldPassword"] || ""}
                    profileZone
                  />
                </div>
                <div className="user-detail passwords-detail">
                  <span>Նոր գաղտնաբառ</span>
                  <TextInput
                    black={true}
                    contentClassName="user-input input-wrapper"
                    className={""}
                    hasError={apiErrorMsg === 'Գաղտնաբառն արդեն օգտագործվել է։ Խնդրում ենք ընտրել այլ գաղտնաբառ։' &&
                      apiErrorMsg || resetErrors["password"]}
                    type="password"
                    onChange={handlePassChange.bind(this, "password")}
                    value={resetFields["password"] || ""}
                    profileZone
                  />
                </div>
                <div className="user-detail passwords-detail">
                  <span>Հաստատել գաղտնաբառը</span>
                  <TextInput
                    black={true}
                    contentClassName="user-input input-wrapper"
                    className={""}
                    hasError={resetErrors["cPassword"]}
                    type="password"
                    onChange={handlePassChange.bind(this, "cPassword")}
                    value={resetFields["cPassword"] || ""}
                    profileZone
                  />
                </div>
                <div className="user-form-btns">
                  <Button
                      className="user-form-btn save"
                      action={resetPassSubmit.bind(this)}
                      text="Պահպանել"
                      loading={loading}
                  />
                  <Button
                    className="user-form-btn link"
                    action={() =>
                      this.setState({ editPass: false, resetFields: {} })
                    }
                    text="Չեղարկել"
                  />
                </div>
              </form>
              {/*{loading && <Loader />}*/}

              {showError && apiErrorMsg != 'Խնդրում ենք մուտքագրել վավեր գաղտնաբառ' &&
                  apiErrorMsg != 'Գաղտնաբառն արդեն օգտագործվել է։ Խնդրում ենք ընտրել այլ գաղտնաբառ։' && (
                <ErrorAlert
                  text={apiErrorMsg}
                  close={() => this.setState({ showError: false })}
                />
              )}
            </>
          ) : (
              <div className={'user-action-box'}>
                <Button
                  className="user-pass-btn"
                  action={() => this.setState({ editPass: true })}
                  text="Փոխել գաղտնաբառը"
                />
                <Button
                    className="user-delete-btn"
                    action={() => this.handleDeleteUserAccountModal(true)}
                    text="Ջնջել հաշիվը"
                />
              </div>
          )}
        </div>
        <AccountPageModal handleDeleteUserAccountModal={this.handleDeleteUserAccountModal} deleteUserAccountModal={this.state.deleteUserAccountModal}/>
      </>
    );
  }
}
export default PasswordSideBar;
