import axios from 'axios'
import {
    ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_AFFILIATION,
    ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_OTHER_CONNECTIONS
} from "../components/articleReadMore/constants";
import GatewayAPI from "../server/gatewayAPI";
import IdentityAPI from "../server/IdentityAPI";

const extractData = (res) => {
    console.log(res,'res')
    const { data } = res
    if (data.accepted && data.data[0]) {
        if (data.data && data.data[0]) {
            return data.data[0]
        }
    }
    return []
}
const extractDataByStatus = (res) => {
    const { data } = res
    if (res.status === 200 && data) return data
    return []
}
export const getSpriteURLAPI = (styleId) =>{
    return axios
        .get(`${process.env.REACT_APP_GIS_URL}/MapboxStyles/${styleId}/Sprite`)
        .then(extractDataByStatus)
}
export const getSpriteAPI = (spriteUrl) =>{
    return fetch(`${spriteUrl}@2x.json`)
        .then(res=>res.json())
}

