import React from "react";
import { useDrag, useDrop } from "react-dnd";
import MapMoke from "../../../assets/imgs/mapMoke.svg";
import EditSlideIcon from '../../../assets/imgs/icons/editSlide.svg'
import DeleteSlideIcon from '../../../assets/imgs/icons/deleteSlide.svg'
import InfoIcon from '../../../assets/imgs/icons/slideInfo.svg'
import SaveSlideIcon from '../../../assets/imgs/icons/saveSlide.svg'
import { connect } from "react-redux";
import { getSlidesData } from "../../../store/selectors";
import DefaultImage from "../../../assets/imgs/defaultImage.png"
import Loader from "../Loader";

const EmptySquares = ({
                             id,
                             index,
                             moveSquare,
                             selectedId,
                             onSelect,
                             handleEdit,
                             handleDelete,
                             handleSave,
                             title,
                             showInfo,
                             imageUrl,
                             isLoading
                         }) => {


    const [, drag] = useDrag({
        type: "SQUARE",
        item: { id, index },
    });

    const [, drop] = useDrop({
        accept: "SQUARE",
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveSquare(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });
    return (
        <div className="square__container">
            <div id="screenshot-image"></div>
                <div
                    ref={(node) => drag(drop(node))}
                    className={`mini__square empty__square`}
                >
                    {isLoading && <Loader/>}
                </div>
        </div>
    );
};

const mapStateTopProps = (state) => ({
    isFilled: getSlidesData(state),
});

const mapDispatchToProps = {

};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(EmptySquares);
