import { COLORS_DATA, SIZE_DATA } from "../../utils/color_picker_data";
import {
  SET_COLOR_PICKER_SHOW_STATE,
  SET_ERASER_CLICKED_STATE,
  SET_PAINTER_COLOR,
  SET_PAINTER_COLOR_DATA,
  SET_PAINTER_SIZE,
  SET_PAINTER_SIZE_DATA,
  SET_PAINTER_START,
  SET_RULER_CLICKED_STATE,
  SET_START_POINTER_CLICK_STATE,
} from "../constants";

const initialState = {
  data: false,
  startPaintClickState: false,
  painterColor: "#000000",
  painterColorData: COLORS_DATA,
  painterSize: 8,
  painterSizeData: SIZE_DATA,
  colorPickerShowState: false,
  rulerClickedState: false,
  eraserClickedState: false,
};

export const painterStartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAINTER_START:
      return {
        ...state,
        data: action.payload,
      };
    case SET_START_POINTER_CLICK_STATE:
      return {
        ...state,
        startPaintClickState: action.payload,
      };
    case SET_PAINTER_COLOR:
      return {
        ...state,
        painterColor: action.payload,
      };
    case SET_PAINTER_COLOR_DATA:
      return {
        ...state,
        painterColorData: action.payload,
      };
    case SET_PAINTER_SIZE:
      return {
        ...state,
        painterSize: action.payload,
      };
    case SET_PAINTER_SIZE_DATA:
      return {
        ...state,
        painterSizeData: action.payload,
      };
    case SET_COLOR_PICKER_SHOW_STATE:
      return {
        ...state,
        colorPickerShowState: action.payload,
      };
    case SET_RULER_CLICKED_STATE:
      return {
        ...state,
        rulerClickedState: action.payload,
      };
    case SET_ERASER_CLICKED_STATE:
      return {
        ...state,
        eraserClickedState: action.payload,
      };
    default:
      return state;
  }
};
