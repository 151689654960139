import {
  SET_PAINTER_GEO_JSON,
  SET_PAINTER_LAYER_LINE_NAME_DATA,
  SET_PAINTER_PREV_DATA,
  SET_PAINTER_PREV_LINE_NAME_DATA,
} from "../constants";

export const setPainterGeoJsonData = (state) => ({
  type: SET_PAINTER_GEO_JSON,
  payload: state,
});

export const setPainterPrevData = (state) => ({
  type: SET_PAINTER_PREV_DATA,
  payload: state,
});

export const setPainterPrevLineNameData = (state) => ({
  type: SET_PAINTER_PREV_LINE_NAME_DATA,
  payload: state,
});

export const setPainterLayerLineNameData = (state) => {
  return {
    type: SET_PAINTER_LAYER_LINE_NAME_DATA,
    payload: state,
  };
};
