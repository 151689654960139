export const checkValidationErrors = (fields) => {
  let errors = {}
  let hasError = false
  //firstName
  if (!fields["firstName"]) {
    hasError = true
    errors["firstName"] = "Պարտադիր դաշտ"
  }else if (fields["firstName"].length <2) {
    hasError = true
    errors["firstName"] = "Դաշտը պետք է պարունակի առնվազն 2 նիշ"
  }

  //lastName
  if (!fields["lastName"]) {
    hasError = true
    errors["lastName"] = "Պարտադիր դաշտ"
  }else if (fields["lastName"].length <2) {
    hasError = true
    errors["lastName"] = "Դաշտը պետք է պարունակի առնվազն 2 նիշ"
  }

  //Email
  if (!fields["email"] && fields["selectedRegisterType"] === "Email") {
    hasError = true
    errors["email"] = "Պարտադիր դաշտ"
  }

  if (typeof fields["email"] !== "undefined") {
    let lastAtPos = fields["email"].lastIndexOf("@")
    let lastDotPos = fields["email"].lastIndexOf(".")
    if (
      !(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      fields["email"].indexOf("@@") == -1 &&
      lastDotPos > 2 &&
      fields["email"].length - lastDotPos > 2 &&
      !/\s/.test(fields['email'])
      )
    ) {
      hasError = true
      errors["email"] = "Խնդրում ենք մուտքագրել վավեր էլ․ հասցե"
    }
  }

  //speciality
  if (!fields["speciality"]) {
    hasError = true
    errors["speciality"] = "Պարտադիր դաշտ"
  }
  if (!fields["phoneNumber"] && fields["selectedRegisterType"] === "Phone") {
    hasError = true
    errors["phoneNumber"] = "Պարտադիր դաշտ"
  }

  //Password
  if (!fields["password"]) {
    hasError = true
    errors["password"] = "Պարտադիր դաշտ"
  }

  if (fields["password"]?.length < 6) {
    hasError = true
    errors["password"] = "Գաղտնաբառը պետք է պարունակի առնվազն 6 նիշ"
  }

  //CPassword
  if (!fields["cPassword"]) {
    hasError = true
    errors["cPassword"] = "Պարտադիր դաշտ"
  }

  if (fields["password"] !== fields["cPassword"]) {
    hasError = true
    errors["cPassword"] = "Խնդրում ենք մուտքագրել վավեր գաղտնաբառ։"
  }

  return hasError ? errors : null
}
