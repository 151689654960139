export const checkAccountValidationErrors = (fields) => {
  let errors = {};
  let hasError = false;
  //firstName
  if (!fields["firstName"]) {
    hasError = true;
    errors["firstName"] = "Պարտադիր դաշտ";
  }

  //lastName
  if (!fields["lastName"]) {
    hasError = true;
    errors["lastName"] = "Պարտադիր դաշտ";
  }
  if (fields.speciality.text === 'Other' && !fields["occupation"]) {
    hasError = true;
    errors["occupation"] = "Պարտադիր դաշտ";
  }

  //firstName
  if (!fields["phone"]) {
    hasError = true;
    errors["phone"] =
      "Պարտադիր դաշտ։";
  }

  if (fields["phone"]?.search(/[0-9]/) == -1) {
    hasError = true;
    errors["phone"] =
      "Պարտադիր դաշտ։";
  }

  if (fields["phone"]?.length > 11) {
    hasError = true;
    errors["phone"] =
      "Պարտադիր դաշտ։";
  }
  if (!fields["region"]) {
    hasError = true;
    errors["region"] =
      "Պարտադիր դաշտ։";
  }
  if (!fields["school"] && fields['speciality']?.text !== "Other") {
    hasError = true;
    errors["school"] =
        "Պարտադիր դաշտ։";
  }
  if (!fields["state"]) {
    hasError = true;
    errors["state"] =
        "Պարտադիր դաշտ։";
  }
  if (!fields["city"]) {
    hasError = true;
    errors["city"] =
        "Պարտադիր դաշտ։";
  }
  if(fields['speciality']?.text === "Teacher" && fields['subject']?.length === 0 && fields["country"].label === 'Հայաստան' ){
    hasError = true;
    errors["subject"] =
        "Պարտադիր դաշտ։";
  }
  return hasError ? errors : null;
};

export const checkResetPassValidationErrors = (fields) => {
  let errors = {};
  let hasError = false;
  if (!fields["oldPassword"]) {
    hasError = true;
    errors["oldPassword"] = "Պարտադիր դաշտ";
  }

  //Password
  if (!fields["password"]) {
    hasError = true;
    errors["password"] = "Պարտադիր դաշտ";
  }

  if (fields["password"] && fields["password"].length < 6) {
    hasError = true;
    errors["password"] =
      "Գաղտնաբառը պետք է պարունակի առնվազն 6 նիշ";
  }

  //CPassword
  if (!fields["cPassword"]) {
    hasError = true;
    errors["cPassword"] = "Պարտադիր դաշտ";
  }

  if (fields["password"] != fields["cPassword"]) {
    hasError = true;
    errors["cPassword"] = "Խնդրում ենք մուտքագրել վավեր գաղտնաբառ։";
  }

  return hasError ? errors : null;
};

export const checkAdditionalValidationErrors = (fields) => {
  let errors = {};
  let hasError = false;
  //lastName
  if (!fields["gender"]?.value) {
    hasError = true;
    errors["gender"] = "Պարտադիր դաշտ";
  }

  if (!fields["birthDate"]) {
    hasError = true;
    errors["birthDate"] = "Պարտադիր դաշտ";
  }

  if (!fields["country"]?.value) {
    hasError = true;
    errors["country"] = "Պարտադիր դաշտ";
  }

  if (!fields["region"]?.value) {
    hasError = true;
    errors["region"] = "Պարտադիր դաշտ";
  }
  if (!fields["state"]?.value) {
    hasError = true;
    errors["state"] = "Պարտադիր դաշտ";
  }
  if (fields.speciality.text != 'Other' && !fields["school"]?.value) {
    hasError = true;
    errors["school"] = "Պարտադիր դաշտ";
  }
  if (fields.speciality.text === "Student" && !fields["grade"]?.value) {
    hasError = true;
    errors["grade"] = "Պարտադիր դաշտ";
  }
  if (fields.speciality.text === "Teacher" && fields["subject"]?.length === 0) {
    hasError = true;
    errors["subject"] = "Պարտադիր դաշտ";
  }
  if (fields.type === "Email" && !fields.changeEmail) {
    hasError = true;
    errors["email"] = "Խնդրում ենք մուտքագրել էլ․ հասցե";
  }
  if (fields.type === "Phone" && !fields.changePhoneNumber) {
    hasError = true;
    errors["phoneNumber"] = "Խնդրում ենք մուտքագրել հեռախոսահամար";
  }
  if (fields.type === "Phone" && fields.changePhoneNumber?.length && (fields.changePhoneCode === undefined || fields.changePhoneCode === '374')) {
    if(fields.changePhoneNumber.length < 8)
    hasError = true;
    errors["phoneNumber"] = "Խնդրում ենք մուտքագրել վավեր հեռախոսահամար";
  }
  if (typeof fields["changeEmail"] !== "undefined") {
    const lastAtPos = fields["changeEmail"].lastIndexOf("@");
    const lastDotPos = fields["changeEmail"].lastIndexOf(".");
    if (
        !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["changeEmail"].indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            fields["changeEmail"].length - lastDotPos > 2 &&
            !/\s/.test(fields['changeEmail'])
        )
    ) {
      hasError = true;
      errors["email"] = "Խնդրում ենք մուտքագրել վավեր էլ․ հասցե";
    }
  }
  return hasError ? errors : null;
};
