import {
  CHANGE_MAP_REQUIREMENTS,
  CHANGE_MAP_REQUIREMENTS_SCREEN_SHOT,
} from "../constants";

export const changeMapRequirements = (value) => {
  return {
    type: CHANGE_MAP_REQUIREMENTS,
    payload: value,
  };
};

export const changeMapRequirementsScreenShot = (value) => {
  return {
    type: CHANGE_MAP_REQUIREMENTS_SCREEN_SHOT,
    payload: value,
  };
};
