import {
  SET_COMPASS_COORDINATES, SET_DISABLE_COMPASS,
  SET_NEW_ANGLE,
  SET_NEW_ROTATE,
  SET_RESET_COMPASS,
} from "../constants";

const initialState = {
  newAngle: null,
  newRotate: null,
  compassCoordinates: {},
  resetCompass: false,
  disable: false,
};

export const compassReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_ANGLE:
      return {
        ...state,
        newAngle: action.payload,
      };
    case SET_NEW_ROTATE:
      return {
        ...state,
        newRotate: action.payload,
      };
    case SET_COMPASS_COORDINATES:
      return {
        ...state,
        compassCoordinates: action.payload,
      };
    case SET_RESET_COMPASS:
      return {
        ...state,
        resetCompass: action.payload,
      };
    case SET_DISABLE_COMPASS:
      return {
        ...state,
        disable: action.payload,
      };
    default:
      return state;
  }
};
