import { connect } from "react-redux";
import "./style.css";
import DynamicTitle from "../../CustomCardComponents/DynamicTitle";
import Line from "../../CustomCardComponents/Line";
import CardBody from "../../CustomCardComponents/CardBody";
import CloseDark from "../../CloseDark";
import CloseBtn from "../../CustomCardComponents/CloseBtn";
import {
    getScreenShotDataFromAPI,
    removeScreenShotCardAPI,
} from "../../../../store/actions/mapStateAction";
import { getRemoveCardId } from "../../../../store/selectors";
const cardBodyStyle = {
    aspectRatio: "677/255",
    gridTemplateRows: "1fr 1fr",
    padding: "20px",
    backgroundColor: '#fff',
};
const SlideSave = (props) => {
    const { onClose } = props;

    // const queryParams = new URLSearchParams(window.location.search);
    // let pageLoc = +queryParams.get("page");


    const onSave = () => {
        onClose()
    };

    return (
        <div className="slide-save-content">
            <CardBody hasIcon={false} styles={cardBodyStyle}>
                <div className={"slide-save-container"}>
                    <div className="slide-title">
                        <p>
                            Ցանկանո՞ւմ եք պահապանել փոփոխություններն էջում
                        </p>
                    </div>
                    <div className="slide_zone">
                        <button
                            className="slide_submit slide_buttons"
                            onClick={onClose}
                        >
                            Այո
                        </button>
                        <button
                            className="slide_cancel slide_buttons"
                            onClick={onSave}
                        >
                            Ոչ
                        </button>
                    </div>
                </div>
            </CardBody>
        </div>
    );
};

const mapStateTopProps = (state) => ({
    getRemoveCardId: getRemoveCardId(state),
});

const mapDispatchToProps = {
    removeScreenShotCardAPI: removeScreenShotCardAPI,
    getScreenShotDataFromAPI,
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(SlideSave);
