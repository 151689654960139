import React from "react";

const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.316"
      height="21.316"
      viewBox="0 0 21.316 21.316"
    >
      <path
        d="M2.553-1H16.763a3.557,3.557,0,0,1,3.553,3.553V14.987a3.557,3.557,0,0,1-3.553,3.553H2.553A3.557,3.557,0,0,1-1,14.987V2.553A3.557,3.557,0,0,1,2.553-1ZM16.763,16.763a1.778,1.778,0,0,0,1.776-1.776V2.553A1.778,1.778,0,0,0,16.763.776H2.553A1.778,1.778,0,0,0,.776,2.553V14.987a1.778,1.778,0,0,0,1.776,1.776Z"
        transform="translate(1 2.776)"
        fill="#8a8a8a"
      />
      <path
        d="M19.428.776H-.112A.888.888,0,0,1-1-.112.888.888,0,0,1-.112-1h19.54a.888.888,0,0,1,.888.888A.888.888,0,0,1,19.428.776Z"
        transform="translate(1 8.105)"
        fill="#8a8a8a"
      />
      <path
        d="M-.112,4.329A.888.888,0,0,1-1,3.441V-.112A.888.888,0,0,1-.112-1a.888.888,0,0,1,.888.888V3.441A.888.888,0,0,1-.112,4.329Z"
        transform="translate(10.77 1)"
        fill="#8a8a8a"
      />
      <path
        d="M-.112,4.329A.888.888,0,0,1-1,3.441V-.112A.888.888,0,0,1-.112-1a.888.888,0,0,1,.888.888V3.441A.888.888,0,0,1-.112,4.329Z"
        transform="translate(5.441 1)"
        fill="#8a8a8a"
      />
      <path
        d="M-.112,4.329A.888.888,0,0,1-1,3.441V-.112A.888.888,0,0,1-.112-1a.888.888,0,0,1,.888.888V3.441A.888.888,0,0,1-.112,4.329Z"
        transform="translate(16.099 1)"
        fill="#8a8a8a"
      />
      <path
        d="M1.665.776H-.112A.888.888,0,0,1-1-.112.888.888,0,0,1-.112-1H1.665a.888.888,0,0,1,.888.888A.888.888,0,0,1,1.665.776Z"
        transform="translate(4.553 12.546)"
        fill="#8a8a8a"
      />
      <path
        d="M1.665.776H-.112A.888.888,0,0,1-1-.112.888.888,0,0,1-.112-1H1.665a.888.888,0,0,1,.888.888A.888.888,0,0,1,1.665.776Z"
        transform="translate(9.882 12.546)"
        fill="#8a8a8a"
      />
      <path
        d="M1.665.776H-.112A.888.888,0,0,1-1-.112.888.888,0,0,1-.112-1H1.665a.888.888,0,0,1,.888.888A.888.888,0,0,1,1.665.776Z"
        transform="translate(15.211 12.546)"
        fill="#8a8a8a"
      />
      <path
        d="M1.665.776H-.112A.888.888,0,0,1-1-.112.888.888,0,0,1-.112-1H1.665a.888.888,0,0,1,.888.888A.888.888,0,0,1,1.665.776Z"
        transform="translate(4.553 16.099)"
        fill="#8a8a8a"
      />
      <path
        d="M1.665.776H-.112A.888.888,0,0,1-1-.112.888.888,0,0,1-.112-1H1.665a.888.888,0,0,1,.888.888A.888.888,0,0,1,1.665.776Z"
        transform="translate(9.882 16.099)"
        fill="#8a8a8a"
      />
      <path
        d="M1.665.776H-.112A.888.888,0,0,1-1-.112.888.888,0,0,1-.112-1H1.665a.888.888,0,0,1,.888.888A.888.888,0,0,1,1.665.776Z"
        transform="translate(15.211 16.099)"
        fill="#8a8a8a"
      />
    </svg>
  );
};
export default CalendarIcon;
