import {
    ADD_SLIDE,
    SET_PLAY_SLIDES,
    SET_SEE_ALL_SLIDES, SET_SELECTED_SLIDE,
    SET_SLIDES_ITEMS,
    SET_SLIDES_LOADING,
    SET_FILTERED_TOTAL_SLIDES,
    REPLACE_SLIDE
} from "../constants";
import {stringify} from "flatted";
import {
    extractItems,
    extractResult,
    presentationDeleteMethod, presentationPatchMethod,
    presentationPostMethod, presentationPutMethod
} from "../../service/presentationMode";
import {removeScreenShotCardAPI, setScreenShotButtonStateST} from "./mapStateAction";
import {genPatchBody} from "../../utils/utils";

export const setSlidesItemsData = data => ({
    type: SET_SLIDES_ITEMS,
    payload: data
});
export const setSelectedSlideData = data => ({
    type: SET_SELECTED_SLIDE,
    payload: data
});
export const addSlideData = (data) => ({
    type: ADD_SLIDE,
    payload: data
});
export const replaceSlideData = (data,index) => ({
    type: REPLACE_SLIDE,
    payload: {data,index}
});
export const setSlidesLoading= (bool) => ({
    type: SET_SLIDES_LOADING,
    payload: bool
});
export const setPlayPresentation = (bool) => ({
    type: SET_PLAY_SLIDES,
    payload: bool
});
export const setSeeAll = (bool) => ({
    type: SET_SEE_ALL_SLIDES,
    payload: bool
});
export const setFilteredTotal = (length) => ({
    type: SET_FILTERED_TOTAL_SLIDES,
    payload: length
});

// export const setSlides = (bool, id, emptyArray) => (dispatch) => {
//     dispatch(setSlidesData(bool, id, emptyArray));
// };
export const setSelectedSlide = data => (dispatch) => {
    dispatch(setSelectedSlideData(data));
};
export const setSlidesItems = data => (dispatch) => {
    dispatch(setSlidesItemsData(data));
};
export const setSlidesItemsByOrder = (data) => (dispatch) => {
    data.map((el, index) => {el.order = index; return el})
    dispatch(setSlidesItemsData(data));
};
export const setPlaySlides = (bool) => (dispatch) => {
    dispatch(setPlayPresentation(bool));
};

export const setSeeAllSlides = (bool) => (dispatch) => {
    dispatch(setSeeAll(bool))
}
export const getMultipleSlidsByPresentationAPI = (ids,search,type='new') => (dispatch,states) => {
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Slide/DataProvider`
    const body = {
        "page": 1,
        "perPage": 1000,
        "columnFiltersMany": {
            "presentationId": ids,
        },
        "sort": [
            {
                "field": "order",
                "type": "asc"
            }
        ],
        ...search
    }
    return presentationPostMethod(url,body)
        .then((resp)=>{
            dispatch(setFilteredTotal(resp.data.data[0].filteredTotal))
            return resp
        })
        .then(extractItems)
        .then(resp=>{
            if(type === 'add' && body.page > 1){
                dispatch(addSlideData(resp))
            }else{
                dispatch(setSlidesItemsData(resp));
            }
        })

}

export const addMultipleSlidesAPI = (presentationId,ids) => (dispatch,states) => {
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Slide/MapStateBatchImport`;
    const body ={
        "presentationId": presentationId,
        "mapStateIds": ids
    }
    return presentationPostMethod(url, body)
                .then(extractResult)
}
export const createSlideAPI = (title, data,index,locationData) => (dispatch,states) => {
    const state = states()
    const selectedSlide = state?.slides?.selectedSlide
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Slide`;
    const body = {
        "presentationId": state.presentationMode.data?.id,
        "title":  'Էջ',
        "shortDescription": "",
        "description": "",
        "transitionDuration": 10,
        "imageUrl": data?.imageUrl,
        "state": stringify(data),
        "order":selectedSlide?.order + 1 || null,
        ...locationData
    };
    dispatch(setSlidesLoading(true));
    return presentationPostMethod(url, body)
        .then(extractResult)
        .then((resp) => {
            dispatch(addSlideData([resp]))
            return data
        })

};

export const removeSlideAPI = (id) => (dispatch,states) => {
    const state = states()
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Slide/${id}`;
    dispatch(setSlidesLoading(true));
    return presentationDeleteMethod(url)
        .then(extractResult)
};
export const updateSlideOrderAPI = (id,order) => (dispatch,states) => {
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Slide/${id}/${order}`;
    return presentationPutMethod(url)
        .then(extractResult)
};
export const editSlideAPI = (id,data) => (dispatch,states) => {
    const state = states()
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Slide/${id}`;
    const body = Object.keys(data)?.map(el=>(
        {
            key:el,
            value:data[el]
        }
    ))
    dispatch(setSlidesLoading(true));
    return presentationPatchMethod(url,genPatchBody(body))
        .then(extractResult)
        .then((resp) => {
            dispatch(replaceSlideData(resp,resp.order-1))
            return data
        })
};
