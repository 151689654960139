import React, {useEffect, useRef, useState} from "react";
import { connect } from "react-redux";
import "./style.css";
import PresentationIcon from "../../Presentation";
import CloseIcon from '../../../../assets/imgs/icons/Close-blue.svg'
import SliderCards from "./SliderCards";
import plusIcon from '../../../../assets/imgs/icons/plus.svg'

import {
    addMultipleSlidesAPI,
    getMultipleSlidsByPresentationAPI,
    setSlidesItems
} from "../../../../store/actions/slides";
import {
    getPresentationData,
    getPresentationFilteredTotal,
    getPresentationModeData,
    getSlidesData
} from "../../../../store/selectors";
import {getPresentationAPI, setMenu} from "../../../../store/actions/presentationMode";
import SearchSection from "../SearchSection";
import {getAllPresentationsFromAPI} from "../../../../store/actions/presentation";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Loader";

const ChooseSlides = (props) => {
    const { handleNextPage,onClose,filteredTotal, onCancel, setGetNewData, selectedId, setSelectedId, openSlides, multiple=true, data, selectedPresentation, handleSetSearchParams} = props;
    const [activeIndices, setActiveIndices] = useState([]);
    const [activeItemId, setActiveItemId] = useState(null);
    const handleSingleCardClick = (id) => {
        if (activeItemId === id) {
            setActiveItemId(null);
        } else {
            setActiveItemId(id);
        }
    };

    const handleCardClick = (id) => {
        setActiveIndices((prevActiveIndices) => {
            if (prevActiveIndices.includes(id)) {
                return prevActiveIndices.filter((i) => i !== id);
            } else {
                return [...prevActiveIndices, id];
            }
        });
    };


    const handleChoose = () => {
                const ids = activeIndices.map(el=>(el + ''))
                 props.addMultipleSlidesAPI(selectedPresentation?.id,ids)
                        .then(()=>{
                            return setGetNewData(true)
                        })
            // })

        // if(activeIndices.length <= 1) {
        //     if(slides.find(el => el.id === selectedId)) {
        //         setSlidesItems(true, selectedId+1)
        //     } else {
        //         setSlidesItems(true, selectedId)
        //     }
        // } else {
        //     activeIndices.forEach((value,index) => {
        //         const currentId = selectedId + index;
        //         if (slides.find(slide => slide.id === currentId)) {
        //             setSlidesItems(true, currentId);
        //         } else {
        //             setSlidesItems(true, currentId);
        //         }
        //     });
        // }
        setSelectedId('')
        onClose()
    };

    const handleOpenSlider = (id) => {
        openSlides(id? id : activeItemId)
        onClose()
    }
    return (
        <div className="card-body__choose-slide-form">
            <div className='choose__header__container'>
                <div className='first__section'>
                    <PresentationIcon isActive={false}/>
                    <h5>{multiple ? 'Իմ պահպանված քարտեզները' : 'Իմ ցուցադրությունները'}</h5>
                </div>
                <button onClick={() => onCancel ? onCancel() : onClose()}>
                    <img src={CloseIcon}/>
                </button>
            </div>
            <SearchSection handleSetSearchParams={handleSetSearchParams}/>
              <InfiniteScroll
                dataLength={data.length}
                next={handleNextPage}
                hasMore={filteredTotal > data.length}
                loader={<div style={{scale:'0.5',width:'100%',display:'flex',justifyContent:'center'}}> <Loader /></div>}
                className='cards__container'
                height={500}
              >
                  {!multiple && <SliderCards
                      key={'new'}
                      active={
                          false
                      }
                      imageUrl={plusIcon}
                      onClick={props.createNew}
                      text=''
                  />}
                  {data.map((el) => (
                    <SliderCards
                      key={el.id}
                      active={
                          multiple
                            ? activeIndices.includes(el.id)
                            : activeItemId === el.id
                      }
                      imageUrl={el.imageUrl}
                      onClick={() => multiple ? handleCardClick(el.id) : handleSingleCardClick(el.id)}
                      onDoubleClick={() => !multiple && handleOpenSlider(el.id)}
                      text={el.title}
                    />
                  ))}
                  {!data.length && multiple && <div className="empty__data">
                      Ցուցադրություններ չեն գտնվել
                  </div>}
              </InfiniteScroll>
            <div className='button__container'>
                <button
                    disabled={!activeIndices.length && typeof activeItemId !== 'number'}
                    className={`add_slide_button ${!activeIndices.length && typeof activeItemId !== 'number' ? 'disabled__button' : ''}`}
                    onClick={()=>multiple ? handleChoose() : handleOpenSlider()}>
                    {multiple ? 'Ավելացնել' : 'Բացել'}
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    setSlidesItems: setSlidesItems,
    setIsPresentationMode: setMenu,
    getAllPresentationsFromAPI:getAllPresentationsFromAPI,
    // getMultipleSlidsByPresentationAPI:getMultipleSlidsByPresentationAPI,
    addMultipleSlidesAPI:addMultipleSlidesAPI,
    getPresentationAPI:getPresentationAPI,
};
const mapStateToProps = (state) => ({
    slides: getSlidesData(state),
    presentationsData: getPresentationData(state),
    selectedPresentation: getPresentationModeData(state),

});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseSlides);
