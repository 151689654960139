import {getData, getZippedData} from "../pages/map/utils";
export const getDateTime = (url) => {
    const parsedUrl = url.split('/')
    const file = parsedUrl[parsedUrl.length - 1]
    const dateJson = file.split('_')[1]
    return dateJson.split('.')[0]
};

export const getFileName = (url) => {
    const parsedUrl = url.split('/')
    const file = parsedUrl[parsedUrl.length - 1]
    return file.split('.')[0]
};



const getFromLocalStorage = async () => {
    const storage = await localStorage.getItem ("geoJsonData")
    if (storage === null || storage === undefined || typeof storage === 'undefined') {
        return {
            'topic': [],
            'lesson': [],
            'article':[]
        }
    }
    return JSON.parse(storage)
}

const findItemFromData = async (data, type, id) => {
    return data[type].find (x => x.id === id)
}

const setToLocalStorage = async (data) => {
    try {
        await localStorage.setItem('geoJsonData', JSON.stringify(data))
    }
    catch (e) {
        console.log(e,'local error')
    }
}

export const getGeoJsonDataFromStorage = async (url, type, id) => {

    let geoData

    let geoJSONData = await getFromLocalStorage()
    const date = getDateTime (url)
    const filteredData = await findItemFromData(geoJSONData, type, id)
    const index = geoJSONData[type].findIndex(x => x.id === id)
    if (filteredData !== undefined || typeof filteredData !== 'undefined') {
        const storageDate = filteredData.time
        if (storageDate === date) {
            geoData = filteredData.data
        } else {
            geoData = await getZippedData (url)
            geoJSONData[type][index].time = date
            geoJSONData[type][index].data = geoData
            await setToLocalStorage (geoJSONData)
        }
    } else {
        geoData = await getZippedData (url)
        geoJSONData[type].push({
            id: id,
            time: date,
            data: geoData
        })
        await setToLocalStorage (geoJSONData)
    }
    return geoData
};
