import {
    SET_PLAY_SLIDES,
    SET_SLIDES_LOADING,
    SET_SEE_ALL_SLIDES,
    SET_SLIDES_ITEMS,
    ADD_SLIDE,
    SET_SELECTED_SLIDE,
    SET_FILTERED_TOTAL_SLIDES,
    REPLACE_SLIDE,
} from "../constants";

const initialState = {
    data: [],
    selectedSlide: [],
    isLoading:false,
    isPlaying: false,
    isSeeAllMode: false,
    filteredTotal: 0,
};

export const slidesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SLIDES_ITEMS:
            return {
                ...state,
                isLoading:false,
                data:action.payload
            };
        case ADD_SLIDE:
            return {
                ...state,
                isLoading:false,
                data:[...state.data,...action.payload]
            };
        case SET_SELECTED_SLIDE:
            return {
                ...state,
                selectedSlide:action.payload,
            };
        case SET_SLIDES_LOADING:
            return {
                ...state,
                isLoading:action.payload,
            };
        case REPLACE_SLIDE:
            const {index,data} = action.payload
            const newData = JSON.parse(JSON.stringify(state.data))
            newData[index] = data
            return {
                ...state,
                data: newData,
            }
        case SET_FILTERED_TOTAL_SLIDES:
            return {
                ...state,
                filteredTotal:action.payload,
            };
        default:
            return state;
    }
};


export const slidesManipulation = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLAY_SLIDES:
            return {
                ...state,
                isPlaying: action.payload,
            };
        case SET_SEE_ALL_SLIDES:
            return {
                ...state,
                isSeeAllMode: action.payload,
            }
        default:
            return state;
    }
};
