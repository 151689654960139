const Text = ({ show, disable }) => {
  return disable
          ? (<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none" opacity={0.5}>
              <path d="M4.22656 11.3525V8.47087C4.22656 6.65004 5.69906 5.19337 7.50406 5.19337H30.4941C32.3149 5.19337 33.7716 6.66587 33.7716 8.47087V11.3525" stroke="#8D9092" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19 32.8068V6.50768" stroke="#8D9092" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.7617 32.8066H25.2384" stroke="#8D9092" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>)
          :show
              ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                      <path d="M4.22656 11.3525V8.47087C4.22656 6.65004 5.69906 5.19337 7.50406 5.19337H30.4941C32.3149 5.19337 33.7716 6.66587 33.7716 8.47087V11.3525" stroke="#292D32" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M19 32.8068V6.50768" stroke="#292D32" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12.7617 32.8066H25.2384" stroke="#292D32" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                )
              : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 40 38" fill="none" opacity="0.5">
                    <path d="M37.5 2L3.5 32.5" stroke="#8D9092" stroke-width="3.3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.22656 11.3525V8.47086C4.22656 6.65003 5.69906 5.19336 7.50406 5.19336H30.4941C32.3149 5.19336 33.7716 6.66586 33.7716 8.47086V11.3525" stroke="#8D9092" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19 32.8069V6.50769" stroke="#8D9092" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.7617 32.8066H25.2384" stroke="#8D9092" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              );
};
export default Text;
