import {
  SET_LAMP,
  LOADING_LAMP,
  SET_SORTED_TOOLTIP_SELECT_ITAM,
  SET_LAMP_MODAL_STATE,
  SET_LAMP_MODAL_DATA, SET_LAMP_MODAL_STATE_INDEX,
} from "../constants";

const initialState = {
  data: [],
  isLoading: false,
  sortedTooltipSelectItem: -1,
  lampModalStateIndex:null,
  lampModalState: false,
  lampModalData: [],
};

export const lampReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_LAMP: {
      return { ...state, isLoading: true };
    }
    case SET_LAMP:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case SET_SORTED_TOOLTIP_SELECT_ITAM:
      return {
        ...state,
        sortedTooltipSelectItem: action.payload,
      };
    case SET_LAMP_MODAL_STATE:
      return {
        ...state,
        lampModalState: action.payload,
      };
      case SET_LAMP_MODAL_STATE_INDEX:
      return {
        ...state,
        lampModalStateIndex: action.payload,
      };
    case SET_LAMP_MODAL_DATA:
      return {
        ...state,
        lampModalData: action.payload,
      };
    default:
      return state;
  }
};
