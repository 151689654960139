import React from "react"

const ErrorCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="68"
      viewBox="0 0 68 68"
    >
      <g
        id="Group_2986"
        data-name="Group 2986"
        transform="translate(-1086.654 -831.655)"
      >
        <path
          id="Path_1429"
          data-name="Path 1429"
          d="M34,0A34,34,0,1,1,0,34,34,34,0,0,1,34,0Z"
          transform="translate(1086.654 831.655)"
          fill="#eb572c"
        />
        <g
          id="Group_35034"
          data-name="Group 35034"
          transform="translate(400.154 556.654)"
        >
          <line
            id="Line_140"
            data-name="Line 140"
            x2="23"
            y2="23"
            transform="translate(709.5 298)"
            fill="none"
            stroke="#fff"
            stroke-width="6"
          />
          <line
            id="Line_141"
            data-name="Line 141"
            x1="23"
            y2="23"
            transform="translate(709.5 298)"
            fill="none"
            stroke="#fff"
            stroke-width="6"
          />
        </g>
      </g>
    </svg>
  )
}
export default ErrorCheck
