import { connect } from "react-redux";
import "./style.css";
import {useState} from "react";
import defaultImage from "../../../../assets/imgs/function_set_default_image_when_image_not_present.png";

const PresentationImageInfo = (props) => {
    const {imageUrl,slidesCount} = props;
    const [screenShotImage, setScreenShotImage] = useState(imageUrl);
    const handleSetImage = (src) => setScreenShotImage(()=>src)
    const handleImageError  = () => handleSetImage(defaultImage)

    return (
        <div className="card-body__presentation-info-image-box presentation-info-image-box" data-count={slidesCount}>
            <div className="card-body__presentation-info-image presentation-info-image">
                <div className={'card-body__presentation-info-image-box-contour image-box-contour-1'}></div>
                <div className={'card-body__presentation-info-image-box-contour image-box-contour-2'}></div>
                <div className="presentation-image">
                    <img src={screenShotImage ?? ''} alt="screen-shot-image" onError={handleImageError} />
                </div>
            </div>
        </div>
    );
};
export default connect(null, null)(PresentationImageInfo);
