import { useRef, useState } from "react";
import { connect } from "react-redux";
import { OutlinedInput, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import "./style.css";
import { editScreenShotCardAPI } from "../../../../store/actions/mapStateAction";

const ScreenShotEdit = (props) => {
  const { item, onClose, editScreenShotCardAPI } = props;

  const [text, setText] = useState(props.title);

  const [editButtonDisable, setEditButtonDisable] = useState(true);

  const queryParams = new URLSearchParams(window.location.search);
  let pageLoc = +queryParams.get("page");

  const onScreenShotEdit = () => {
    setEditButtonDisable(false);
    editScreenShotCardAPI(
      pageLoc,
      item.userId,
      item.id,
      text,
      item.data,
      onClose
    );
  };

  return (
    <div className="card-body__edit-screenshot-title-form edit-screenshot-title-form">
      <h5>Վերնագիր`</h5>
      <div className="edit-screenshot-title-form__btn-box">
        <OutlinedInput
          onChange={(e) => setText(e.target.value)}
          defaultValue={item.title}
          variant="outlined"
          autoFocus={true}
        />
      </div>
      <Stack spacing={2} direction="row" justifyContent={"center"}>
        <Button onClick={onClose} variant="outlined" color={"inherit"}>
          Չեղարկել
        </Button>
        <Button
          variant="contained"
          onClick={onScreenShotEdit}
          disabled={!editButtonDisable || !text.length}
        >
          Պահպանել
        </Button>
      </Stack>
    </div>
  );
};

const mapDispatchToProps = {
  editScreenShotCardAPI: editScreenShotCardAPI,
};

export default connect(null, mapDispatchToProps)(ScreenShotEdit);
