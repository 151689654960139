const HorizontalDots = () => {
    return (
        <svg width="8" height="28" viewBox="0 0 8 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="3.5" stroke="#2C476C"/>
            <circle cx="4" cy="14" r="3.5" stroke="#2C476C"/>
            <circle cx="4" cy="24" r="3.5" stroke="#2C476C"/>
        </svg>
    )
}
export default HorizontalDots;
