import Eye from "../UI/Eye";
import {Component} from "react";

class GroupTemplate extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            this.props.group?.className === 'isLesson' && (
                <div className='lesson__content-cat'>
                    <label className="main__subitem-label">
                        <input
                            className="main__subitem-checkbox show-checkbox"
                            type="checkbox"
                            defaultChecked={this.props.group?.show}
                        />
                        <Eye show={this.props.group?.show} />
                    </label>
                    <span className="lesson__content-svg" style={{
                        opacity: this.props.group?.show ? 1 : 0.5
                    }}>
                        <img src={this.props.group?.catIcon} style={{
                            width: 30,
                            height: 30,
                        }}/>
                    </span>
                </div>
            )
        )
    }
}
export default GroupTemplate
