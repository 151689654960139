import axios from "axios";
import { filterBySelectedSubject } from "../pages/accountBoards/utils";

const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items;
    }
  }
  return [];
};

const santizeSelectedResult = (items) =>
  items.map((value) => ({
    value: value.id,
    label: value.title,
  }));

const sortItemsByAlphabetical = (items) =>
  items.sort((a, b) =>
    a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
  );

export const getGradesAPI = (url, body) => {
  return axios
    .post(url, body)
    .then((response) => extractItems(response))
    .then((items) => santizeSelectedResult(items))
    .then((items) => sortItemsByAlphabetical(items))
    .catch(console.log);
};

const sanitizeFilteredSelectedResult = (items) =>
  items.map((value, key) => ({
    id: value.id,
    value: value.id,
    title: value.title,
    subjects: value.subjects,
  }));

const filterBySelectedGrade = (items, userInfo) => {
  if (userInfo.speciality.text === "Student" && userInfo.countryId == 11) {
    const userGradeId = userInfo.grade.value;
    if (userGradeId) {
      return items.filter((x) => x.id === userGradeId);
    }
  }
  return items;
};

const selectAPIRequest = (url, body) => {
  return axios.post(url, body).then(extractItems);
};

export const getFilteredGradesAPI = (url, body, state) => {
  return selectAPIRequest(url, body)
    .then((items) => sanitizeFilteredSelectedResult(items))
    // .then((items) => filterBySelectedGrade(items, state.user.data))
    .catch(console.error);
};
