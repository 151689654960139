import { SET_TIMELINE_EXPEND } from '../constants'

const initialState = {
    data: 1,
}

export const timeLineExpendReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TIMELINE_EXPEND:
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state
    }
}
