import {
  SET_QUIZ,
  SET_SORTED_TOOLTIP_SELECT_ITAM_QUIZ,
  SET_QUIZ_MODAL_STATE,
  SET_QUIZ_MODAL_DATA,
  LOADING_QUIZ,
  SET_SELECTED_QUIZ_MODAL_DATA,
} from "../constants";

const initialState = {
  data: [],
  isLoading: false,
  sortedTooltipSelectItem: -1,
  quizModalState: false,
  quizModalData: [],
  selectedQuizData: [],
};

export const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_QUIZ: {
      return { ...state, isLoading: true };
    }
    case SET_QUIZ:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case SET_SORTED_TOOLTIP_SELECT_ITAM_QUIZ:
      return {
        ...state,
        sortedTooltipSelectItem: action.payload,
      };
    case SET_QUIZ_MODAL_STATE:
      return {
        ...state,
        quizModalState: action.payload,
      };
    case SET_QUIZ_MODAL_DATA:
      return {
        ...state,
        quizModalData: action.payload,
      };
    case SET_SELECTED_QUIZ_MODAL_DATA:
      return {
        ...state,
        selectedQuizData: action.payload,
      };
    default:
      return state;
  }
};
