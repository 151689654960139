import { connect } from "react-redux";
import DateInfo from "../DateInfo";
import ShareBtn from "../ShareBtn";
import "./style.css";
import React from "react";
const DateSection = (props) => {
  const { content = {} } = props;

  return (
    <div className="card-body__date-section-info date-section-info">
      <div className="date-section-info__date">
        {content?.map(
          (el) => el.dateTime && <DateInfo key={el.id} content={el} />
        )}
      </div>
      <ShareBtn link={props.link} />
    </div>
  );
};
export default connect(null, null)(DateSection);
