import {SET_TIME_GEO_JSON, LOADING_TIME_GEO_JSON} from "../constants";

const timeGeoJSONInitialState = {
  data: {
    topic: {

    },
    lesson: {

    },
    article:{

    }
  },
  isLoading: false,
};

export const timeGeoJSONReducer = (state = timeGeoJSONInitialState, action) => {
  switch (action.type) {
    case LOADING_TIME_GEO_JSON: {
      return { ...state, isLoading: true };
    }
    case SET_TIME_GEO_JSON:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
