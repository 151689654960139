import React, {useEffect, useState} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import './style.css';
import {connect} from "react-redux";
import DraggableSquare from "./SlideBoxes";
import {
    getPresentationModeData,
    getSlidesData,
    getSlidesFilteredLength,
    getSlidesLoading
} from "../../../store/selectors";
import {
    getMultipleSlidsByPresentationAPI,
    setSelectedSlide,
    setSlidesItemsByOrder,
    updateSlideOrderAPI
} from "../../../store/actions/slides";
import InfiniteHorizontalScroll from "../InfiniteHorizontalScroll";
import EmptySquares from "./EmptySquares";
const PresentationSlideBoxes = (props) => {
    const [page,setPage] = useState(1)
    const [searchParamsInfo, setSearchParamsInfo] = useState({});
    const {selectedId,
        onSelect,
        handleEdit,
        handleShowInfo,
        handleDelete,
        handleSave,
        handleChooseForm,
        slides,
        showInfo=false,
        setSelectedSlide,
        setSlidesItemsByOrder,
        updateSlideOrderAPI,
        map,
        getSlidesByPresentationID,
        getNewData,
        setGetNewData,
        globalLoading
    } = props;
    const handleSelect = (id, el) => {
        if(selectedId !== id) {
            onSelect(id)
            setSelectedSlide(el)
        } else {
            onSelect('')
            setSelectedSlide('')
        }
    }
    const handleMoveSquare = (dragIndex, hoverIndex) => {
        const newSlides = [...slides];
        const draggedSlide = newSlides[dragIndex];

        newSlides.splice(dragIndex, 1);

        newSlides.splice(hoverIndex, 0, draggedSlide);

        updateSlideOrderAPI(draggedSlide?.id,hoverIndex + 1)
        setSlidesItemsByOrder(newSlides);
    };
    const handleChangePage = (e, value) => {
        const pageLoc = +value;
        if (!isNaN(pageLoc) && typeof pageLoc === "number" && pageLoc !== page) {
            setPage(() => pageLoc);
        }
    };
    const handleNextPage = () =>{
        const nextPage = page+1
        handleChangePage(null, nextPage)
    }
    const getSlidesData = () =>{
        let pageLoc = page;
        const search = {
            page,
            perPage:10,
            ...searchParamsInfo
        }
        if (pageLoc > Math.ceil(props.getSlidesFilteredLength / 10))
            pageLoc = Math.ceil(props.getSlidesFilteredLength / 10);
        if (pageLoc < 1) pageLoc = 1;
        if (!isNaN(pageLoc) && typeof pageLoc === "number") {
            if (pageLoc !== page) setPage(() => pageLoc);
            getSlidesByPresentationID([`${props.getPresentationModeData?.id}`], search,'add');
        }
    }
    useEffect(getSlidesData, [page]);
    useEffect(()=> {
       if(getNewData) {
           getSlidesData()
           setGetNewData(false)
       }
    }, [getNewData]);
    return (
        <div className="scrollable__container" >
            <DndProvider backend={HTML5Backend}>
                    <div className="draggable__squares">
                                <InfiniteHorizontalScroll
                                    fetchData={handleNextPage}
                                    fetching={props.slidesIsLoading}
                                    hasMore={props.getSlidesFilteredLength > slides?.length}
                                >

                                { !!slides.length
                                    && slides?.map((el, index) => (
                                        <DraggableSquare
                                            showInfo={showInfo}
                                            item={el}
                                            key={el.id}
                                            id={el.id}
                                            index={index}
                                            title={el.title}
                                            imageUrl={el.imageUrl}
                                            moveSquare={handleMoveSquare}
                                            selectedId={selectedId}
                                            onSelect={(id) => handleSelect(id, el)}
                                            handleEdit={handleEdit}
                                            handleDelete={handleDelete}
                                            handleSave={handleSave}
                                            handleChooseForm={handleChooseForm}
                                            openInfoPopup={handleShowInfo}
                                            map={map}
                                            globalLoading={globalLoading}
                                        />
                                    ))
                                }
                                {slides?.length < 10 &&
                                    <>
                                        {Array.from({ length: 10 - slides?.length }).map((_, index) => (
                                            <EmptySquares key={index} />
                                        ))}
                                    </>}
                            </InfiniteHorizontalScroll>
                    </div>
            </DndProvider>
        </div>
    );
};
const mapStateTopProps = (state) => ({
    slides: getSlidesData(state),
    squares:getPresentationModeData(state),
    slidesIsLoading:getSlidesLoading(state),
    getPresentationModeData: getPresentationModeData(state),
    getSlidesFilteredLength: getSlidesFilteredLength(state),
});

const mapDispatchToProps = {
    setSlidesItemsByOrder: setSlidesItemsByOrder,
    setSelectedSlide:setSelectedSlide,
    updateSlideOrderAPI:updateSlideOrderAPI,
    getSlidesByPresentationID:getMultipleSlidsByPresentationAPI,
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(PresentationSlideBoxes);
