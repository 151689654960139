import { LOGOUT_USER, SET_TOKENS } from "../constants";

const initialState = {
  data: {
    token: localStorage.getItem("accessToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
  },
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKENS:
      return {
        ...state,
        data: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        data: { ...state.data, token: null, refreshToken: null },
      };
    default:
      return state;
  }
};
