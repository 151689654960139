import axios from "axios";

const extractData = (res) => {
    const { data } = res
    if (data.accepted && data.data[0]) {
        if (data.data && data.data[0]) {
            return data.data[0]
        }
    }
    return []
}
export const getQuizAPI = (type,id) => {
    return axios
        .get(
            `${process.env.REACT_APP_HEADING_URL}/api/${type}/${id}/Quiz`
        )
        .then(extractData)
}