import {ADD_PRESENTATION, LOADING_PRESENTATION, SET_PRESENTATION,ADD_PRESENTATION_FILTERED_TOTAL} from "../constants";
import {editPresentation, extractItems, presentationPostMethod} from "../../service/presentationMode";
import {removeScreenShot} from "../../service/mapStateService";
import {setRemoveCardId} from "./mapStateAction";
import {genPatchBody} from "../../utils/utils";

export const loadingPresentation = () => ({
    type: LOADING_PRESENTATION,
});

export const setPresentation = (presentations) => ({
    type: SET_PRESENTATION,
    payload: presentations,
});
export const addPresentation = (presentations) => ({
    type: ADD_PRESENTATION,
    payload: presentations,
});
export const addPresentationsFilteredTotal = (presentations) => ({
    type: ADD_PRESENTATION_FILTERED_TOTAL,
    payload: presentations,
});

export const getAllPresentationsFromAPI = (search,type='map') => (dispatch, getState) => {
    const state = getState();
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Presentation/DataProvider`
    dispatch(loadingPresentation());
    return presentationPostMethod(url,search)
            .then((resp)=>{
                dispatch(addPresentationsFilteredTotal(resp.data.data[0].filteredTotal))
                return resp
            })
            .then(extractItems)
            .then((presentation) => {
                if(search?.page > 1 && type === 'map') return  dispatch(addPresentation(presentation))
                return dispatch(setPresentation(presentation));
            });
};
export const editPresentationCardAPI =
    (pageLoc, userId, id, title,description, data, onClose) => (dispatch) => {
        const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Presentation/${id}`;
        const body = [{
            key:'title',
            value:title
        },
        {
            key:'shortDescription',
            value:description
        }]
        return editPresentation(url, genPatchBody(body))
            // .then((data) => {
            //       return dispatch(getAllPresentationsFromAPI(pageLoc))
            // })
            .then(()=>{
                return onClose?.()
            })
            .catch((err) => console.log("err", err));
    };
export const removePresentationCardAPI =
    (pageLoc, cardId, userId, onClose) => (dispatch) => {
        const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Presentation/${cardId}`;

        return removeScreenShot(url).then((data) => {
                dispatch(setRemoveCardId(cardId));
            });
    };
