import { SET_MAP_STYLE } from "../constants";
import { setSearchData } from "./search";
import { setMapRequirementData } from "./mapRequirement";
import { setHintsData } from "./hints";
import { setMenuData } from "./menu";
import { setPainterStartData } from "./painterStart";
import {setQuizPopupData} from "./quizPopup";

export const setMapStyleData = (bool) => ({
  type: SET_MAP_STYLE,
  payload: bool,
});

export const setMapStyle = (bool) => (dispatch) => {
  dispatch(setPainterStartData(false));
  dispatch(setSearchData(false));
  dispatch(setMenuData(false));
  dispatch(setMapRequirementData(false));
  dispatch(setHintsData(false));
  dispatch(setQuizPopupData(false));
  dispatch(setMapStyleData(bool));
};
