import React, { Component } from 'react'
import Gallery from '../gallery'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import ReactAudioPlayer from 'react-audio-player'
import Slider from '@mui/material/Slider'
import {
    getCategoriesData,
} from '../../store/selectors'
import { connect } from 'react-redux'
import Plus from '../UI/Plus'
import Minus from '../UI/Minus'
import { setMapZoomIn, setMapZoomOut } from '../../store/actions/zoom'
import {
    closeArticlePopup,
    collapsArticlePopup,
} from '../../store/actions/articles'
import AudioPlayer from '../audioPlayer'
import CloseDark from '../UI/CloseDark'

class miniGallery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            galleryPaginationActive: 1,
        }
        this.sliderRef = React.createRef()
    }

    render() {
        const { gallery, openLightBox } = this.props
        return (
            <Splide
                ref={this.sliderRef}
                options={{
                    pagination: false,
                    arrows: false,
                    rewind: true,
                    width: 140,
                    gap: '1rem',
                }}
                onMove={(e) =>
                    this.setState({
                        galleryPaginationActive: e.index + 1,
                    })
                }
                renderControls={() => (
                    <div
                        className="splide__arrows"
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <div className="hero__category-btns">
                            <button className="hero__category-prev splide__arrow--prev">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7.725"
                                    height="15.321"
                                    viewBox="0 0 7.725 15.321"
                                >
                                    <g transform="translate(13.561 17.661) rotate(180)">
                                        <path
                                            d="M5.915,13.2.481,7.767a1.655,1.655,0,0,1,0-2.333L5.915,0"
                                            transform="translate(6.585 3.4)"
                                            fill="none"
                                            stroke="#292d32"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeMiterlimit="10"
                                            stroke-width="1.5"
                                            strokeDasharray="0 0"
                                        />
                                    </g>
                                </svg>
                            </button>
                            <p className="hero__category-current">
                                {this.state.galleryPaginationActive} /{' '}
                                {gallery.length}
                            </p>
                            <button className="hero__category-next splide__arrow--next">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7.725"
                                    height="15.321"
                                    viewBox="0 0 7.725 15.321"
                                >
                                    <g transform="translate(13.561 17.661) rotate(180)">
                                        <path
                                            d="M5.915,13.2.481,7.767a1.655,1.655,0,0,1,0-2.333L5.915,0"
                                            transform="translate(6.585 3.4)"
                                            fill="none"
                                            stroke="#292d32"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeMiterlimit="10"
                                            stroke-width="1.5"
                                            strokeDasharray="0 0"
                                        />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                )}
                className="hints__content-items"
            >
                {gallery.map((gallery) => {
                    return (
                        <SplideSlide
                            key={gallery.mediaId}
                            onClick={(e) => {
                                openLightBox(gallery.mediaId)
                            }}
                        >
                            <button
                                className="hero__media"
                                id="lightgallery"
                                type="button"
                            >
                                <img src={gallery.path} alt="hero" />
                            </button>
                        </SplideSlide>
                    )
                })}
            </Splide>
        )
    }
}

const mapStateTopProps = (state) => ({
    categories: getCategoriesData(state),
})

const mapDispatchToProps = {
    setMapZoomIn,
    setMapZoomOut,
    collapsArticlePopup,
    closeArticlePopup,
}

export default connect(mapStateTopProps, mapDispatchToProps)(miniGallery)
