export const checkValidationErrors = (fields) => {
  let errors = {};
  let hasError = false;

  if (fields["selectedLoginType"] !== "Phone" && !fields["email"]) {
    hasError = true;
    errors["email"] = "Մուտքագրեք Էլ․ հասցե";
  }
  if (fields["selectedLoginType"] === "Phone" && !fields["phoneNumber"]) {
    hasError = true;
    errors["phoneNumber"] = "Մուտքագրեք հեռախոսահամարը";
  }

  if (fields["phoneNumber"] &&
      fields["selectedLoginType"] === "Phone" &&
      fields['phoneCode'] == '374' &&
      fields['phoneNumber'].length !== 8 ) {
    hasError = true
    errors["phoneNumber"] = "Խնդրում ենք մուտքագրել վավեր հեռախոսահամար"
  }

  //Name
  if (!fields["password"]) {
    hasError = true
    errors["password"] = "Մուտքագրեք Գաղտնաբառ";
  }

  if (typeof fields["email"] !== "undefined" && fields["selectedLoginType"] !== "Phone") {
    const lastAtPos = fields["email"].lastIndexOf("@");
    const lastDotPos = fields["email"].lastIndexOf(".");

    if (
      !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2 &&
          !/\s/.test(fields['email'])
      )
    ) {
      hasError = true;
      errors["email"] = "Խնդրում ենք մուտքագրել վավեր էլ․ հասցե";
    }
  }

  return hasError ? errors : null;
};
