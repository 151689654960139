import {LOADING_PRESENTATION, SET_PRESENTATION, ADD_PRESENTATION, ADD_PRESENTATION_FILTERED_TOTAL} from "../constants";

const initialState = {
    data: [],
    total:0,
    isLoading: true,
    filteredTotal:0
};

export const presentationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_PRESENTATION: {
            return { ...state, isLoading: true };
        }
        case SET_PRESENTATION:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case ADD_PRESENTATION:
            return {
                ...state,
                isLoading: false,
                data: [...state.data,...action.payload],
            };
        case ADD_PRESENTATION_FILTERED_TOTAL:
            return {
                ...state,
                filteredTotal: action.payload,
            };
        default:
            return state;
    }
};
