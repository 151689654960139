import {
  SET_GRADES,
  LOADING_GRADES,
  LOADING_FILTERED_GRADES,
  SET_FILTERED_GRADES,
} from "../constants";

const initialState = {
  data: [],
  isLoading: false,
};

export const gradesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_GRADES: {
      return { ...state, isLoading: true };
    }
    case SET_GRADES:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const filteredGradesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_FILTERED_GRADES: {
      return { ...state, isLoading: true };
    }
    case SET_FILTERED_GRADES:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
