import html2canvas from "html2canvas";
import {presentationPostMethod, extractResult} from "../service/presentationMode";

export const dataURLToBinary = (dataURL) => {
    var arr = dataURL.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return u8arr;
}
export const handleCaptureTypeBlob = (callBack,elementId,elementRef) =>{
    html2canvas(elementRef?.current || document.getElementById(elementId || 'root'))
        .then((canvas) => {
            // Convert the canvas to an image and display it
                canvas.toBlob(callBack, 'image/jpeg');
        })
}
export const uploadImage = (blob,alt,title,access) => {
    const url =`${process.env.REACT_APP_MEDIA_CONTENT_URL}/api/Upload`;
    var formData = new FormData();
    formData.append('content', blob,'capturedImage.jpeg');
    formData.append('title', title || 'capturedImage.jpeg')
    formData.append('alt', alt || title || 'capturedImage.jpeg')
    formData.append('access', access || "PublicRead")
    return presentationPostMethod(url,formData)
            .then(extractResult)
}
