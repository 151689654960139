import {
  SET_COMPASS_COORDINATES, SET_DISABLE_COMPASS,
  SET_NEW_ANGLE,
  SET_NEW_ROTATE,
  SET_RESET_COMPASS,
} from "../constants";

export const setCompassNewAngle = (data) => {
  return {
    type: SET_NEW_ANGLE,
    payload: data,
  };
};

export const setCompassRotate = (data) => {
  return {
    type: SET_NEW_ROTATE,
    payload: data,
  };
};

export const setCompassCoordinates = (data) => {
  return {
    type: SET_COMPASS_COORDINATES,
    payload: data,
  };
};

export const setResetCompass = (state) => {
  return {
    type: SET_RESET_COMPASS,
    payload: state,
  };
};
export const setDisableCompass = (state) => {
  return {
    type: SET_DISABLE_COMPASS,
    payload: state,
  };
};
