import React, { Component } from "react";
import './style.css';

class SuccessAlert extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        setTimeout(()=>{
            this.props.close()
        },5000)
    }

    render() {
        return (
                <div className="success-alert" onClick={this.props.close}>
                    <p>{this.props.text}</p>
                </div>
        );
    }
}

export default SuccessAlert;
