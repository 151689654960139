import {
    SET_CATEGORIES_ARTICLE_ITEM_ID,
    SET_LAMP_DATA_FILTERED_SCREEN_SHOT,
    SET_LAMP_SELECTED_ID,
    SET_LAMP_SORTED_TOOLTIP_STATE,
    SET_MAP_CENTER_POSITION,
    SET_MAP_CENTER_POSITION_STATUS,
    SET_MAP_REQUIREMENT_SCREEN_SHOT,
    SET_MAP_STATE_DATA,
    SET_MAP_STATE_SINGLE_DATA,
    SET_MAP_STYLES_DARK_ID,
    SET_MAP_STYLES_ID,
    SET_MAP_STYLES_STATUS,
    SET_MOBILE_MENU_CLOSE,
    SET_OPEN_CATEGORY_ID,
    SET_OPEN_SUB_CATEGORY_ID,
    SET_PAINTER_GEOJSON_DATA_ST,
    SET_REMOVE_CARD_ID,
    SET_RULER_GEOJSON_DATA_ST,
    SET_RULER_HIDE_ALL_POPUP,
    SET_RULER_LINE_STRING_ST,
    SET_RULER_POINTS_POS_DATA_ST,
    SET_RULER_SOURCE_NAME_ST,
    SET_RULER_SOURCE_SECONDE_NAME_ST,
    SET_SCREENSHOT_BUTTON_CLICK,
    SET_SCREENSHOT_BUTTON_STATE,
    SET_SCREENSHOT_INTERMEDIATE_DATA,
    SET_SCREENSHOT_LOADER,
    SET_SCREENSHOT_SEND_DATA,
    SET_SCREEN_SHOT_LOADING,
    SET_TEST_FILTERED_CATEGORIES,
    SET_TIMELINE_CLICK_STATE,
    SET_TIMELINE_CURSOR_STATUS_ST,
    SET_TIMELINE_EVENT_DATA_ST,
    SET_TIMELINE_MOVE_COUNT_ST,
    SET_TIMELINE_MOVE_DATA_ST,
    SET_TIMELINE_MOVE_DATA_STATUS_ST,
    SET_TIMELINE_MOVE_POSITION,
    SET_TIMELINE_MOVE_STATUS,
    SET_TIMELINE_ZOOM_INDEX_ST,
    SET_TIMELINE_ZOOM_LEVEL,
    SET_TIMELINE_ZOOM_ST,
    SET_TOPIC_STATIC_TITLE_ST,
    SET_TIMELINE_HEIGHT,
    SET_SHOW_NOTIFICATION,
    ADD_MAP_STATE_DATA
} from "../constants";

const initialState = {
    isLoading: false,
    screenShootButtonState: false,
    data: [],
    singleData: [],
    categoryId: null,
    subCategoryId: null,
    mapRequiredScreenShot: null,
    categoryArticleClickedId: null,
    lampDataFilteredScreenShot: [],
    lampSortedTooltipState: false,
    testFilteredCategories: [],
    lampSelectedId: null,
    mapStylesId: null,
    mapStylesDarkId: null,
    painterGeoJsonDataST: {},
    rulerGeoJsonDataST: {},
    rulerSourceName: null,
    rulerSourceSecondName: [],
    rulerPointsPosData: [],
    rulerLineStringData: {},
    rulerPopupHide: null,
    mapStylesChangedStatus: null,
    timelineZoomST: 0,
    timelineZoomIndexST: null,
    timeLineMoveCountST: 0,
    timeLineCursorStatusST: null,
    timeLineEventDataST: {},
    timeLineMOvePosition: {},
    timeLineMoveStatus: false,
    timeTineZoomLevel: null,
    screenShotLoading: true,
    mapCenterCoordinates: {},
    mapCenterCoordinatesStatus: false,
    screenShotSendData: "",
    screenShotIntermediateData: "",
    screenShotButtonClick: true,
    removeCardId: null,
    timeLineMovieDataStatus: false,
    timeLineMovieData: {},
    topicStaticTitle: "",
    timeLineClickState: false,
    mobileMenuState: false,
    timeLineHeight: 0,
    showNotificationST:null,
};

export const mapStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCREENSHOT_BUTTON_STATE:
            return {
                ...state,
                screenShootButtonState: action.payload,
            };
        case SET_MAP_STATE_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case ADD_MAP_STATE_DATA:
            const newData = action.payload
            if(state.data?.items){
                newData.items = [...state.data?.items,...action.payload?.items]
            }
            return {
                ...state,
                data: newData,
            };
        case SET_MAP_STATE_SINGLE_DATA:
            return {
                ...state,
                singleData: action.payload,
            };
        case SET_OPEN_CATEGORY_ID:
            return {
                ...state,
                categoryId: action.payload,
            };
        case SET_OPEN_SUB_CATEGORY_ID:
            return {
                ...state,
                subCategoryId: action.payload,
            };
        case SET_MAP_REQUIREMENT_SCREEN_SHOT:
            return {
                ...state,
                mapRequiredScreenShot: action.payload,
            };
        case SET_CATEGORIES_ARTICLE_ITEM_ID:
            return {
                ...state,
                categoryArticleClickedId: action.payload,
            };
        case SET_LAMP_DATA_FILTERED_SCREEN_SHOT:
            return {
                ...state,
                lampDataFilteredScreenShot: action.payload,
            };
        case SET_LAMP_SORTED_TOOLTIP_STATE:
            return {
                ...state,
                lampSortedTooltipState: action.payload,
            };
        case SET_TEST_FILTERED_CATEGORIES:
            return {
                ...state,
                testFilteredCategories: action.payload,
            };
        case SET_LAMP_SELECTED_ID:
            return {
                ...state,
                lampSelectedId: action.payload,
            };
        case SET_MAP_STYLES_ID:
            return {
                ...state,
                mapStylesId: action.payload,
            };
        case SET_MAP_STYLES_DARK_ID:
            return {
                ...state,
                mapStylesDarkId: action.payload,
            };
        case SET_PAINTER_GEOJSON_DATA_ST:
            return {
                ...state,
                painterGeoJsonDataST: action.payload,
            };
        case SET_RULER_GEOJSON_DATA_ST:
            return {
                ...state,
                rulerGeoJsonDataST: action.payload,
            };
        case SET_RULER_SOURCE_NAME_ST:
            return {
                ...state,
                rulerSourceName: action.payload,
            };
        case SET_RULER_SOURCE_SECONDE_NAME_ST:
            return {
                ...state,
                rulerSourceSecondName: action.payload,
            };
        case SET_RULER_POINTS_POS_DATA_ST:
            return {
                ...state,
                rulerPointsPosData: action.payload,
            };
        case SET_RULER_LINE_STRING_ST:
            return {
                ...state,
                rulerLineStringData: action.payload,
            };
        case SET_RULER_HIDE_ALL_POPUP:
            return {
                ...state,
                rulerPopupHide: action.payload,
            };
        case SET_MAP_STYLES_STATUS:
            return {
                ...state,
                mapStylesChangedStatus: action.payload,
            };
        case SET_TIMELINE_ZOOM_ST:
            return {
                ...state,
                timelineZoomST: action.payload,
            };
        case SET_TIMELINE_ZOOM_INDEX_ST:
            return {
                ...state,
                timelineZoomIndexST: action.payload,
            };
        case SET_TIMELINE_MOVE_COUNT_ST:
            return {
                ...state,
                timeLineMoveCountST: action.payload,
            };
        case SET_TIMELINE_CURSOR_STATUS_ST:
            return {
                ...state,
                timeLineCursorStatusST: action.payload,
            };
        case SET_TIMELINE_EVENT_DATA_ST:
            return {
                ...state,
                timeLineEventDataST: action.payload,
            };
        case SET_TIMELINE_MOVE_POSITION:
            return {
                ...state,
                timeLineMOvePosition: action.payload,
            };
        case SET_TIMELINE_MOVE_STATUS:
            return {
                ...state,
                timeLineMoveStatus: action.payload,
            };
        case SET_TIMELINE_ZOOM_LEVEL:
            return {
                ...state,
                timeTineZoomLevel: action.payload,
            };
        case SET_SCREEN_SHOT_LOADING:
            return {
                ...state,
                screenShotLoading: action.payload,
            };
        case SET_MAP_CENTER_POSITION:
            return {
                ...state,
                mapCenterCoordinates: action.payload,
            };
        case SET_MAP_CENTER_POSITION_STATUS:
            return {
                ...state,
                mapCenterCoordinatesStatus: action.payload,
            };
        case SET_SCREENSHOT_SEND_DATA:
            return {
                ...state,
                screenShotSendData: action.payload,
            };
        case SET_SCREENSHOT_INTERMEDIATE_DATA:
            return {
                ...state,
                screenShotIntermediateData: action.payload,
            };
        case SET_SCREENSHOT_BUTTON_CLICK:
            return {
                ...state,
                screenShotButtonClick: action.payload,
            };
        case SET_SCREENSHOT_LOADER:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_REMOVE_CARD_ID:
            return {
                ...state,
                removeCardId: action.payload,
            };
        case SET_TIMELINE_MOVE_DATA_STATUS_ST:
            return {
                ...state,
                timeLineMovieDataStatus: action.payload,
            };
        case SET_TIMELINE_MOVE_DATA_ST:
            return {
                ...state,
                timeLineMovieData: action.payload,
            };
        case SET_TOPIC_STATIC_TITLE_ST:
            return {
                ...state,
                topicStaticTitle: action.payload,
            };
        case SET_TIMELINE_CLICK_STATE:
            return {
                ...state,
                timeLineClickState: action.payload,
            };
        case SET_MOBILE_MENU_CLOSE:
            return {
                ...state,
                mobileMenuState: action.payload,
            };
        case SET_TIMELINE_HEIGHT:
            return {
                ...state,
                timeLineHeight: action.payload,
            };
        case SET_SHOW_NOTIFICATION:
            return {
                ...state,
                showNotificationST: action.payload
            }
        default:
            return state;
    }
};
