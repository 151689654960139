import {useState} from "react";

const InfiniteHorizontalScroll = ({fetchData, children,className, loadingIndicator,style, offset = 5,fetching,hasMore}) => {
    const [isFetching, setIsFetching] = useState(fetching);

    const handleScroll = (e) => {
        const {scrollLeft, clientWidth, scrollWidth} = e.target;
        if (Math.floor(scrollWidth - scrollLeft - offset) <= clientWidth && hasMore && !isFetching) {
            setIsFetching(()=>true);
            fetchData()
            setTimeout(()=>{
                setIsFetching(false);
            },100)
        }
    };

    return (
        <div
            className={className || ''}
            style={{
                width: '100%',
                overflowX: 'auto',
                display: 'flex',
                ...style
            }}
            onScroll={handleScroll}
        >
            {children}
            {hasMore && loadingIndicator && <div style={{textAlign: 'center'}}>{loadingIndicator}</div>}
        </div>
    );
};
export default InfiniteHorizontalScroll;
