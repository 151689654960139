const LessonArrow = () => {
  return (
    <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1">
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="arrow-down-copy"
          transform="translate(9.000000, 9.000000) rotate(-180.000000) translate(-9.000000, -9.000000) "
        >
          <path
            d="M14.9400587,6.71246338 L10.0500584,11.6024637 C9.47255838,12.1799637 8.52755892,12.1799637 7.95005894,11.6024637 L3.06005859,6.71246338"
            id="Vector"
            stroke="#fff"
            stroke-width="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="0,0"
            fillRule="nonzero"
          ></path>
          <path
            d="M18,0 L18,18 L0,18 L0,0 L18,0 Z"
            id="Vector"
            opacity="0"
            transform="translate(9.000000, 9.000000) rotate(-180.000000) translate(-9.000000, -9.000000) "
          ></path>
        </g>
      </g>
    </svg>
  );
};
export default LessonArrow;
