import { SET_ABOUT } from "../constants";
import { setMenuData } from "./menu";

export const setAboutData = (bool) => ({
  type: SET_ABOUT,
  payload: bool,
});

export const setAbout = (bool) => (dispatch) => {
  dispatch(setMenuData(false));
  dispatch(setAboutData(bool));
};
