import React, { useState, useEffect } from 'react';
import ScreenShotNotificationIcon from "../../../../assets/imgs/screenshot-notification-icon.svg";
import './style.css'
import {connect} from "react-redux";
import {getShowNotificationST} from "../../../../store/selectors";
import {setShowNotificationST} from "../../../../store/actions/mapStateAction";
import ScreenShotNotificationPopup from "../ScreenShotNotificationPopup";
import {useLocation} from "react-router-dom";
const ScreenShotNotification = (props) => {
    const { getShowNotificationST,setShowNotificationST } = props
    const [notificationState, setNotificationState] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const toggleNotification = () => {
        setNotificationState((prev) => !prev);
    };

    useEffect(() => {

        let timeoutId;
        if ((12 & getShowNotificationST) === 8) {
            // Set timeout to hide notification after 2500 milliseconds
            timeoutId = setTimeout(() => {
                setShowNotificationST(4);
            }, 3000);
        }

        // Cleanup function to clear timeout if component unmounts or notification is toggled again
        return () => {
            clearTimeout(timeoutId);
        };
    }, [getShowNotificationST]);
    if(!(12 & getShowNotificationST)){
        return <></>
    }
    return (
        <>
            <div className={`screenShot_notification-box ${typeof +query.get('screenShot') === 'number' && !isNaN(+query.get('screenShot')) && (12 & getShowNotificationST) === 8 ? 'active' : ''}`}>
                {typeof +query.get('screenShot') === 'number' && !isNaN(+query.get('screenShot')) && ((12 & getShowNotificationST) === 8)
                    && (<div className={'screenShot_notification-description'}>
                            <p>
                               'Կոնտենտում ադմինի կողմից տեղի է ունեցել փոփոխություն, որը կցուցադրվի Ձեր պահպանված քարտեզում:'
                            </p>
                        </div>
                    )}
                <button
                    onClick={toggleNotification}
                    id="screenShot_notification_icon"
                    className="right-side__search-btn main__tab-btn"
                    type="button"
                >
              <span className="icon-wrapper screenshot-icon">
                <img src={ScreenShotNotificationIcon} />
              </span>
                </button>
            </div>
            <ScreenShotNotificationPopup  notificationState={notificationState}
                                          toggleNotification={toggleNotification}/>
        </>
    );
};
const mapStateTopProps = (state) => ({
    getShowNotificationST:getShowNotificationST(state)
})
const mapDispatchToProps = {
    setShowNotificationST
}
export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(ScreenShotNotification);
