import ReactDOM from "react-dom";
import GroupTemplate from "../components/GroupTemplate";
import ItemTemplate from "../components/ItemTemplate";

//Ruler popup icons
import IconCar from "../assets/imgs/PaintBar/icon-car.svg";
import IconClose from "../assets/imgs/PaintBar/icon-close.svg";
import IconHorse from "../assets/imgs/PaintBar/icon-horse.svg";
import IconPlane from "../assets/imgs/PaintBar/icon-plane.svg";
import IconWalk from "../assets/imgs/PaintBar/icon-walk.svg";

export const loginURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/SignIn`;
export const loginByPhoneURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/SignInByPhoneNumber`;
export const uploadURI = `${process.env.REACT_APP_MEDIA_CONTENT_URL}/api/Upload`;
export const userInfoURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User`;
export const updateUserInfoURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User`;
export const changeUserPasswordURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/ChangePassword`;
export const deleteUserURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/Deactivate`;
export const checkUserPasswordURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/CheckPassword`;

export const getCategoriesAPIParams = () => {
  return {
    url: `${process.env.REACT_APP_ARTICLE_URL}/api/Category/DataProvider/1`,
    body: {
      page: 1,
      perPage: 50,
      includeLanguage: true,
      includeSubCategory: true,
      includeAttribute: true,
    },
  };
};

export const mapRequirements = [
  {
    id: 1,
    value: "all",
    title: "Բոլորը",
    active: true,
  },
  {
    id: 2,
    value: 0,
    title: "Պարտադիրները",
    active: false,
  },
  {
    id: 3,
    value: 1,
    title: "Հավելյալները",
    active: false,
  },
];

export const mapStyles = [
  {
    id: 1,
    value: "physical",
    title: "Ֆիզիկական",
    style: "mapbox://styles/brainograph/ckyypimuc004114s0jxn3nytt",
    active: true,
  },
  {
    id: 2,
    value: "satellite",
    title: "Արբանյակային",
    style: "mapbox://styles/brainograph/ckyypqrvd000j15lb9osv5hda",
    active: false,
  },
  {
    id: 3,
    value: "politics",
    title: "Քաղաքական",
    style: "mapbox://styles/brainograph/ckyypjczl001w15qrx5woidl5",
    active: false,
  },
  {
    id: 4,
    value: "terrain",
    title: "Տեղանքային",
    style: "mapbox://styles/brainograph/cl3bx9ey8003b14rxe6nnha51",
    active: false,
  },
];

export const typesNumbers = [
  "Pin",
  "River",
  "Lake",
  "Sea",
  "Ocean",
  "Country",
  "Other",
];

export const timeLineDataItems = [];

export const timeLineDataGroup = [];

export const timeLineSelectedDataItems = [];

export const timeLineSelectedDataGroup = [];

export const specialityOptions = [
  {
    value: 1,
    text: "Teacher",
    label: "Ուսուցիչ",
  },
  {
    value: 3,
    text: "Student",
    label: "Աշակերտ",
  },
  {
    value: 4,
    text: "Other",
    label: "Այլ",
  },
];

export const genderOptions = [
  {
    value: "Female",
    label: "Իգական",
  },
  {
    value: "Male",
    label: "Արական",
  },
];

export const timeLineOptions = {
  autoResize: false,
  stack: true,
  showCurrentTime: false,
  horizontalScroll: true,
  verticalScroll: true,
  zoomKey: "ctrlKey",
  maxHeight: "230px",
  minHeight:
  window.innerWidth > 800 && window.innerWidth < 1800 ? "160px" : "230px",
  zoomMin: 315569520000,
  // groupHeightMode: 'fixed',
  format: {
    minorLabels: function (a) {
      if (a.format("YYYY").includes("-")) {
        return "Ք.ա. " + a.format("y").replace("-", "");
      }
      return a.format("y");
    },
  },
  orientation: {
    item: "top",
    axis: "bottom",
  },
  order: function (a, b) {
    return a.order - b.order;
  },
  groupTemplate: function (group, element) {
    return ReactDOM.createPortal(
      ReactDOM.render(<GroupTemplate group={group} />, element),
      element
    );
  },
  template: function (item, element) {
    return ReactDOM.createPortal(
      ReactDOM.render(<ItemTemplate item={item} />, element),
      element
    );
  },
};

export const dropDownCustomAccountStyles = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    background: "transparent",
    minHeight: '35px',
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    border: "none",
    borderRadius: 20,
    color: "#000",
    marginBottom: "5px",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      border: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#2c476c" : "black",
    background: state.isSelected ? "#42D3B0" : "transparent",
    "&:hover": {
      // Overwrittes the different states of border
      background: "#42D3B0",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 5,
    overflow: "hidden",
    background: "rgba(255, 255, 255,)",
    color: "white",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 10,
    color: "#2c476c",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 10,
    color: state.isSelected ? "#2c476c" : "#2c476c",
  }),
};
export const dropDownCustomAccountStylesForLittleScreens = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    background: "transparent",
    minHeight: '35px',
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    border: "none",
    borderRadius: 20,
    color: "#000",
    marginBottom: "5px",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      border: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#2c476c" : "black",
    background: state.isSelected ? "#42D3B0" : "transparent",
    "&:hover": {
      // Overwrittes the different states of border
      background: "#42D3B0",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 5,
    maxHeight: '145px',
    overflow: "hidden",
    background: "rgba(255, 255, 255,)",
    color: "white",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 10,
    color: "#2c476c",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 10,
    color: state.isSelected ? "#2c476c" : "#2c476c",
  }),
};
export const dropDownCustomAccountStylesForRegions = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    background: "transparent",
    minHeight: '35px',
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    border: "none",
    borderRadius: 20,
    color: "#000",
    marginBottom: "5px",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      border: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#2c476c" : "black",
    background: state.isSelected ? "#42D3B0" : "transparent",
    "&:hover": {
      // Overwrittes the different states of border
      background: "#42D3B0",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 5,
    maxHeight: '260px',
    overflow: "hidden",
    background: "rgba(255, 255, 255,)",
    color: "white",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 10,
    color: "#2c476c",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 10,
    color: state.isSelected ? "#2c476c" : "#2c476c",
  }),
};

export const dropDownCustomStyles = {
  control: (base, state) => ({
    ...base,
    background: "transparent",
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: "transparent",
    color: "white",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: "transparent",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    background: state.isSelected ? "#f3bfda" : "transparent",
    "&:hover": {
      // Overwrittes the different states of border
      background: "#f3bfda",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 5,
    overflow: "hidden",
    background: "rgba(255, 255, 255,)",
    color: "white",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 40,
    color: "white",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 40,
    color: state.isSelected ? "white" : "white",
  }),
};

export const years = [...Array(new Date().getFullYear() - 1899).keys()].map(
  (e) => e + 1900
);
export const months = [
  "հունվար",
  "փետրվար",
  "մարտ",
  "ապրիլ",
  "մայիս",
  "հունիս",
  "հուլիս",
  "օգոստոս",
  "սեպտեմբեր",
  "հոկտեմբեր",
  "նոյեմբեր",
  "դեկտեմբեր",
];

//Painter

export const SPEED_NAME = {
  AIRPLANE: "AIRPLANE",
  CAR: "CAR",
  HORSE: "HORSE",
  WALK: "WALK",
};

export const SPEED = {
  AIRPLANE: 1000,
  CAR: 120,
  HORSE: 80,
  WALK: 5,
};

export const CURSOR_TYPE = {
  DRAW: "DRAW",
  ERASER: "ERASER",
  RULER: "RULER",
};

export const changeCursorIcon = (type) => {
  switch (true) {
    case type === CURSOR_TYPE.DRAW:
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_painter");
      break;
    case type === CURSOR_TYPE.ERASER:
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_eraser");
      break;
    case type === CURSOR_TYPE.RULER:
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_ruler");
      break;

    default:
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      break;
  }
};

let days, hours, minutes, seconds;

export const getTime = (value, type) => {
  let target_date = new Date().getTime() + (value / SPEED[type]) * 3600000;
  var current_date = new Date().getTime();
  var seconds_left = (target_date - current_date) / 1000;

  days = parseInt(seconds_left / 86400);
  seconds_left = seconds_left % 86400;

  hours = parseInt(seconds_left / 3600);
  seconds_left = seconds_left % 3600;

  minutes = parseInt(seconds_left / 60);

  if (days > 0 && hours === 0) {
    return `${days} Օր`;
  } else if (days > 0 && hours > 0) {
    return `${days} Օր ${hours} Ժամ`;
  } else if (hours > 0 && minutes === 0) {
    return `${hours} Ժամ`;
  } else if (hours > 0 && minutes > 0) {
    return `${hours} Ժամ ${minutes} Րոպե`;
  } else {
    return `${minutes} Րոպե`;
  }
};

export const getRulerPopupTemplate = (distance, index) => {
  return `
    <div class='ruler_parent'>
      <div class='ruler_modal_arrow ruler_modal_top_arrow' id='ruler_modal_top_arrow'></div>
                <div class='ruler_modal ruler_modal_${index}'>
                <p>${distance.toFixed(2)} կմ</p>
                              <img src="${IconClose}" class="close_icon" id='ruler_modal_${index}'  />
                            <div>
                              <img src="${IconPlane}" class="IconPlane"/><span>${getTime(
                  distance.toFixed(2),
                  SPEED_NAME.AIRPLANE
                )}</span>
                            </div>
                              <div>
                              <img src="${IconCar}" class="IconCar"><span>${getTime(
                  distance.toFixed(2),
                  SPEED_NAME.CAR
                )}</span>
                            </div>
                              <div>
                              <img src="${IconHorse}" class="IconHorse"/><span>${getTime(
                  distance.toFixed(2),
                  SPEED_NAME.HORSE
                )}</span>
                            </div>
                              <div>
                              <img src="${IconWalk}" class="IconWalk"/><span>${getTime(
                  distance.toFixed(2),
                  SPEED_NAME.WALK
                )}</span>
                  </div>
                </div>
              <div class='ruler_modal_arrow ruler_modal_bottom_arrow' id='ruler_modal_bottom_arrow'></div>
    </div>
  `;
};
