import axios from "axios";

const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items;
    }
  }
  return [];
};

const sanitizeFilteredSelectedResult = (items) =>
  items.map((value, key) => ({
    id: value.id,
    value: value.id,
    title: value.title,
  }));

const selectAPIRequest = (url, body) => {
  return axios.post(url, body).then(extractItems);
};

export const getTopicsAPI = (url, body, state) => {
  return selectAPIRequest(url, body)
    .then((items) => sanitizeFilteredSelectedResult(items))
    .catch(console.error);
};
