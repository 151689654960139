import React, {useEffect, useState} from "react";
import Account from "../account";
import { useNavigate } from "react-router-dom";
import "./style.css";
import LeftNavigation from "../../components/leftNavigation";
import AccountFooter from "../../components/accountFooter";
import { HandleToken } from "../../helpers";
import PageLoader from "../../components/UI/PageLoader";
import TopBar from "./components/TopBar";
import LeftSideBar from "./components/LeftSideBar";
import RightSideBar from "./components/RightSideBar";
import {
  getCountriesData,
  getGradesData,
  getRegionsData,
  getSchoolsData,
  getStatesData,
  getSubjectsData,
  getUserData,
} from "../../store/selectors";
import {
  getSubjectsFromAPI,
  loadingSubjects,
  setSubjects,
} from "../../store/actions/subjects";
import { connect } from "react-redux";
// import { getUserFromAPI } from "../../store/actions/user";
import { getGradesFromAPI } from "../../store/actions/grades";
import { getCountriesFromAPI } from "../../store/actions/countries";
import { getStatesFromAPI } from "../../store/actions/states";
import { getRegionsFromAPI } from "../../store/actions/regions";
import { getSchoolsFromAPI } from "../../store/actions/schools";

export const withNavigation = (Component: Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

const AccountPage = (props) => {
  const [edit, setEdit] = useState(false)
  const [uploadFile, setUploadFile] = useState([])
  const handleEdit = () => {
    setEdit(!edit)
  }

  useEffect(() => {
    HandleToken();
    // if (!props.user) {
    //   props.getUserFromAPI();
    // }
  }, [props.user]);


  useEffect(() => {
    if (props.user) {
      if (props.countries.length === 0) {
        props.getCountriesFromAPI();
      }
      if (props.regions.length === 0) {
        props.getRegionsFromAPI();
      }
      if (props.states.length === 0) {
        props.getStatesFromAPI();
      }
      if (props.schools.length === 0) {
        props.getSchoolsFromAPI();
      }
      if (props.subjects.length === 0) {
        props.getSubjectsFromAPI();
      }
      if (props.grades.length === 0) {
        props.getGradesFromAPI();
      }
    }
  }, [props.user]);

  return (
    <Account onlyBG>
      <LeftNavigation />
      <TopBar />
      {!props.user && (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 1222,
          }}
        >
          <PageLoader />
        </div>
      )}
      <div
        className="account-dashboards-new"
        style={{ opacity: props.user ? 1 : 0 }}
      >
        {props.user &&
            <LeftSideBar
                setUploadFile={setUploadFile}
              edit={edit}/>}
        <div className="user-right">
          {props.user && (
              <RightSideBar
              uploadFile={uploadFile}
              edit={edit}
              action={handleEdit}/>
          )}
          {/*{props.user && <PasswordSideBar />}*/}
        </div>
      </div>
      <AccountFooter />
    </Account>
  );
};

const mapStateTopProps = (state) => ({
  user: getUserData(state),
  subjects: getSubjectsData(state),
  grades: getGradesData(state),
  countries: getCountriesData(state),
  states: getStatesData(state),
  regions: getRegionsData(state),
  schools: getSchoolsData(state),
});

const mapDispatchToProps = {
  getSubjectsFromAPI,
  getGradesFromAPI,
  // getUserFromAPI,
  getCountriesFromAPI,
  getStatesFromAPI,
  getRegionsFromAPI,
  getSchoolsFromAPI,
  loadingSubjects,
  setSubjects,
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withNavigation(AccountPage));
