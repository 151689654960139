export const ZoomInFont = ({active, size}) => {
    const fill = active ? "#2C476C" : "#D9D9D9"
    return(
        size === 'big' ?
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6734 4.30425C19.3443 3.51447 18.5727 3 17.7171 3C16.8615 3 16.0898 3.51447 15.7607 4.30425L5.16359 29.7373C4.71338 30.8178 5.22433 32.0587 6.30481 32.5088C7.38529 32.959 8.62616 32.448 9.07635 31.3675L12.0653 24.1942H23.3689L26.3577 31.3675C26.808 32.448 28.0487 32.959 29.1292 32.5088C30.2097 32.0587 30.7207 30.8178 30.2705 29.7373L19.6734 4.30425ZM21.6026 19.9553H13.8314L17.7171 10.6299L21.6026 19.9553Z" fill={fill}/>
        </svg> :
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7063 10.0405C19.4438 9.41044 18.8282 9 18.1456 9C17.463 9 16.8473 9.41044 16.5848 10.0405L8.13051 30.3308C7.77134 31.1928 8.17897 32.1828 9.04097 32.5419C9.90296 32.901 10.8929 32.4934 11.2521 31.6314L13.6366 25.9085H22.6545L25.039 31.6314C25.3983 32.4934 26.3881 32.901 27.2501 32.5419C28.1121 32.1828 28.5198 31.1928 28.1606 30.3308L19.7063 10.0405ZM21.2455 22.5268H15.0457L18.1456 15.0871L21.2455 22.5268Z" fill={fill}/>
            </svg>
    )
}
