import { formatLessonTime, formatServerResponseTime } from '../utils'
import axios from "axios";

export const processMiddleTimeLineTopicItemsRendering =  (data) => {

    const timeLineDataItem = []

    const { startFrom, endTo } = formatServerResponseTime(data)
    timeLineDataItem.push({
        id: data.id,
        order: 1,
        content: data.language?.[0].title,
        start: startFrom,
        end: endTo,
        className: 'isGroup',
        color: '#2c476c',
        startFrom: startFrom,
        endTo: endTo,
    })
    data.lessons.forEach((lesson, index) => {
        const { lessonStartFrom, lessonEndTo } = formatLessonTime(lesson)

        timeLineDataItem.push({
            id: `${data.id}-${lesson.id}`,
            order: index + 2,
            content: lesson.language[0].title,
            lessonId:lesson.id,
            nextLessonID:
                index + 1 === data.lessons.length
                    ? null
                    : data.lessons[index + 1].id,
            prevLessonID: index === 0 ? null : data.lessons[index - 1].id,
            start: lessonStartFrom,
            end: lessonEndTo,
            color: '#2c476c',
            groupId: data.id,
        })
    })
    return timeLineDataItem
}
