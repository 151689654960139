import { SET_LESSON_ID, SET_NEXT_LESSON, SET_PREV_LESSON } from '../constants'

const initialNextState = {
    data: null,
}
const initialPrevState = {
    data: null,
}

const initialLessonID = {
    data: null,
}

export const handleNextLessonIdReducer = (state = initialNextState, action) => {
    switch (action.type) {
        case SET_NEXT_LESSON:
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state
    }
}

export const handlePrevLessonIdReducer = (state = initialPrevState, action) => {
    switch (action.type) {
        case SET_PREV_LESSON:
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state
    }
}

export const handleSelectLessonReducer = (state = initialLessonID, action) => {
    switch (action.type) {
        case SET_LESSON_ID:
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state
    }
}
