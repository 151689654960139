import React from "react";
import Loader from "./Loader";

export const Button = ({ className = "account-submit", text,  value = '', action, disabled= false, style,loading }) => {
  return (
    <button disabled={loading || disabled} value={value} className={className} onClick={action} style={style}>

      {loading
          ? <div style={{scale:'0.5'}}> <Loader /></div>
          : text
      }
    </button>
  );
};
