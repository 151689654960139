import React, { Component } from "react";
import './style.css';

class PageLoader extends Component {
    render() {
        return (
            <div className="loadingio-spinner-ripple-orgsc8fmyn">
                <div className="ldio-4i2q8i173j7">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }
}

export default PageLoader;
