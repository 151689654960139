import React, { Component } from "react";
import "./style.css";

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="policy-popup">
                <button className="policy-close" type="button" onClick={() => this.props.handlePolicy()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0,0H12" transform="translate(6 12)" fill="none" stroke="#292d32" stroke-width="1.5" strokeDasharray="0 0"/><path d="M0,12V0" transform="translate(12 6)" fill="none" stroke="#292d32" stroke-width="1.5" strokeDasharray="0 0"/><path d="M24,0V24H0V0Z" fill="none" opacity="0"/></svg>
                </button>
                <div className="WordSection1">
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Գաղտնիության քաղաքականություն </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Սույն Գաղտնիության քաղաքականությունը (այսուհետև՝ նաև Քաղաքականություն) վերջին անգամ թարմացվել է 2023 թ․ սեպտեմբերի 1-ին։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Շնորհակալ ենք Բրեյնոգրաֆին միանալու համար։ Մենք Բրեյնոգրաֆում (այսուհետև՝{" "} </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> « </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> մենք </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> » </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Times New Roman",serif'
                        }}> , </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> « </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> մեզ </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> » </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ) հարգում ենք Ձեր գաղտնիության իրավունքը և ցանկանում ենք, որ Դուք տեղեկացված լինեք, թե մենք ինչպես ենք հավաքում, օգտագործում և տրամադրում Ձեզ վերաբերող տվյալները։ Սույն Գաղտնիության քաղաքականությունը մեր կողմից տվյալների հավաքման, օգտագործման, պահպանման և անձնական տվյալների հետ կապված Ձեր իրավունքների մասին է։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Քանի դեռ մենք հղում չենք կատարում այլ Քաղաքականութան կամ այլ բան չենք հայտարարում, սույն Գաղտնիության քաղաքականությունը գործում է, երբ Դուք այցելում կամ օգտվում եք Բրեյնոգրաֆի կայքերից, Կիրառական ծրագրավորման միջերեսից ((ԿԾՄ)API), կամ հարակից ծառայություններից («Ծառայություններ»): Սա վերաբերում է նաև մեր բիզնեսի և ընկերությունների համար նախատեսված արտադրանքների պոտենցիալ հաճախորդներին: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Օգտվելով մեր կողմից Ձեզ տրամադրվող Ծառայություններից՝ Դուք համաձայնում եք սույն Գաղտնիության քաղաքականության պայմաններին։ Դուք չպետք է օգտվեք Ծառայություններից, եթե համաձայն չեք Քաղաքականության կամ որևէ այլ պայմանի հետ, որը կարգավորում է Ծառայություններից օգտվելը։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Ստորև կարող եք գտնել հետևյալ տեղեկատվությանը՝{" "} </span>
                    </p>
                    <ol style={{ marginTop: "0in",marginLeft: "0.7in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <b>
        <span lang="EN" style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
        }}> Ինչ տվյալներ ենք մենք հավաքում և ինչպես </span>
                            </b>
                        </li>
                        <li className="MsoNormal">
                            <b>
        <span lang="EN" style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
        }}> Ինչ նպատակով ենք օգտագործում հավաքած տվյալները </span>
                            </b>
                        </li>
                        <li className="MsoNormal">
                            <b>
        <span lang="EN" style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
        }}> Ինչպես և երբ ենք մենք տրամադրում Ձեր տվյալները </span>
                            </b>
                        </li>
                        <li className="MsoNormal">
                            <b>
        <span lang="EN" style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
        }}> Անձնական տվյալների հետ կապված Ձեր իրավունքները </span>
                            </b>
                        </li>
                        <li className="MsoNormal">
                            <b>
        <span lang="EN" style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
        }}> Երեխաների տվյալների գաղտնիությունը </span>
                            </b>
                        </li>
                        <li className="MsoNormal">
                            <b>
        <span lang="EN" style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
        }}> Քուքի ֆայլեր </span>
                            </b>
                        </li>
                        <li className="MsoNormal">
                            <b>
        <span lang="EN" style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
        }}> Անվտանգություն </span>
                            </b>
                        </li>
                        <li className="MsoNormal">
                            <b>
        <span lang="EN" style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
        }}> Օրենսդրություն </span>
                            </b>
                        </li>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <b>
        <span lang="EN" style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
        }}> Կապ մեզ հետ </span>
                            </b>
                        </li>
                    </ol>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մենք Ձեզանից ուղիղ կերպով հավաքում ենք որոշ տվյալներ, օրինակ՝ Ձեր կողմից մուտքագրված, բովանդակությունից և Ծառայություններից օգտվելու հետ կապված Ձեր տվյալները։ Մենք նաև որոշ տվյալներ հավաքում ենք ավտոմատ եղանակով, օրինակ՝ տեղեկություններ Ձեր սարքի մասին, այն մասին, թե մեր Ծառայությունների որ մասերի հետ եք անռչվում կամ որոնցից օգտվելու արդյունքում եք ավելի շատ ժամանակ ծախսում մեր հարթակում: Այս բաժնում թվարկված բոլոր տվյալները ենթակա են մշակման հետևյալ փուլերին՝ հավաքում, գրանցում, համակարգում, պահպանում, փոփոխում, առբերում, գաղտնագրում, կեղծանունացում, ջնջում, համակցում և փոխանցում: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Մաս 1․ Ինչ տվյալներ ենք մենք հավաքում և ինչպես </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> 1.1 </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} Տվյալներ, որոնք Դուք տրամադրում եք մեզ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Օգտահաշվի տվյալները․{" "} </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> անուն և ազգանուն, էլեկտրոնային հասցե, օգտանուն, գաղտնաբառ, սեռ, ծննդյան ամսաթիվ, բնակության վայր, նկար, հեռախոսահամար։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Դասարանի և դպրոցի մասին տվյալները․{" "} </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> դպրոցի վայրը, դպրոցի անվանումը, դասարանը, բովանդակությունը, որը Դուք կիսում եք Ձեր ուսուցչի կամ համադասարանցիների հետ։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Կիսված բովանդակությունը․{" "} </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> բովանդակությունը, որը Դուք կիսում եք կամ հասանելի ու տեսանելի եք դարձնում այլ օգտատերերին: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> 1.2 </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} Տվյալներ, որոնք մենք հավաքում ենք ավտոմատ կերպով </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Երբ Դուք մուտք եք գործում Ծառայություններ (ներառյալ՝ բովանդակության ներբեռնումը), մենք որոշակի տվյալներ հավաքում ենք ավտոմատ կերպով, այդ թվում՝ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Համակարգի տվյալներ․{" "} </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> այնպիսի տեխնիկական տվյալներ Ձեր համակարգչի կամ սարքի մասին, որոնց միջոցով Դուք մուտք եք գործում Ծառայություններ. այդպիսին են Ձեր սարքի IP հասցեն, սարքի տեսակը, օպերացիոն համակարգի տեսակը և սերիան, սարքի եզակի նույնացուցիչները, ցանցային դիտարկիչի (բրաուզեր) տեսակն ու կարգավորումները, դոմեյնը և այլ համակարգային տվյալներ և հարթակի տեսակներ։&nbsp; </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Օգտագործման տվյալներ․{" "} </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ծառայությունների օգտագործման վերաբերյալ վիճակագրությունը, ներառյալ՝ հասանելի դարձած, դիտված կամ որևէ այլ կերպ օգտագործված բովանդակությունը, Ծառայությունների էջերում անցկացված ժամանակը, այցելած էջերը, օգտագործած ֆունկցիաները, փնտրված ինֆորմացիան, սեղմումների վիճակագրությունը, Ձեր այցերի ամսաթվերն ու ժամանակը, հղումը և Ծառայություններից օգտվելուն վերաբերող այլ տվյալներ։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Մոտավոր աշխարհագրական տվյալներ․{" "} </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> մոտավոր աշխարհագրական տեղակայում, այդ թվում՝ այնպիսի տեղեկատվություն, ինչպիսին է երկիրը, քաղաքը և աշխարհագրական կոորդինատները, որոնք հաշվարկվում են Ձեր սարքի IP հասցեի հիման վրա։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Վերոթվարկյալ տվյալները հավաքվում են սերվերի գրանցամատյանների ֆայլերի և հետևելու տեխնոլոգիաների միջոցով, ինչպես մանրամասնած է ստորև՝ Քուքի ֆայլեր բաժնում։ Մենք տվյալները պահում ենք և զուգորդում Ձեր հաշվի հետ։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> 1.3 </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} Տվյալների հավաքման գործիքները, որ մենք օգտագործում ենք </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Բրեյնոգրաֆն օգտագործում է Տվյալների հավաքման հետևյալ գործիքները՝ ստորև նշված նպատակներով՝{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> խիստ անհրաժեշտ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ․ տվյալների հավաքման այս գործիքները հնարավորություն են տալիս Ձեզ մուտք գործել կայքէջ, ապահովում են բազային ֆունկցիաների գործարկումը (օրինակ՝ մուտք գործել կայք կամ բովանդակություն), կայքի անվտանգությունը, պաշտպանում են կեղծ մուտքերից, հայտնաբերում և կանխում են Ձեր հաշվի չարտոնված օգտագործումը։ Դրանք անհրաժեշտ են Ծառայությունների պատշաճ աշխատանքն ապահովելու համար, ուստի, եթե Դուք անջատեք դրանք, կայքի որոշ մասեր կխափանվեն կամ անհասանելի կդառնան: </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Ֆունկցիոնալ․ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Տվյալների հավաքման այս գործիքները հիշում են Ձեր բրաուզերի մասին տվյալները և Ձեր նախասիրությունները, ապահովում են հավելյալ ֆունկցիաներ, Ձեզ համար անհատականացնում են բովանդակությունը (մեր կայնքի արդյունավետ և օպտիմալ ներկայացման լեգիտիմ շահերի հիմքով) և հիշում են այն կարգավորումները, որոնք ազդում են Ծառայությունների ապահովման և վարքագծի վրա (օրինակ՝ Ձեզ համար գերադասելի լեզուն կամ տեսանյութի ձայնի նախընտրելի մակարդակը)։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Արդյունավետություն. </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Տվյալների հավաքման այս գործիքներն օգնում են չափել և բարելավել Ծառայությունները՝ տրամադրելով օգտագործման և արդյունավետության տվյալներ, այցելությունների քանակ, թրաֆիքի աղբյուրները կամ որտեղից է ներբեռնվել հավելվածը: Այս գործիքները կարող են օգնել մեզ փորձարկել Բրեյնոգրաֆի տարբեր տարբերակներ՝ հասկանալու համար, թե որ ֆունկցիաները կամ բովանդակությունն են նախընտրում օգտվողները և որոշել, թե որ էլեկտրոնային նամակներն են բացվում։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Գովազդ․ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Տվյալների հավաքման այս գործիքներն օգտագործում են համապատասխան գովազդներ ցուցադրելու համար (կայքում և (կամ) այլ կայքերում)՝ հիմնված այն տեղեկատվության վրա, որը մենք ունենք Ձեր մասին, ինչպիսին է Ձեր Համակարգը կամ Օգտագործման տվյալները (ինչպես մանրամասնված է Մաս 1-ում), և տվյալներ, որ գովազդային ծառայություններ մատուցողները գիտեն Ձեր մասին՝ իրենց թրեքինգ տվյալների հիման վրա։ Գովազդները կարող են հիմնված լինել Ձեր վերջին գործողությունների կամ ժամանակի ընթացքում արած գործողությունների վրա այլ կայքերում և ծառայություններում։ Թիրախային գովազդ ցուցադրելու և Ձեզ ցուցադրվող գովազդի արդյունավետությունը հասկանալու և չափելու համար (մեր կայքի և ծառայությունների օգտագործումը հասկանալու, դրանք զարգացնելու, բիզնեսը աճեցնելու և մեր մարքեթինգային քաղաքականությունը բարելավելու լեգիտիմ շահերի հիման վրա), մենք կարող ենք այդ ծառայությունների մատուցողներին տրամադրել ձեր էլեկտրոնային հասցեների խճճած, անանուն տարբերակներ (մարդու համար ոչ ընթեռնելի լեզվով) և բովանդակություն, որը Դուք հանրային են դարձնում մեր Ծառայությունների միջոցով։&nbsp; </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Սոցիալական հարթակներ․ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} Տվյալների հավաքման այս գործիքները հնարավորություն են տալիս օգտվել սոցիալական մեդիա հարթակների ֆունկցիաներից, օրինակ՝ համացանցի և ընկերների հետ բովանդակության կիսումը։ Այս քուքիները կարող են հետևել օգտատիրոջը կամ սարքը այլ կայքերում և ստեղծել օգտատիրոջ հետաքրքրությունների շտեմարան՝ թիրախային գովանդային նպատակներից ելնելով։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Դուք կարող եք կարգավորել Ձեր վեբ բրաուզերն այնպես, որ այն Ձեզ տեղեկացնի Ձեր համակարգչում քուքիներ ներդնելու փորձերի մասին, սահմանափակի քուքիների տեսակները կամ առհասարակ արգելափակի դրանք։ Դա անելու դեպքում, Դուք հնարավոր է չկարողանաք օգտվել մեր Ծառայությունների որոշ ֆունկցիաներից և արդյունքում օգտվել այլ՝ պակաս ֆունկցիոնալ ծառայություններից։ Տվյալների հավաքման գործիքների մասին ավելին իմանալու համար կարդացեք Մաս 4-ը, որը վերաբերում է{" "} <b>Անձնական տվյալների հետ կապված Ձեր իրավունքներին։ </b>
    </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Մաս </span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> 2 </span>
                            <span lang="EN"></span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> &nbsp;&nbsp;&nbsp;Ինչ նպատակով ենք օգտագործում հավաքված տվյալները{" "} </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մենք օգտագործում ենք Ձեր տվյալներն այնպիսի գործողություններ իրականացնելու համար, ինչպիսիք են մեր Ծառայությունները, Ձեզ հետ հաղորդակցությունը, խնդիրների լուծումները, կեղծիքից և չարաշահումներից ապահովագրումը, մեր Ծառայությունների բարելավումն ու թարմացումները, մեր Ծառայությունների օգտագործման վերլուծությունները, անհատականացված գովադները, և, ինչպես օրենքն է պահանջում կամ ինչպես հարկն է, անվտանգություն և միասնականություն ապահովելու համար։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մենք Ծառայությունների օգտագործման միջոցով Ձեր մասին հավաքած տվյալներն օգտագործում ենք՝ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ծառայությունները մատուցելու և կառավարելու համար, այդ թվում՝ կրթական բովանդակության հասանելիությունը, ավարտական վկայականներ ստանալը, անհատականացված բովանդակություն առաջարկելը և մյուս օգտատերերի հետ հաղորդակցությունը դյուրացնելու համար։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Կրթական բովանդակության, հատուկ Ծառայությունների, տեղեկատվության կամ ֆունկցիաների հետ կապված Ձեր դիմումներն ու պատվերները մշակելու համար։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ձեր օգտահաշվի շուրջ Ձեզ հետ հաղորդակցվելու համար՝ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: "1.0in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> անդրադառնալով Ձեր հարցերին և մտահոգություններին։ </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ձեզ ուղարկելով վարչարարական բնույթի նամակներ և տեղեկատվություն, այդ թվում՝ նամակներ ուսուցիչներից, սովորողներից և դասավանդող օգնականներից, այլ օգտատերերից, ծանուցումներ մեր Ծառայությունների փոփոխությունների մասին և թարմացումներ մեր համաձայնությունների վերաբերյալ։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Էլեկտրոնային նամակագրության կամ կարճ հաղորդագրությունների միջոցով Ձեզ տեղեկատվություն ուղարկելով Ձեր առաջընթացի, խրախուսական ծրագրերի, նոր ծառայությունների, նոր ֆունկցիաների, խթանումների, տեղեկագրերի և այլ հասանելի բովանդակության մասին (որից Դուք կարող եք ցանկացած պահի հրաժարվել)։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "1.0in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ուղարկելով Ձեզ ուղղորդող ծանուցումներ՝ թարմացումներ և այլ համապատասխան նամակներ տրամադրելու նպատակով (որոնք Դուք կարող եք կառավարել Ձեր հեռախոսային հավելվածի Տարբերակներ կամ Կարգավորումներ բաժիններից)։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ձեր օգտահաշիվը և օգտահաշվի նախասիրությունները կառավարելու և Ձեր փորձառությունն անհատականացնելու համար։ </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ծառայությունների տեխնիկական գործունակությունը դյուրացնելու, ներառյալ՝ խնդիրների կարգավորման, Ծառայությունների անվտանգության, կեղծիքի և չարաշահումների կանխարգելման համար։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Օգտատերերից հետադարձ կապ ստանալու համար։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ապրանքներ, ծառայություններ գովազդելու, հարցումներ և առաջարկներ անելու համար։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Շուկայական բաժանորդագրության պլաններ գովազդելու, եթե մենք որոշենք ապագայում կիրառել Բաժանորդագրության վրա հիմնված մոդել, և հետևյալ գործողություններն իրականացնելու համար՝ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: "1.0in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> գովազդները հարմարեցնել տարբեր սարքերին,{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> բարելավել մեր Ծառայությունները և զարգացնել նոր պրոդուկտներ, ծառայություններ և ֆունկցիաներ, </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> վերլուծել միտումներն ու թրաֆիքը, օգտագործման տվյալները, </span>
                    </p>
                    <p className="MsoNormal">
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> &nbsp; </span>
                    </p>
                    <p className="MsoNormal">
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ինչպես պահանջում է կամ թույլատրված է օրենքով կամ </span>
                        <span lang="EN"></span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ինչպես մենք, մեր հայեցողությամբ, ինչպես կհամարենք անհրաժեշտ ապահովելու համար մեր օգտատերերի, աշխատակիցների, երրորդ կողմերի, հանրության կամ մեր Ծառայությունների անվտանգությունն ու միասնականությունը։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp; </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> ՄԱՍ 3 </span>
                            <span lang="EN"></span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp;&nbsp; </span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "7.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp; </span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Ինչպես և երբ ենք մենք երրորդ կողմին տրամադրում Ձեր տվյալները </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մենք Ձեր մասին որոշակի տվյալներ կարող ենք փոխանցել մեզ համար ծառայություններ մատուցող ընկերություններին, Բրեյնոգրաֆի մասնաճյուղերին, մեր գործընկերներին, վերլուծություններ կատարողներին, ընկերություններին, որոնք օգնում են մեզ իրականացնել գովազդային ակցիաներ և հարցումներ, ինչպես նաև գովազդային ընկերություններին, որոնք գովազդում են մեր Ծառայությունները: Մենք կարող ենք նաև Ձեր տվյալները տրամադրել անվտանգության նկատառումներից, օրենքի պահանջից ելնելով, կամ ընկերության վերակազմակերպման շրջանակներում: Մենք կարող ենք նաև տվյալներ փոխանցել այլ ձևերով, եթե դրանք միասնականացված են կամ նույնականացված չան, կամ եթե&nbsp; մենք ստանանք Ձեր համաձայնությունը: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մենք կարող ենք Ձեր տվյալները տրամադրել երրորդ կողմին հետևյալ հանգամանքների ներքո կամ ինչպես այլ կերպ ներկայացված է սույն Գաղտնիության քաղաքականության մեջ՝ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Այլ սովորողների կամ ուսուցիչների </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> , եթե Դուք հյուր-օգտվող եք, Դուք կարող եք հրաժարվել կիսել Ձեր բովանդակությունը, ուստի կախված Ձեր կարգավորումներից, Ձեր կիսված բովանդակությունը և էջի տվյալները կարող են հանրայնորեն տեսանելի լինել, ներառյալ՝ այլ օգտատերերի։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Ծառայություն մատուցողների, կապալառուների և գործակալների, եթե </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} մենք Ձեր տվյալները տրամադրում ենք երրորդ կողմ ընկերությունների մեր անունից կեղծիքների կամ շահագործման կանխարգելման, տվյալների վերլուծության, մարքեթինգի և գովանդային ծառայությունների (ներառյալ՝ վերաթիրախավորված գովազդը), էլեկտրոնային փոստի և հոսթինգ ծառայությունների, և հաճախորդների ծառայությունների ու աջակցության նպատակներով։ Նման ծառայություններ մատուցողները կարող են մուտք գործել Ձեր անձնական տվյալներ, սակայն պարտավորված են օգտվել տվյալներից բացառապես մեր ուղղորդմամբ՝ պահանջվող ծառայությունները մեզ մատուցելու նպատակով։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Բրեյնոգրաֆի մասնաճյուղերի հետ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ․ մենք կարող են տրամադրել Ձեր տվյալները մեր ընկերությունների խմբի անդամներին, որոնց հետ կապված ենք համատեղ սեփականությամբ կամ Ծառայությունների մատուցման որակի վերահսկողական նպատակներով։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Գործընկերների հետ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ․ մենք համաձայնություններ ունենք այլ կայքերի և հարթակների հետ մեր Ծառայությունների տարածման և դեպի Բրեյնոգրաֆ թրաֆիք ուղղորդելու հարցերում։ Կախված Ձեր գտնվելու վայրից, մենք կարող ենք Ձեր տվյալները կիսել մեր վստահելի գործընկերների հետ։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%" }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Վերլուծական և տվյլաների մշակման ծառայությունների հետ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Times New Roman",serif'
                        }}> .{" "} </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> որպես երրորդ կողմի վերլուծական գործիքների օգտագործման մաս, ինչպիսին եք Google Analytics-ը և տվյալների հարստացման ծառայությունները, մենք տրամադրում եք որոշակի կոնտակտային տեղեկատվություն կամ ապանույնականացված տվյալներ։ Ապանույնականացված տվյալներ նկաշակում է տվյալներ, որոնցից մենք հեռացրել ենք այնպիսի տեղեկատվություն, ինչպիսիք են Ձեր անունը, էլեկտրոնային հասցեն և փոխարինել ենք դրանք թոքենի ID-ով։ Դա թույլ է տալիս սույն պրովայդերներին տրամադրել վերլուծական ծառայություններ կամ համեմատել Ձեր տվյալները հանրայնացված հասանելի տվյալների բազայի հետ (ներառյալ կապը և սոցիալական տեղեկատվությունն այլ աղբյուրներից)։ Մենք սա անում ենք Ձեզ հետ առավել արդյունավետ և անհատականացված հաղորդկացվելու նպատակով։ </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Գովազդային արշավներ և հարցումներ իրականացնելու համար․ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} մենք կարող ենք ըստ անհրաժեշտության տրամադրել Ձեր տվյալները գովազդային արշավներ և հարցումներ իրականացնելու, մարքեթինգի կամ հովանավորման համար, այն դեպքում, եթե Դուք համաձայնում եք մասնակցել դրանց, ըստ կիրառելի օրենքի (օրինակ՝ հաղթողների ցանկեր հրապարակելու կամ պահանջվող լրացումներ կատարելու համար), կամ գովազդային կամ հարցումների վերաբերյալ կանոնակարգերի։ </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Գովազդի համար․ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} եթե ապագայում որոշենք օգտագործել գովազդահեն եկամտի մոդել, մենք կարող ենք օգտագործել և տրամադրել որոշակի համակարգային և օգտագործման տվյալներ երրորդ կողմի գովազդատուներին և ցանցերին՝ մեր օգտատերերի շրջանում ընդհանուր ժողովրդագրական և նախապատվությունների մասին տեղեկություններ ցուցադրելու համար: Մենք կարող ենք նաև թույլատրել գովազդատուներին հավաքել համակարգային տվյալներ Տվյալների հավաքման գործիքների միջոցով (ինչպես մանրամասնված է Բաժին 2.1-ում), օգտագործել այդ տվյալները՝ Ձեզ նպատակային գովազդ ցուցադրելու, Ձեր փորձառությունն անհատականացնելու (վարքային գովազդի միջոցով) և վեբ վերլուծություն իրականացնելու նպատակով: Գովազդատուները կարող են նաև մեզ հետ կիսել Ձեր մասին հավաքած տվյալները: Ավելին իմանալու կամ գովազդային ցանցերի վարքագծային գովազդին մասնակցելուց հրաժարվելու համար տե'ս ստորև Բաժին 4-ը (Անձնական տվյալների հետ կապված Ձեր իրավունքները): Նկատի ունեցեք, որ հրաժարվելու դեպքում Դուք կշարունակեք ստանալ տարերային գովազդներ:{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Անվտանգության ու իրավական նկատառումներով․ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} մենք կարող ենք երրորդ կողմին տրամադրել Ձեր տվյալները, եթե մենք (բացառապես մեր հայեցողությամբ) հավատացած ենք, որ դա՝{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> պահանջվում է դատական, կառավարական կամ իրավական նկատառումներից ելնելով, </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%" }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ողջամտորեն անհրաժեշտ է որպես օրինական հայց, օրդեր կամ իրավաբանորեն պարտադիր պահանջ, </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ողջամտորեն անհրաժեշտ է մեր օգտագործման պայմանները, գաղտնիության քաղաքականությունը կամ այլ իրավական փաստաթղթերի օգտագործման համար, </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> անհրաժեշտ է կեղծիքները, շահարկումները, սխալ օգտագործումը կամ օրենքի պոտենցիալ խախտումները (օրենք կամ կանոնակարգ) կամ այլ անվտանգային կամ տեխնիկական խնդիրները հայտնաբերելու, կանխելու կամ հասցեագրելու համար, </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> անհրաժեշտ է մեր հայեցողությամբ Բրեյնոգրաֆի, մեր օգտատերերի, աշխատակիցների, հանրության անդամների կամ մեր Ծառայությունների իրավունքներին, գույքին կամ անվտանգությանը սպառնացող անմիջական վնասից պաշտպանելու համար, </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> պահանջվում է մեր աուդիտորների և իրավական խորհրդատուների կողմից, որպեսզի գնահատեն սույն Գաղտնիության քաղաքականության շրջանակներում մեր պարտավորությունները և իրավունքները,{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> օրենքի պահանջ է կամ թույլատրելի է օրենքի սահմաններում։ <br />
      <br />
    </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Ձեր թույտվությամբ․ </span>
                        </u>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} Ձեր համաձայնությամբ մենք կարող ենք երրորդ կողմին տվյալներ տրամադրել Գաղտնիության քաղաքականությունից դուրս։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Մաս{" "} </span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> 4 </span>
                        </b>
                        <span lang="EN"></span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Անձնական տվյալների հետ կապված Ձեր իրավունքները </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        textIndent: ".5in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Դուք որոշակի իրավունքներ ունեք Ձեր տվյալների հետ կապված, ներառյալ գովադային էլեկտրոնային նամակներից, քուքիներից և երրորդ անձանց կողմից Ձեր տվյալների հավաքումից հրաժարվելը։ Դուք կարող եք թարմացնել կամ կասեցնել Ձեր օգտահաշիվը մեր Ծառայությունների միջոցով կամ կարող եք կապ հաստատել մեզ հետ աջակցության համար, ինչպես նաև կարող եք կապ հաստատել մեզ հետ Ձեր անձնական տվյալների հետ կապված որոշակի իրավունքներից օգտվելու պահանջով։ Այն ծնողները, ովքեր կարծում են, որ մենք ոչ նպատակադրված անձնական տվյալներ ենք հավաքել իրենց անչափահաս երեխաների վերաբերյալ, պետք է կապ հաստատեն մեզ հետ, որպեսզի օգնեն հեռացնել այդ տվյալները։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Դուք կարող եք որոշել մեզ չտրամադրել որոշակի տվյալներ, սակայն այդ դեպքում Դուք հնարավոր է չկարողանաք ողջ ծավալով օգտվել մեր Ծառայություններից։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Բրեյնոգրաֆում Դուք իրավունք ունեք՝{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> պահանջել հասանելիություն Ձեր տվյալներին, </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> թարմացնել, փոփոխել կամ ուղղել Ձեր տրամադրած տվյալները,{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN"> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ջնջել Ձեր տվյալները և (կամ) փակել Ձեր օգտահաշիվը։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Ձեր տվյալներին հասանելություն ստանալու, տվյալները թարմացնելու կամ ջնջելու համար խնդրում ենք էլեկտրոնային նամակ ուղարկել{" "} </span>
                        <span lang="EN">
      <a href="mailto:privacy@brainograph.com">
        <span style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "#1155CC"
        }}> privacy@brainograph.com </span>
      </a>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> էլ․ հասցեին՝ Ձեր հստակ հարցումով։ Խնդրում ենք մեզ տրամադրել 30 օր հարցմանը պատասխանելու համար։ Ձեր տվյալները թարմացնելիս խնդրում ենք նկատի ունենալ, որ Դուք պետք է տրամադրեք ճշգրիտ տվյալներ։ Եթե դուք ջնջեք այն տվյալները, որոնք անհրաժեշտ են Ձեզ Ծառայություններ տրամադրելու համար, ապա այլևս չեք կարողանա օգտվել մեր Ծառայություններից: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Ձեր կողմից ուղղակի տրամադրվող տվյալները թարմացնելու համար Դուք կարող եք մուտք գործել Ձեր օգտահաշիվ և ցանկացած ժամանակ թարմացնել Ձեր օգտահաշիվը։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Խնդրում ենք հաշվի առնել, որ նույնիսկ Ձեր օգտահաշիվը փակելուց հետո, Ձեր ստեղծած բովանդակության և տվյալների որոշ մասը կամ ամբողջը կարող են դեռևս տեսանելի լինել այլոց, ներառյալ առանց սահմանափակման ցանկացած տվյալ, որը (ա) կրկնօրինակվել, պահվել կամ տարածվել է այլ օգտատերերի կողմից (ներառյալ բովանդակության մասին մեկնաբանությունները), (բ) տարածվել կամ կիսվել է Ձեր կամ այլոց կողմից (ներառյալ Ձեր կիսված բովանդակությունը)։ Նույնիսկ Ձեր օգտահաշվի փակումից հետո մենք պահպանում ենք Ձեր տվյալներն այնքան ժամանակ, քանի դեռ լեգիտիմ նպատակ ունենք դա անելու համար (գործող օրենքին համապատասխան), ներառյալ օրինական պարտավորությունների, վեճերի կարգավորման կամ մեր միջև ձեռք բերված պայմանավորվածությունների պահպանման նկատառումներով։ Մենք կարող ենք պահպանել և բացել այդ տվյալները սույն Գաղտնիության քաղաքականության համաձայն՝ Ձեր օգտահաշիվը փակելուց հետո։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> ՄԱՍ 5․{" "} </span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "7.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp;&nbsp; </span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Երեխաների տվյալների գաղտնիությունը </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մենք ճանաչում ենք երեխաների գաղտնիության իրավունքները և հորդրում ենք ծնողներին և խնամակալներին ակտիվ ներգրավվել իրենց երեխաների առցանց գործողություններում և հետաքրքրություններում։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Ծառայություններից օգտվելու համար անհրաժեշտ է բավարարել սոցիալական հարթակներում հաշիվ ստեղծելու համար տվյալ երկրի օրենքներով սահմանվող տարիքային շեմին ներկայացվող պահանջները։ Սահմանված տարիքային շեմից ցածր (ըստ ՀՀ օրենքի՝ 16 տարեկան, ըստ ԱՄՆ օրենքի՝ 13, և այլն) օգտատերերի համար օգտահաշիվ կարող են բացել նրանց ծնողները կամ խնամակալները։ Նման դեպքերում, ծնողը կամ խնամակալն են պատասխանատու օգտահաշվի վարման և դրա հետ կապված բոլոր գործողությունների համար։ Պահանջվող տարիքին չհասած անհատները չեն կարող օգտվել Ծառայություններից։ Եթե մենք տեղեկանանք, որ անձնական տվյալներ ենք հավաքել այդ տարիքի երեխաներից, մենք քայլեր կձեռնարկենք օգտահաշիվը ջնջելու համար։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Եթե ծնողը կամ խնամակալը կարծում են, որ հնարավոր է Բրեյնոգրաֆն անձնական տվյալներ է հավաքել սույն կարգավորման խախտմամբ, կարող են դիմել մեզ դրանք վերացնելու համար։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Մաս 6&nbsp; Քուքիներ </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մենք օգտագործում ենք քուքիներ, որոնք փոքր ծավալով տվյալներ պարունակող ֆայլեր են, որոնք թույլ են տալիս հավաքել, պահպանել և կիսել տվյալներ օգտատերերի՝ կայքերում, այդ թվում՝ Բրեյնոգրաֆում, ակտիվության վերաբերյալ։ Դրանք թույլ ենք տալիս մեզ հիշել Բրեյնոգրաֆում Ձեր ակտիվության մասին, օրինակ՝ Ձեր սիրելի ֆունկցիաների մասին՝ այդպիսով կայքի օգտագործումը Ձեզ համար ավելի հարմարավետ դարձնելով։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Բրեյնոգրաֆը և մեր անունից ծառայություններ մատուցողները (օրինակ՝ Google Analytics-ը և երրորդ կողմի գովազդատուները) օգտագործում են տվյալների հավաքման ավտոմատացված ֆայլեր, ինչպիսիք են քուքիները, թագերը, անհատականացված հղումները և այլն (միասին՝ Տվյալների հավաքման գործիքներ), երբ Դուք մուտք եք գործում և օգտվում Ծառայություններից։ Սույն Տվյալների հավաքման գործիքները մեխանիկորեն ֆիքսում և պահում են որոշակի համակարգային և օգտագործման տվյալները (ինչպես մանրամասն նշված է Մաս 1-ում), երբ Դուք օգտվում եք Ծառայություններից։ Որոշ դեպքերում, մենք այդ գործիքների միջոցով հավաքված տվյալները համակցում ենք այլ տվյալների հետ, որոնք մենք հավաքում ենք, ինչպես նկարագրված է սույն Գաղտնիության քաղաքականության մեջ։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> 7. </span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "7.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Անվտանգություն </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Երբ Դուք մեր հարթակում ստեղծում եք օգտահաշիվ, Դուք այն անվտանգ եք դարձնում օգտանունի և գաղտնաբառի միջոցով։ Դուք պետք է Ձեր գաղտնաբառն անվտանգ պահեք և սահմանափակեք երրորդ անձանց մուտքը Ձեր օգտահաշիվ, համակարգիչ կամ որևէ այլ տեխնիկա, որից Դուք մուտք եք գործում և օգտվում մեր Ծառայություններից։ Բրեյնոգրաֆը միջոցներ է ձեռնարկում Ձեր տվյալներն անօրինական մուտքերից, փոփոխություններից, ոչնչացումից կամ կորստից պաշտպանելու համար։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Ձեր անձնական տվյալների անվտանգությունն ապահովելու համար Բրեյնոգրաֆի ձեռնարկած միջոցներից մի քանիսն են անանունացումը, գաղտնագրումը, Ձեր տվյալներին հասանելիություն ունեցող աշխատակիցների թվի սահմանափակումը, առանձին համաձայնագրերը, ինչպես նաև աշխատակիցների հետ անցկացվող վերապատրաստումները՝ օգտատերերի անձնական տվյալների հետ աշխատանքի թեմայով։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մենք հավատում ենք Ձեր գաղտնիության պաշտպանության կարևորությանը և ձեռնարկում ենք բոլոր անհրաժեշտ քայլերը զերծ մնալու համար Ձեր անձնական տվյալների հետ կապված որևէ խնդրից, այդուամենայնիվ մենք չենք կարող երաշխավորել, որ Ձեր տեղեկատվությունը մշտապես լիարժեք ապահով կլինի։&nbsp; Երրորդ անձանց չթույլատրված մուտքը կամ օգտագործումը, սարքերի կամ ծրագրային խափանումները կարող են խաթարել Ձեր անձնական տվյալների անվտանգությունր։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> 8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Օրենսդրություն </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman", serif'
      }}> 8.1 </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> {" "} Կալիֆորնիայի օգտատերերը </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Կալիֆորնիայում բնակվող օգտատերերը որոշակի իրավունքներ ունեն Կալիֆորնիայի սպառողների գաղտնիության ակտի շրջանակներում, այդ թվում հետևյալ իրավունքները՝ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Տեղեկացված լինելու իրավունքը. Դուք իրավունք ունեք պահանջելու ավելին իմանալ այն կատեգորիաների և անձնական տեղեկատվության մասին, որոնք մենք հավաքել ենք Ձեր մասին և ստանալու Ձեր անձնական տվյալների պատճենը։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ուղղման իրավունք․ Դուք իրավունք ունեք պահանջելու, որպեսզի Ձեր մասին սխալ անձնական տեղեկատվությունն ուղղվի։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ջնջման իրավունք․ Դուք իրավունք ունեք պահանջելու ջնջել Ձեր անձնական տվյալները, որոնք մենք հավաքել ենք Ձեր մասին։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Խտրականության չենթարկվելու իրավունք․ եթե Դուք որոշեք իրացնել սույն ակտի ներքո Ձեր իրավունքները, Բրեյնոգրաֆը Ձեզ կվերաբերի այնպես, ինչպես մյուս բոլոր օգտատերերին։ Այլ կերպ ասած Ակտով նախատեսված ձեր իրավունքների իրականացման համար որևէ տույժ չի նախատեսվում: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Հրաժարվելու իրավունք - Դուք իրավունք ունեք հրաժարվել Ձեր անձնական տվյալների վաճառքից։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        textIndent: ".5in"
                    }}>
                        <a name="_gjdgxs" />
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ակտը հատուկ սահմանում ունի «վաճառք»-ի համար և քանի որ Բրեյնոգրաֆը դասական իմաստով չի վաճառում Ձեր անձնական տվյալները կամ որևէ այլ օգտատիրոջ տվյալները, մենք օգտագործում ենք քուքիներ, որոնք անհատականապես չնույնականացվող տվյալները հասանելի են դարձում երրորդ կողմերին։ Այսպիսի «վաճառքից» հրաժարվելու համար մեր{" "} </span>
                        <span lang="EN">
      <a href="mailto:privacy@brainograph.com">
        <span style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            color: "windowtext",
            textDecoration: "none"
        }}> privacy@brainograph.com </span>
      </a>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> էլ հասցեին ուղարկեք նամակ «Չվաճառել իմ անձնական տեղեկատվությունը»&nbsp; վերնագրով։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        textIndent: ".5in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Վերոնշյալ իրավունքներից օգտվելու համար խնդրում ենք էլելտրոնային նամակ ուղարկել{" "} </span>
                        <span lang="EN">
      <a href="mailto:privacy@brainograph.com">
        <span style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "#1155CC"
        }}> privacy@brainograph.com </span>
      </a>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> էլ․ հասցեին։ Ակտը Ձեզ թույլ է տալիս նշանակել լիազորված գործակալ, որը կկատարի այս հարցումները Ձեր անունից: Ձեր պաշտպանության համար մենք կարող ենք պահանջել, որ հարցումն ուղարկվի Ձեր հաշվին կցված էլ.փոստի հասցեից, և մենք կարող ենք պահանջել Ձեր և (կամ) Ձեր գործակալի նույնականացում՝ նախքան Ձեր պահանջը կատարելը։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in",
                        textIndent: ".5in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Որպես Կալիֆորնիայի բնակիչ` Դուք իրավունք ունեք նաև որոշակի տեղեկություններ պահաջել Ձեր անձնական տվյալների մասին, որոնք մենք կիսում ենք երրորդ կողմերի հետ մարքեթինգային նպատակներից ելնելով։ Ձեր պահանջն ուղարկելու համար, էլելտորնային նամակ գրեք{" "} </span>
                        <span lang="EN">
      <a href="mailto:privacy@brainograph.com">
        <span style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            color: "windowtext",
            textDecoration: "none"
        }}> privacy@brainograph.com </span>
      </a>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> էլ․ հասցեին, որում պետք է ներառել Ձեր փոստային հասցեն, բնակության վայրը և էլ․ հասցեն։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> 8.2 </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Times New Roman",serif'
                        }}> {" "} </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Օգտատերեր </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Times New Roman",serif'
                        }}></span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Եվրոպական տնտեսական տարածքից (ԵՏԱ) և Մեծ Բրիտանիայի և Հյուսիսային Իռլանդիայի Միացյալ Թագավորությունից (ՄԹ) </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Եթե Դուք բնակվում եք Եվրոպական տնտեսական տարածքում կամ ՄԹ-ում, Դուք իրավունք ունեք ստանալու Ձեր տվյալները շարժական ձևաչափով և պահանջելու ուղղել, ջնջել, սահմանափակել կամ արգելել Ձեր անձնական տվյալների մշակումը։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Times New Roman",serif'
    }}> <b>9.</b>
    </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "7.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        </b>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Կապ մեզ հետ </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Եթե ունեք կասկած, որ Ձեր օգտահաշվում իրականացվել են գործողություներ, որոնք չեն կատարվել և (կամ) արտոնվել Ձեր կողմից, խնդրում ենք անմիջապես տեղեկացնել մեզ՝ նամակ ուղարկելով privacy@brainograph.com հասցեին: Մեր Տվյալների պաշտպանության պատասխանատուն կզբաղվի Ձեր դիմումով։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> &nbsp; </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> &nbsp; </span>
                        </b>
                    </p>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
