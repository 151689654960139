export const getMapStyles = (id, mapStyles,list) => {
    return mapStyles.map((style) => {
        const findStyle = list.find(item => style.id === item.mapTypeId);
        style.hide = true
        style.active = style.id === id ? 'light' : false
        if (findStyle) {
            style.hide = false
        }
        return style
    })
}


export const getMapStylesDark = (id, mapStyles) => {
    return mapStyles.map((style) => {
        style.active = style.id === id ? style.active === 'light' ? 'dark' : 'light' : false
        return style
    })
}

export const changeMapStylesList = (mapStyles, initialStyles,id) => {
    const data = [];
    if (!Array.isArray(mapStyles)) {
        return initialStyles
    }
    const mainStyle = mapStyles.find(el=>el.isMain).mapTypeId
    const activeStyle = mapStyles.find(item => id === item.mapTypeId);
     const result = initialStyles.map((style) => {
        const findStyle = mapStyles.find(item => style.id === item.mapTypeId);
        style.hide = true
         if(!window.scrrenShot || !activeStyle){
             style.active = false
             if(mainStyle === style.id){
                 style.active = 'light'
             }
         }
        if (findStyle) {
            style.hide = false
        }
        return style
    });
    return result
}
