import React, {useState, useEffect, useRef} from 'react';
import SliderPosition from '../SliderPosition';
import './style.css';
import DragAndDropIcon from "../DragAndDropIcon";
import Close from '../../../assets/imgs/close-black-icon.svg'
import SlideInfo from '../../../assets/imgs/icons/slideInfoBlue.svg'
import SliderPositionCenter from "../SliderPositionCenter";
import SliderPositionRight from "../SliderPositionRight";
import SliderPositionLeft from "../SliderPositionLeft";
const DraggableDiv = ({onClose,item}) => {
    const [position, setPosition] = useState({
        x: window.innerWidth * 33 / 100,
        y: window.innerHeight * 33 / 100,
    });
    const [initialClick, setInitialClick] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [hardPosition, setHardPosition] = useState('center');
    const [positionPopupVisible, setPositionPopupVisible] = useState(false);
    const dragItemRef = useRef()

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isDragging) return;
            const newX = e.clientX - initialClick.x;
            const newY = e.clientY - initialClick.y;
            const maxX = window.innerWidth - (hardPosition === 'center' ? (30 * window.innerWidth) / 100 : (25 * window.innerWidth) / 100);
            const maxY = window.innerHeight - (hardPosition === 'center' ? (35 * window.innerHeight) / 100 : (70 * window.innerHeight) / 100);
            setPosition({
                x: Math.max(0, Math.min(newX, maxX)),
                y: Math.max(0, Math.min(newY, maxY)),
            });
        };
        const handleMouseUp = () => {
            setIsDragging(false);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, initialClick]);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setInitialClick({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
    };

    const handleOpenPositionPopup = () => {
        setPositionPopupVisible(!positionPopupVisible)
    }

    window.addEventListener('beforeunload', () => {
        localStorage.removeItem('infoPopupPosition');
    });

    const handleHardPosition = (position) => {
        setHardPosition (position)
        handleOpenPositionPopup ()
        localStorage.setItem('infoPopupPosition', position)
        if (position === 'right') {
            setPosition ({
                x: window.innerWidth - (window.innerWidth * 25 / 100),
                y: window.innerHeight * 9 / 100,
            });
        }
        if (position === 'left') {
            setPosition({
                x: 0,
                y: window.innerHeight * 9 / 100,
            });
        }
        if (position === 'center') {
            setPosition({
                x: (window.innerWidth - (35 * window.innerWidth / 100)) / 2,
                y: (window.innerHeight - (32 * window.innerHeight / 100)) / 2,
            });
        }
    }

    useEffect(() => {
        const localPosition = localStorage.getItem('infoPopupPosition')
        if(localPosition) {
            handleHardPosition(localPosition)
            setPositionPopupVisible(false)
        }
    },[])

    useEffect(() => {
        if (dragItemRef.current) {
            dragItemRef.current.classList.add('draggable__div__box');
        }
        const timer = setTimeout(() => {
            dragItemRef.current.classList.remove('draggable__div__box');
        }, 500);
        return () => clearTimeout(timer);
    }, [item]);

    return (
        <div
            ref={dragItemRef}
            style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
                width: hardPosition === 'center' ? '30%' : '25%',
                borderRadius: '15px',
                minHeight: '275px',
                height: hardPosition === 'center' ?  window.innerHeight * 31.5 / 100 : window.innerHeight * 70 / 100,
                backgroundColor: '#fff',
                userSelect: isDragging && 'none'
            }}
        >
            <div className="draggable__div__header">
                {hardPosition === 'center' ?
                    <div style={{cursor: isDragging && 'grabbing'}} className="drag__icon" onMouseDown={handleMouseDown}>
                    <DragAndDropIcon/>
                </div> : <div></div>}
                {!isDragging && <div>
                    <button onClick={handleOpenPositionPopup}>
                        <SliderPosition position={hardPosition}/>
                    </button>
                    <button onClick={onClose}>
                        <img src={Close}/>
                    </button>
                </div>}
            </div>
            <div className='main__info'>
                <div className='slide__title'>
                    <img src={SlideInfo}/>
                    <p>{item?.title}</p>
                </div>
                <div style={{height: hardPosition === 'center' ? window.innerHeight * 23 / 100 :  window.innerHeight*62/100 }} className='slide__main__info'>
                    {item?.shortDescription}
                </div>
            </div>
            {positionPopupVisible && (
                <div className='position__popup'>
                    <button onClick={() => handleHardPosition('center')}>
                        <SliderPositionCenter active={hardPosition === 'center'} />
                    </button>
                    <button onClick={() => handleHardPosition('right')}>
                        <SliderPositionRight active={hardPosition === 'right'} />
                    </button>
                    <button onClick={() => handleHardPosition('left')}>
                        <SliderPositionLeft active={hardPosition === 'left'} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default DraggableDiv;
