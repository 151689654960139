import React, { Component } from "react";
import Account from "../account";
import "./style.css";
import Loader from "../../components/UI/Loader";
import axios from "axios";
import ErrorAlert from "../../components/errorAlert";
import { Link } from "react-router-dom";
import { getErrorMessage } from "../../helpers";
import ErrorCheck from "../../components/UI/ErrorCheck";
import SuccessCheck from "../../components/UI/SuccessCheck";
const queryParams = new URLSearchParams(window.location.search);

class ConfirmEmail extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            token: queryParams.get("data"),
            confirmed: false,
        };
    }

    componentDidMount() {
        if (this.state.token) {
            axios
                .post(
                    `${process.env.REACT_APP_IDENTITY_URL}/api/User/ConfirmEmail?data=${this.state.token}`
                )
                .then(async (res) => {
                    const errorState = {
                        loading: false,
                        showError: true,
                        confirmed: false,
                    };
                    if (res.data.accepted) {
                        errorState.confirmed = true;
                        errorState.showError = false;
                        this.setState(errorState);
                    } else {
                        errorState.apiErrorMsg = getErrorMessage(res.data.errorMessages);
                        this.setState(errorState);
                    }
                });
        } else {
            window.location.href = "/signup";
        }
    }

    render() {
        const { confirmed, loading, showError, apiErrorMsg } = this.state;
        const { setState } = this;

        return (
            <Account>
                <div className="account-content">
                    {loading ? (
                        <Loader />
                    ) : confirmed ? (
                        <>
                            <SuccessCheck />
                            <p className="check-text">Շնորհակալություն</p>
                            <p className="check-text">
                                ՁԵՐ ԳՐԱՆՑՈՒՄԸ «ԱՇԽԱՐՀԱՑՈՒՅՑ» ԿՐԹԱԿԱՆ ՀԱՐԹԱԿՈՒՄ ՀԱՋՈՂՈՒԹՅԱՄԲ
                                ԻՐԱԿԱՆԱՑՎԵԼ Է։
                            </p>
                        </>
                    ) : (
                        <>
                            <ErrorCheck />
                            <p className="check-text">
                                Ձեր գրանցումը «Աշխարհացույց» կրթական հարթակում ձախողվել է։
                                Խնդրում ենք կրկին փորձել։
                            </p>
                        </>
                    )}
                    {showError && (
                        <ErrorAlert
                            text={apiErrorMsg}
                            close={() => setState({ showError: false })}
                        />
                    )}
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <span style={{ color: "#fff" }}></span>{" "}
                    <Link className="reg" to="/login">
                        ՄՈՒՏՔ
                    </Link>
                </div>
            </Account>
        );
    }
}

export default ConfirmEmail;
