import { SET_MAP_REQUIREMENT } from "../constants";
import { setSearchData } from "./search";
import { setMenuData } from "./menu";
import { setHintsData } from "./hints";
import { setMapStyleData } from "./mapStyle";
import { setQuizPopupData } from "./quizPopup";

export const setMapRequirementData = (bool) => ({
  type: SET_MAP_REQUIREMENT,
  payload: bool,
});

export const setMapRequirement = (bool) => (dispatch) => {
  dispatch(setSearchData(false));
  dispatch(setMenuData(false));
  dispatch(setMapStyleData(false));
  dispatch(setHintsData(false));
  dispatch(setQuizPopupData(false));
  dispatch(setMapRequirementData(bool));
};
