import { Link } from "react-router-dom";
import AccountBoards from "../../../components/UI/AccountBoards";
import AccountPageIcon from "../../../components/UI/AccountPage";
import { LogOut } from "../../../helpers";
import LogOutIcon from "../../../components/UI/LogOut";
import React from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../../store/actions/user";
import AccountBoard from "../../../components/UI/AccountBoards";
import DynamicScreenshot from "../../../components/UI/DynamicScreenshot";
import Bookmark from "../../../components/UI/Bookmark";
import AccountPage from "../../../components/UI/AccountPage";
import Logout from "../../../components/UI/LogOut";
import PresentationIcon from "../../../components/UI/Presentation";

const TopBar = (props) => {
  return (
    <div className="top-btns">
      <div className="top-btns-left-bar">
        <div className={`main__tab`}>
          <Link to="/account-boards">
            <div className="main__tab-btn">
              <AccountBoard />
            </div>
            <div className="main__tab-btn__title">
              Թեմաներ ԵՎ <br /> դասեր
            </div>
          </Link>
        </div>
        <div className={`main__tab bookmarks__main-tab`}>
          <Link to="/bookmarks?page=1">
            <div className="main__tab-btn">
              <Bookmark />
            </div>
            <div className="main__tab-btn__title">
              Պահպանված <br /> Հոդվածներ
            </div>
          </Link>
        </div>
        {window.innerWidth > 1024 &&
        (<div className={`main__tab dynamic-screenshot__main-tab`}>
          <Link to="/presentations?page=1">
            <div className="main__tab-btn">
              <PresentationIcon />
            </div>
            <div className="main__tab-btn__title">
              Ցուցադրութ<br />յուններ
            </div>
          </Link>
        </div>)}
        {window.innerWidth > 1024 &&
        (<div className={`main__tab dynamic-screenshot__main-tab`}>
          <Link to="/dynamic-screenshots?page=1">
            <div className="main__tab-btn">
              <DynamicScreenshot />
            </div>
            <div className="main__tab-btn__title">
              պահպանված <br /> քարտեզներ
            </div>
          </Link>
        </div>)}
      </div>
      <div className="top-btns-right-bar">
        <div>
          <Link to="/account-page">
            <div className="main__tab-btn">
              <AccountPage active={true} />
            </div>
            <div className="main__tab-btn__title activeTitle">
              Անձնական <br /> էջ
            </div>
          </Link>
        </div>
        <div className={`main__tab`}>
          <button type="button" onClick={LogOut}>
            <div className="main__tab-btn">
              <Logout />
            </div>
            <div className="main__tab-btn__title">դուրս գալ</div>
          </button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logoutUser,
};

export default connect(null, mapDispatchToProps)(TopBar);
