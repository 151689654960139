import { connect } from "react-redux";
import "./style.css";
import React from "react";
const DynamicInfo = (props) => {
  const { content = {} } = props;
  return (
    <div className="card-body__dynamic-info dynamic-info">
      {content?.title && <h4>{content?.subject} - &nbsp;</h4>}
      {content?.title && <p>{content?.title}</p>}
    </div>
  );
};
export default connect(null, null)(DynamicInfo);
