import {connect} from "react-redux";
import CloseDark from "../../CloseDark";
import './style.css'
const CloseBtn = (props) => {
    const { onClose = ()=>{} } = props
    return (
        <div className="card-body__dynamic-close-btn-box dynamic-close-btn-box">
            <button className='dynamic__close-btn' onClick={onClose}>
                <CloseDark />
            </button>
        </div>
    )
}
export default connect(null, null)(CloseBtn);
