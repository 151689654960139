import { SET_RESET_STATE } from "../constants"

const initialState = {
    resetState: null,
}



export const resetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RESET_STATE:
            return {
                ...state,
                resetState: action.payload,
            }
        default:
            return state
    }
}

