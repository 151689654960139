import React, {useState} from "react";
import {connect} from "react-redux";
import './style.css'
import HorizontalDots from "../../HorizontalDots";
import Popover from "../../Popover";
import DynamicAction from "../DynamicActions";
const DynamicActionBtn = (props) => {
    const { handleEditItem, handleRemoveItem  } = props
    const [openPopover, setOpenPopover] = useState(false)
    const [referenceElement, setReferenceElement] = useState(null);
    const handleClosePopover = () =>{
        setOpenPopover(false)
    }
    const handleTogglePopover = (e) =>{
        e.stopPropagation()
        setOpenPopover(()=>!openPopover)
    }

    return (
        <div className="card-body__dynamic-actions-btn-box dynamic-actions-btn-box">
            <button className="dynamic-action-btn"
                    data-active={openPopover}
                    ref={setReferenceElement}
                    onClick={handleTogglePopover}>
                <HorizontalDots/>
            </button>
            <Popover isOpened={openPopover}
                     onClose={handleClosePopover}
                     reference={referenceElement}
                     placement={'bottom-end'}
                     popoverStyle={{zIndex:2000}}>
                <DynamicAction edit={handleEditItem} remove={handleRemoveItem}/>
            </Popover>
        </div>
    )
}
export default connect(null, null)(DynamicActionBtn);
