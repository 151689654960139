import { getTopicsAPI } from "../../service/topics";
import { SET_TOPICS, LOADING_TOPICS,SET_TOPIC_ID } from "../constants";

export const loadingTopics = () => ({
  type: LOADING_TOPICS,
});

export const setTopics = (topics) => ({
  type: SET_TOPICS,
  payload: topics,
});

export const setTopicId = (id) => {
  return {
    type: SET_TOPIC_ID,
    payload: id,
  }
}


export const getTopicsFromAPI = (url, body) => (dispatch, getState) => {
  dispatch(loadingTopics());
  const state = getState();

  getTopicsAPI(url, body, state).then((topics) => {
    dispatch(setTopics(topics));
  });
};
