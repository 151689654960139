import { SET_TIMELINE_EXPEND } from '../constants'

export const setTimeLineExpendData = (bool) => ({
    type: SET_TIMELINE_EXPEND,
    payload: bool,
})

export const setTimeLineExpend = (bool) => (dispatch) => {
    dispatch(setTimeLineExpendData(bool))
}
