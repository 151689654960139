import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import { connect } from "react-redux";
import PainterStartIcon from "../../assets/imgs/PaintBar/painter_start_icon.svg";
import {
  closeArticlePopup,
  getArticleFromAPI,
} from "../../store/actions/articles";
import { setMapStyle } from "../../store/actions/mapStyle";
import {
  changeMapStyles,
  setMapStylesDark, setMapStylesList,
} from "../../store/actions/mapStyles";
import { setMenu } from "../../store/actions/menu";
import {
  setColorPickerShowState,
  setPainterStart,
  setStartPaintClickState,
} from "../../store/actions/painterStart";
import { setSearch } from "../../store/actions/search";
import {
  getCategoriesData,
  getColorPickerShowState,
  getMapStyleData,
  getMapStyledId,
  getMapStylesData, getMapStylesListData,
  getMenuData,
  getMobileMenuState,
  getPainterStartData,
  getScreenShootButtonStateST,
  getSearchData,
  getStartPaintClickState,
} from "../../store/selectors";
import LeftNavigation from "../leftNavigation";
import PaintBar from "../PaintBar/PaintBar";
import CategoryFilter from "../UI/CategoryFilter";
import CategoryFilterActive from "../UI/CategoryFilterActive";
import "./style.css";
//base map img
import BaceMapIcon from "../../assets/imgs/basemap.svg";
import physical from "../../assets/imgs/BaseMap/physical.png";
import politics from "../../assets/imgs/BaseMap/politics.png";
import satellite from "../../assets/imgs/BaseMap/satellite.png";
import terrain from "../../assets/imgs/BaseMap/terrain.png";
import geographyTerrain from "../../assets/imgs/BaseMap/geography-terrain.png";
import CheckIcon from "../../assets/imgs/check.svg";
import SearchIcon from "../../assets/imgs/search-icon.svg";
import MobileSearchIcon from "../../assets/imgs/mobile/mobile_search.svg";

import { isMobile } from "react-device-detect";
import {
  setMapStylesDarkId,
  setMapStylesIdST,
  setMobileMenuClose,
} from "../../store/actions/mapStateAction";
import MapScreenShotButton from "../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import Loader from "../UI/Loader";
import { useLocation } from "react-router-dom";
import close_icon from "../../assets/imgs/mobile/mobile_mode_close_icon.png";
import { cutWord } from "../../utils/utils";
import LeftNavigationMobile from "../leftNavigationMobile/LeftNavigationMobile";
import ClickOutside from '../ClickOutside'
import {MAP_TOOLBOX_KEYS} from "../mapToolbox";
import InfiniteScroll from 'react-infinite-scroll-component';
const debounce = (fn, wait) => {
  let timeoutID;
  // call as many times as you wish!
  // I'll do my job after `wait` miliseconds
  return (...arg) => {
    if (timeoutID) clearTimeout(timeoutID);
    timeoutID = setTimeout(() => fn(...arg), wait);
  };
};
const debeounced400 = debounce((fn) => fn(), 400);
const mapStyleImg = {
  politics,
  physical,
  satellite,
  terrain,
  geographyTerrain
};
const MapHeader = (props) => {
  const {mapTypes, tools,globalLoading} = props;
  const referenceElementRef = useRef()
  const filterReferenceElementRef = useRef()
  const [searchResult, setSearchResult] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [filterToggle, setFilterToggle] = useState(false);
  const [mode, setMode] = useState(true);

  const hasRubber = tools?.[MAP_TOOLBOX_KEYS.RUBBER];
  const hasRuler = tools?.[MAP_TOOLBOX_KEYS.RULER];
  const hasPencil = tools?.[MAP_TOOLBOX_KEYS.PENCIL];
  const hasUndo = tools?.[MAP_TOOLBOX_KEYS.UNDO];
  const hasRedo = tools?.[MAP_TOOLBOX_KEYS.REDO];
  const hasDelete = tools?.[MAP_TOOLBOX_KEYS.DELETE];

  const showToolboxButton = hasRubber || hasRuler || hasPencil || hasUndo || hasRedo || hasDelete


  const [isLandscape, setIsLandscape] = useState(
    Math.abs(window.orientation) === 90
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      if (Math.abs(window.orientation) === 90) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  // useEffect(() => {
  //   if (mapTypes) {
  //     props.setMapStylesList(mapTypes);
  //   }
  // },[mapTypes])


  //Mobile
  const [mobileShow, setMobileShow] = useState(false);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isScreenShot = query.get("screenShot");

  const handleSearch = (value,type, catID) => {
    if (value.length > 0) {
      setSearchTerm(value);
      setSearchLoader(true);
      debeounced400(() => {
        if(pageNumber > 0) setPageNumber(0);
        let queryParams = {
          Term: value,
          CategoryId: catID || selectedCategoryId,
          PageSize: 10,
          PageNumber: 0,
        };
        axios
          .get(`${process.env.REACT_APP_INDEXING_URL}/api/Map/Search`, {
            params: queryParams,
          })
          .then((response) => {
            if (response.data.data[0].total <= response.data.data[0].items.length) {
              setLoadMore(false);
            }else{
              setLoadMore(true);
            }
            setSearchResult(response.data.data[0].items);
            setSearchLoader(false);
          });
      });
    } else {
      setSearchTerm(value);
      setSearchResult([]);
    }
  };
  const handleCloseMapStyle = () =>{
    setMapStyle(false)
  }
  const handleCloseFilter = () =>{
    setFilterToggle(false)
  }
  const loadMoreResult = () => {
    let newPageNumber = pageNumber + 1;
    setPageNumber(newPageNumber);
    let queryParams = {
      Term: searchTerm,
      CategoryId: selectedCategoryId,
      PageSize: 10,
      PageNumber: newPageNumber,
    };
    axios
      .get(`${process.env.REACT_APP_INDEXING_URL}/api/Map/Search`, {
        params: queryParams,
      })
      .then((response) => {
        let data = response.data.data[0].items;
        let newData = [...searchResult, ...data];
        if (response.data.data[0].total <= newData.length) {
          setLoadMore(false);
        }
        setSearchResult(newData);
      });
  };
  const stopPinAnimation = () => {
    let mapMarkers = document.getElementsByClassName(
      "mapboxgl-marker-anchor-center"
    );

    for (let i = 0; i < mapMarkers.length; i++) {
      mapMarkers[i].classList.remove("tic_animation");
      mapMarkers[i].style.zIndex = "1";
    }
  };
  const changeCategory = (id) => {
    setSelectedCategoryId(()=>id);
    handleSearch(searchTerm,'new',id);
  };
  const toggleFilter = () => {
    setFilterToggle((prev) => !prev);
  };

  const onClickMapStyle = id => {
    setMapStyles(id);
    setMapStylesIdST(id);
    if (!isMobile) setMapStyle(!mapStyle);
    closeArticlePopup("all");
    stopPinAnimation();
  }

  const {
    mapStyle,
    setMapStyle,
    search,
    setSearch,
    mapStyles,
    mapStylesList,
    setMapStyles,
    setMapStylesDark,
    categories,
    getArticleFromAPI,
    painterStart,
    setPainterStart,
    map,
    closeArticlePopup,
    setMapStylesIdST,
    setMapStylesDarkId,
    getScreenShootButtonStateST,
    getStartPaintClickState,
    getColorPickerShowState,
    setColorPickerShowState,
    setStartPaintClickState,
    setMobileMenuClose,
    getMobileMenuState,
  } = props;


  const onMobileMenuClose = () => {
    setMobileMenuClose(!getMobileMenuState);
    setTimeout(() => {
      setSearch(false);
      setPainterStart(false);
      setMobileShow(false);
      setColorPickerShowState(false);
      setStartPaintClickState(false);
      setFilterToggle(false);
      setSearchTerm("");
      setSearchResult([]);
    }, 0);
  };

  const [activeBaseMapId, setActiveBaseMapId] = useState(1);

  return (
      <>
        <div style={{'zIndex': '10000'}}>
          <LeftNavigationMobile map={map} globalLoading={globalLoading}/>
        </div>
        <header className="header">
      {window.innerWidth > 1024 ? (
        <div className="header__right">
          <div
            className={`right-side__search main__tab ${search ? "active" : ""}`}
            style={{ marginRight: "6px", borderRadius: "10px",'--loader-color':'#ececec ' }}
            data-is-loading={globalLoading}
          >
            {!painterStart && (
              <button
                className="right-side__search-btn main__tab-btn"
                type="button"
                onClick={() => {
                  setSearchTerm("");
                  setSearch(!search);
                  setSearchResult([]);
                  closeArticlePopup("all");
                  stopPinAnimation();
                }}
                style={{ borderRadius: "10px" }}
              >
                <span className="main__tab-icon icon-wrapper search">
                  <img
                    src={SearchIcon}
                    alt=""
                    onClick={() => {
                      setSearchTerm("");
                      setSearch(false);
                      setSearchResult([]);
                    }}
                  />
                </span>
              </button>
            )}
            <div className="right-side__search-content">
              <div className="right-side__search-field">
                <div>
                  <div>
                    {
                        search &&
                        (
                            <input
                                autoComplete="off"
                                autoFocus={true}
                                value={searchTerm}
                                name="search"
                                className="right-side__search-input"
                                placeholder="Որոնել"
                                onInput={(e) => handleSearch(e.target.value,'new')}
                            />
                        )
                    }
                  </div>
                  <span
                    className="right-side__filter-box"
                    // onMouseLeave={() => setFilterToggle(false)}
                      ref={filterReferenceElementRef}
                  >
                    <span
                      className="right-side__filter-icon"
                      onClick={toggleFilter}
                      data-show={!filterToggle}
                    >
                      {!filterToggle ? (
                        <CategoryFilter />
                      ) : (
                        <CategoryFilterActive />
                      )}
                    </span>
                    {filterReferenceElementRef.current && filterToggle && (<ClickOutside
                        reference={filterReferenceElementRef.current} onClickOutside={handleCloseFilter}>
                      <ul
                          className="right-side__search-filters right-side__filters custom-scroll2"
                          data-show={!filterToggle}
                      >
                        <li className="right-side__filters-item categories right-side__filters-title">
                          <p>ֆիլտրել </p>
                        </li>
                        <li
                            className={
                              selectedCategoryId
                                  ? "right-side__filters-item categories"
                                  : "right-side__filters-item categories active"
                            }
                        >
                          <button
                              onClick={(e) => changeCategory(null)}
                              className="right-side__filters-btn "
                              type="button"
                          >
                            {selectedCategoryId === null && (
                                <img
                                    className={"activeState"}
                                    src={CheckIcon}
                                    alt=""
                                />
                            )}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                style={{
                                  marginRight: "5px",
                                }}
                            >
                              <path
                                  d="M9.5,0a1.254,1.254,0,0,1,1.25,1.25V2.625a2.333,2.333,0,0,1-.625,1.438L7.437,6.437a2.035,2.035,0,0,0-.625,1.438v2.687a1.392,1.392,0,0,1-.562,1.063l-.875.563a1.274,1.274,0,0,1-1.938-1.062V7.813a2.416,2.416,0,0,0-.5-1.312L.563,4A2.072,2.072,0,0,1,0,2.75V1.313A1.268,1.268,0,0,1,1.25,0Z"
                                  transform="translate(2.125 1.312)"
                                  fill="none"
                              />
                              <path
                                  d="M3.081,0,0,4.938"
                                  transform="translate(3.75 1.312)"
                                  fill="none"
                              />
                              <path d="M15,0V15H0V0Z" fill="none" opacity="0"/>
                            </svg>
                            <span>Բոլորը</span>
                          </button>
                        </li>
                        {categories.map((item, index) => {
                          return (
                              <li
                                  key={item.id}
                                  className={`right-side__filters-item categories ${
                                      selectedCategoryId === item.id ? "active" : ""
                                  }`}
                              >
                                <button
                                    onClick={(e) => changeCategory(item.id)}
                                    className="right-side__filters-btn "
                                    type="button"
                                >
                                  {selectedCategoryId === item.id && (
                                      <img
                                          className={"activeState"}
                                          src={CheckIcon}
                                          alt=""
                                      />
                                  )}
                                  <img
                                      src={item.iconPath}
                                      style={{
                                        marginRight: "5px",
                                      }}
                                      alt=""
                                  />
                                  <span>{item.title}</span>
                                </button>
                              </li>
                          );
                        })}
                      </ul>
                    </ClickOutside>)}
                  </span>
                  {/*<span className="right-side__search-icon icon-wrapper">*/}
                  {/*  <Search />*/}
                  {/*</span>*/}
                </div>
              </div>

              {searchResult.length > 0 && (
                <>
                  <div className="right-side__search-response">
                    <div id="scrollableDiv" className="response main__subitem custom-scroll">
                      <InfiniteScroll
                          dataLength={searchResult.length} //This is important field to render the next data
                          next={loadMoreResult}
                          hasMore={loadMore}
                          loader={<h4 style={{ textAlign: "center" }}>...</h4>}
                          height={247}
                      >
                          {searchResult.map((item) => {
                            return (
                              <div
                                key={item.id}
                                className="right-side__filters-item"
                              >
                                <button
                                  className="right-side__filters-btn with-svg"
                                  onClick={() => {
                                    setSearch(!search);
                                    getArticleFromAPI(item.id);
                                  }}
                                >
                                  <img
                                    src={
                                      categories?.find(
                                        (x) => x.id === item.category[0].categoryId
                                      )?.iconPath
                                    }
                                    alt=""
                                  />
                                  <span className={"search-text"}>
                                    {item.content[0].mainTitle}
                                  </span>
                                </button>
                              </div>
                            );
                          })}
                      </InfiniteScroll>
                    </div>
                    {/*{loadMore && (*/}
                    {/*  <button*/}
                    {/*    style={{*/}
                    {/*      width: "100%",*/}
                    {/*      justifyContent: "center",*/}
                    {/*      alignItems: "center",*/}
                    {/*      display: "flex",*/}
                    {/*      marginBottom: "20px",*/}
                    {/*      fontSize: "11px",*/}
                    {/*    }}*/}
                    {/*    onClick={() => loadMoreResult()}*/}
                    {/*  >*/}
                    {/*    Ավելին*/}
                    {/*  </button>*/}
                    {/*)}*/}
                  </div>
                </>
              )}
              {searchTerm.length > 0 && searchResult.length === 0 && (
                <div className="right-side__search-response">
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginBottom: "20px",
                      fontSize: "11px",
                      backgroundColor: "#fff",
                    }}
                  >
                    {searchLoader ? <Loader /> : <span>Ոչինչ չի գտնվել</span>}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`right-side__toolbox main__tab`}
            style={{ marginRight: "6px", borderRadius: "10px",'--loader-color':'#ececec ' }}
            data-is-loading={globalLoading}
          >
            {!query.get("preview") && !painterStart && !isScreenShot && (
              <MapScreenShotButton map={map}/>
            )}
            {!query.get("preview") && !painterStart && isScreenShot && (
              <MapScreenShotButton map={map} disable={getScreenShootButtonStateST} />
            )}
          </div>
          {
            showToolboxButton &&
              <div
                  className={`right-side__toolbox main__tab ${
                      painterStart ? "active" : ""
                  }`}
                  style={{ marginRight: "6px", borderRadius: "10px",'--loader-color':'#ececec ' }}
                  data-is-loading={globalLoading}
              >
                <button
                    className="right-side__search-btn main__tab-btn"
                    type="button"
                    onClick={() => {
                      setPainterStart(!painterStart);
                      closeArticlePopup("all");
                      stopPinAnimation();
                    }}
                    style={{ borderRadius: "10px" }}
                >
              <span className="main__tab-icon icon-wrapper">
                <img src={PainterStartIcon} alt="" />
              </span>
                </button>
                {map && <PaintBar tools={tools} map={map} />}
              </div>
          }


          <div
            className={`right-side__filters filters main__tab ${
              mapStyle ? "active" : ""
            }`}
            // onMouseLeave={() => {
            //   mapStyle && setMapStyle(false);
            // }}
            style={{ marginRight: "6px", borderRadius: "10px",'--loader-color':'#ececec ' }}
            data-is-loading={globalLoading}
          >
            <button
              className="filters__tab main__tab-btn"
              type="button"
              ref={referenceElementRef}
              onClick={() => {
                if (!isMobile) setMapStyle(!mapStyle);
                closeArticlePopup("all");
                stopPinAnimation();
              }}
              onTouchStart={() => (isMobile ? setMapStyle(!mapStyle) : null)}
              style={{ borderRadius: "10px" }}
            >
              <span className="main__tab-icon icon-wrapper">
                <img src={BaceMapIcon} alt="" />
              </span>
            </button>
            {referenceElementRef.current && mapStyle && (
                <ClickOutside reference={referenceElementRef.current} onClickOutside={handleCloseMapStyle}>
                  <div className="filters__content base-map">
                    {/*<h2 className="filters__title">Բազային քարտեզ</h2>*/}
                    <fieldset className="filters__radio">
                      {mapStyles?.map((item, index) => {
                        return (
                            <label className="filters__radio-label" key={item.id} style={{'display': item.hide ? 'none' : 'unset'}}>
                              <input
                                  type="radio"
                                  name="mapStyle"
                                  onChange={() => {
                                    onClickMapStyle(item.id)

                                  }}
                                  onTouchStart={() => (isMobile ? setMapStyle(!mapStyle) : null)}

                                  checked={item.active !== false}
                              />
                              <div className="filters__radio-label__img-box">
                                <img
                                    className="filters__radio-label__img"
                                    src={mapStyleImg[item.value]}
                                    alt={item.value}
                                />
                              </div>
                              <span className={`filters__radio-text map${item.id}`}>
                        {item.title}
                      </span>
                            </label>
                        );
                      })}
                      {mapStyles.filter((x) => x.active)[0].dark !== false && (
                          <div className="mode-switch-box">
                            <label
                                className="filters__radio-label"
                                htmlFor="light-mode"
                                onClick={() => setMode(true)}
                            >
                              <input
                                  type="radio"
                                  name="mapMode"
                                  checked={
                                      mapStyles.filter((x) => x.active !== false)[0]
                                          .active === "light"
                                  }
                                  onChange={() => {
                                    setMapStylesDark(
                                        mapStyles.filter((x) => x.active !== false)[0].id
                                    );
                                    if (!isMobile) setMapStyle(!mapStyle);
                                    closeArticlePopup("all");
                                    stopPinAnimation();
                                    setMapStylesDarkId(null);
                                  }}
                                  onTouchStart={() => (isMobile ? setMapStyle(!mapStyle) : null)}

                                  id="light-mode"
                              />
                              <div className="filters__radio-label__img-box mode light-mode-icon"></div>
                            </label>
                            <label
                                className="filters__radio-label"
                                htmlFor="dark-mode"
                                onClick={() => setMode(false)}
                            >
                              <input
                                  type="radio"
                                  name="mapMode"
                                  checked={
                                      mapStyles.filter((x) => x.active)[0].active === "dark"
                                  }
                                  onChange={() => {
                                    setMapStylesDark(
                                        mapStyles.filter((x) => x.active !== false)[0].id
                                    );
                                    setMapStylesDarkId(
                                        mapStyles.filter((x) => x.active !== false)[0].id
                                    );
                                    if (!isMobile) setMapStyle(!mapStyle);
                                    closeArticlePopup("all");
                                    stopPinAnimation();
                                  }}
                                  onTouchStart={() => (isMobile ? setMapStyle(!mapStyle) : null)}

                                  id="dark-mode"
                              />
                              <div className="filters__radio-label__img-box mode dark-mode-icon"></div>
                            </label>
                          </div>
                      )}
                    </fieldset>
                  </div>
                </ClickOutside>)}
          </div>
        </div>
      ) : (
        <div
          className="header_right_mobile"
          data-is-loading={globalLoading}
        >
          <div
              id="nav-lines"
              className={`mobile_menu_burger ${mobileShow && 'active'}`}
              onClick={() => {
                !mobileShow
                ?setMobileShow(true)
                :onMobileMenuClose()
              }}
            >
                <svg viewBox="0 0 64 64">
                  <line id="nav-line-1" x1="8" x2="56" y1="16" y2="16" className="nav-line"/>
                  <line id="nav-line-2" x1="8" x2="56" y1="32" y2="32" className="nav-line"/>
                  <line id="nav-line-3" x1="8" x2="56" y1="48" y2="48" className="nav-line"/>

                  <line x1="16" x2="48" y1="16" y2="48" className="cross-line"/>
                  <line x1="16" x2="48" y1="48" y2="16" className="cross-line"/>
                  <rect className="rect" width="42" height="42" x="11" y="11"/>
                </svg>
            </div>
            <div
              className="mobile_menu_buttons"
              style={{
                right: mobileShow ? '0px' : '-600px',
                height:
                  filterToggle && !isLandscape
                    ? "450px"
                    : filterToggle && isLandscape
                    ? "calc(325px / 0.7)"
                    : searchTerm.length > 0 && !isLandscape
                    ? "368px"
                    : searchTerm.length > 0 && isLandscape
                    ? "calc(325px / 0.7)"
                    : searchResult.length > 0
                    ? "368px"
                    : mapStyle
                    ? "132px"
                    : getStartPaintClickState && getColorPickerShowState && !isLandscape
                    ? "440px"
                    : getStartPaintClickState && getColorPickerShowState && isLandscape
                    ? "calc(325px / 0.7)"
                    : painterStart && getStartPaintClickState
                    ? "200px"
                    : painterStart
                    ? "100px"
                    : "100px",
                display: "flex",
                alignItems: "baseline",
                paddingRight: mapStyle
                  ? "calc(32px / 0.7)"
                  : "calc(32px / 0.7)",
                paddingLeft: "calc(32px / 0.7)",
                maxHeight:filterToggle && !isLandscape
                          ? "450px"
                          : filterToggle && isLandscape
                              ? "calc((100vh - 85px) / 0.7)"
                              : searchTerm.length > 0 && !isLandscape
                                  ? "368px"
                                  : searchTerm.length > 0 && isLandscape
                                      ? "calc((100vh - 85px) / 0.7)"
                                      : searchResult.length > 0
                                          ? "368px"
                                          : mapStyle
                                              ? "132px"
                                              : getStartPaintClickState && getColorPickerShowState && !isLandscape
                                                  ? "440px"
                                                  : getStartPaintClickState && getColorPickerShowState && isLandscape
                                                      ? "calc((100vh - 85px) / 0.7)"
                                                      : painterStart && getStartPaintClickState
                                                          ? "200px"
                                                          : painterStart
                                                              ? "100px"
                                                              : "100px",
              }}
            >
              <div className="mobile_menu_buttons_content">
                {!painterStart && !mapStyle && (
                  <>
                    <div
                      className={`right-side__search main__tab ${
                        search ? "active" : ""
                      }`}
                      style={{
                        marginRight: "6px",
                        borderRadius: "10px",
                        display: search ? "none" : "inline-block",
                      }}
                    >
                      {!painterStart && (
                        <button
                          className="right-side__search-btn main__tab-btn "
                          type="button"
                          onClick={() => {
                            setSearchTerm("");
                            setSearch(!search);
                            setSearchResult([]);
                            closeArticlePopup("all");
                            stopPinAnimation();
                          }}
                          style={{
                            borderRadius: "10px",
                            width: "36px",
                            height: "36px",
                          }}
                        >
                          <span
                            className="main__tab-icon icon-wrapper search"
                            id="button_color"
                          >
                            <img
                              src={MobileSearchIcon}
                              onClick={() => {
                                setSearchTerm("");
                                setSearch(false);
                                setSearchResult([]);
                              }}
                              alt=""
                            />
                          </span>
                        </button>
                      )}
                    </div>
                    {search && (
                      <div className="right-side__search-content">
                        <div className="right-side__search-field">
                          <>
                            <>
                              <input
                                autoComplete="off"
                                value={searchTerm}
                                autoFocus={true}
                                name="search"
                                className="right-side__search-input"
                                placeholder="Որոնել"
                                onInput={(e) => handleSearch(e.target.value)}
                              />
                            </>
                            <span className="right-side__filter-box">
                              <span
                                className="right-side__filter-icon"
                                onClick={toggleFilter}
                                data-show={!filterToggle}
                              >
                                {!filterToggle ? (
                                  <CategoryFilter />
                                ) : (
                                  <CategoryFilterActive />
                                )}
                              </span>

                              {/*{filterToggle && (*/}
                              {/*  <div*/}
                              {/*    style={{*/}
                              {/*      position: "absolute",*/}
                              {/*      top: "56px",*/}
                              {/*      transform: "rotate(45deg)",*/}
                              {/*      left: "-4px",*/}
                              {/*      width: "30px",*/}
                              {/*      height: "30px",*/}
                              {/*      backgroundColor: "white",*/}
                              {/*      background: "#fff",*/}
                              {/*      zIndex: 999,*/}
                              {/*    }}*/}
                              {/*  ></div>*/}
                              {/*)}*/}
                            </span>

                            <span
                              className="mobile_search"
                              id="button_color"
                              style={{
                                width: "calc(18px / 0.7)",
                                height: "calc(18px / 0.7)",
                                position: "absolute",
                                top: "29%",
                                right: "21px",
                                zIndex: 9999999999999,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSearchTerm("");
                                setSearch(false);
                                setSearchResult([]);
                                setFilterToggle(false);
                              }}
                            >
                              {/*<img*/}
                              {/*  src={MobileSearchIcon}*/}
                              {/*  onClick={() => {*/}
                              {/*    setSearchTerm("");*/}
                              {/*    setSearch(false);*/}
                              {/*    setSearchResult([]);*/}
                              {/*    setFilterToggle(false);*/}
                              {/*  }}*/}
                              {/*  alt=""*/}
                              {/*  width="20px"*/}
                              {/*/>*/}
                              <span >
                               <svg  width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <g id="Group 2">
                                    <path id="Vector" d="M1.08181 1.05101L10.9178 10.949" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_2" d="M1.08224 10.949L10.9182 1.05101" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    <g id="Group 2_2">
                                    <path id="Vector_3" d="M1.08181 1.05101L10.9178 10.949" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_4" d="M1.08224 10.949L10.9182 1.05101" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    </g>
                                </svg>
                              </span>
                            </span>
                          </>
                          <ul
                            className="right-side__search-filters right-side__filters custom-scroll2"
                            data-show={!filterToggle}
                          >
                            <li className="right-side__filters-item categories right-side__filters-title">
                              <p>ֆիլտրել </p>
                            </li>
                            <li
                              className={`search_menu_filter_item ${
                                selectedCategoryId
                                  ? "right-side__filters-item categories"
                                  : "right-side__filters-item categories active"
                              }`}
                            >
                              <button
                                onClick={(e) => changeCategory(null)}
                                className="right-side__filters-btn "
                                type="button"
                              >
                                {selectedCategoryId === null && (
                                  <img
                                    className={"activeState"}
                                    src={CheckIcon}
                                    alt=""
                                  />
                                )}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                >
                                  <path
                                    d="M9.5,0a1.254,1.254,0,0,1,1.25,1.25V2.625a2.333,2.333,0,0,1-.625,1.438L7.437,6.437a2.035,2.035,0,0,0-.625,1.438v2.687a1.392,1.392,0,0,1-.562,1.063l-.875.563a1.274,1.274,0,0,1-1.938-1.062V7.813a2.416,2.416,0,0,0-.5-1.312L.563,4A2.072,2.072,0,0,1,0,2.75V1.313A1.268,1.268,0,0,1,1.25,0Z"
                                    transform="translate(2.125 1.312)"
                                    fill="none"
                                  />
                                  <path
                                    d="M3.081,0,0,4.938"
                                    transform="translate(3.75 1.312)"
                                    fill="none"
                                  />
                                  <path
                                    d="M15,0V15H0V0Z"
                                    fill="none"
                                    opacity="0"
                                  />
                                </svg>
                                <span>Բոլորը</span>
                              </button>
                            </li>
                            {categories.map((item, index) => {
                              return (
                                <li
                                  key={item.id}
                                  className={`right-side__filters-item categories search_menu_filter_item ${
                                    selectedCategoryId === item.id
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <button
                                    onClick={(e) => changeCategory(item.id)}
                                    className="right-side__filters-btn "
                                    type="button"
                                  >
                                    {selectedCategoryId === item.id && (
                                      <img
                                        className={"activeState"}
                                        src={CheckIcon}
                                        alt=""
                                      />
                                    )}
                                    <img
                                      src={item.iconPath}
                                      alt=""
                                      style={{
                                        marginRight: "5px",
                                      }}
                                    />
                                    <span>{item.title}</span>
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        {searchResult.length > 0 && (
                          <>
                            <div className="right-side__search-response">
                              <div id="scrollableDiv" className="response main__subitem custom-scroll">
                                <InfiniteScroll
                                    dataLength={searchResult.length} //This is important field to render the next data
                                    next={loadMoreResult}
                                    hasMore={loadMore}
                                    loader={<h4 style={{ textAlign: "center" }}>...</h4>}
                                    height={247}
                                >
                                  {searchResult.map((item) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className="right-side__filters-item"
                                        >
                                          <button
                                              className="right-side__filters-btn with-svg"
                                              onClick={() => {
                                                setSearch(!search);
                                                getArticleFromAPI(item.id);
                                              }}
                                          >
                                            <img
                                                src={
                                                  categories?.find(
                                                      (x) => x.id === item.category[0].categoryId
                                                  )?.iconPath
                                                }
                                                alt=""
                                            />
                                            <span className={"search-text"}>
                                    {item.content[0].mainTitle}
                                  </span>
                                          </button>
                                        </div>
                                    );
                                  })}
                                </InfiniteScroll>
                              </div>
                              {/*{loadMore && (*/}
                              {/*  <button*/}
                              {/*    style={{*/}
                              {/*      width: "100%",*/}
                              {/*      justifyContent: "center",*/}
                              {/*      alignItems: "center",*/}
                              {/*      display: "flex",*/}
                              {/*      marginBottom: "20px",*/}
                              {/*      fontSize: "11px",*/}
                              {/*    }}*/}
                              {/*    onClick={() => loadMoreResult()}*/}
                              {/*  >*/}
                              {/*    Ավելին*/}
                              {/*  </button>*/}
                              {/*)}*/}
                            </div>
                          </>
                        )}
                        {searchTerm.length > 0 && searchResult.length === 0 && (
                          <div className="right-side__search-response">
                            <div
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                marginBottom: "20px",
                                fontSize: "11px",
                                backgroundColor: "#fff",
                              }}
                            >
                              {searchLoader ? (
                                <Loader />
                              ) : (
                                <span>Ոչինչ չի գտնվել</span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}

                {!search && !mapStyle && showToolboxButton && (
                  <div
                    className={`right-side__toolbox main__tab ${
                      painterStart ? "active" : ""
                    }`}
                    style={{
                      marginRight: "6px",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                      width: painterStart
                        ? "calc(36px / 0.7)"
                        : "calc(36px / 0.7)",
                      height: painterStart
                        ? "calc(36px / 0.7)"
                        : "calc(36px / 0.7)",
                    }}
                  >
                    <button
                      className={`right-side__search-btn main__tab-btn ${
                        painterStart ? "mobile_main_tab" : ""
                      }`}
                      type="button"
                      onClick={() => {
                        setPainterStart(!painterStart);
                        closeArticlePopup("all");
                        stopPinAnimation();
                      }}
                      style={{
                        borderRadius: "10px",
                        width: painterStart
                          ? "calc(30px / 0.7)"
                          : "36px",
                        height: painterStart
                          ? "calc(30px / 0.7)"
                          : "36px",
                        border: painterStart ? "1px solid #fff" : "",
                      }}
                    >
                      <span className="main__tab-icon icon-wrapper">
                        <img
                          src={PainterStartIcon}
                          id={`${painterStart ? "main_tab_icon_img" : ""}`}
                          alt=""
                        />
                      </span>
                    </button>
                    {map && <PaintBar map={map} tools={tools}/>}
                  </div>
                )}

                {!search && !painterStart && (
                  <div
                    className={`right-side__filters filters main__tab ${
                      mapStyle ? "active" : ""
                    }`}
                    style={{
                      marginRight: "6px",
                      borderRadius: "10px",
                    }}
                  >
                    <button
                      className={`filters__tab main__tab-btn ${
                        mapStyle ? "sss" : ""
                      }`}
                      type="button"
                      onClick={() => {
                        setMapStyle(!mapStyle);
                        closeArticlePopup("all");
                        stopPinAnimation();
                      }}
                      // onTouchEnd={() =>
                      //   isMobile ? setMapStyle(!mapStyle) : null
                      // }
                      style={{
                        borderRadius: "10px",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <span className="main__tab-icon icon-wrapper">
                        <img src={BaceMapIcon} alt="" />
                      </span>
                    </button>

                    {mapStyle && (
                      <div
                        className="filters__content base-map"
                        id={
                          mapStyles.filter((x) => x.active)[0].dark !== false
                            ? `is_base_map_dark`
                            : `is_not_base_map_dark`
                        }
                      >
                        <fieldset className="filters__radio">
                          {mapStyles?.map((item, index) => {
                            return (
                              <label
                                className="filters__radio-label"
                                key={item.id}
                                style={{'display': item.hide ? 'none' : 'unset'}}
                              >
                                <input
                                  type="radio"
                                  name="mapStyle"
                                  onChange={() => {
                                    setMapStyles(item.id);
                                    setActiveBaseMapId(item.id);
                                  }}
                                />
                                <div
                                  className="filters__radio-label__img-box"
                                  style={{
                                    border:
                                      activeBaseMapId === item.id
                                        ? "2px solid rgba(5, 118, 244, 1)"
                                        : "",
                                  }}
                                >
                                  <img
                                    className="filters__radio-label__img"
                                    src={mapStyleImg[item.value]}
                                    alt={item.value}
                                  />
                                </div>
                                <span
                                  className={`filters__radio-text map${item.id}`}
                                >
                                  {isMobile && isLandscape && item.title}
                                  {isMobile &&
                                    !isLandscape &&
                                    cutWord(item.title)}
                                  {!isMobile && item.title}
                                </span>
                              </label>
                            );
                          })}
                          {mapStyles.filter((x) => x.active)[0].dark !==
                            false && (
                            <div className="mode-switch-box">
                              <label
                                className="filters__radio-label"
                                htmlFor="light-mode"
                                onClick={() => setMode(true)}
                              >
                                <input
                                  type="radio"
                                  name="mapMode"
                                  checked={
                                    mapStyles.filter(
                                      (x) => x.active !== false
                                    )[0].active === "light"
                                  }
                                  onTouchStart={() => {
                                    setMapStylesDark(
                                      mapStyles.filter(
                                        (x) => x.active !== false
                                      )[0].id
                                    );
                                    setMapStylesDarkId(null);
                                  }}
                                  id="light-mode"
                                />
                                <div className="filters__radio-label__img-box mode light-mode-icon"></div>
                              </label>
                              <label
                                className="filters__radio-label"
                                htmlFor="dark-mode"
                                onClick={() => setMode(false)}
                              >
                                <input
                                  type="radio"
                                  name="mapMode"
                                  checked={
                                    mapStyles.filter((x) => x.active)[0]
                                      .active === "dark"
                                  }
                                  onTouchStart={() => {
                                    setMapStylesDark(
                                      mapStyles.filter(
                                        (x) => x.active !== false
                                      )[0].id
                                    );
                                    setMapStylesDarkId(
                                      mapStyles.filter(
                                        (x) => x.active !== false
                                      )[0].id
                                    );
                                  }}
                                  id="dark-mode"
                                />
                                <div className="filters__radio-label__img-box mode dark-mode-icon"></div>
                              </label>
                            </div>
                          )}
                        </fieldset>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/*<div*/}
              {/*  onClick={() => {*/}
              {/*    onMobileMenuClose();*/}
              {/*  }}*/}
              {/*  className="mobile_menu_close_icon"*/}
              {/*  style={{*/}
              {/*    // top: !painterStart ? "43px" : "35px",*/}
              {/*    right: mapStyle ? "calc(18px / 0.7)" : "calc(15px / 0.7)",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <img src={close_icon} alt="" width="100%" />*/}
              {/*</div>*/}
            </div>
        </div>
      )}
    </header>
      </>
  );
};

const mapStateTopProps = (state) => ({
  categories: getCategoriesData(state),
  menu: getMenuData(state),
  search: getSearchData(state),
  mapStyle: getMapStyleData(state),
  mapStyles: getMapStylesData(state),
  mapStylesList: getMapStylesListData(state),
  painterStart: getPainterStartData(state),
  getMapStyledId: getMapStyledId(state),
  getScreenShootButtonStateST: getScreenShootButtonStateST(state),
  getStartPaintClickState: getStartPaintClickState(state),
  getColorPickerShowState: getColorPickerShowState(state),
  getMobileMenuState: getMobileMenuState(state),
});

const mapDispatchToProps = {
  setMenu: setMenu,
  setSearch: setSearch,
  setMapStyle: setMapStyle,
  setMapStyles: changeMapStyles,
  setMapStylesDark: setMapStylesDark,
  getArticleFromAPI: getArticleFromAPI,
  setPainterStart: setPainterStart,
  closeArticlePopup: closeArticlePopup,
  setMapStylesIdST: setMapStylesIdST,
  setMapStylesDarkId: setMapStylesDarkId,
  setColorPickerShowState: setColorPickerShowState,
  setStartPaintClickState: setStartPaintClickState,
  setMobileMenuClose: setMobileMenuClose,
  setMapStylesList: setMapStylesList,
};

export default connect(mapStateTopProps, mapDispatchToProps)(MapHeader);
