import {SET_QUIZ_POPUP} from "../constants";

const initialState = {
  data: false,
};

export const quizPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUIZ_POPUP:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
