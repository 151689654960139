const EyeLamp = ({ show }) => {
  return show ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 20 20"
    >
      <path
        d="M2.983,5.967A2.983,2.983,0,1,1,5.967,2.983,2.98,2.98,0,0,1,2.983,5.967Z"
        transform="translate(7.017 7.017)"
        fill="none"
        stroke="#292d32"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M15.746,9.058a4.438,4.438,0,0,0,0-4.325C13.838,1.733,11.1,0,8.154,0S2.471,1.733.562,4.733a4.438,4.438,0,0,0,0,4.325c1.908,3,4.65,4.733,7.592,4.733S13.838,12.058,15.746,9.058Z"
        transform="translate(1.846 3.1)"
        fill="none"
        stroke="#292d32"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M20,0V20H0V0Z"
        transform="translate(20 20) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 20 20"
    >
      <g opacity="0.5">
        <path
          d="M.875,5.092A2.982,2.982,0,1,1,5.092.875Z"
          transform="translate(7.017 7.017)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M13,1.7A8.026,8.026,0,0,0,8.154,0C5.213,0,2.471,1.733.562,4.733a4.438,4.438,0,0,0,0,4.325A11.939,11.939,0,0,0,2.821,11.7"
          transform="translate(1.846 3.108)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M0,9.667a7.679,7.679,0,0,0,2.983.617c2.942,0,5.683-1.733,7.592-4.733a4.438,4.438,0,0,0,0-4.325A13.518,13.518,0,0,0,9.692,0"
          transform="translate(7.017 6.608)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M2.35,0A2.971,2.971,0,0,1,0,2.35"
          transform="translate(10.575 10.583)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M6.225,0,0,6.225"
          transform="translate(1.667 12.108)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M6.225,0,0,6.225"
          transform="translate(12.108 1.667)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M20,0V20H0V0Z"
          transform="translate(20 20) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};
export default EyeLamp;
