import Collapsible from "react-collapsible";
import AudioPlayer from "../audioPlayer";
export const TriggerSection = (props) => {
  const { title = "collaps" } = props;

  return (
    <div className={"collaps-trigger-section"}>
      <span className={"trigger-title"}>{title}</span>
      <span>
        <svg
          version="1.1"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            fill="none"
            fillRule="evenodd"
            stroke-dasharray="0,0"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <g
              transform="translate(10 10) rotate(180) translate(-10 -10)"
              fillRule="nonzero"
              stroke="#000"
              stroke-width="1.5"
            >
              <path d="m12.5 16.6-5.4333-5.4333c-0.64167-0.64167-0.64167-1.6917 0-2.3333l5.4333-5.4333"></path>
            </g>
          </g>
        </svg>
      </span>
    </div>
  );
};
export const StrongCollaps = (props) => {
  const { trigger, openState, children, articleData, index } = props;

  return openState ? (
    <Collapsible
      trigger={trigger}
      easing={"ease-in"}
      transitionTime={500}
      open={openState}
    >
      <div className={"article-read-more-body-main__collaps-content"}>
        {children}
      </div>
    </Collapsible>
  ) : (
    <></>
  );
};
