const Eye = ({ show }) => {
  return show ? (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <title>hide-blue</title>
    <g id="hide-blue" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <g id="eye">
            <path d="M10.0000366,12.9833698 C8.35003654,12.9833698 7.01670329,11.6500366 7.01670329,10.0000366 C7.01670329,8.35003654 8.35003654,7.01670329 10.0000366,7.01670329 C11.6500366,7.01670329 12.9833698,8.35003654 12.9833698,10.0000366 C12.9833698,11.6500366 11.6500366,12.9833698 10.0000366,12.9833698 Z" id="Vector" stroke="#2C476C" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0" fillRule="nonzero"></path>
            <path d="M17.5916386,12.1583088 C18.3416385,10.9833089 18.3416385,9.00830835 17.5916386,7.83330838 C15.6833053,4.83330846 12.941638,3.09997559 9.99997139,3.09997559 C7.05830475,3.09997559 4.31663811,4.83330846 2.40830481,7.83330838 C1.65830483,9.00830835 1.65830483,10.9833089 2.40830481,12.1583088 C4.31663811,15.1583087 7.05830475,16.8916432 9.99997139,16.8916432 C12.941638,16.8916432 15.6833053,15.1583087 17.5916386,12.1583088 Z" id="Vector" stroke="#2C476C" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0" fillRule="nonzero"></path>
            <path d="M20,0 L20,20 L0,20 L0,0 L20,0 Z" id="Vector" opacity="0" transform="translate(10.000000, 10.000000) rotate(-180.000000) translate(-10.000000, -10.000000) "></path>
        </g>
    </g>
</svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g opacity="0.5">
        <path
          d="M.875,5.092A2.982,2.982,0,1,1,5.092.875Z"
          transform="translate(7.017 7.017)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M13,1.7A8.026,8.026,0,0,0,8.154,0C5.213,0,2.471,1.733.562,4.733a4.438,4.438,0,0,0,0,4.325A11.939,11.939,0,0,0,2.821,11.7"
          transform="translate(1.846 3.108)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M0,9.667a7.679,7.679,0,0,0,2.983.617c2.942,0,5.683-1.733,7.592-4.733a4.438,4.438,0,0,0,0-4.325A13.518,13.518,0,0,0,9.692,0"
          transform="translate(7.017 6.608)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M2.35,0A2.971,2.971,0,0,1,0,2.35"
          transform="translate(10.575 10.583)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M6.225,0,0,6.225"
          transform="translate(1.667 12.108)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M6.225,0,0,6.225"
          transform="translate(12.108 1.667)"
          fill="none"
          stroke="#292d32"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          strokeDasharray="0 0"
        />
        <path
          d="M20,0V20H0V0Z"
          transform="translate(20 20) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};
export default Eye;
