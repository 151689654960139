export const processTimeLineLessonGroupRendering = (data, categories) => {
    let timeLineSelectedDataGroup = []
    data.forEach((item, index) => {
        let mainId

        let cat = categories.find((x) => x.id === item.categoryId)
        item?.subcategory.forEach(el=> {
            if (el.isMain) mainId = el.subCategoryId
        })
        cat?.subCategories.forEach((el)=>{
            if(el.id === mainId) {
                el.isMain = true
            }else{
                el.isMain = false
            }
        })
        if(!item.isLamp){
            timeLineSelectedDataGroup.push({
                id: item.isLamp ? 111 : index + 1,
                catId: item.isLamp ? 222 : item?.categoryId,
                catIcon: cat?.iconPath,
                content: cat?.title,
                className: 'isLesson',
                color: cat?.color,
                show: true,
                isLamp: item.isLamp,
            })
        }

    })
    const uniqueIds = []
    return timeLineSelectedDataGroup
        .filter((element) => {
            const isDuplicate = uniqueIds.includes(element.catId)
            if (!isDuplicate) {
                uniqueIds.push(element.catId)
                return true
            }
        })
        .sort((a, b) => (a.catId < b.catId ? 1 : -1))
}
