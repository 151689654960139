import { connect } from "react-redux";
import "./style.css";
import React, { useState } from "react";
import Popup from "../../Popup";
import DynamicScreenShotShare from "../DynamicScreenShotShare";
import { useSearchParams } from "react-router-dom";

const DateInfo = (props) => {
  let [searchParams] = useSearchParams(props.link);
  let topicID = searchParams.get("topicID");
  let lessonID = searchParams.get("lessonID");

  const type = props?.link?.includes("type=lesson")
    ? "type=lesson"
    : "type=topic";
  const ID = props?.link?.includes("lessonID=")
    ? `lessonID=${lessonID}`
    : `topicID=${topicID}`;

  const getLastScreenShotAndUserIdUrl = (url) => {
    const urlParts = url?.split("&");
    let screenShotCount = 0;
    let userIdCount = 0;
    let lastScreenShot;
    let lastUserId;

    for (let i = 0; i < urlParts?.length; i++) {
      if (urlParts[i].startsWith("screenShot")) {
        screenShotCount++;
        lastScreenShot = urlParts[i].split("=")[1];
      } else if (urlParts[i].startsWith("userId")) {
        userIdCount++;
        lastUserId = urlParts[i].split("=")[1];
      }
      if (screenShotCount === 2 && userIdCount === 2) {
        break;
      }
    }
    return `${window.location.origin}/map?${type}&${ID}&screenShot=${lastScreenShot}&userId=${lastUserId}`;
  };

  const url = props.link;
  const lastScreenShotAndUserIdUrl = getLastScreenShotAndUserIdUrl(url);

  const [openSharePopup, setOpenSharePopup] = useState(false);

  const handleClosePopup = () => {
    setOpenSharePopup(() => false);
  };

  const handleOpenPopup = () => {
    setOpenSharePopup(() => true);
  };

  return (
    <>
      <button
        className="card-body__dynamic-share-btn dynamic-share-btn"
        onClick={handleOpenPopup}
      >
        Կիսվել
      </button>
      <Popup
        isOpened={openSharePopup}
        onClose={handleClosePopup}
        styleOverlayUser={{ backgroundColor: "#1b283ae8" }}
      >
        <DynamicScreenShotShare link={url} onClose={handleClosePopup} />
      </Popup>
    </>
  );
};
export default connect(null, null)(DateInfo);
