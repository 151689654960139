import {
    SET_TIMELINE_ITEMS,
    SET_TIMELINE_GROUPS,
    LOADING_TIMELINE,
    SET_TIMELINE_ARTICLE,
    SET_MIDDLE_TIMELINE_ITEMS,
    SET_MAX_MIDDLE_TIMELINE_ITEMS
} from '../constants'

const initialState = {
    data:[],
    middleTimelineData:[],
    middleMaxTimelineData:[],
    groups:[],
    isLoading: true,
}

export const handleTimeLineItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MAX_MIDDLE_TIMELINE_ITEMS:
            return {
                ...state,
                isLoading: false,
                middleMaxTimelineData: action.payload,
            }
        case SET_MIDDLE_TIMELINE_ITEMS:
            return {
                ...state,
                isLoading: false,
                middleTimelineData: action.payload,
            }
        case SET_TIMELINE_ITEMS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        case SET_TIMELINE_ARTICLE:
            return {
                ...state,
                isLoading: false,
                data: []
            }
        case LOADING_TIMELINE: {
            return { ...state, isLoading: action.payload }
        }
        default:
            return state
    }
}

export const handleTimeLineGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TIMELINE_GROUPS:
            return {
                ...state,
                isLoading: false,
                groups: action.payload,
            }
        case SET_TIMELINE_ARTICLE:
            return {
                ...state,
                isLoading: false,
                data: []
            }
        case LOADING_TIMELINE: {
            return { ...state, isLoading: true }
        }
        default:
            return state
    }
}
