import { connect } from "react-redux";
import "./style.css";
import Enter from "../../Enter";
import React from "react";
import { useSearchParams } from "react-router-dom";
const NewTab = (props) => {
  const { content = "#", actionType } = props;
  let [searchParams] = useSearchParams(content);
  let topicID = searchParams.get("topicID");
  let lessonID = searchParams.get("lessonID");

  const type = content.includes("type=lesson") ? "type=lesson" : "type=topic";
  const ID = content.includes("lessonID=")
    ? `lessonID=${lessonID}`
    : `topicID=${topicID}`;

  const getLastScreenShotAndUserIdUrl = (url) => {
    const urlParts = url?.split("&");
    let screenShotCount = 0;
    let userIdCount = 0;
    let lastScreenShot;
    let lastUserId;

    for (let i = 0; i < urlParts?.length; i++) {
      if (urlParts[i].startsWith("screenShot")) {
        screenShotCount++;
        lastScreenShot = urlParts[i].split("=")[1];
      } else if (urlParts[i].startsWith("userId")) {
        userIdCount++;
        lastUserId = urlParts[i].split("=")[1];
      }
      if (screenShotCount === 2 && userIdCount === 2) {
        break;
      }
    }
    return `${window.location.origin}/map?${type}&${ID}&screenShot=${lastScreenShot}&userId=${lastUserId}`;
  };

  const url = content;
  const lastScreenShotAndUserIdUrl = getLastScreenShotAndUserIdUrl(url);

  return (
    <div className="card-body__dynamic-new-tab dynamic-new-tab">
      <a
        href={actionType === 'screenShot' ? lastScreenShotAndUserIdUrl : content}
        className="dynamic-new-tab__btn"
        target="_blank"
      >
        <Enter show={true} />
      </a>
    </div>
  );
};
export default connect(null, null)(NewTab);
