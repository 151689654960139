import {
    SET_LEGENDS,
    SET_FILTERED_LEGENDS,
    LOADING_LEGENDS,
} from '../constants'

const initialState = {
    data: [],
    filteredData: [],
    isLoading: false,
}

export const legendsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_LEGENDS: {
            return { ...state, isLoading: true }
        }
        case SET_FILTERED_LEGENDS:
            return {
                ...state,
                isLoading: false,
                filteredData: action.payload,
            }
        case SET_LEGENDS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        default:
            return state
    }
}
