const TimeLineClock = ()=>(
    <svg version="1.1" className="iconic-clock" xmlns="http://www.w3.org/2000/svg"
          x="0px" y="0px" width="384px" height="384px" viewBox="0 0 384 384"
         enableBackground="new 0 0 384 384" space="preserve">
        <path xmlns="http://www.w3.org/2000/svg"
         d="M192 0C85.961 0 0 85.961 0 192C0 298.039 85.961 384 192 384C298.039 384 384 298.039 384 192C384 85.961 298.039 0 192 0ZM315.037 315.037C305.583 324.491 295.228 332.716 284.173 339.646L273.79 345.671C250.634 358.034 224.843 364.983 197.998 365.887H185.998C159.153 364.984 133.362 358.033 110.205 345.67L99.825 339.645C88.77 332.714 78.415 324.491 68.961 315.037C59.507 305.583 51.282 295.228 44.351 284.173L38.329 273.791C25.966 250.637 19.017 224.846 18.113 198V186C19.017 159.156 25.966 133.366 38.329 110.209L44.354 99.827C51.285 88.772 59.51 78.417 68.964 68.963C78.418 59.509 88.773 51.284 99.828 44.353L110.208 38.33C133.365 25.966 159.155 19.017 186 18.113H198C224.846 19.017 250.635 25.966 273.792 38.329L284.174 44.352C295.23 51.283 305.584 59.508 315.039 68.963C324.493 78.417 332.718 88.771 339.647 99.826L345.672 110.208C358.035 133.365 364.984 159.156 365.89 186L365.887 198C364.983 224.845 358.034 250.636 345.671 273.792L339.646 284.177C332.715 295.229 324.491 305.583 315.037 315.037Z"
        fill="#2c476c"/>
        <line className="iconic-clock-hour-hand" id="iconic-anim-clock-hour-hand" fill="none" stroke="#000000"
              strokeWidth="20" strokeMiterlimit="10" x1="192" y1="192" x2="192" y2="87.5"/>
        <line className="iconic-clock-minute-hand" id="iconic-anim-clock-minute-hand" fill="none" stroke="#000000"
              strokeWidth="20" strokeMiterlimit="10" x1="192" y1="192" x2="280" y2="54"/>
        <path xmlns="http://www.w3.org/2000/svg"
              d="M192 201C196.971 201 201 196.971 201 192C201 187.029 196.971 183 192 183C187.029 183 183 187.029 183 192C183 196.971 187.029 201 192 201Z"
              fill="#2c476c"/>
    </svg>
)
export default TimeLineClock

