import * as turf from "@turf/turf";
import React, { memo, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import BrashIcon from "../../assets/imgs/PaintBar/brush.png";
import ClearIcon from "../../assets/imgs/PaintBar/clear.png";
import EraserIcon from "../../assets/imgs/PaintBar/eraser-icon.svg";
import NextIcon from "../../assets/imgs/PaintBar/next-icon.png";
import PrevIcon from "../../assets/imgs/PaintBar/prev-icon.png";
import RulerIcon from "../../assets/imgs/PaintBar/ruler-icon.png";
import "./style.css";

import mapboxgl from "mapbox-gl";
import { changeCursorIcon, getRulerPopupTemplate } from "../../shared/mockData";

import { connect } from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {
  setHideRulerPopupST,
  setPainterGeoJsonDataST,
  setRulerGeoJsonDataST,
  setRulerPointsPosDataST,
} from "../../store/actions/mapStateAction";
import {
  setColorPickerShowState,
  setEraserClickState,
  setPainterStart,
  setPointerColor,
  setPointerColorData,
  setPointerSize,
  setPointerSizeData,
  setRulerClickedState,
  setStartPaintClickState,
} from "../../store/actions/painterStart";
import {
  setPainterGeoJsonData,
  setPainterLayerLineNameData,
  setPainterPrevData,
  setPainterPrevLineNameData,
} from "../../store/actions/setPainterStartData";
import {
  getColorPickerShowState,
  getEraserClickedState,
  getMapStyleData,
  getMapStyledDarkId,
  getMapStyledId,
  getMapStylesChangeStatus,
  getMapStylesData,
  getMobileMenuState,
  getPainterColor,
  getPainterColorData,
  getPainterGeoJsonData,
  getPainterGeoJsonDataST,
  getPainterLayerLineNameData,
  getPainterPrevData,
  getPainterPrevLineNameData,
  getPainterSize,
  getPainterSizeData,
  getPainterStartData,
  getRulerClickedState,
  getRulerGeoJsonDataST,
  getRulerPointsPosDataST,
  getRulerPopupShowST,
  getScreenShootButtonStateST,
  getStartPaintClickState,
  getTimeLineClickState,
} from "../../store/selectors";
import { color_picker_data } from "../../utils/color_picker_data";
import MapScreenShotButton from "../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import { CURSOR_TYPE } from "./../../shared/mockData";
import {MAP_TOOLBOX_KEYS} from "../mapToolbox";
export const isNotEmpty = (obj) => {
  return !!obj && Object.keys(obj).length !== 0;
};

const getUniqueNumber = () => {
  return `${new Date().getTime()}`;
};

let index = 0;

let data = [];

let soursId = [];

const PaintBar = ({
  map,
  painterStart,
  mapStyles,
  getPainterGeoJsonData,
  setPainterGeoJsonData,
  getPainterPrevData,
  setPainterPrevData,
  getPainterPrevLineNameData,
  setPainterPrevLineNameData,
  getPainterLayerLineNameData,
  setPainterLayerLineNameData,
  getStartPaintClickState,
  setStartPaintClickState,
  getPainterColor,
  setPointerColor,
  getPainterSize,
  setPointerSize,
  setPointerColorData,
  getPainterColorData,
  setPointerSizeData,
  getPainterSizeData,
  getColorPickerShowState,
  setColorPickerShowState,
  getRulerClickedState,
  setRulerClickedState,
  getEraserClickedState,
  setEraserClickState,
  getPainterGeoJsonDataST,
  setPainterGeoJsonDataST,
  setRulerGeoJsonDataST,
  getRulerGeoJsonDataST,
  setRulerPointsPosDataST,
  getRulerPointsPosDataST,
  getRulerPopupShowST,
  setHideRulerPopupST,
  getMapStylesChangeStatus,
  getScreenShootButtonStateST,
  getTimeLineClickState,
  getMapStyledId,
  getMapStyledDarkId,
  getMobileMenuState,
                    tools
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const hasRubber = tools?.[MAP_TOOLBOX_KEYS.RUBBER];
  const hasRuler = tools?.[MAP_TOOLBOX_KEYS.RULER];
  const hasPencil = tools?.[MAP_TOOLBOX_KEYS.PENCIL];
  const hasUndo = tools?.[MAP_TOOLBOX_KEYS.UNDO];
  const hasRedo = tools?.[MAP_TOOLBOX_KEYS.REDO];
  const hasDelete = tools?.[MAP_TOOLBOX_KEYS.DELETE];

  const [getRulerGeoJsonDataFirst, setRulerGeoJsonDataFirst] = useState(true);
  const [isScreenShot, setIsScreenShot] = useState(query.get("screenShot"));
  const [isLandscape, setIsLandscape] = useState(
    Math.abs(window.orientation) === 90
  );
  const [painterFirstLoader, setPainterFirstLoader] = useState(true);

  useEffect(()=>{
    const query = new URLSearchParams(location.search);
    setIsScreenShot(()=>query.get("screenShot"))

    if(query.get("loading") === 'true') {
      removeAllLayersByClick()
    }
    setTimeout(()=>{
      setPainterFirstLoader(()=>true)
      setPainterGeoJsonDataSTFirstLoad(()=>true)
      setRulerGeoJsonDataFirst(()=>true);
      startRulerIconClickStateRef.current = true;
    },500)
  },[searchParams])
  useEffect(() => {
    const handleOrientationChange = () => {
      if (Math.abs(window.orientation) === 90) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (getTimeLineClickState !== null) {
      resetActiveButton();
      setTimeout(() => {
        for (let i = 0; i < layerLineNameRef.current.length; i++) {
          const features = map.queryRenderedFeatures({
            layers: [layerLineNameRef.current[i]],
          });

          if (features.length > 0) {
            map.removeLayer(layerLineNameRef.current[i]);
            map.removeSource(layerLineNameRef.current[i]);
          }
        }

        for (let i = 0; i < geojsonRef.current.features.length; i++) {
          // layerLineNameRef.current.push(geojsonRef.current.features[i].id);
          if(!map.getLayer(geojsonRef.current.features[i].id)){
            map.addLayer({
              id: `${geojsonRef.current.features[i].id}`,
              type: "line",
              source: {
                type: "geojson",
                data: geojsonRef.current.features[i],
              },

              paint: {
                "line-width": ["get", "width"],
                "line-color": ["get", "color"],
              },
            });
          }
        }
      }, 0);
    } else if (getTimeLineClickState === "all") {
      removeAllLayers();
    }
  }, [getTimeLineClickState, getMapStyledDarkId]);

  useEffect(() => {
    if (
      isScreenShot &&
      getRulerGeoJsonDataFirst &&
      getRulerGeoJsonDataST?.features?.length > 0
    ) {
      setRulerGeoJsonDataFirst(false);
      rulerCircleMouseEnterRef.current = true;
      changeCursorIcon(CURSOR_TYPE.RULER);

      rulerGeojsonRef.current = {
        ...rulerGeojsonRef.current,
        features: [...getRulerGeoJsonDataST.features],
      };

      for (let i = 0; i < getRulerGeoJsonDataST.features.length - 1; i++) {
        rulerButtonClick();

        if (startRulerIconClickStateRef.current) {
          if (rulerGeojsonRef.current.features.length > 1)
            rulerGeojsonRef.current.features.pop();

          if (rulerGeojsonRef.current.features.length > 1) {
            lineStringRef.current.geometry.coordinates =
              rulerGeojsonRef.current.features.map(
                (point) => point.geometry.coordinates
              );

            rulerGeojsonRef.current.features.push(lineStringRef.current);

            const newData = lineStringRef.current.geometry.coordinates.splice(
              0,
              lineStringRef.current.geometry.coordinates.length
            );

            lineStringRef.current.geometry.coordinates = newData.filter(
              (item, index) => index <= i
            );

            const distance = turf.length(lineStringRef.current);
            if (distance !== 0) {
              popupRef.current = new mapboxgl.Popup({
                closeOnClick: false,
                className: `ruler ruler_modal_${index}`,
              })
                .setLngLat([
                  getRulerGeoJsonDataST.features[i].geometry.coordinates[0],
                  getRulerGeoJsonDataST.features[i].geometry.coordinates[1],
                ])
                .setHTML(getRulerPopupTemplate(distance, index))
                .addTo(map);

              onCloseRulerPopup();
            }

            onHidePopups();

            onAddMouseEnter();

            index++;
          }

          map
            .getSource(rulerSourceNameRef.current)
            .setData(rulerGeojsonRef.current);
        }
      }

      if (getRulerPopupShowST) {
        const popups = document.getElementsByClassName("ruler_modal");

        for (let i = 0; i < popups.length; i++) {
          popups[i].style.width = "10px";
          popups[i].style.height = "10px";
          popups[i].style.minWidth = "10px";
          popups[i].style.opacity = 0;
          popups[i].style.overflow = "hidden";
        }

        if (popups?.[popups.length]?.style) {
          popups[popups.length].style.width = "fit-content";
          popups[popups.length].style.height = "fit-content";
          popups[popups.length].style.minWidth = "180px";
          popups[popups.length].style.opacity = 1;
        }

        onAddMouseEnterST();
      }
    }
  }, [getRulerGeoJsonDataST, getRulerPopupShowST, getMapStyledDarkId]);

  // const [colorPiker, setColorPicker] = useState(false);

  // const [sizeColorData, setSizeColorData] = useState(SIZE_ICON);

  // const [colorData, setColorData] = useState(COLORS);

  // const [color, setColor] = useState("#000000");

  // const [size, setSize] = useState(8);

  // const prevDataRef = useRef([]);
  // const prevLineNameRef = useRef([]);

  const isPainterButtonClickStateRef = useRef(false);

  // const [startPaintClickState, setStartPaintClickState] = useState(false);

  const isEraserButtonClickStateRef = useRef(false);

  const layerLineNameRef = useRef([]);

  const geojsonRef = useRef({
    type: "FeatureCollection",
    features: [],
  });

  const popupRef = useRef(null);

  const startRulerIconClickStateRef = useRef(false);

  const rulerSourceNameRef = useRef(null);
  const rulerSourceNameRef2 = useRef([]);

  const rulerGeojsonRef = useRef({
    type: "FeatureCollection",
    features: [],
  });

  useEffect(() => {
    getRulerClickedState &&
      document.body.addEventListener("mouseup", onAddRulerGeoJsonData);
    if (!getRulerClickedState) {
      !isMobile && map?.off("mousedown", onRulerStart);
      isMobile && map?.off("touchstart", onRulerStart);
    } else {
      !isMobile && map?.on("mousedown", onRulerStart);
      isMobile && map?.on("touchstart", onRulerStart);
    }
    return () => {
      document.body.removeEventListener("mouseup", onAddRulerGeoJsonData);
    };
  }, [getRulerClickedState]);

  useEffect(() => {
    getStartPaintClickState &&
      document.body.addEventListener("mouseup", onAddGeoJsonData);

    return () => {
      document.body.removeEventListener("mouseup", onAddGeoJsonData);
    };
  }, [getStartPaintClickState]);

  const onAddGeoJsonData = () => {
    setPainterGeoJsonDataST(geojsonRef.current);
  };

  const onAddRulerGeoJsonData = () => {
    const rulerModal = document.getElementsByClassName("close_icon");

    if (rulerModal.length > 0) {
      rulerModal[rulerModal.length - 1].addEventListener(
        "click",
        onHideRulerPopup
      );
    } else {
    }
    setRulerGeoJsonDataST(rulerGeojsonRef.current);
  };

  const onHideRulerPopup = () => {
    setHideRulerPopupST(true);
  };

  const [getPainterGeoJsonDataSTFirstLoad, setPainterGeoJsonDataSTFirstLoad] = useState(true);

  useEffect(() => {
    if (
      isScreenShot &&
      Object.keys(getPainterGeoJsonDataST).length > 0 &&
      getPainterGeoJsonDataSTFirstLoad
    ) {
      // geojsonRef.current = getPainterGeoJsonDataST;

      setPainterGeoJsonDataSTFirstLoad(false);
      getPainterGeoJsonDataST?.features.forEach((el,i)=> {
        layerLineNameRef.current.push(getPainterGeoJsonDataST?.features[i]?.id);
        geojsonRef.current.features.push({
          id: getPainterGeoJsonDataST?.features[i]?.id,
          type: "Feature",
          properties: {
            ...getPainterGeoJsonDataST?.features[i]?.properties,
          },
          geometry: {
            ...getPainterGeoJsonDataST?.features[i]?.geometry,
          },
        });
        map.addLayer({
          id: getPainterGeoJsonDataST?.features[i]?.id,
          type: "line",
          source: {
            type: "geojson",
            data: geojsonRef?.current,
          },

          paint: {
            "line-width": ["get", "width"],
            "line-color": ["get", "color"],
          },
        });
      })

      map
        ?.getSource(
          `${
            geojsonRef.current.features[geojsonRef.current.features.length - 1]
              ?.id
          }`
        )
        ?.setData(geojsonRef?.current);
    }
  }, [getPainterGeoJsonDataST]);
  useEffect(() => {
    if (!painterStart) {
      resetActiveButton();
      getRulerClickedState && removeRuler();
      setRulerClickedState(false);
      startRulerIconClickStateRef.current = false;
    }
  }, [painterStart]);

  const lineStringRef = useRef({
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: [],
    },
  });

  const painterMouseDown = (e) => {
    // prevDataRef.current = [];
    setPainterPrevData([]);
    isPainterButtonClickStateRef.current = true;

    const id = getUniqueNumber();
    layerLineNameRef.current.push(`line_${id}`);
    geojsonRef.current.features.push({
      id: `line_${id}`,
      type: "Feature",
      properties: {
        color: getPainterColor,
        width: getPainterSize,
      },
      geometry: {
        coordinates: [],
        type: "LineString",
      },
    });

    map.addLayer({
      id: `line_${id}`,
      type: "line",
      source: {
        type: "geojson",
        data: geojsonRef?.current,
      },

      paint: {
        "line-width": ["get", "width"],
        "line-color": ["get", "color"],
      },
    });
    if (isPainterButtonClickStateRef.current) {
      !isMobile && map.on("mousemove", painterMouseMove);
      isMobile && map.on("touchmove", painterMouseMove);
    }
  };

  const painterMouseMove = (e) => {
    if (!!isPainterButtonClickStateRef?.current && getStartPaintClickState) {
      const y = e.lngLat.lat.toFixed(5);
      const x = e.lngLat.lng.toFixed(5);

      geojsonRef.current.features?.[
        geojsonRef.current.features.length - 1
      ].geometry?.coordinates.push([x, y]);

      map
        .getSource(
          `${
            geojsonRef.current.features?.[geojsonRef.current.features.length - 1]?.id
          }`
        )
        ?.setData(geojsonRef?.current);
    }
  };

  const painterMouseUp = () => {
    !isMobile && map.off("mousemove", painterMouseMove);
    isMobile && map.off("touchmove", painterMouseMove);
    isPainterButtonClickStateRef.current = false;
  };

  useEffect(() => {
    return () => {
      map["dragPan"].enable();
      changeCursorIcon();
    };
  }, [painterStart]);


  useEffect(() => {
    getStartPaintClickState && addPainterEvents();

    if (isScreenShot && getStartPaintClickState && painterFirstLoader) {
      if (!getStartPaintClickState) {
        changeCursorIcon();
        map["dragPan"].enable();
      } else {
        changeCursorIcon(CURSOR_TYPE.DRAW);
        map["dragPan"].disable();
      }
      setPainterFirstLoader(false);
    }

    return () => {
      removePainterEvents();
    };
  }, [getStartPaintClickState, getPainterColor, getPainterSize]);

  const addPainterEvents = () => {
    !isMobile && map?.on("mousedown", painterMouseDown);
    isMobile && map?.on("touchstart", painterMouseDown);
    !isMobile && map?.on("mouseup", painterMouseUp);
    isMobile && map?.on("touchend", painterMouseUp);
  };

  const removePainterEvents = () => {
    !isMobile && map?.off("mousedown", painterMouseDown);
    isMobile && map?.off("touchstart", painterMouseDown);
    !isMobile && map?.off("mouseup", painterMouseUp);
    isMobile && map?.off("touchend", painterMouseUp);
    !isMobile && map?.off("mousemove", painterMouseMove);
    isMobile && map?.off("touchmove", painterMouseMove);
  };

  const removeAllLayers = (e) => {
    resetActiveButton();
    for (let i = 0; i < layerLineNameRef.current.length; i++) {
      const features = map.queryRenderedFeatures({
        layers: [layerLineNameRef.current[i]],
      });

      if (features.length > 0) {
        map.removeLayer(layerLineNameRef.current[i]);
        map.removeSource(layerLineNameRef.current[i]);
      }
    }

    layerLineNameRef.current = [];

    // prevDataRef.current = [];

    // setPainterPrevData([]);
    // setPainterPrevLineNameData([]);

    // prevLineNameRef.current = [];

    geojsonRef.current = {
      type: "FeatureCollection",
      features: [],
    };
    geojsonRef.current.features.length = 0;

    removeRuler();
  };
  useEffect(() => {
    removeRuler();
    setRulerClickedState(false);
  }, [getMobileMenuState]);

  const removeAllLayersByClick = (e) => {

    setPainterGeoJsonDataST({
      type: "FeatureCollection",
      features: [],
    });
    resetActiveButton();
    for (let i = 0; i < layerLineNameRef.current.length; i++) {
      const features = map.queryRenderedFeatures({
        layers: [layerLineNameRef.current[i]],
      });
      if (features.length > 0) {
        map.removeLayer(layerLineNameRef.current[i]);
        map.removeSource(layerLineNameRef.current[i]);
      }
    }

    layerLineNameRef.current = [];

    // prevDataRef.current = [];

    // setPainterPrevData([]);
    // setPainterPrevLineNameData([]);

    // prevLineNameRef.current = [];

    geojsonRef.current.features = [];
    geojsonRef.current.features.length = 0;

    removeRuler();
      const updatedSearchParams = new URLSearchParams(searchParams);
      updatedSearchParams.delete('loading');
      setSearchParams(updatedSearchParams);

    // navigate(newUrl, { replace: true })

  };

  const onEraserHandle = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setStartPaintClickState(false);
    isPainterButtonClickStateRef.current = false;

    getRulerClickedState && removeRuler();

    setRulerClickedState(false);
    startRulerIconClickStateRef.current = false;

    isEraserButtonClickStateRef.current = !isEraserButtonClickStateRef.current;
    setEraserClickState(false);
    if (isEraserButtonClickStateRef.current) {
      changeCursorIcon(CURSOR_TYPE.ERASER);

      map["dragPan"].disable();
      isPainterButtonClickStateRef.current = false;
      removePainterEvents();
      setStartPaintClickState(false);
      setEraserClickState(true);
    } else {
      changeCursorIcon();
      map["dragPan"].enable();
    }
  };

  const [firstLoadFromEraser, setFirstLoadFromEraser] = useState(true);

  useEffect(() => {
    getEraserClickedState && addEasterEvents();
    return () => {
      removeEasterEvents();
    };
  }, [getEraserClickedState]);

  const addEasterEvents = () => {
    !isMobile && map.on("mousedown", easterDown);
    isMobile && map.on("touchstart", easterDown);
    !isMobile && map.on("mouseup", easterUp);
    isMobile && map.on("touchend", easterUp);
  };

  const easterDown = () => {
    !isMobile && map.on("mousemove", easterMove);
    isMobile && map.on("touchmove", easterMove);
  };

  const easterUp = () => {
    !isMobile && map.off("mousemove", easterMove);
    isMobile && map.off("touchmove", easterMove);
  };

  const easterMove = (e) => {
    const y = e.lngLat.lat.toFixed(5);
    const x = e.lngLat.lng.toFixed(5);

    const bbox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ];

    const selectedFeatures = map.queryRenderedFeatures(bbox, {
      layers: layerLineNameRef.current,
    });

    const currentData = geojsonRef.current.features.find((item) => {
      return item.geometry.coordinates.find((coords) => {
        return (
          coords[0] > x - 1 &&
          coords[0] < x + 1 &&
          coords[1] > y - 1 &&
          coords[1] < y + 1
        );
      });
    });

    const currentSelectedFeature = selectedFeatures.find(
      (item) => item?.layer?.id === currentData?.id
    );

    if (currentData && currentSelectedFeature) {
      const currentIndex = geojsonRef?.current?.features?.findIndex(
        (item) => item.id === currentSelectedFeature?.layer?.id
      );

      const separateCoords = currentData?.geometry?.coordinates?.find(
        (item) => {
          return (
            item[0] > x - 1 &&
            item[0] < x + 1 &&
            item[1] > y - 1 &&
            item[1] < y + 1
          );
        }
      );

      const separateCoordsIndex =
        currentData?.geometry?.coordinates?.indexOf(separateCoords);

      if (!!separateCoords && separateCoordsIndex !== -1) {
        let currentColor = currentData.properties.color;
        let currentWidth = currentData.properties.width;

        let leftPart =
          currentData?.geometry?.coordinates?.slice(0, separateCoordsIndex) ||
          [];

        let rightPart =
          currentData?.geometry?.coordinates?.slice(
            Math.ceil(separateCoordsIndex + 1)
          ) || [];

        let newCoordinates = [
          leftPart && leftPart.length > 0 ? leftPart : [],
          rightPart && rightPart.length > 0 ? rightPart : [],
        ];

        let removeId = geojsonRef.current.features[currentIndex].id;

        layerLineNameRef.current = layerLineNameRef.current.filter(
          (item) => item !== removeId
        );

        map.removeLayer(removeId);
        map.removeSource(removeId);

        geojsonRef.current.features.splice(currentIndex, 1);

        if (newCoordinates.length > 0) {
          newCoordinates.forEach((item, i) => {
            if (i > 2) {
              return;
            }
            const id = getUniqueNumber() + getRandomIntInclusive(1, 98);
            layerLineNameRef.current.push(`line_${id}`);
            geojsonRef.current.features.push({
              id: `line_${id}`,
              type: "Feature",
              properties: {
                color: currentColor,
                width: currentWidth,
              },
              geometry: {
                coordinates: [...item],
                type: "LineString",
              },
            });

            map.addLayer({
              id: `line_${id}`,
              type: "line",
              source: {
                type: "geojson",
                data: geojsonRef?.current,
              },
              paint: {
                "line-width": ["get", "width"],
                "line-color": ["get", "color"],
              },
            });
          });
        }
      }
    }

    for (let i = 0; i < layerLineNameRef.current?.length; i++) {
      map.getSource(layerLineNameRef.current[i])?.setData(geojsonRef?.current);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (geojsonRef.current?.features?.length > 0) {
        setPainterGeoJsonData({ ...geojsonRef.current });
      } else if (getPainterGeoJsonData?.features?.length > 0) {
        geojsonRef.current = {
          type: "FeatureCollection",
          features: getPainterGeoJsonData.features,
        };

        setPainterGeoJsonData({ ...getPainterGeoJsonData });
      }

      removeEasterEvents();
      // !getMapStylesChangeStatus && removeAllLayers();
    }, 0);
  }, [mapStyles, getMapStylesChangeStatus]);

  useEffect(() => {
    setTimeout(() => {
      if (Array.isArray(getPainterGeoJsonData.features) && isScreenShot) {
        // geojsonRef.current = {
        //   type: "FeatureCollection",
        //   features: getPainterGeoJsonDataST.features,
        // };

        for (let i = 0; i < getPainterGeoJsonData.features.length; i++) {
          layerLineNameRef.current.push(getPainterGeoJsonData.features[i].id);
          if(!map.getLayer(getPainterGeoJsonData.features[i].id)){
            map.addLayer({
              id: `${getPainterGeoJsonData.features[i].id}`,
              type: "line",
              source: {
                type: "geojson",
                data: getPainterGeoJsonData.features[i],
              },

              paint: {
                "line-width": ["get", "width"],
                "line-color": ["get", "color"],
              },
            });
          }
        }
      }
    }, 2000);
  }, [getPainterGeoJsonData]);

  useEffect(() => {
    setTimeout(() => {
      if (Array.isArray(getPainterGeoJsonDataST.features && isScreenShot)) {
        geojsonRef.current = {
          type: "FeatureCollection",
          features: getPainterGeoJsonDataST.features,
        };
      }
    }, 2000);
  }, [getPainterGeoJsonDataST]);

  const onPrevHandle = () => {
    resetActiveButton();
    const prevGeojsonData = geojsonRef.current.features.pop();
    const prevLineName = layerLineNameRef.current.pop();

    if (prevGeojsonData && prevLineName) {
      setPainterPrevData([...getPainterPrevData, prevGeojsonData]);
      // prevDataRef?.current.push(prevGeojsonData);
      setPainterPrevLineNameData([...getPainterPrevLineNameData, prevLineName]);
      // prevLineNameRef?.current.push(prevLineName);

      if (map.getLayer(prevLineName)) {
        map.removeLayer(prevLineName);
      }
      if (map.getSource(prevLineName)) {
        map.removeSource(prevLineName);
      }

      for (let i = 0; i < layerLineNameRef.current?.length; i++) {
        map
          .getSource(layerLineNameRef.current[i])
          ?.setData(geojsonRef?.current);
      }
    }
  };

  const onNextHandle = () => {
    resetActiveButton();
    // const nextGeoJsonData = prevDataRef?.current.pop();
    const nextGeoJsonData = getPainterPrevData.pop();
    const nextLineName = getPainterPrevLineNameData.pop();

    if (nextGeoJsonData && nextLineName) {
      geojsonRef.current.features.push(nextGeoJsonData);

      layerLineNameRef.current.push(geojsonRef.current.features.at(-1).id);

      map.addLayer({
        id: `${geojsonRef.current.features.at(-1).id}`,
        type: "line",
        source: {
          type: "geojson",
          data: geojsonRef?.current,
        },

        paint: {
          "line-width": ["get", "width"],
          "line-color": ["get", "color"],
        },
      });
    }
  };

  const removeEasterEvents = () => {
    !isMobile && map?.off("mousedown", easterDown);
    isMobile && map?.off("touchstart", easterDown);
    !isMobile && map?.off("mouseup", easterUp);
    isMobile && map?.off("touchend", easterUp);
    !isMobile && map?.off("mousemove", easterMove);
    isMobile && map?.off("mousemove", easterMove);
  };

  const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const rulerCircleMouseEnterRef = useRef(false);

  /////////////
  const rulerButtonClick = () => {
    rulerCircleMouseEnterRef.current = true;

    changeCursorIcon(CURSOR_TYPE.RULER);

    if (startRulerIconClickStateRef.current) {
      rulerSourceNameRef.current = getUniqueNumber();
      soursId.push(rulerSourceNameRef.current);
      rulerSourceNameRef2.current.push(getUniqueNumber() + 1);
      if(!map.getSource(rulerSourceNameRef.current)){
        map.addSource(rulerSourceNameRef.current, {
          type: "geojson",
          data: rulerGeojsonRef.current,
        });
      }
      map.addLayer({
        id: rulerSourceNameRef.current,
        type: "line",
        source: rulerSourceNameRef.current,
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#EB572C",
          "line-width": 4,
        },
        filter: ["in", "$type", "LineString"],
      });
      if(!map.getLayer(rulerSourceNameRef2.current.at(-1))){
        map.addLayer({
          id: rulerSourceNameRef2.current.at(-1),
          type: "circle",
          source: rulerSourceNameRef.current,
          paint: {
            "circle-radius": 4.8,
            "circle-color": "#fff",
            "circle-stroke-color": "#EB572C",
            "circle-stroke-opacity": 1,
            "circle-stroke-width": 4.8,
          },
          filter: ["in", "$type", "Point"],
        });
      }


      !isMobile &&
        map.on("mouseenter", rulerSourceNameRef2.current.at(-1), () => {
          rulerCircleMouseEnterRef.current = false;
        });

      !isMobile &&
        map.on("mouseleave", rulerSourceNameRef2.current.at(-1), () => {
          rulerCircleMouseEnterRef.current = true;
        });

      isMobile &&
        map.on("touchstart", rulerSourceNameRef2.current.at(-1), (e) => {
          rulerCircleMouseEnterRef.current = false;
        });

      isMobile &&
        map.on("touchend", rulerSourceNameRef2.current.at(-1), (e) => {
          rulerCircleMouseEnterRef.current = true;
        });
    }
  };

  const onRulerStart = (e) => {
    if (!rulerCircleMouseEnterRef.current) return;

    if (startRulerIconClickStateRef.current) {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [rulerSourceNameRef.current],
      });

      if (rulerGeojsonRef.current.features.length > 1)
        rulerGeojsonRef.current.features.pop();

      if (features.length > 0) {
        const id = features[0].properties.id;
        rulerGeojsonRef.current.features =
          rulerGeojsonRef.current.features.filter(
            (point) => point.properties.id !== id
          );
      } else {
        const point = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        };

        rulerGeojsonRef.current.features.push(point);
      }

      if (rulerGeojsonRef.current.features.length > 1) {
        lineStringRef.current.geometry.coordinates =
          rulerGeojsonRef.current.features.map(
            (point) => point.geometry.coordinates
          );

        rulerGeojsonRef.current.features.push(lineStringRef.current);
        const distance = turf.length(lineStringRef.current);

        // <p>${distance.toFixed(2)} կմ</p>
        if (distance !== 0) {
          popupRef.current = new mapboxgl.Popup({
            closeOnClick: false,
            className: `ruler ruler_modal_${index}`,
          })
            .setLngLat([e.lngLat.lng, e.lngLat.lat])
            .setHTML(getRulerPopupTemplate(distance, index))
            .addTo(map);

          onCloseRulerPopup();
        }

        setHideRulerPopupST(false);

        onHidePopups();

        onAddMouseEnter();

        index++;
      }

      map
        .getSource(rulerSourceNameRef.current)
        .setData(rulerGeojsonRef.current);
    }
  };

  const onCloseRulerPopup = () => {
    const popups = document.getElementsByClassName("mapboxgl-popup");

    for (let i = 0; i < popups.length; i++) {
      popups[i]?.addEventListener("click", (e) => {
        if (e.target.parentNode.classList[1] === e.target.id) {
          e.target.parentNode.style.width = "10px";
          e.target.parentNode.style.height = "5px";
          e.target.parentNode.style.minWidth = "10px";
          e.target.parentNode.style.padding = "0px !important";
          e.target.parentNode.style.opacity = 0;

          e.target.parentNode.removeEventListener("mouseenter", showPopupEvent);
          e.target.parentNode.removeEventListener("mouseleave", hidePopupEvent);
          e.target.parentNode.previousElementSibling.removeEventListener(
            "mouseenter",
            showPopupEvent
          );
          e.target.parentNode.previousElementSibling.removeEventListener(
            "mouseleave",
            hidePopupEvent
          );
          e.target.parentNode.nextElementSibling.removeEventListener(
            "mouseenter",
            showPopupEvent
          );
          e.target.parentNode.nextElementSibling.removeEventListener(
            "mouseleave",
            hidePopupEvent
          );
          e.target.parentNode.addEventListener("mouseenter", showPopupEvent);
          e.target.parentNode.addEventListener("mouseleave", hidePopupEvent);
          e.target.parentNode.previousElementSibling.addEventListener(
            "mouseenter",
            showPopupEvent
          );
          e.target.parentNode.previousElementSibling.addEventListener(
            "mouseleave",
            hidePopupEvent
          );
          e.target.parentNode.nextElementSibling.addEventListener(
            "mouseenter",
            showPopupEvent
          );
          e.target.parentNode.nextElementSibling.addEventListener(
            "mouseleave",
            hidePopupEvent
          );
        }
      });
    }
  };

  const removeAllRulerPopups = () => {
    const popups = document.getElementsByClassName("mapboxgl-popup");

    for (let i = 0; i < index; i++) {
      for (let ii = 0; ii < popups.length; i++) {
        popups[ii]?.remove();
      }
    }
  };

  const onAddMouseEnter = () => {
    const popups = document.getElementsByClassName("ruler_modal");

    for (let i = 0; i < popups.length - 1; i++) {
      popups[i].removeEventListener("mouseenter", showPopupEvent);
      popups[i].removeEventListener("mouseleave", hidePopupEvent);
      popups[i].previousElementSibling.removeEventListener(
        "mouseenter",
        showPopupEvent
      );
      popups[i].previousElementSibling.removeEventListener(
        "mouseleave",
        hidePopupEvent
      );
      popups[i].nextElementSibling.removeEventListener(
        "mouseenter",
        showPopupEvent
      );
      popups[i].nextElementSibling.removeEventListener(
        "mouseleave",
        hidePopupEvent
      );
    }

    for (let i = 0; i < popups.length - 1; i++) {
      popups[i].addEventListener("mouseenter", showPopupEvent);
      popups[i].addEventListener("mouseleave", hidePopupEvent);
      popups[i].previousElementSibling.addEventListener(
        "mouseenter",
        showPopupEvent
      );
      popups[i].previousElementSibling.addEventListener(
        "mouseleave",
        hidePopupEvent
      );
      popups[i].nextElementSibling.addEventListener(
        "mouseenter",
        showPopupEvent
      );
      popups[i].nextElementSibling.addEventListener(
        "mouseleave",
        hidePopupEvent
      );
    }
  };
  const onAddMouseEnterST = () => {
    const popups = document.getElementsByClassName("ruler_modal");

    for (let i = 0; i < popups.length; i++) {
      popups[i].removeEventListener("mouseenter", showPopupEvent);
      popups[i].removeEventListener("mouseleave", hidePopupEvent);
      popups[i].previousElementSibling.removeEventListener(
        "mouseenter",
        showPopupEvent
      );
      popups[i].previousElementSibling.removeEventListener(
        "mouseleave",
        hidePopupEvent
      );
      popups[i].nextElementSibling.removeEventListener(
        "mouseenter",
        showPopupEvent
      );
      popups[i].nextElementSibling.removeEventListener(
        "mouseleave",
        hidePopupEvent
      );
    }

    for (let i = 0; i < popups.length; i++) {
      popups[i].addEventListener("mouseenter", showPopupEvent);
      popups[i].addEventListener("mouseleave", hidePopupEvent);
      popups[i].previousElementSibling.addEventListener(
        "mouseenter",
        showPopupEvent
      );
      popups[i].previousElementSibling.addEventListener(
        "mouseleave",
        hidePopupEvent
      );
      popups[i].nextElementSibling.addEventListener(
        "mouseenter",
        showPopupEvent
      );
      popups[i].nextElementSibling.addEventListener(
        "mouseleave",
        hidePopupEvent
      );
    }
  };

  const showPopupEvent = (e) => {
    e.target.parentNode.parentNode.style.zIndex = 15;
    e.target.parentNode.style.zIndex = 15;
    if (e.target.id === "ruler_modal_top_arrow") {
      e.target.nextElementSibling.style.width = "fit-content";
      e.target.nextElementSibling.style.height = "fit-content";
      e.target.nextElementSibling.style.opacity = 1;
      e.target.nextElementSibling.style.padding = "13px 31px 10px 20px";
      e.target.nextElementSibling.style.overflow = "hidden";
    } else if (e.target.id === "ruler_modal_bottom_arrow") {
      e.target.previousElementSibling.style.width = "fit-content";
      e.target.previousElementSibling.style.height = "fit-content";
      e.target.previousElementSibling.style.opacity = 1;
      e.target.previousElementSibling.style.padding = "13px 31px 10px 20px";
      e.target.previousElementSibling.style.overflow = "hidden";
    } else {
      e.target.style.width = "fit-content";
      e.target.style.height = "fit-content";
      e.target.style.opacity = 1;
      e.target.style.padding = "13px 31px 10px 20px";
      e.target.style.overflow = "hidden";
    }
  };

  const hidePopupEvent = (e) => {
    e.target.parentNode.parentNode.style.zIndex = 0;
    e.target.parentNode.style.zIndex = 0;
    if (e.target.id === "ruler_modal_top_arrow") {
      e.target.nextElementSibling.style.width = "10px";
      e.target.nextElementSibling.style.height = "5px";
      e.target.nextElementSibling.style.opacity = 0;
      e.target.nextElementSibling.style.padding = "0";
    } else if (e.target.id === "ruler_modal_bottom_arrow") {
      e.target.previousElementSibling.style.width = "10px";
      e.target.previousElementSibling.style.height = "5px";
      e.target.previousElementSibling.style.opacity = 0;
      e.target.previousElementSibling.style.padding = "0";
    } else {
      e.target.style.width = "10px";
      e.target.style.height = "5px";
      e.target.style.opacity = 0;
      e.target.style.padding = "0";
    }
  };

  const onHidePopups = () => {
    const popups = document.getElementsByClassName("ruler_modal");

    for (let i = 0; i < popups.length - 1; i++) {
      popups[i].style.width = "10px";
      popups[i].style.height = "10px";
      popups[i].style.minWidth = "10px";
      popups[i].style.opacity = 0;
      popups[i].style.overflow = "hidden";
    }

    if (popups?.[popups.length - 1]?.style) {
      popups[popups.length - 1].style.width = "fit-content";
      popups[popups.length - 1].style.height = "fit-content";
      popups[popups.length - 1].style.minWidth = "180px";
      popups[popups.length - 1].style.opacity = 1;
    }
  };

  const removeRuler = () => {
    rulerSourceNameRef.current = null;

    setRulerClickedState(false);
    data = [];
    setRulerPointsPosDataST([]);
    startRulerIconClickStateRef.current = false;
    isEraserButtonClickStateRef.current = false;

    !isMobile && map?.off("mousedown", onRulerStart);
    isMobile && map?.off("touchstart", onRulerStart);

    changeCursorIcon();
    for (let i = 0; i < soursId.length; i++) {
      if (map.getLayer(soursId[i])) {
        map.removeLayer(soursId[i]);
      }
      if (map.getLayer(soursId[i] + 1)) {
        map.removeLayer(soursId[i] + 1);
      }
      if (map.getSource(soursId[i])) {
        map.removeSource(soursId[i]);
      }
    }

    for (let i = 0; i < rulerSourceNameRef2.current.length; i++) {
      if (map.getLayer(rulerSourceNameRef2.current[i])) {
        map.removeLayer(rulerSourceNameRef2.current[i]);
      }
      if (map.getSource(rulerSourceNameRef2.current[i])) {
        map.removeSource(rulerSourceNameRef2.current[i]);
      }

    }

    // if (map.getSource(rulerSourceNameRef?.current)) {
    //   map.removeSource(rulerSourceNameRef?.current);
    // }

    // if (map.getLayer(rulerSourceNameRef?.current)) {
    //   map.removeLayer(rulerSourceNameRef?.current);
    // }

    soursId = [];

    rulerGeojsonRef.current = {
      type: "FeatureCollection",
      features: [],
    };

    map
      .getSource(rulerSourceNameRef?.current)
      ?.setData(rulerGeojsonRef?.current);

    removeAllRulerPopups();

    index = 0;

    popupRef?.current?.remove();
    popupRef.current = null;
    rulerSourceNameRef2.current = [];
  };

  const updateSizeColorIcon = (id) => {
    setPointerSizeData(
      getPainterSizeData.map((item) => {
        item.status = 0;
        if (item.id === id) {
          item.status = 1;
        }

        return item;
      })
    );
  };

  const updateColorStatus = (id) => {
    setPointerColorData(
      getPainterColorData.map((item) => {
        item.status = 0;
        if (item.id === id) {
          item.status = 1;
        }

        return item;
      })
    );
  };

  const resetActiveButton = () => {
    setStartPaintClickState(false);
    changeCursorIcon();
    removeRuler();
    //eraser
    setEraserClickState(false);
    isEraserButtonClickStateRef.current = false;
    setRulerClickedState(false);
    setEraserClickState(false);
    map["dragPan"].enable();
  };

  const [firstLoadFromRuler, setFirstLoadFromRuler] = useState(true);

  useEffect(() => {
    if (isScreenShot && firstLoadFromRuler && getRulerClickedState) {
      startRulerIconClickStateRef.current = getRulerClickedState;
      startRulerIconClickStateRef.current ? rulerButtonClick() : removeRuler();
      setFirstLoadFromRuler(false);
    }
  }, [getRulerClickedState]);

  return (
    <>
      <div
        className="paintBar_container"
        style={{ display: painterStart ? "flex" : "none" }}
      >
        <div id={"screen-shot-icon"} className="lastToolboxBtn">
          {painterStart && !isScreenShot && <MapScreenShotButton map={map}/>}
          {painterStart && isScreenShot && (
            <MapScreenShotButton map={map} disable={getScreenShootButtonStateST} />
          )}
        </div>
        {
          hasDelete &&
            <div
                className="pain_items"
                onClick={removeAllLayersByClick}
                style={{
                  marginLeft: "7px",
                }}
            >
              <img src={ClearIcon} alt="" className="icon_img" />
            </div>
        }
        {
          hasUndo &&
            <div className="pain_items" onClick={onPrevHandle}>
              <img src={PrevIcon} alt="" className="icon_img" />
            </div>
        }
        {
          hasRedo &&
            <div className="pain_items" onClick={onNextHandle}>
              <img src={NextIcon} alt="" className="icon_img" />
            </div>
        }

        {
            hasRubber &&
            <div
                id="easter_icon"
                className={`pain_items ${
                    getEraserClickedState ? "button_active" : ""
                }`}
                onClick={onEraserHandle}
            >
              <img src={EraserIcon} alt="" className="icon_img" />
            </div>
        }

        {
          hasRuler &&
            <div
                id="ruler_icon"
                className={`pain_items ${
                    getRulerClickedState ? "button_active" : ""
                }`}
                onClick={() => {
                  !isMobile && map?.off("mousedown", onRulerStart);
                  isMobile && map?.off("touchstart", onRulerStart);

                  setStartPaintClickState(false);
                  setEraserClickState(false);
                  setRulerClickedState(!getRulerClickedState);
                  startRulerIconClickStateRef.current =
                      !startRulerIconClickStateRef.current;
                  isEraserButtonClickStateRef.current = false;
                  startRulerIconClickStateRef.current
                      ? rulerButtonClick()
                      : removeRuler();
                }}
            >
              <img src={RulerIcon} alt="" className="icon_img" />
            </div>
        }
        {
          hasPencil &&
            <div
                onClick={() => {
                  startRulerIconClickStateRef.current = false;
                  setEraserClickState(false);
                  setStartPaintClickState(!getStartPaintClickState);
                  isEraserButtonClickStateRef.current = false;
                  getRulerClickedState && removeRuler();
                  setRulerClickedState(false);

                  if (getStartPaintClickState) {
                    changeCursorIcon();
                    map["dragPan"].enable();
                    setColorPickerShowState(false);
                    setStartPaintClickState(false);
                  } else {
                    changeCursorIcon(CURSOR_TYPE.DRAW);
                    map["dragPan"].disable();
                  }
                }}
                className={`pain_items ${
                    getStartPaintClickState ? "button_active" : ""
                }`}
            >
              <img src={BrashIcon} alt="" className="icon_img" />

              {isLandscape && getStartPaintClickState ? (
                  <div className="landscape_color_container">
                    {getStartPaintClickState && (
                        <>
                          <div
                              className={`painter_tools_container ${
                                  getColorPickerShowState
                                      ? "painter_tools_container_landscape"
                                      : ""
                              }`}
                          >
                            <div className="painter_tools_size">
                              {getPainterSizeData.map((item) => (
                                  <div
                                      key={`a-${item.id}`}
                                      style={{
                                        backgroundColor: item?.status ? "#2c476c" : "",
                                      }}
                                      className="painter_tools_size_icon_block"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        updateSizeColorIcon(item?.id);
                                        setPointerSize(item.value);
                                      }}
                                  >
                                    <div
                                        className="painter_tools_size_icon"
                                        style={{
                                          backgroundImage: `url(${
                                              item.status ? item?.active : item?.passive
                                          })`,
                                        }}
                                    ></div>
                                  </div>
                              ))}
                            </div>
                            <div
                                style={{
                                  width: "100%",
                                  height: "1px",
                                  background: "#BFBFC1",
                                }}
                            ></div>
                            <div className="painter_tools_color">
                              {getPainterColorData.map((item, i) => (
                                  <div
                                      key={i}
                                      className="painter_tools_color_data_item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        updateColorStatus(item.id);
                                        !item?.img && setPointerColor(item?.color);
                                        setColorPickerShowState(false);
                                        i === 5 &&
                                        setColorPickerShowState(!getColorPickerShowState);
                                      }}
                                  >
                                    {i === 5 ? (
                                        <div className={"color_circle"}>
                                          <svg
                                              style={{
                                                background:
                                                    "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177," +
                                                    " 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                                borderRadius: "50%",
                                                stroke: item.status ? "#fff" : "#0000",
                                              }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="#0000"
                                          >
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="11.5"
                                                stroke="#0000"
                                            />
                                            <circle cx="12" cy="12" r="9.5" fill="#0000" />
                                          </svg>
                                        </div>
                                    ) : (
                                        <div className={"color_circle"}>
                                          <svg
                                              style={{
                                                stroke: item.status ? "#fff" : item?.color,
                                              }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill={item?.color}
                                          >
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="11.5"
                                                stroke={item?.color}
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="9.5"
                                                fill={item?.color}
                                            />
                                          </svg>
                                        </div>
                                    )}
                                  </div>
                              ))}
                            </div>
                          </div>
                          {getColorPickerShowState && (
                              <div
                                  className={`painter_tools_color_item_container`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                              >
                                {color_picker_data.map((item, i) => (
                                    <div
                                        key={i}
                                        className="painter_tools_color_item"
                                        style={{
                                          backgroundColor: item,
                                        }}
                                        onClick={() => {
                                          setPointerColor(item);
                                          setColorPickerShowState(false);
                                        }}
                                    ></div>
                                ))}
                                <span></span>
                              </div>
                          )}
                        </>
                    )}
                  </div>
              ) : (
                  <>
                    {getStartPaintClickState && (
                        <div className="painter_tools_container">
                          <div className="painter_tools_size">
                            {getPainterSizeData.map((item) => (
                                <div
                                    key={item.id}
                                    style={{
                                      backgroundColor: item?.status ? "#2c476c" : "",
                                    }}
                                    className="painter_tools_size_icon_block"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      updateSizeColorIcon(item?.id);
                                      setPointerSize(item.value);
                                    }}
                                >
                                  <div
                                      className="painter_tools_size_icon"
                                      style={{
                                        backgroundImage: `url(${
                                            item.status ? item?.active : item?.passive
                                        })`,
                                      }}
                                  ></div>
                                </div>
                            ))}
                          </div>
                          <div
                              style={{
                                width: "100%",
                                height: "1px",
                                background: "#BFBFC1",
                              }}
                          ></div>
                          <div className="painter_tools_color">
                            {getPainterColorData.map((item, i) => (
                                <div
                                    key={i}
                                    className="painter_tools_color_data_item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      updateColorStatus(item.id);
                                      !item?.img && setPointerColor(item?.color);
                                      setColorPickerShowState(false);
                                      i === 5 &&
                                      setColorPickerShowState(!getColorPickerShowState);
                                    }}
                                >
                                  {i === 5 ? (
                                      <div className={"color_circle"}>
                                        <svg
                                            style={{
                                              background:
                                                  "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177," +
                                                  " 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                              borderRadius: "50%",
                                              stroke: item.status ? "#fff" : "#0000",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="#0000"
                                        >
                                          <circle cx="12" cy="12" r="11.5" stroke="#0000" />
                                          <circle cx="12" cy="12" r="9.5" fill="#0000" />
                                        </svg>
                                      </div>
                                  ) : (
                                      <div className={"color_circle"}>
                                        <svg
                                            style={{
                                              stroke: item.status ? "#fff" : item?.color,
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill={item?.color}
                                        >
                                          <circle
                                              cx="12"
                                              cy="12"
                                              r="11.5"
                                              stroke={item?.color}
                                          />
                                          <circle
                                              cx="12"
                                              cy="12"
                                              r="9.5"
                                              fill={item?.color}
                                          />
                                        </svg>
                                      </div>
                                  )}
                                </div>
                            ))}
                            {getColorPickerShowState && (
                                <div
                                    className="painter_tools_color_item_container"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                >
                                  {color_picker_data.map((item, i) => (
                                      <div
                                          key={i}
                                          className="painter_tools_color_item"
                                          style={{
                                            backgroundColor: item,
                                          }}
                                          onClick={() => {
                                            setPointerColor(item);
                                            setColorPickerShowState(false);
                                          }}
                                      ></div>
                                  ))}
                                  <span></span>
                                </div>
                            )}
                          </div>
                        </div>
                    )}
                  </>
              )}
            </div>
        }
      </div>
    </>
  );
};

const mapStateTopProps = (state) => ({
  painterStart: getPainterStartData(state),
  mapStyle: getMapStyleData(state),
  mapStyles: getMapStylesData(state),
  getPainterGeoJsonData: getPainterGeoJsonData(state),
  getPainterPrevData: getPainterPrevData(state),
  getPainterPrevLineNameData: getPainterPrevLineNameData(state),
  getPainterLayerLineNameData: getPainterLayerLineNameData(state),
  getStartPaintClickState: getStartPaintClickState(state),
  getPainterColor: getPainterColor(state),
  getPainterColorData: getPainterColorData(state),
  getPainterSize: getPainterSize(state),
  getPainterSizeData: getPainterSizeData(state),
  getColorPickerShowState: getColorPickerShowState(state),
  getRulerClickedState: getRulerClickedState(state),
  getEraserClickedState: getEraserClickedState(state),
  getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
  getRulerGeoJsonDataST: getRulerGeoJsonDataST(state),
  getRulerPointsPosDataST: getRulerPointsPosDataST(state),
  getRulerPopupShowST: getRulerPopupShowST(state),
  getMapStylesChangeStatus: getMapStylesChangeStatus(state),
  getScreenShootButtonStateST: getScreenShootButtonStateST(state),
  getTimeLineClickState: getTimeLineClickState(state),
  getMapStyledId: getMapStyledId(state),
  getMapStyledDarkId: getMapStyledDarkId(state),
  getMobileMenuState: getMobileMenuState(state),
});

const mapDispatchToProps = {
  setPainterStart: setPainterStart,
  setPainterGeoJsonData: setPainterGeoJsonData,
  setPainterPrevData: setPainterPrevData,
  setPainterPrevLineNameData: setPainterPrevLineNameData,
  setPainterLayerLineNameData: setPainterLayerLineNameData,
  setStartPaintClickState: setStartPaintClickState,
  setPointerColor: setPointerColor,
  setPointerColorData: setPointerColorData,
  setPointerSize: setPointerSize,
  setPointerSizeData: setPointerSizeData,
  setColorPickerShowState: setColorPickerShowState,
  setRulerClickedState: setRulerClickedState,
  setEraserClickState: setEraserClickState,
  setPainterGeoJsonDataST: setPainterGeoJsonDataST,
  setRulerGeoJsonDataST: setRulerGeoJsonDataST,
  setRulerPointsPosDataST: setRulerPointsPosDataST,
  setHideRulerPopupST: setHideRulerPopupST,
};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(PaintBar));
