import {connect} from "react-redux";
import LikeIcon from "../../LikeIcon";
import {useState} from "react";
import './style.css'

const Like = (props) => {
    const {id,liked = false,handleClick=()=>{}} = props
    // const [likedState, setLikedState] = useState(liked)
    // const handleLikeItem = () => setLikedState((prev)=> !prev)
    return (
        <div id="like" data-liked={liked}>
            <div>
                <input onClick={handleClick} type="checkbox" className={'checkbox'}  id={`checkbox${id}`}/>
                <label htmlFor={`checkbox${id}`}>
                    <LikeIcon />
                </label>
            </div>
        </div>
    )
}
export default connect(null, null)(Like);
