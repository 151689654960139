import React from 'react';

const DEFAULT_SIZE = 20;
const DEFAULT_COLOR = '#2C476C';

const PresentationPlayIcon = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7976 8.85967C13.5958 6.84281 11 8.49505 11 12.5471V42.4495C11 46.5056 13.5958 48.1557 16.7976 46.1407L40.5978 31.1518C43.8007 29.1342 43.8007 25.8655 40.5978 23.8484L16.7976 8.85967Z"
        fill={color}/>
    </svg>

  );
};

export default PresentationPlayIcon;
