import axios from "axios"

const extractItems = (res) => {
  const { data } = res
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items
    }
  }
  return []
}

const santizeSelectedResult = (items) =>
  items.map((value) => ({
    value: value.id,
    label: value.title,
  }))

const santizeFilteredSelectedResult = (items) =>
  items.map((value) => ({ id: value.id, value: value.id, title: value.title }))

const sortItemsByAlphabetical = (items) =>
  items.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))

export const getSubjectsAPI = (url, body) => {
  return axios
    .post(url, body)
    .then(extractItems)
    .then(santizeSelectedResult)
    .then(sortItemsByAlphabetical)
    .catch(console.log)
}

export const getFilteredSubjectsAPI = (url, body, userInfo) => {
  return axios
    .post(url, body)
    .then(extractItems)
    .then(santizeFilteredSelectedResult)
    .then((data) => {
      if (userInfo?.userInfo?.userType === "Teacher") {
        const userSubjectIds = userInfo?.userInfo?.subjectIds
        if (userSubjectIds && userSubjectIds.length > 0) {
          return data.filter((x) => userSubjectIds.includes(x.id))
        }
      }
      return data
    })
    .catch(console.log)
}
