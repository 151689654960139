export const checkValidationErrors = (fields, specialityId) => {
  let errors = {};
  let hasError = false;

  //Email
  if (!fields['phoneNumber'] && !fields["email"]) {
    hasError = true
    errors["email"] = "Մուտքագրեք Էլ․ հասցե"
  }

  if (typeof fields["email"] !== "undefined" && !fields['phoneNumber']) {
    let lastAtPos = fields["email"].lastIndexOf("@")
    let lastDotPos = fields["email"].lastIndexOf(".")
    if (
        !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["email"].indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            fields["email"].length - lastDotPos > 2 &&
            !/\s/.test(fields['email'])
        )
    ) {
      hasError = true
      errors["email"] = "Խնդրում ենք մուտքագրել վավեր էլ․ հասցե"
    }
  }
  if (!fields['email'] && !fields["phoneNumber"]) {
    hasError = true;
    errors["phone"] =
      "Պարտադիր դաշտ";
  }

  if (!fields['email'] && fields["phoneNumber"]?.length < 8) {
    hasError = true;
    errors["phone"] =
      "Մուտքագրեք վավեր հեռախոսահամար";
  }

  if (!fields["gender"]?.value) {
    hasError = true;
    errors["gender"] = "Պարտադիր դաշտ";
  }

  if (!fields["birthDate"]) {
    hasError = true;
    errors["birthDate"] = "Պարտադիր դաշտ";
  }

  if (!fields["country"]?.value) {
    hasError = true;
    errors["country"] = "Պարտադիր դաշտ";
  }
  if (specialityId === 4 && !fields["occupation"]) {
    hasError = true;
    errors["occupation"] = "Պարտադիր դաշտ";
  }

  if (!fields["region"]?.value) {
    hasError = true;
    errors["region"] = "Պարտադիր դաշտ";
  }
  if (!fields["state"]?.value) {
    hasError = true;
    errors["state"] = "Պարտադիր դաշտ";
  }
  if (specialityId != 4 &&
      !fields["school"]?.value &&
      !fields["school"]?.length) {
    hasError = true;
    errors["school"] = "Պարտադիր դաշտ";
  }
  if (specialityId == 3 &&
      fields["country"]?.value == 11 &&
      !fields["grade"]?.value) {
    hasError = true;
    errors["grade"] = "Պարտադիր դաշտ";
  }
  if (specialityId == 1 &&
      fields["country"]?.value == 11 &&
      !fields["subject"]?.length) {
    hasError = true;
    errors["subject"] = "Պարտադիր դաշտ";
  }

  return hasError ? errors : null;
};
