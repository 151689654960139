import { SET_COUNTRIES, LOADING_COUNTRIES } from "../constants";

const initialState = {
  data: [],
  isLoading: false,
};

export const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_COUNTRIES: {
      return { ...state, isLoading: true };
    }
    case SET_COUNTRIES:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
