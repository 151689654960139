import React from "react";
import slideBackground from '../../../../../assets/imgs/slideBackground.png'
import InfoIcon from '../../../../../assets/imgs/icons/slideInfo.svg'
import './style.css'
import {useDrag, useDrop} from "react-dnd";
const   SliderCards = ({text, onClick, active, onDoubleClick, id, index,imageUrl}) => {
    // const [, ref] = useDrag({
    //     type: "CARD",
    //     item: { index, id },
    // });
    //
    // const [, drop] = useDrop({
    //     accept: "CARD",
    //     hover: (draggedItem) => {
    //         if (draggedItem.index !== index) {
    //             moveCard(draggedItem.index, index);
    //             draggedItem.index = index;
    //         }
    //     },
    // });

    return (
        <div className={`slide__background ${active ? 'active_card' : ''}`} onClick={onClick} onDoubleClick={onDoubleClick}>
            <div className='slide__img' style={{ backgroundImage: `url(${imageUrl || slideBackground})`, border: `${!text && '1px solid #EAEAEA'}` }}>
                {index && <p>{index}</p>}
            </div>
            <p>{text}</p>
        </div>
    );
};

export default SliderCards
