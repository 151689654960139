const SliderPosition = ({position}) => {
    return ( position === 'center' ?
        <svg width="15" height="15" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_340_12197)">
                <rect x="2" y="8" width="23" height="9" fill="#2C476C"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H25V25H0V0ZM22.9167 2H2.08333V22.9167H22.9167V2Z" fill="#2C476C"/>
            </g>
            <defs>
                <clipPath id="clip0_340_12197">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
            </defs>
        </svg> : position === 'left' ?
        <svg width="15" height="15" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_340_13462)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H25V25H0V0ZM22.9167 3H2.08333V22.9167H22.9167V3Z" fill="#2C476C"/>
                <rect x="1" y="24" width="23" height="9" transform="rotate(-90 1 24)" fill="#2C476C"/>
            </g>
            <defs>
                <clipPath id="clip0_340_13462">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
            </defs>
        </svg> :
        <svg width="15" height="15" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_340_13046)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H25V25H0V0ZM22.9167 2H2.08333V22.9167H22.9167V2Z" fill="#2C476C"/>
                <rect x="16" y="23" width="23" height="9" transform="rotate(-90 16 23)" fill="#2C476C"/>
            </g>
            <defs>
                <clipPath id="clip0_340_13046">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default SliderPosition;
