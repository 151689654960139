import React, { useState, useEffect } from 'react';
import ScreenShotNotificationIcon from "../../../../assets/imgs/screenshot-notification-icon.svg";
import CloseIcon from "../../../../assets/imgs/close-black-icon.svg";
import Popup from "../../Popup";
import './style.css'
import {useLocation} from "react-router-dom";

const ScreenShotNotificationPopup = (props) => {
    const {notificationState,toggleNotification} = props
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    return (
        <Popup
            onClose={toggleNotification}
            isOpened={notificationState}
            styleOverlayUser={{ backgroundColor: "rgba(44, 71, 108, 0.25)" }}
        >
            <div className={`screenShot_notification_popup-box`}>
                <button className={`screenShot_notification_popup-box-close-btn`}
                        onClick={toggleNotification}
                >
                    <img src={CloseIcon} />
                </button>
                <h3>
                    <span className="icon-wrapper screenshot-icon">
                        <img src={ScreenShotNotificationIcon} />
                    </span>
                    Ուշադրություն
                </h3>
                <div className={'screenShot_notification-description'}>
                    <p>
                        {
                            typeof +query.get('screenShot') === 'number' && !isNaN(+query.get('screenShot'))
                            ?'Կոնտենտում ադմինի կողմից տեղի է ունեցել փոփոխություն, որը կցուցադրվի Ձեր պահպանված քարտեզում:'
                            :'Կոնտենտում ադմին կողմից տեղի է ունեցել փոփոխություն, որը կցուցադրվի Ձեր պահպանված ցուցադրությունում'
                        }

                    </p>
                </div>
            </div>
        </Popup>
    );
};

export default ScreenShotNotificationPopup;
