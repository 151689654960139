export const BOOKMARK_SECTIONS = [
    {
        id:1,
        section:'new-tab',
        content:[]
    },
    {
        id:2,
        section:'dynamic-title',
        content:[]
    },
    {
        id:3,
        section:'dynamic-info',
        content: []
    },
    {
        id:4,
        section:'content-info',
        content:[]
    }
]
