import {connect} from "react-redux";
import './style.css'
import React from "react";
import DefaultImage from "../../../../assets/imgs/defaultImage.png"

const BookmarkInfo = (props) =>{
    const {content={}} = props
    return (
        <div className={'bookmark-info'}>
            {
                content?.imgPath
                    ?(
                        <div className="hero__media_animation__box">
                            <div className={'bookmark-image-box hero__media_animation'}>
                                <img src={content?.imgPath} alt="bookmark-image"/>
                            </div>
                            <div className="hero__media__liner-gradien"></div>
                        </div>
                    )
                    : <div className={'hero__media__box'}><img src={DefaultImage} alt="bookmark-image"/></div>
            }
            <div className={'bookmark-description-box'}>
                <p>{content?.description}</p>
            </div>
        </div>
    )

}
export default connect(null, null)(BookmarkInfo);
