import { LOADING_BOOKMARKS, SET_BOOKMARKS } from "../constants";
import {getBookmarksAPI} from "../../service/bookmarks";

export const loadingBookmarks = () => ({
  type: LOADING_BOOKMARKS,
});

export const setBookmarks = (bookmarks) => ({
  type: SET_BOOKMARKS,
  payload: bookmarks,
});

export const getBookmarksFromAPI = (page) => (dispatch, getState) => {
  const state = getState();
  dispatch(loadingBookmarks());
  getBookmarksAPI(page).then((bookmarks) => {
    dispatch(setBookmarks(bookmarks));
  });
};
export const handleSetBookmarks = (items) =>(dispatch, getState) => {
  const state = getState();
  dispatch(loadingBookmarks());
  dispatch(setBookmarks(items));
};
export const handleGetNewBookmarks = (page) =>() => {
  return getBookmarksAPI(page)
}
