import {connect} from "react-redux";
import './style.css'
import React from "react";
const Actions = (props) => {
    const {color=''} = props
    return (
        <div className="card-body__dynamic-line dynamic-line" style={{backgroundColor:color}}></div>
    )
}
export default connect(null, null)(Actions);
