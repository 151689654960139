import React from "react";
import './style.css'
import PresentationSlideBoxes from "../../PresentationSlideBoxes";
import {getIsSeeAllMode, getSlidesSelectedSlideData} from "../../../../store/selectors";
import {setSeeAllSlides} from "../../../../store/actions/slides";
import {connect} from "react-redux";

const ShowSlidesMode = ({setSeeAllSlides, getSlidesSelectedSlideData,handleShowInfo,map,globalLoading}) => {
    const handleSeeAll = () => {
        setSeeAllSlides(true)
    }
    const handleSelect = (id) =>{
    }
    return(
        <div className='slides__show__container'>
            <PresentationSlideBoxes
                selectedId={getSlidesSelectedSlideData?.id}
                showInfo={true}
                onSelect={handleSelect}
                handleShowInfo={handleShowInfo}
                map={map}
                globalLoading={globalLoading}/>
            <button style={{padding: '3px 0'}} onClick={handleSeeAll}>Տեսնել բոլորը</button>
        </div>
    )
}

const mapStateTopProps = (state) => ({
    isSeeAllMode: getIsSeeAllMode(state),
    getSlidesSelectedSlideData: getSlidesSelectedSlideData(state),

});

const mapDispatchToProps = {
    setSeeAllSlides : setSeeAllSlides
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(ShowSlidesMode);
