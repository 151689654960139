import React from "react"

const UploadPhotoIcon = () => {
  return (
    <svg version="1.1" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-995 -378)">
          <g transform="translate(994 377)">
            <path
              id="Vector"
              d="m17.24 22c2.76 0 3.86-1.69 3.99-3.75l0.52-8.26c0.14-2.16-1.58-3.99-3.75-3.99-0.61 0-1.17-0.35-1.45-0.89l-0.72-1.45c-0.46-0.91-1.66-1.66-2.68-1.66h-2.29c-1.03 0-2.23 0.75-2.69 1.66l-0.72 1.45c-0.28 0.54-0.84 0.89-1.45 0.89-2.17 0-3.89 1.83-3.75 3.99l0.52 8.26c0.12 2.06 1.23 3.75 3.99 3.75h10.48z"
              fillRule="nonzero"
              stroke="#2C476C"
              strokeDasharray="0,0"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke-width="1.5"
            />
            <line
              id="Vector"
              x1="10.5"
              x2="13.5"
              y1="8"
              y2="8"
              fillRule="nonzero"
              stroke="#2C476C"
              strokeDasharray="0,0"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke-width="1.5"
            />
            <path
              id="a"
              d="m15.25 14.75c0-1.79-1.46-3.25-3.25-3.25s-3.25 1.46-3.25 3.25 1.46 3.25 3.25 3.25 3.25-1.46 3.25-3.25z"
              fillRule="nonzero"
              stroke="#2C476C"
              strokeDasharray="0,0"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke-width="1.5"
            />
            <path d="m24 0v24h-24v-24h24z" opacity="0" />
          </g>
        </g>
      </g>
    </svg>
  )
}
export default UploadPhotoIcon
