import React from "react";

const DragAndDropIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33333 21.8747C9.48393 21.8747 10.4167 20.9419 10.4167 19.7913C10.4167 18.6407 9.48393 17.708 8.33333 17.708C7.18274 17.708 6.25 18.6407 6.25 19.7913C6.25 20.9419 7.18274 21.8747 8.33333 21.8747Z" fill="#396CE8"/>
            <path d="M8.33333 14.5837C9.48393 14.5837 10.4167 13.6509 10.4167 12.5003C10.4167 11.3497 9.48393 10.417 8.33333 10.417C7.18274 10.417 6.25 11.3497 6.25 12.5003C6.25 13.6509 7.18274 14.5837 8.33333 14.5837Z" fill="#396CE8"/>
            <path d="M8.33333 7.29167C9.48393 7.29167 10.4167 6.35893 10.4167 5.20833C10.4167 4.05774 9.48393 3.125 8.33333 3.125C7.18274 3.125 6.25 4.05774 6.25 5.20833C6.25 6.35893 7.18274 7.29167 8.33333 7.29167Z" fill="#396CE8"/>
            <path opacity="0.4" d="M16.6666 21.8747C17.8172 21.8747 18.75 20.9419 18.75 19.7913C18.75 18.6407 17.8172 17.708 16.6666 17.708C15.5161 17.708 14.5833 18.6407 14.5833 19.7913C14.5833 20.9419 15.5161 21.8747 16.6666 21.8747Z" fill="#396CE8"/>
            <path opacity="0.4" d="M16.6666 14.5837C17.8172 14.5837 18.75 13.6509 18.75 12.5003C18.75 11.3497 17.8172 10.417 16.6666 10.417C15.5161 10.417 14.5833 11.3497 14.5833 12.5003C14.5833 13.6509 15.5161 14.5837 16.6666 14.5837Z" fill="#396CE8"/>
            <path opacity="0.4" d="M16.6666 7.29167C17.8172 7.29167 18.75 6.35893 18.75 5.20833C18.75 4.05774 17.8172 3.125 16.6666 3.125C15.5161 3.125 14.5833 4.05774 14.5833 5.20833C14.5833 6.35893 15.5161 7.29167 16.6666 7.29167Z" fill="#396CE8"/>
        </svg>
    );
};
export default DragAndDropIcon;
