import Phone from "../../../assets/imgs/phone.svg";
import Edit from "../../../assets/imgs/icons/edit.svg";
import Success from "../../../assets/imgs/success.svg";
import NotValid from "../../../assets/imgs/notValid.svg";
import Email from "../../../assets/imgs/email.svg";

export const PhoneEmailContainer = ({fields, handleChangePopupOpen, confirm, registrationMethod}) => {
    return (registrationMethod === 'Phone' ?
        <>
        <div
            className="email-phone">
            <div
                className={'phone-email-container'}>
                <img
                    style={{
                        width: '20px',
                        height: '20px',
                        marginRight:'10px',
                    }}
                    src={Phone}/>
                <span>
                    {fields['phoneCode']} {fields['phone']}
                </span>
            </div>
            <div
                onClick={() => handleChangePopupOpen('Phone')}
                className={'img edit'}>
                <img className={'img-account'} src={Edit}/>
            </div>
            <div
                className={'img'}>
                <img
                    className={'img-account'}
                    src={fields.isPhoneVerified ? Success : NotValid}/>
            </div>
        </div>
    {!fields.isPhoneVerified &&
    <div
        onClick={() => confirm('Phone')}
        className={'notVerified'}>
        Հաստատել
    </div>}
    <div
        style={{marginTop: '45px'}}
        className="email-phone">
        <div
            className={'phone-email-container'}>
            <img
                style={{
                    width: '20px',
                    height: '100%',
                    marginRight:'10px'
                }}
                src={Email}/>
            <span>{fields['email']}</span>
        </div>
        <div
            onClick={() => handleChangePopupOpen('Email')}
            className={'img edit'}>
            <img className={'img-account'} src={Edit}/>
        </div>
        <div
            className={'img'}>
            <img
                className={'img-account'}
                src={fields.isEmailVerified ? Success : NotValid}/>
        </div>
    </div>
            {!fields.isEmailVerified &&
                <div
                    onClick={() => confirm('Email')}
                    className={'notVerified'}>
                    Հաստատել
                </div>}
        </> :
        <>
            <div
                className="email-phone">
                <div
                    className={'phone-email-container'}>
                    <img
                        style={{
                            width: '20px',
                            height: '100%',
                            marginRight:'10px'
                        }}
                        src={Email}/>
                    <span>{fields['email']}</span>
                </div>
                <div
                    onClick={() => handleChangePopupOpen('Email')}
                    className={'img edit'}>
                    <img className={'img-account'} src={Edit}/>
                </div>
                <div
                    className={'img'}>
                    <img
                        className={'img-account'}
                        src={fields.isEmailVerified ? Success : NotValid}/>
                </div>
            </div>
            <div style={{marginTop: '45px'}}
                 className="email-phone">
                <div
                    className={'phone-email-container'}>
                    <img
                        style={{
                            width: '20px',
                            height: '20px',
                            marginRight:'10px',
                        }}
                        src={Phone}/>
                    <span>
                    {fields['phoneCode']} {fields['phone']}
                </span>
                </div>
                <div onClick={() => handleChangePopupOpen('Phone')}
                    className={'img edit'}>
                    <img className={'img-account'} src={Edit}/>
                </div>
                <div className={'img'}>
                    <img
                        className={'img-account'}
                        src={fields.isPhoneVerified ? Success : NotValid}/>
                </div>
            </div>
            {!fields.isPhoneVerified &&
                <div
                    onClick={() => confirm('Phone')}
                    className={'notVerified'}>
                    Հաստատել
                </div>}
        </>
    )
}
