import {
    SET_EXTERNAL_PRESENTATION_MOD_DATA,
    SET_IS_PRESENTATION_MODE,
    SET_PRESENTATION_MODE,
    SET_PRESENTATION_MODE_LOADING,
    SET_SELECTED_PRESENTATION,
} from "../constants";

const initialState = {
    data: {},
    selectedPresentation: {},
    externalPresentation: [],
    isLoading: false,
    isPresentationMode: false,
};

export const presentationModeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_PRESENTATION:
            return {
                ...state,
                isLoading:false,
                selectedPresentation: action.payload,
            };
        case SET_PRESENTATION_MODE:
            return {
                ...state,
                isLoading:false,
                data: action.payload,
            };
        case SET_EXTERNAL_PRESENTATION_MOD_DATA:
            return {
                ...state,
                externalPresentation: action.payload,
            };
        case SET_PRESENTATION_MODE_LOADING:
            return {
                ...state,
                data: action.payload,
            };
        case SET_IS_PRESENTATION_MODE:
            return {
                ...state,
                isPresentationMode: action.payload,
            };
        default:
            return state;
    }
};
