import React, { Component } from "react";
import "./style.css";
import About from "../about";
import { Link } from "react-router-dom";

class Account extends Component {
  constructor() {
    super();
    this.state = {
      about: false,
    };
  }

  showPopup = () => {
    this.setState({about: !this.state.about})
  }
  render() {
    const { onlyBG, children } = this.props;
    const { about } = this.state;
    const { showPopup } = this;

    return (
      <>
        <div className="account">
          <div
            className="account"
            style={{
              zIndex: -1,
            }}
          ></div>
          {!onlyBG && (
            <header className="account-header">
              <div>
                <button
                  className="account-about"
                  type="button"
                  onClick={showPopup}
                >
                  նախագծի մասին
                </button>
              </div>
              <div>
                <h1 className="account-title">աշխարհացույց</h1>
              </div>
            </header>
          )}
          {children}
          {!onlyBG && (
            <footer className="account-footer">
              <p className="account-powered">powered by brainograph</p>
            </footer>
          )}
        </div>
        {about && <About handleAbout={showPopup} />}
      </>
    );
  }
}

export default Account;
