import React from "react"

const AccountBoards = ({active=false}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 22 22"
      stroke={active? '#FF6539' :'#2c476c'}
    >
      <path
        d="M7.792,2.044C7.792.587,7.205,0,5.748,0h-3.7C.587,0,0,.587,0,2.044v3.7C0,7.205.587,7.792,2.044,7.792h3.7c1.458,0,2.044-.587,2.044-2.044Z"
        transform="translate(12.375 1.833)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M7.792,1.815C7.792.523,7.205,0,5.748,0h-3.7C.587,0,0,.523,0,1.815V5.968c0,1.3.587,1.815,2.044,1.815h3.7c1.458.009,2.044-.513,2.044-1.806Z"
        transform="translate(1.833 1.833)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M7.792,2.044C7.792.587,7.205,0,5.748,0h-3.7C.587,0,0,.587,0,2.044v3.7C0,7.205.587,7.792,2.044,7.792h3.7c1.458,0,2.044-.587,2.044-2.044Z"
        transform="translate(1.833 12.375)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M0,0H5.5"
        transform="translate(13.292 16.042)"
        fill="none"
        strokeLinecap="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M0,5.5V0"
        transform="translate(16.042 13.292)"
        fill="none"
        strokeLinecap="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path d="M22,0V22H0V0Z" fill="none" opacity="0" />
    </svg>
  )
}
export default AccountBoards
