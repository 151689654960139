import {useState} from "react";
import Popup from '../Popup'
import {TextInput} from "../TextInput";
import {Button} from "../Button";

import './style.css';
import {checkUserPasswordAPI, deleteUserAPI} from "../../../service/user";
import {LogOut} from "../../../helpers";


const AccountPageModal = (props) =>{
    const {handleDeleteUserAccountModal,deleteUserAccountModal} = props
    const [pass,setPass] = useState('')
    const [passError,setPassError] = useState('')
    const handleChange = (e) =>{
        if(passError) setPassError('')
        setPass(()=>e.target.value)
    }
    const handleSetError = () =>{
        if(pass) return setPassError(()=>('Խնդրում ենք մուտքագրել վավեր գաղտնաբառ'))
        setPassError('Պարտադիր դաշտ')
    }
    const checkUserPassword = async (password) =>{
        let flag = false
        if(!password) return false
        await checkUserPasswordAPI({password})
                .then(resp=>{
                    flag = resp?.data?.accepted
                })
                .catch(err=>{
                    flag = false
                })
        return flag
    }
    const deleteUser = (password) =>{
        checkUserPassword(password)
            .then(resp=>{
                if(!resp) return handleSetError()
                deleteUserAPI()
                    .then(()=>{
                        LogOut()
                    })
            })
    }
    return (<Popup  isOpened={deleteUserAccountModal}
                    onClose={()=>handleDeleteUserAccountModal(false)}
                    styleOverlayUser={{ backgroundColor: "#0000008C" }}>
                <div className={'account-delete-modal-box'}>
                    <h3>Ջնջել Հաշիվը</h3>
                    <p>Դուք ցանկանու՞մ եք ջնջել Ձեր հաշիվը: Մուտքագրեք գաղտնաբառը:</p>
                    <div
                        style={{width: '80%'}}>
                        <TextInput
                            hasError={passError}
                            onChange={handleChange.bind(this)}
                            className='account-input black-input'
                            placeholder='գաղտնաբառ*'
                            type="password"
                            black={true}
                        />
                    </div>
                    <div
                        className='popup-button-container'>
                        <Button
                            className="user-form-btn save"
                            action={deleteUser.bind(this,pass)}
                            text="Հաստատել"
                        />
                        <Button
                            className="user-form-btn link"
                            action={()=>handleDeleteUserAccountModal(false)}
                            text="Չեղարկել"
                        />
                    </div>
                </div>
            </Popup>)
}
export default AccountPageModal
