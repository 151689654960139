import React, {useEffect, useState} from "react";
import './style.css'
export const ResendCode = (props) => {
    const [count, setCount] = useState(30);
    const [enabled, setEnabled] = useState(false)
    const resendButtonClassName =
        enabled ? (props.black ? 'resendTextEnabled black' : 'resendTextEnabled') : (props.black ? 'resendText black' : 'resendText');

    const handleClick = () => {
        setEnabled(false)
        setCount(30)
        props.action()
    }

    useEffect(() => {
        if (count > 0) {
            const timer = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        } else {
            setEnabled(true)
        }
    }, [count]);
    return(
        <div
        className={'resend-container'}>
            <span
            className={props.black ? 'firstText black' : 'firstText'}>
                Չե՞ք ստացել հաստատման ծածկագիրը։
            </span>
            <button
                className={resendButtonClassName}
                onClick={handleClick}
                disabled={!enabled}>
                ԿՐԿԻՆ ՈՒՂԱՐԿԵԼ
            </button>
            <span
                className={props.black ?'countDown black' : 'countDown'}>
                00:{count < 10 ? `0${count}` : count}
            </span>
        </div>
    )
}
