import { SET_AUDIO_PLAYING } from "../constants";

export const setAudioPlaying = (bool) => ({
    type: SET_AUDIO_PLAYING,
    payload: bool,
});

export const setAudioPlayingFunc = (bool) => (dispatch) => {
    dispatch(setAudioPlaying(bool));
};
