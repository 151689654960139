import React from 'react'

const Plus = () => {
    return (
         <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="zoom-in-blue" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <g id="add">
            <line x1="6" y1="12" x2="18" y2="12" id="Vector" stroke="#2C476C" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0"></line>
            <line x1="12" y1="18" x2="12" y2="6" id="Vector" stroke="#2C476C" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0"></line>
            <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0"></path>
        </g>
    </g>
</svg>
    )
}
export default Plus
