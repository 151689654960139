import React, { Component } from "react";
import Account from "../account";
import "./style.css";
import SuccessCheck from "../../components/UI/SuccessCheck";
import {Button} from "../../components/UI/Button";
import {useNavigate, useParams} from "react-router-dom";

export const withNavigation = (Component: Component) => {
    return (props) => {
        return (
            <Component {...props} params={useParams()} navigate={useNavigate()} />
        );
    };
};

class EmailSend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signUp: null,
        };
    }
    componentDidMount() {
        let body = localStorage.getItem("signUpForm");
        if (JSON.parse(body) === null || JSON.parse(body) === undefined) {
            window.location.href = "/signup";
        }else{
            this.setState({
                signUp: JSON.parse(body),
            });
        }
    }
  render() {
    return (
      <Account>
        <div className="account-content-email-send">
          <SuccessCheck />
          <p className="check-text">ՇՆՈՐՀԱԿԱԼՈՒԹՅՈՒՆ</p>
          <p className="check-text text-description">
              Ձեր գրանցումը Աշխարհացույց կրթական հարթակում հաջողությամբ իրականացվել է: <br/>
              Խնդրում ենք ամբողջականացնել Ձեր հաշիվը
          </p>
            <div
                className={'complete-btn-box'}>
                <Button
                    action={() => this.props.navigate('/account-info')}
                    text='Ամբողջականացնել'/>
            </div>
        </div>
      </Account>
    );
  }
}

export default withNavigation(EmailSend)
