import {SET_SPRITE_DATA, SET_SPRITE_URL} from "../constants";
import {getSpriteAPI, getSpriteURLAPI} from "../../service/sprite";

export const setSpriteData = (data) => ({
  type: SET_SPRITE_DATA,
  payload: data,
});
export const setSpriteUrl = (data) => ({
  type: SET_SPRITE_URL,
  payload: data,
});

export const setSprite = (bool) => (dispatch) => {
  dispatch(setSpriteData(bool));
};
export const getSpriteFromAPI = (url) => async (dispatch, getState) => {
  await getSpriteAPI(url)
      .then( data => {
        dispatch(setSpriteData(data))
      })
}
export const getSpriteURLFromAPI = (styleId=1) => async (dispatch, getState) => {
  return  getSpriteURLAPI(styleId)
      .then( (url) => {
         dispatch(setSpriteUrl(url))
         return url
      })
}