import axios from "axios";
import {
  checkUserPasswordURI,
  deleteUserURI,
  genderOptions,
  specialityOptions,
  userInfoURI,
} from "../shared/mockData";
import {
  getGradeByID,
  getSchoolByID,
  getSubjectByID,
} from "../pages/accountPage/utils";
import IdentityAPI from "../server/IdentityAPI";

const extractData = (res) => {
  const { data } = res
  if (data.accepted && data.data[0]) {
    if (data.data && data.data[0]) {
      return data.data[0]
    }
  }
  return []
}
const sanitizeUserInfo = async (data) => {
  const userData = {
    id: data.id,
    avatar: data.userInfo.avatar,
    mediaId: data.userInfo.mediaId,
    firstName: data.userInfo?.firstName,
    lastName: data.userInfo?.lastName,
    countryId: data.userInfo.countryId,
    cityId: data.userInfo.cityId,
    city: data.userInfo.city,
    schoolName: data.userInfo.schoolName,
    speciality: specialityOptions.find(
      (x) => x.text === data.userInfo?.userType
    ),
    phone: data.phoneNumber,
    phoneCode: data.phoneNumberCountryCode,
    email: data.email,
    gender: genderOptions.find((x) => x.value === data.userInfo?.gender),
    birthDate: data.userInfo?.birthDate,
    subject: [],
    isPhoneVerified: data.phoneNumberConfirmed,
    isEmailVerified: data.emailConfirmed,
    occupation: data.userInfo.occupation,
    registeredMethod: data.userInfo.registrationMethod,
    status: data.status,
  };

  if (userData.speciality.text === "Student" && userData.countryId == 11) {
    await getGradeByID(data.userInfo.gradeId).then((gradeResult) => {
      userData.grade = {
        value: gradeResult.data.data[0]?.id,
        label: gradeResult.data.data[0]?.title,
      };
    });
  }
  if (userData.speciality.text === "Teacher" && userData.countryId == 11) {
    data.userInfo?.subjectIds.forEach((subjectID) => {
      getSubjectByID(subjectID).then((subjectResult) => {
        if(Array.isArray(subjectResult.data.data[0])){
          userData.subject.push({
            value: subjectResult.data.data[0][0]?.id,
            label: subjectResult.data.data[0][0]?.title,
          });
        }
      });
    });
  }
  if(userData.speciality.text !== "Other" && userData.countryId == 11) {
    await getSchoolByID (data.userInfo.schoolId).then ((response) => {
      const result = response.data.data[0];
      userData.country = {
        value: result?.city?.state?.countryId,
        label: result?.city?.state?.country?.title,
      };
      userData.region = {
        value: result?.city?.stateId,
        label: result?.city?.state?.title,
      };
      userData.state = {
        value: result?.cityId,
        label: result?.city?.title,
      };
      userData.school = {
        value: result?.id,
        label: result?.title,
      };
    })
  } else {
      userData.country = {
        value: userData?.city?.state?.countryId,
        label: userData?.city?.state?.country?.title,
      };
      userData.region = {
        value: userData?.city?.stateId,
        label: userData?.city?.state?.title,
      };
      userData.state = {
        value: userData?.cityId,
        label: userData?.city?.title,
      };
      if(userData.schoolName){
        userData.school = {
          value: -1,
          label: userData?.schoolName,
        };
      }
  }
  return userData;
};

export const getUserAPI = () => {
  return axios
    .get(userInfoURI)
    .then((res) => {
      if (!res.data.accepted) {
        return [];
      }
      if(res.data.data[0].status !== 'Active' && window.location.pathname !== "/account-info" && window.location.pathname !== "/email-send"){
        window.location.href = "/account-info";
      }
      return res.data.data[0];
    })
    .then(sanitizeUserInfo)
    .catch(console.log);
};
export const checkUserPasswordAPI = (body) => {
  return IdentityAPI
      .post(`${checkUserPasswordURI}/${body.password}`,body)
}
export const deleteUserAPI = () => {
  return IdentityAPI
      .delete(deleteUserURI)
      .then(extractData)
}

