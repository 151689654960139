import React from "react";

const CategoryFilter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        d="M9.5,0a1.254,1.254,0,0,1,1.25,1.25V2.625a2.333,2.333,0,0,1-.625,1.438L7.437,6.437a2.035,2.035,0,0,0-.625,1.438v2.687a1.392,1.392,0,0,1-.562,1.063l-.875.563a1.274,1.274,0,0,1-1.938-1.062V7.813a2.416,2.416,0,0,0-.5-1.312L.563,4A2.072,2.072,0,0,1,0,2.75V1.313A1.268,1.268,0,0,1,1.25,0Z"
        transform="translate(2.125 1.312)"
        fill="none"
      />
      <path
        d="M3.081,0,0,4.938"
        transform="translate(3.75 1.312)"
        fill="none"
      />
      <path d="M15,0V15H0V0Z" fill="none" opacity="0" />
    </svg>
  );
};
export default CategoryFilter;
