import React, { memo, useEffect, useState } from "react";
import EyeLamp from "../../UI/EyeLamp";
import Eye from "../../UI/Eye";
import ConnectedSpriteImageComponent from "../../UI/ConnectedSpriteImageComponent";

const LampItem = ({
  data,
  iconPath,
  getArticleFromAPI,
  changeLampDataShow,
  mainlampSubcategry,
  subCategoryData,
  toggleItemsFromMap,
  getLampSelectedId,
  setLampSelectedId,
}) => {
  const onChangeStatus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    changeLampDataShow(data.id);
  };

  useEffect(() => {
    if(getLampSelectedId) getArticleFromAPI(getLampSelectedId);
  }, [getLampSelectedId]);

  return (
    <li
      className={`main__subitem-item main__subitem-item_lamp`}
      style={{
        opacity: data.isHide ? 0.5 : 1,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        getArticleFromAPI(data.id);
        setLampSelectedId(data.id);
      }}
    >
      <div className="hints__subitem_item hints__subitem_item_lamp">
        <div className="hints__subitem_item_lamp_content">
          <div className="with-svg">
            <ConnectedSpriteImageComponent name={subCategoryData?.key}/>
            {/*<img src={iconPath} />*/}
            {/* <Eye show={true} /> */}
          </div>
          <div
            className="main__subitem-btn main__subitem-btn_lamp"
            type="button"
          >
            {data?.content[0].mainTitle}
          </div>
        </div>
        <label
          id={`main__subitem-label_lamp_${data.id}`}
          className="main__subitem-label main__subitem-label_lamp"
          data-show={!data.isHide}
          onClick={onChangeStatus}
        >
          <EyeLamp className="eye_lamp_icon" show={!data.isHide} />
        </label>
      </div>
    </li>
  );
};

export default LampItem;
