import ErrorMsg from "../errorMsg";
import React from "react";
import { dropDownCustomStyles } from "../../shared/mockData";
import Select from "react-select";

export const SelectBox = ({
  dropDownStyles = dropDownCustomStyles,
  contentClassName = "input-wrapper",
  className = "account-input",
  isMulti = false,
  options,
  placeholder,
  onChange,
  value,
  hasError,
}) => {
  return (
    <div className={`${contentClassName} ${hasError ? "error" : ""}`}>
      <div className={className}>
        <Select
          className={"select"}
          options={options}
          value={value}
          isMulti={isMulti}
          isSearchable={false}
          autoFocus={false}
          menuPlacement="bottom"
          placeholder={placeholder}
          styles={dropDownStyles}
          onChange={onChange}
          noOptionsMessage={() => "Տարբերակ չկա"}
        />
      </div>
      {/*{hasError ? (*/}
      {/*  <>*/}
      {/*    <button className="error-btn" type="button" />*/}
      {/*    <ErrorMsg text={hasError} />*/}
      {/*  </>*/}
      {/*) : null}*/}
      {hasError && <span className={'error-text'}>* {hasError}</span>}

    </div>
  );
};
