import {Splide, SplideSlide} from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import React, {useCallback, useEffect, useRef, useState, useMemo} from "react";
import {connect, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import LampSortedSelectIcon from "../../assets/imgs/checked-icon.svg";
import CloseIcon from "../../assets/imgs/close_blue_lamp_modal.svg";
import EyeWhiteClose from "../../assets/imgs/eye-white-close.svg";
import EyeWhiteOpen from "../../assets/imgs/eye-white-open.svg";
import LampActive from "../../assets/imgs/lamp_icon_article.svg";
import LampIconMenu from "../../assets/imgs/lamp_icon_menu.png";
import QuizPassive from "../../assets/imgs/quiz-passive.svg";
import QuizActive from "../../assets/imgs/quiz_icon_article.svg";
import SortBlue from "../../assets/imgs/sorting-blue.svg";
import SortWhite from "../../assets/imgs/sorting-white.svg";
import {getArticleFromAPI} from "../../store/actions/articles";
import {setHints} from "../../store/actions/hints";
import {
    setLamp,
    setLampModalData,
    setLampModalState,
    setLampModalStateIndex,
    setSortedTooltipSelectItem,
} from "../../store/actions/lamp";
import {setMapRequirement} from "../../store/actions/mapRequirement";
import {changeMapRequirements} from "../../store/actions/mapRequirements";
import {
    setCategoriesClickedArticleId,
    setLampSelectedId,
    setLampSortedTooltipState,
    setMapRequiredScreenShot,
    setOpenCategoryID,
} from "../../store/actions/mapStateAction";
import {setMenuExpend} from "../../store/actions/menuExpend";
import {
    getSelectedQuizDataFromApi,
    setQuizModalData,
    setQuizModalState,
    setSelectedQuizData,
    setSortedTooltipSelectItemQuiz,
} from "../../store/actions/quiz";
import {setQuizPopup} from "../../store/actions/quizPopup";
import {setTimeLineExpend} from "../../store/actions/timeLineExpend";
import {
    getCategoriesData,
    getFilteredCategoriesData,
    getFilteredCategoriesDataST,
    getFilteredLegendsData,
    getHintsData,
    getLampData,
    getLampDataFilteredScreenShot,
    getLampModalData,
    getLampModalState,
    getLampModalStateIndex,
    getLampSelectedId,
    getLampSortedTooltipState,
    getLegends,
    getLegendsLoading,
    getMapRequirementData,
    getMapRequirementsData,
    getMenuExpendData,
    getOpenCategoryID,
    getQuizData,
    getQuizModalData,
    getQuizModalState,
    getQuizPopupData, getScreenShotLoadingST,
    getSelectedQuizData,
    getSortedTooltipSelectItem,
    getTestFilteredCategories,
    getTimeLineExpendData
} from "../../store/selectors";
import Quiz from "../quiz";
import {isNotEmpty} from "../timeline";
import Expend from "../UI/Expend";
import Eye from "../UI/Eye";
import Popup from "../UI/Popup";
import Requirements from "../UI/Requirements";
import Sort from "../UI/Sort";
import Flag from "../UI/Flag";
import LampItem from "./LampItem/LampItem";
import QuizItem from "./QuizItem/QuizItem";
import {filterCategory, filterLamp} from "./utils/filtring";

import Collapsible from 'react-collapsible';
import "./style.css";
import Text from "../UI/Text";
import ConnectedSpriteImageComponent from "../UI/ConnectedSpriteImageComponent";
import SideBarClock from "../../assets/imgs/sidebar_clock";
import {setFilteredLegends} from "../../store/actions/legends";
import {setFilteredCategories} from "../../store/actions/categories";
import {delay} from '../../utils/utils'
import EyeLamp from "../UI/EyeLamp";

export const LeftSide = ({
                             leftBarSTF,
                             menuExpend,
                             setSortedTooltipSelectItem,
                             filteredCategories,
                             mapRequirements,
                             setMenuExpend,
                             toggleItemsFromMap,
                             toggleItemsFromTimeLine,
                             toggleLegendsFromMapByID,
                             lampData,
                             quizData,
                             hints,
                             quizPopup,
                             mapRequirement,
                             setHints,
                             setQuizPopup,
                             setMapRequirement,
                             getArticleFromAPI,
                             setMapRequirements,
                             sortedTooltipSelectItem,
                             getCategories,
                             setTimeLineExpend,
                             timelineExtend,
                             lampModalState,
                             lampModalData,
                             timeLine,
                             setLampModalState,
                             toggleLampFromMap,
                             getSelectedQuizDataFromApi,
                             selectedQuiz,
                             setSelectedQuizData,
                             getSelectedQuizData,
                             legends,
                             filteredLegends,
                             loadingLegends,
                             setOpenCategoryID,
                             getOpenCategoryID,
                             setMapRequiredScreenShot,
                             setCategoriesClickedArticleId,
                             getLampDataFilteredScreenShot,
                             getLampSortedTooltipState,
                             setLampSortedTooltipState,
                             getTestFilteredCategories,
                             setLampSelectedId,
                             getLampSelectedId,
                             stopPinAnimation,
                             setLampModalStateIndex,
                             getLampModalStateIndex,
                             setLamp,
                             filteredCategoriesST,
                             toggleItemFromMap,
                             getScreenShotLoadingST,
                             globalLoading
                         }) => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const isScreenShot = query.get("screenShot");
    const dispatch = useDispatch();
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState([]);

    const [sortedTooltipState, setSortedTooltipState] = useState(false);
    const [lampEyeIconState, setLampEyeIconState] = useState(false);
    const [lampDataState, setLampDataState] = useState([]);
    const [quizDataState, setQuizDataState] = useState([]);

    const [sortBoard, setSortBoard] = useState(false);
    //fil
    const [filterAction, setFilterAction] = useState("alphabetically");
    const [filterActionFirstLoad, setFilterActionFirstLoad] = useState(true);
    const [lampFilterActionFirstLoad, setLampFilterActionFirstLoad] =
        useState(true);
    const [filteredCategoriesIsMounted, setFilteredCategoriesIsMounted] = useState(false)
    const [lampSliderMoveMounted, setLampSliderMoveMounted] = useState(false);

    const lampModalRef = useRef();
    const sideBarRef = useRef();
    const quizModalRef = useRef();
    const legendMainBtnRef = useRef();
    const categoryMainBtnRefs = useRef([]);
    const categoryModalRef0 = useRef();
    const categoryModalRef1 = useRef();
    const categoryModalRef2 = useRef();
    const categoryModalRef3 = useRef();
    const scrollZone = useRef();
    const lampScrollZone = useRef();
    const quizScrollZone = useRef();
    const lampSplideRef = useRef();



    const [hintsFirstLoad, setHintsFirstLoad] = useState(true);

    const browserInfo = useMemo(() => {
        const isMozilla = navigator.userAgent.toLowerCase().includes('firefox');
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        return {
            isMozilla,
            isSafari,
        };
    }, []);

    useEffect(() => {
        !hints && setLampSortedTooltipState(false);
    }, [hints]);

    useEffect(() => {
        if(hintsFirstLoad && isScreenShot && Object.keys(getLampDataFilteredScreenShot).length > 0){
            const isHideCount = Object.keys(getLampDataFilteredScreenShot).length
            if (isHideCount === lampData.length) {
                setLampEyeIconState(true);
            } else {
                setLampEyeIconState(false);
            }
            setHintsFirstLoad(false);
        }
    }, [hints,getLampDataFilteredScreenShot]);

    useEffect(() => {
        const arrows = document.getElementsByClassName("splide__arrows")[0];
        if (!arrows) return;
        if (lampModalState) {
            if (lampModalData?.length > 1) {
                arrows.style.display = "block";
                addSliderCounter(arrows);
            } else {
                arrows.style.display = "none";
            }
        }
    }, [getLampModalStateIndex, lampModalData, lampModalState]);

    useEffect(() => {
        if (!lampSliderMoveMounted && lampModalData?.length > 1 && lampSplideRef.current && lampModalState && getLampModalStateIndex !== undefined && getLampModalStateIndex !== null) {
            lampSplideRef.current?.go(getLampModalStateIndex);
            setLampSliderMoveMounted(true)
        }
    }, [lampSplideRef, getLampModalStateIndex, lampModalData, lampModalState, lampSliderMoveMounted]);

    const addSliderCounter = (arrows) => {
        !!document.getElementsByClassName("counter_element")[0] &&
        document.getElementsByClassName("counter_element")[0].remove();

        const counterElement = document.createElement("div");
        counterElement.classList.add("counter_element");

        const elInnerFirst = document.createElement("span");
        elInnerFirst.classList.add("first_counter");
        elInnerFirst.innerText = `${getLampModalStateIndex + 1} `;

        const elInnerSlash = document.createElement("span");
        elInnerSlash.classList.add("slash_counter");
        elInnerSlash.innerText = "/";

        const elInnerSecond = document.createElement("span");
        elInnerSecond.classList.add("first_counter");
        elInnerSecond.innerText = ` ${lampModalData.length}`;

        counterElement.appendChild(elInnerFirst);
        counterElement.appendChild(elInnerSlash);
        counterElement.appendChild(elInnerSecond);
        arrows.appendChild(counterElement);
    };

    useEffect(() => {
        const prev = document.getElementsByClassName("splide__arrow--prev")[0];
        const next = document.getElementsByClassName("splide__arrow--next")[0];

        if (lampModalState) {
            prev?.addEventListener("click", onPrevHandle);
            next?.addEventListener("click", onNextHandle);
        }

        return () => {
            prev?.removeEventListener("click", onPrevHandle);
            next?.removeEventListener("click", onNextHandle);
        };
    }, [lampModalState]);

    const onPrevHandle = () => {
        let index = getLampModalStateIndex - 1

        if (index < 1) {
            return;
        }
        const counterEl = document.getElementsByClassName("first_counter")[0];
        counterEl.innerText = `${index + 1} `;
    };
    const onNextHandle = () => {
        let index = getLampModalStateIndex + 1
        if (index > lampModalData.length) {
            return;
        }
        const counterEl = document.getElementsByClassName("first_counter")[0];
        counterEl.innerText = `${index + 1} `;
    };
    const hideLampItems = useCallback(()=>{
        Object.values(getLampDataFilteredScreenShot).forEach(value=>{
            changeLampDataShow(value.id,true)
        })
    },[getLampDataFilteredScreenShot])

    useEffect(() => {
        if (lampDataState.length > 0 && isScreenShot && Object.keys(getLampDataFilteredScreenShot).length > 0) {
            hideLampItems()
        }
    }, [getLampDataFilteredScreenShot]);
    useEffect(() => {
        if (lampData.length > 0) {
            setLampDataState([...lampData])
        }
    }, [lampData]);

    useEffect(() => {
        quizData.length > 0 && setQuizDataState(quizData);
    }, [quizData]);

    const changeLampDataShow = (id,action) => {
        let isHideCount = 0
        setLampDataState(
            lampDataState.map((item) => {
                if (item.id === id) {
                    item.isHide = action !== void 0 ? action : !item.isHide;
                    const lampPin = document.getElementsByClassName(`lamp_pin_${id}`)[0];
                    if (lampPin?.classList.contains(`show_lamp_article`)) {
                        lampPin?.classList.remove("show_lamp_article");
                        lampPin?.classList.add("hide_lamp_article");
                    } else {
                        lampPin?.classList.remove("hide_lamp_article");
                        lampPin?.classList.add("show_lamp_article");
                    }
                    // toggleLampFromMap(item.id,!item.isHide)
                    toggleItemsFromMap(
                        item?.subcategory[0].categoryId,
                        item?.subcategory[0].subCategoryId,
                        'lamp',
                        item.id,
                        item.elementIds,
                        null,
                        !item.isHide)
                }
                if (item.isHide) isHideCount++
                return item;
            })
        );
        // const isHideCount = lampDataState.filter((item) => item.isHide);
        if (isHideCount === lampData.length) {
            setLampEyeIconState(true);
        } else {
            setLampEyeIconState(false);
        }
    };

    const onLampHeaderEyeClick = () => {
        const lampPin = document.getElementsByClassName("lamp_pin");
        setLampEyeIconState(!lampEyeIconState);
        for (let i = 0; i < lampPin.length; i++) {
            lampPin[i].classList.add(
                !lampEyeIconState ? "hide_lamp_article" : "show_lamp_article"
            );
            lampPin[i].classList.remove(
                lampEyeIconState ? "hide_lamp_article" : "show_lamp_article"
            );
        }
        setLampDataState(
            lampDataState.map((item) => {
                item.isHide = !lampEyeIconState;
                // toggleLampFromMap(item.id,!item.isHide)
                toggleItemsFromMap(
                    item?.subcategory[0].categoryId,
                    item?.subcategory[0].subCategoryId,
                    'lamp',
                    item.id,
                    item.elementIds,
                    null,
                    !item.isHide)
                return item;
            })
        )

    };

    useEffect(() => {
        setSortedTooltipSelectItem(1);

        setFilterAction("alphabetically");

        setSelectedCategoryId(null);
    }, [menuExpend]);

    const onChangeSortedItem = (close = true, index) => {
        setFilterAction("alphabetically");

        setSortedTooltipSelectItem(index);
        close && setLampSortedTooltipState(false);
    };

    const openSubCategory = (id) => {
        const subCategories = selectedSubCategoryId;
        if (!subCategories.includes(id)) {
            subCategories.push(id);
        } else {
            subCategories.splice(subCategories.indexOf(id), 1);
        }
        setSelectedSubCategoryId([]);
        setTimeout(() => {
            setSelectedSubCategoryId(subCategories);
        }, 1);
    };

    const onShowSortedTooltip = () => {
        setLampSortedTooltipState(!getLampSortedTooltipState);
    };

    useEffect(() => {
        if (getOpenCategoryID) {
            setSortedTooltipSelectItem(1);
            setFilterAction("alphabetically");
            setSelectedCategoryId(getOpenCategoryID);
            setOpenCategoryID(getOpenCategoryID);
        }
    }, [getOpenCategoryID]);

    const openCategory = (id) => {
        if (id === selectedCategoryId) {
            setSortedTooltipSelectItem(1);

            setFilterAction("alphabetically");
            setOpenCategoryID(null);

            return setSelectedCategoryId(null);
        }
        setSortedTooltipSelectItem(1);

        setFilterAction("alphabetically");
        setSelectedCategoryId(id);
        setOpenCategoryID(id);
        setMapRequiredScreenShot(null);
    };
    const checkSortOutClick = (e) => {
        if (
            !e.target.closest(".sort-board") &&
            !e.target.closest(".main__subitem-sort")
        ) {
            setSortBoard(false);
            document.removeEventListener("mousemove", checkSortOutClick);
        }
    };

    const toggleSorting = (toggle, action) => {
        if (action) {
            setFilterAction(() => action);
        }
        setSortBoard(toggle);
    };
    //fil
    const subItemsRend = useCallback(
        (item) => {
            let noFilterData = item;
            let data = item.subCategories.reduce((acum, subItem, subIndex) => {
                acum.push(
                    ...subItem?.articles?.filter((articleItem, artIndex) => {
                        if (articleItem.isUniq) {
                            articleItem.subIndex = subIndex;
                            articleItem.subItem = subItem;
                            return articleItem;
                        }
                    })
                );
                return acum;
            }, []);
            return filterCategory(filterAction, data);
        },
        [filterAction, filteredCategoriesST]
    );

    const toggleCollapsible = (key, bool) => () => {
        const data = filteredLegends;
        (data?.[0]?.children || []).forEach(child => {
            if (child.key === key) child.closed = !bool
        })
        dispatch(setFilteredLegends(data));
    }

    useEffect(() => {
        getTestFilteredCategories?.map((item, index) => {
            subItemsRend(item).map((articleItem, artIndex) => {
                if (articleItem.show) {
                    toggleItemsFromMap(
                        item.id,
                        articleItem.subItem.id,
                        "article",
                        articleItem.id
                    );

                    // toggleItemsFromMap(item.id, null, "category");
                }
            });
        });
    }, [getTestFilteredCategories]);


    useEffect(() => {
        if (filteredCategoriesST?.length && isScreenShot && !filteredCategoriesIsMounted) {
            setTimeout(() => {
                filteredCategoriesST.forEach((item) => {
                    if (!item.show) {
                        toggleItemFromMap(item.id, "category", item.show);
                    } else {
                        subItemsRend(item).forEach(articleItem => {
                            // if (articleItem.show) {
                            //     toggleItemsFromMap(
                            //         item.id,
                            //         articleItem.subItem.id,
                            //         'article',
                            //         articleItem.id,
                            //         articleItem.elementsIds
                            //     )
                            // }
                            if (!articleItem.show) {
                                toggleItemFromMap(item.id, "article", !articleItem.show, articleItem.subItem?.id, articleItem.id, articleItem.elementsIds, true);
                            }
                        })
                    }

                })
            }, 100)
            setFilteredCategoriesIsMounted(true)
        }

    }, [filteredCategoriesST])

    const lampRend = useCallback(
        (item) => {
            return filterLamp(filterAction, item);
        },
        [filterAction]
    );
    //fil
    useEffect(() => {
        if (sortBoard) {
            document.addEventListener("mousemove", checkSortOutClick);
        }
        return () => {
            document.removeEventListener("mousemove", checkSortOutClick);
        };
    }, [sortBoard]);
    useEffect(() => {
        if (selectedCategoryId) {
            setSortedTooltipSelectItem(1);

            setFilterAction("alphabetically");
            setSelectedCategoryId(null);
            setOpenCategoryID(null);
        }
    }, [timelineExtend]);

    const calculateHeight = (e) => {
        if (!e) return
        let result = 0;
        const windowHeight = (window.innerHeight * 100) / 70;
        const {top, height} = e.getBoundingClientRect();
        // result += windowHeight - e.clientY*100/70 - 106.5 - 20
        result += windowHeight - top - height - 135.5;
        return result !== 0 ? result : 0;
    };

    const [isLandscape, setIsLandscape] = useState(
        Math.abs(window.orientation) === 90
    );

    useEffect(() => {
        const handleOrientationChange = () => {
            if (Math.abs(window.orientation) === 90) {
                setIsLandscape(true);
            } else {
                setIsLandscape(false);
            }
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);
    const onCloseLampSlide = () => {
        setLampModalState(false);
        setLampModalStateIndex(null);
        setLampModalData([]);
        // setLamp([])
    }

    return (
        <div
            style={{
                width: isLandscape && !menuExpend ? "calc(36px / 0.7)" : "unset",
            }}
            className="category_menu_button"
        >
            <div ref={sideBarRef} className={`main__left left-side ${sideBarRef.current?.clientWidth > 380 ?'left-to-right':'right-to-left'} ${menuExpend && 'first__main_btn'} `}>
                <div
                    className={`categories-collapse main__tab ${
                        menuExpend ? "active" : ""
                    }`}
                    data-is-loading={globalLoading}
                    style={{'--loader-color':'#ececec '}}
                >
                    <button
                        className='main__tab-btn'
                        type="button"
                        onClick={() => setMenuExpend(!menuExpend)}
                    >
                        <Expend/>
                        {lampData.length > 0 && !menuExpend ? (
                            <div
                                className={"lampIcon"}
                                style={{
                                    position: "absolute",
                                    top: -10,
                                    right: -10,
                                    background: "#FFB207",
                                    borderRadius: 40,
                                    padding: "3px 9px",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img src={LampIconMenu}/>
                            </div>
                        ) : null}
                    </button>
                </div>
                <div className={`main__tabs-wrapper ${menuExpend ? "active" : ""}`}>
                    <ul className="main__tabs left-side__tabs">
                        {filteredLegends?.map((item, index) => {
                            return (
                                <li
                                    key={item.id + 'legend'}
                                    className={`main__tab persons legend ${
                                        selectedCategoryId === item.id &&
                                        'active'
                                    }`}
                                    data-is-loading={globalLoading}
                                >
                                    <button
                                        ref={legendMainBtnRef}
                                        className="main__tab-btn"
                                        type="button"
                                        style={{
                                            backgroundColor:
                                                selectedCategoryId === item.id
                                                    ? item?.color
                                                    : 'transparent',
                                        }}
                                        onClick={(e) => {
                                            openCategory(item.id)
                                            setMapRequirement(false)
                                            setHints(false)
                                            setQuizPopup(false)
                                            item.height = calculateHeight(e.target)
                                        }}
                                    >
                                        <span className="main__tab-icon icon-wrapper legend-icon">
                                            {/*<img*/}
                                            {/*    src={item?.iconPath}*/}
                                            {/*    alt={item?.title}*/}
                                            {/*/>*/}
                                            <Flag/>
                                        </span>
                                    </button>

                                    <div
                                        ref={index === 0 ? categoryModalRef0 : index === 1 ? categoryModalRef1 : index === 2 ? categoryModalRef2 : categoryModalRef3}
                                        className="main__subitem"
                                        style={{
                                            height: 'fit-content',
                                            minHeight: '100px',
                                            maxHeight: window.innerWidth >= 900 && window.innerWidth <= 1800
                                                ? `${item.height || calculateHeight(legendMainBtnRef.current)}px`
                                                : `calc(100vh - 293px -  ${index * 65}px)`
                                        }}
                                    >
                                        <div className="main__subitem-heading" data-color={item.color}>
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        "--data-color": item.color,
                                                    }}
                                                />
                                            <h2 className="main__subitem-title ">
                                                {item.title}
                                            </h2>
                                            <label
                                                className="main__subitem-text-label "
                                            >
                                                <input
                                                    className="main__subitem-text-checkbox show-checkbox"
                                                    type="checkbox"
                                                    onChange={() =>
                                                        toggleLegendsFromMapByID(
                                                            item.id,
                                                            item.key,
                                                            null,
                                                            null,
                                                            'all-layers',
                                                            'text'
                                                        )
                                                    }
                                                    checked={item.textShow}
                                                    disabled={!item.show}
                                                />
                                                <Text show={item.textShow} disable={!item.show}/>
                                            </label>
                                            <label
                                                className="main__subitem-label "
                                            >
                                                <input
                                                    className="main__subitem-checkbox show-checkbox"
                                                    type="checkbox"
                                                    onChange={() =>
                                                        toggleLegendsFromMapByID(
                                                            item.id,
                                                            item.key,
                                                            null,
                                                            null,
                                                            'all-layers'
                                                        )
                                                    }
                                                    checked={item.show}
                                                />
                                                <Eye show={item.show}/>
                                            </label>
                                            {/*<button*/}
                                            {/*    className="main__subitem-close"*/}
                                            {/*    type="button"*/}
                                            {/*    onClick={() =>*/}
                                            {/*        openCategory(null)*/}
                                            {/*    }*/}
                                            {/*>*/}
                                            {/*    <span>*/}
                                            {/*        <Close />*/}
                                            {/*    </span>*/}
                                            {/*</button>*/}

                                            <div className="sort-board category_sorted_tooltip"
                                                 id='category_sorted_tooltip' data-active={sortBoard}>
                                                <span className="sort-title" style={{textTransform: 'uppercase'}}>Դասակարգել Ըստ</span>
                                                <div onClick={() =>
                                                    toggleSorting(false, 'chronologically')
                                                }>
                                                    <input name={`sort${item.id}`} type="radio"
                                                           id={`chronologically${item.id}`}/>
                                                    <label htmlFor={`chronologically${item.id}`}>Ժամանակաշրջանի</label>
                                                </div>
                                                <div onClick={() =>
                                                    toggleSorting(false, 'alphabetically')
                                                }>
                                                    <input checked={filterAction === 'alphabetically'}
                                                           name={`sort${item.id}`} type="radio"
                                                           id={`alphabetically${item.id}`}/>
                                                    <label htmlFor={`alphabetically${item.id}`}>Այբբենական կարգի</label>
                                                </div>
                                                {/*<div onClick={() =>*/}
                                                {/*    toggleSorting(false,'lessonOrder')*/}
                                                {/*}>*/}
                                                {/*    <input name={`sort${item.id}`} type="radio" id={`lessonOrder${item.id}`}/>*/}
                                                {/*    <label htmlFor={`lessonOrder${item.id}`}>Ըստ դասի</label>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                        <div className="main__subitem-itemswrapper"
                                             style={{
                                                 flexDirection: 'column',
                                                 height: 'fit-content',
                                                 maxHeight: window.innerWidth >= 900 && window.innerWidth <= 1800
                                                     ? `${(item.height || calculateHeight(legendMainBtnRef.current)) - 70 > 0
                                                         ? (item.height || calculateHeight(legendMainBtnRef.current)) - 70
                                                         : 0}px`
                                                     : browserInfo.isSafari && window.innerWidth > 1800 ?
                                                         `calc(100dvh - 657px -  ${index * 65}px)` :
                                                         `calc(100dvh - 357px -  ${index * 65}px)`
                                             }}
                                        >
                                            {item.children.length > 0
                                                ? item.children.map((layer) => (
                                                    (filteredLegends?.findById(layer.key) || filteredLegends?.checkIsHideItem?.(layer.key) || filteredLegends?.checkIsHideAnyItem?.(layer.key)) && <>
                                                        <Collapsible
                                                            onTriggerOpening={toggleCollapsible(layer.key, true)}
                                                            onTriggerClosing={toggleCollapsible(layer.key, false)}
                                                            key={layer.id + 'collapse'}
                                                            trigger={
                                                                <>
                                                                    <span>{layer.title}</span>
                                                                    <span className={'collapseIcon'}></span>

                                                                </>
                                                            }
                                                            triggerSibling={
                                                                <div>
                                                                    <label
                                                                        className="main__subitem-text-label legend-layar-text"
                                                                        key={`b-${layer.id}`}
                                                                        htmlFor={`input-text-${layer.id}`}
                                                                    >
                                                                        <input
                                                                            id={`input-text-${layer.id}`}
                                                                            className="main__subitem-text-checkbox show-checkbox"
                                                                            type="checkbox"
                                                                            onChange={() =>
                                                                                toggleLegendsFromMapByID(
                                                                                    item.id,
                                                                                    item.key,
                                                                                    layer.id,
                                                                                    layer.key,
                                                                                    'layer',
                                                                                    'text'
                                                                                )
                                                                            }
                                                                            checked={layer.textShow}
                                                                            disabled={!layer.show}
                                                                        />
                                                                        <Text show={layer.textShow}
                                                                              disable={!layer.show}/>
                                                                    </label>
                                                                    <label
                                                                        className="main__subitem-label legend-layar-eye"
                                                                        key={`c-${layer.id}`}
                                                                        htmlFor={`input-${layer.id}`}
                                                                    >
                                                                        <input
                                                                            id={`input-${layer.id}`}
                                                                            className="main__subitem-checkbox show-checkbox"
                                                                            type="checkbox"
                                                                            onChange={() =>
                                                                                toggleLegendsFromMapByID(
                                                                                    item.id,
                                                                                    item.key,
                                                                                    layer.id,
                                                                                    layer.key,
                                                                                    'layer'
                                                                                )
                                                                            }
                                                                            checked={layer.show}
                                                                        />
                                                                        <Eye show={layer.show}/>
                                                                    </label>
                                                                </div>
                                                            }
                                                            open={!filteredLegends.checkIsClose(layer.key)}
                                                        >
                                                            <ul className="main__subitem-list person ">
                                                                {
                                                                    layer.children.map((subLayer, artIndex) =>
                                                                        (filteredLegends?.findById(subLayer.key) || filteredLegends?.checkIsHideItem?.(subLayer.key)) && (
                                                                            <li
                                                                                key={`cat_${item.id}_${layer.id}_${subLayer.id}`}
                                                                                className="main__subitem-li"
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        "--data-color": item.color,
                                                                                        opacity: subLayer.show ? 1 : 0.5
                                                                                    }}>
                                                                                    <p className="with-svg">
                                                                                        <ConnectedSpriteImageComponent
                                                                                            name={subLayer?.key}/>

                                                                                        {/*<img*/}
                                                                                        {/*    src={*/}
                                                                                        {/*      item?.iconPath*/}
                                                                                        {/*    }*/}
                                                                                        {/*    alt={*/}
                                                                                        {/*      layer.title*/}
                                                                                        {/*    }*/}
                                                                                        {/*/>*/}
                                                                                    </p>
                                                                                    <div
                                                                                        className="main__subitem-libtn"
                                                                                        type="button"
                                                                                    >
                                                                                        {
                                                                                            subLayer.title
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <label
                                                                                    id={`category_text_filters_icon_${subLayer.id}`}
                                                                                    className="main__subitem-label item "
                                                                                    data-show={subLayer.show}
                                                                                >
                                                                                    <input
                                                                                        id={`input-text-${layer.id}`}
                                                                                        className="main__subitem-text-checkbox show-checkbox"
                                                                                        type="checkbox"
                                                                                        onChange={() =>
                                                                                            toggleLegendsFromMapByID(
                                                                                                layer.id,
                                                                                                layer.key,
                                                                                                subLayer.id,
                                                                                                subLayer.title,
                                                                                                'sub-layer',
                                                                                                'text'
                                                                                            )
                                                                                        }
                                                                                        checked={subLayer.textShow}
                                                                                        disabled={!subLayer.show}
                                                                                    />
                                                                                    <Text show={subLayer.textShow}
                                                                                          disable={!subLayer.show}/>
                                                                                </label>
                                                                                <label
                                                                                    id={`category_filters_icon_${subLayer.id}`}
                                                                                    className="main__subitem-label  category-filters category_filters_icon"
                                                                                    data-show={subLayer.show}>
                                                                                    <input
                                                                                        className="main__subitem-checkbox show-checkbox"
                                                                                        type="checkbox"
                                                                                        onChange={() =>
                                                                                            toggleLegendsFromMapByID(
                                                                                                layer.id,
                                                                                                layer.key,
                                                                                                subLayer.id,
                                                                                                subLayer.title,
                                                                                                'sub-layer',
                                                                                            )
                                                                                        }
                                                                                        checked={
                                                                                            subLayer.show
                                                                                        }
                                                                                    />
                                                                                    <Eye
                                                                                        show={
                                                                                            subLayer.show
                                                                                        }
                                                                                    />
                                                                                </label>
                                                                            </li>
                                                                        ))
                                                                }
                                                            </ul>
                                                        </Collapsible>
                                                    </>
                                                ))
                                                : <div className="empty-items">Ոչինչ չի գտնվել</div>
                                            }
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                        }
                        {filteredCategories.length > 0 &&
                            filteredCategories?.map((item, index) => {
                                return !item.isLegend && (
                                    <li
                                        key={item.id + item?.color}
                                        className={`main__tab persons ${
                                            selectedCategoryId === item.id &&
                                            'active'
                                        }`}
                                        data-is-loading={globalLoading}
                                    >
                                        <button
                                            className="main__tab-btn"
                                            type="button"
                                            ref={el => categoryMainBtnRefs.current[index] = el}
                                            style={{
                                                backgroundColor:
                                                    selectedCategoryId === item?.id
                                                        ? item?.color
                                                        : 'transparent',
                                            }}
                                            onClick={(e) => {
                                                openCategory(item?.id)
                                                setMapRequirement(false)
                                                setHints(false)
                                                setQuizPopup(false)
                                                item.height = calculateHeight(e.target)
                                            }}
                                        >
                                        <span className="main__tab-icon icon-wrapper">
                                            <img
                                                src={item?.iconPath}
                                                alt={item?.title}
                                            />
                                        </span>
                              </button>
                              {item?.subCategories !== null &&
                              item?.subCategories.length > 0 ? (
                                  <div
                                  ref={index === 0 ? categoryModalRef0 : index === 1 ? categoryModalRef1 : index === 2 ? categoryModalRef2 : categoryModalRef3}
                                      className="main__subitem"
                                      key={`a-${item.id}`}
                                      style={{
                                        height:'fit-content',
                                          maxHeight:window.innerWidth >= 900
                                              ? browserInfo.isMozilla ? `${item.height-190}px` : `${item.height}px`
                                              : `unset`
                                      }}
                                  >
                                      <div className="main__subitem-heading" data-color={item.color}>
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        "--data-color": item.color,
                                                    }}
                                                />
                                                    <h2 className="main__subitem-title ">
                                                        {item.title}
                                                    </h2>
                                                    <label
                                                        className="main__subitem-label "
                                                        key={`z-${item.id}`}
                                                    >
                                                        <input
                                                            className="main__subitem-checkbox show-checkbox"
                                                            type="checkbox"
                                                            onChange={() =>
                                                                toggleItemsFromMap(
                                                                    item.id,
                                                                    null,
                                                                    'category'
                                                                )
                                                            }
                                                            checked={item.show}
                                                        />
                                                        <Eye show={item.show}/>
                                                    </label>
                                                    <label
                                                        className="main__subitem-label clock-icon "
                                                        key={item.id}
                                                    >
                                                        <input
                                                            className="main__subitem-checkbox show-checkbox"
                                                            type="checkbox"
                                                            onChange={() =>
                                                                toggleItemsFromTimeLine(
                                                                    item.id,
                                                                    null,
                                                                    'category',
                                                                    null,
                                                                    null,
                                                                    'showInTimeLine'
                                                                )
                                                            }
                                                            checked={item.showInTimeLine}
                                                        />
                                                        <SideBarClock show={item.showInTimeLine}/>
                                                    </label>
                                                    {/*<button*/}
                                                    {/*    className="main__subitem-close"*/}
                                                    {/*    type="button"*/}
                                                    {/*    onClick={() =>*/}
                                                    {/*        openCategory(null)*/}
                                                    {/*    }*/}
                                                    {/*>*/}
                                                    {/*    <span>*/}
                                                    {/*        <Close />*/}
                                                    {/*    </span>*/}
                                                    {/*</button>*/}
                                                    <button
                                                        className="main__subitem-sort"
                                                        type="button"
                                                        onClick={() =>
                                                            toggleSorting(!sortBoard)
                                                        }
                                                    >
                                                    <span>
                                                        <Sort/>
                                                    </span>
                                                    </button>
                                                    <div className="sort-board category_sorted_tooltip"
                                                         id='category_sorted_tooltip' data-active={sortBoard}>
                                                        <span className="sort-title"
                                                              style={{textTransform: 'uppercase'}}>Դասակարգել Ըստ</span>
                                                        <div onClick={() =>
                                                            toggleSorting(false, 'chronologically')
                                                        }>
                                                            <input name={`sort${item.id}`} type="radio"
                                                                   id={`chronologically${item.id}`}/>
                                                            <label
                                                                htmlFor={`chronologically${item.id}`}>Ժամանակաշրջանի</label>
                                                        </div>
                                                        <div onClick={() =>
                                                            toggleSorting(false, 'alphabetically')
                                                        }>
                                                            <input checked={filterAction === 'alphabetically'}
                                                                   name={`sort${item.id}`} type="radio"
                                                                   id={`alphabetically${item.id}`}/>
                                                            <label htmlFor={`alphabetically${item.id}`}>Այբբենական
                                                                կարգի</label>
                                                        </div>
                                                        {/*<div onClick={() =>*/}
                                                        {/*    toggleSorting(false,'lessonOrder')*/}
                                                        {/*}>*/}
                                                        {/*    <input name={`sort${item.id}`} type="radio" id={`lessonOrder${item.id}`}/>*/}
                                                        {/*    <label htmlFor={`lessonOrder${item.id}`}>Ըստ դասի</label>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                                <div className="main__subitem-itemswrapper"
                                                     style={{
                                                         height: 'fit-content',
                                                         maxHeight: window.innerWidth >= 900 && window.innerWidth <= 1800
                                                             ?`${(item.height || calculateHeight(categoryMainBtnRefs.current[index])) - 70 > 0
                                                                 ? browserInfo.isMozilla ?
                                                                     (item.height || calculateHeight(categoryMainBtnRefs.current[index])) - 270 :
                                                                     (item.height || calculateHeight(categoryMainBtnRefs.current[index])) - 70
                                                                 : 0}px`
                                                             : browserInfo.isSafari && window.innerWidth > 1800 ?
                                                                 `calc(100dvh - 657px -  ${index * (50 - index )}px)` :
                                                                     `calc(100dvh - 357px -  ${index * (50 - index )}px)`

                                                     }}
                                                >
                                                    <ul className="main__subitem-list person">
                                                        {
                                                            subItemsRend(item).map((articleItem, artIndex) =>
                                                                (<li
                                                                    key={`acat_${item.id}_${articleItem.subIndex}_${articleItem.subItem.id}_${artIndex}_${articleItem.id}`}
                                                                    className="main__subitem-li"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            "--data-color": item.color,
                                                                            opacity: !articleItem.show ? 0.5 : 1
                                                                        }}>
                                                                        <p className="with-svg">
                                                                            <ConnectedSpriteImageComponent
                                                                                name={articleItem?.subItem?.key}/>
                                                                            {/*<img*/}
                                                                            {/*    src={*/}
                                                                            {/*        item.uniqueIds[articleItem.id].iconPath*/}
                                                                            {/*    }*/}
                                                                            {/*    alt={*/}
                                                                            {/*        articleItem.subItem.title*/}
                                                                            {/*    }*/}
                                                                            {/*/>*/}
                                                                        </p>
                                                                        <div
                                                                            className="main__subitem-libtn"
                                                                            type="button"
                                                                            onClick={() => {
                                                                                stopPinAnimation()
                                                                                getArticleFromAPI(
                                                                                    articleItem.id
                                                                                )
                                                                            }
                                                                            }
                                                                        >
                                                                            {
                                                                                articleItem.title
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <label
                                                                        id={`category_filters_icon_${articleItem.id}`}
                                                                        className="main__subitem-label  category-filters category_filters_icon"
                                                                        data-show={!articleItem.show}>
                                                                        {/*{console.log(articleItem,'item')}*/}

                                                                        <input
                                                                            className="main__subitem-checkbox show-checkbox"
                                                                            type="checkbox"
                                                                            onChange={() =>
                                                                                toggleItemsFromMap(
                                                                                    item.id,
                                                                                    articleItem.subItem.id,
                                                                                    'article',
                                                                                    articleItem.id,
                                                                                    articleItem.elementsIds
                                                                                )
                                                                            }
                                                                            checked={
                                                                                articleItem.show
                                                                            }
                                                                        />
                                                                        <Eye
                                                                            show={
                                                                                articleItem.show
                                                                            }
                                                                        />
                                                                    </label>
                                                                    <label
                                                                        className="main__subitem-label clock-icon "
                                                                    >
                                                                        <input
                                                                            className="main__subitem-checkbox show-checkbox"
                                                                            type="checkbox"
                                                                            onChange={() =>
                                                                                toggleItemsFromTimeLine(
                                                                                    item.id,
                                                                                    articleItem.subItem.id,
                                                                                    'article',
                                                                                    articleItem.id,
                                                                                    articleItem.elementsIds,
                                                                                    'showInTimeLine'
                                                                                )
                                                                            }
                                                                            checked={articleItem.showInTimeLine}
                                                                        />
                                                                        <SideBarClock
                                                                            show={articleItem.showInTimeLine}/>
                                                                    </label>
                                                                </li>)
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        ) : null}
                                    </li>
                                )
                            })}
                    </ul>
                    <div className="left-side__bottom">
                        <div className={`filters ${mapRequirement ? "active" : ""}`}>
                            <button
                                className={`filters__btn main__tab ${
                                    mapRequirement ? "active" : ""
                                }`}
                                type="button"
                                onClick={() => {
                                    setMapRequirement(!mapRequirement);
                                    setSelectedCategoryId(null);
                                    setSortedTooltipSelectItem(1)
                                    setFilterAction('alphabetically')
                                    setOpenCategoryID(null);
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                data-is-loading={globalLoading}
                            >
                                <Requirements/>
                            </button>
                            <div className="filters__content ">
                                <h2 className="filters__title">ցույց տալ</h2>
                                <fieldset className="filters__radio">
                                    {mapRequirements?.map((item) => {
                                        return (
                                            <div className={`filters__radio-label ${item.active ? 'bg' : ''}`}
                                                 key={item.id + 'filter'}
                                                 onClick={() => setMapRequirements(item.value)}>
                                                <span className="filters__radio-text">{item.title}</span>
                                            </div>
                                        );
                                    })}
                                </fieldset>
                            </div>
                        </div>
                        {lampData.length > 0 && (
                            <div className={`left-side__hints hints ${hints ? "active" : ""}`}>
                                {getLampSortedTooltipState && (
                                    <div className="sorted_tooltip">
                                        <div className="sorted_tooltip_arrow"></div>
                                        <p className="sorted_tooltip_title">Դասակարգել Ըստ</p>
                                        <div
                                            className={`sorted_tooltip_content ${
                                                sortedTooltipSelectItem == 0
                                                    ? "sorted_tooltip_content_active"
                                                    : ""
                                            }`}
                                            onClick={(e) => {
                                                onChangeSortedItem(e, 0)
                                                toggleSorting(false, 'chronologically')
                                            }}
                                        >
                                            <div>
                                                <img src={LampSortedSelectIcon} width="7.5px"/>
                                            </div>
                                            <span>Ժամանակաշրջանի</span>
                                        </div>
                                        <div
                                            className={`sorted_tooltip_content ${
                                                sortedTooltipSelectItem == 1
                                                    ? "sorted_tooltip_content_active"
                                                    : ""
                                            }`}
                                            onClick={(e) => {
                                                onChangeSortedItem(e, 1)
                                                toggleSorting(false, "alphabetically");
                                            }}
                                        >
                                            <div>
                                                <img src={LampSortedSelectIcon} width="7.5px"/>
                                            </div>
                                            <span>Այբբենական կարգի</span>
                                        </div>
                                        {/*<div*/}
                                        {/*  className={`sorted_tooltip_content ${*/}
                                        {/*    sortedTooltipSelectItem == 2*/}
                                        {/*      ? "sorted_tooltip_content_active"*/}
                                        {/*      : ""*/}
                                        {/*  }`}*/}
                                        {/*  onClick={(e) => {*/}
                                        {/*      onChangeSortedItem(e,2)*/}
                                        {/*      toggleSorting(false,'lessonOrder')*/}
                                        {/*  }}*/}
                                        {/*>*/}
                                        {/*  <div>*/}
                                        {/*    <img src={LampSortedSelectIcon} width="7.5px" />*/}
                                        {/*  </div>*/}
                                        {/*  <span>Ըստ դասի</span>*/}
                                        {/*</div>*/}
                                    </div>
                                )}
                                <button
                                    className={`hints__btn main__tab hints__btn_lamp`}
                                    style={{
                                        backgroundColor: hints ? "#FFB301" : "#fff",
                                    }}
                                    type="button"
                                    data-is-loading={globalLoading}
                                    onClick={() => {
                                        if (lampData.length > 0) {
                                            setHints(!hints);
                                            setQuizPopup(false);
                                            // timelineExtend && setTimeLineExpend(1);
                                            setSelectedCategoryId(null);
                                            setOpenCategoryID(null);
                                            timelineExtend && setFilterAction("alphabetically");
                                            timelineExtend &&
                                            timelineExtend && setSortedTooltipSelectItem(1);
                                            // timelineExtend &&
                                            // timeLine.current.setOptions?.({
                                            //     minHeight: !timelineExtend ? "200px" : "90px",
                                            // });
                                        }
                                    }}
                                >
                                    <img src={LampActive}/>
                                </button>
                                <div
                                    ref={lampModalRef}
                                    className="hints__content"
                                    style={{
                                        height: "fit-content",
                                        maxHeight:
                                            window.innerHeight >= 320 && window.innerHeight <= 800
                                                ? browserInfo.isSafari && window.innerWidth < 1800 ? `calc(100vh - 820px)` : `calc(100vh - 520px)`
                                                : lampModalRef?.current?.getBoundingClientRect().y - 200 + 'px',
                                    }}
                                >
                                    <div className="hints__content_item">
                                        <div className="hints__content_title">ԼԱՄՊ</div>
                                        <div className="hints__content_buttons">
                                            <div
                                                className="hints__content_buttons_sort lamp_header_icon"
                                                onClick={() => {
                                                    onLampHeaderEyeClick();
                                                }}
                                            >
                                            <EyeLamp className="eye_lamp_icon" show={!lampEyeIconState} />
                                                {/*<img*/}
                                                {/*    srcSet={*/}
                                                {/*        !lampEyeIconState ? EyeWhiteOpen : EyeWhiteClose*/}
                                                {/*    }*/}
                                                {/*/>*/}
                                            </div>
                                            <div
                                                className="hints__content_buttons_eye"
                                                onClick={() => {
                                                    setLampSortedTooltipState(!getLampSortedTooltipState);
                                                    toggleSorting(!sortBoard);
                                                }}
                                            >
                                                <img
                                                    srcSet={
                                                        !getLampSortedTooltipState ? SortWhite : SortBlue
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hints__content_itemswrapper hints__content_itemswrapper_lamp">
                                        <ul
                                            ref={lampScrollZone}
                                            className="main__subitem-items custom-scroll main__subitem-items_lamp"
                                            id="lamp_scroll_zone"
                                            style={{
                                                // height: "fit-content",
                                                maxHeight:
                                                    window.innerHeight >= 320 && window.innerHeight <= 800
                                                        ? browserInfo.isMozilla ? `calc(100vh - 630px)` : `calc(100vh - 600px)`
                                                        :lampModalRef?.current?.getBoundingClientRect().y - 200 + 'px'
                                            }}
                                        >
                                            {getCategories.length > 0 &&
                                                lampData.length > 0 &&
                                                lampRend(lampDataState).map((item, i) => {
                                                    let data = [];

                                                    // let mainlampSubcategry = item?.subcategory?.find(
                                                    //   (el) => el.isMain
                                                    // );
                                                    let mainlampSubcategry = item?.subcategory[0]
                                                    for (let i = 0; i < getCategories.length; i++) {
                                                        if (getCategories[i]?.subCategories !== false) {
                                                            getCategories[i]?.subCategories?.map((el) => {
                                                                data.push(el);
                                                            });
                                                        }
                                                    }

                                                    let subCategoryData = data.find(
                                                        (el) => el.id === mainlampSubcategry?.subCategoryId
                                                    );
                                                    return (
                                                        <>
                                                            <LampItem
                                                                key={item.id}
                                                                lampEyeIconState={lampEyeIconState}
                                                                data={item}
                                                                iconPath={subCategoryData?.iconPath}
                                                                getArticleFromAPI={(arg) => {
                                                                    getArticleFromAPI(arg)
                                                                    stopPinAnimation()
                                                                }}
                                                                changeLampDataShow={changeLampDataShow}
                                                                mainlampSubcategry={mainlampSubcategry}
                                                                subCategoryData={subCategoryData}
                                                                toggleItemsFromMap={toggleItemsFromMap}
                                                                getLampSelectedId={getLampSelectedId}
                                                                setLampSelectedId={setLampSelectedId}
                                                            />
                                                        </>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}

                        {quizData.length > 0 && (
                            <div className={`left-side__hints hints ${quizPopup ? "active" : ""}`}>
                                <button
                                    className={`hints__btn main__tab hints__btn_quiz`}
                                    style={{
                                        backgroundColor: quizPopup ? "#9E5D82" : "#fff",
                                    }}
                                    type="button"
                                    onClick={() => {
                                        if (quizData.length > 0) {
                                            setHints(false);
                                            setQuizPopup(!quizPopup);
                                            // timelineExtend && setTimeLineExpend(1);
                                            setSelectedCategoryId(null);
                                            setOpenCategoryID(null);
                                            timelineExtend && setFilterAction("alphabetically");
                                            timelineExtend && setSortedTooltipSelectItem(1);
                                        }
                                    }}
                                    data-is-loading={globalLoading}
                                >
                                    <img src={quizPopup ? QuizActive : QuizPassive}/>
                                </button>
                                <div
                                    ref={quizModalRef}
                                    className="hints__content quiz__content"
                                    style={{
                                        height: "fit-content",
                                        maxHeight:
                                            window.innerWidth >= 320 && window.innerWidth <= 1800
                                                ? `calc(100vh - 272px)`
                                                : window.innerHeight -
                                                quizModalRef?.current?.getBoundingClientRect().y -
                                                14,
                                    }}
                                >
                                    <div className="hints__content_item quiz_content">
                                        <div className="hints__content_title">թեստ</div>
                                    </div>
                                    <div className="hints__content_itemswrapper hints__content_itemswrapper_quiz">
                                        <ul className="main__subitem-items custom-scroll main__subitem-items_quiz"
                                            ref={quizScrollZone}
                                            id={"quizScrollZone"}
                                            style={{
                                                height: "fit-content",
                                                maxHeight:
                                                    window.innerWidth >= 320 && window.innerWidth <= 1800
                                                        ? `calc(${
                                                            window.innerHeight <= 700
                                                                ? "100vh - 600px"
                                                                : "100vh - 600px"
                                                        })`
                                                        : window.innerHeight -
                                                        quizModalRef?.current?.getBoundingClientRect().y -
                                                        114,
                                            }}>
                                            {quizDataState.length > 0 &&
                                                quizDataState.map((item, i) => {
                                                    return (
                                                        <>
                                                            <QuizItem
                                                                key={item.id + 'quiz'}
                                                                data={item}
                                                                getSelectedQuizDataFromApi={getSelectedQuizDataFromApi}
                                                            />
                                                        </>
                                                    )
                                                })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Popup
                            onClose={() => setSelectedQuizData([])}
                            isOpened={selectedQuiz && selectedQuiz.length !== 0}
                        >
                            <Quiz
                                close={() => setSelectedQuizData([])}
                                selectedQuiz={selectedQuiz}
                            />
                        </Popup>
                    </div>
                </div>
            </div>
            {lampModalState && (<div
                className={`${
                    window.innerWidth <= 1024 && !isLandscape
                        ? "hints_lamp_popup_sm"
                        : window.innerWidth <= 1024 && isLandscape && menuExpend
                            ? "hints_lamp_popup_landscape_menu_open_sm"
                            : window.innerWidth <= 1024 && isLandscape && !menuExpend
                                ? "hints_lamp_popup_landscape_sm"
                                : "hints_lamp_popup_lg"
                }`}
            >
                <div
                    className="modal_close_block"
                    onClick={onCloseLampSlide}
                >
                    <img src={CloseIcon}/>
                </div>
                <div className={`hints_lamp`}>
                    {lampModalState && (
                        <Splide
                            ref={lampSplideRef}
                            options={{
                                pagination: false,
                                infinite: false,
                                speed: 700,
                            }}
                            onMove={(_, index) => {
                                setLampModalStateIndex(index)
                            }
                            }
                            className="hints__content-items hints__content-items_lamp"
                        >
                            {lampModalData.length > 0
                                ? lampModalData.map((item, index) => {
                                    return (
                                        <SplideSlide key={`${item.id}_'lamp`}>

                                            <div
                                                className="hints__content-item hints__content-item_lamp"
                                                onClick={() => {
                                                    stopPinAnimation()
                                                    getArticleFromAPI(item.id);
                                                }}
                                            >
                                                <div className={'hints__content-parent'}>

                                                    <div className="hints__content-box">
                                                        <button
                                                            className="hints__content-img"
                                                            type="button"
                                                        >
                                                            {(!!item?.content[0]?.featuredImageResized ||
                                                                !!item?.content[0]?.featuredImage)
                                                                ? (
                                                                    <img
                                                                        src={item?.content[0]?.featuredImageResized || item?.content[0]?.featuredImage}
                                                                        alt={item?.content[0]?.mainTitle}
                                                                    />
                                                                ) : item?.gallery?.filter((x) => x.type === 0)
                                                                    .length > 0 ? (
                                                                    <img
                                                                        src={
                                                                            item?.gallery?.filter(
                                                                                (x) => x.type === 0
                                                                            )[0].path
                                                                        }
                                                                        alt="image_placeholder"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={require("../../assets/imgs/image_placeholder.png")}
                                                                        alt="image_placeholder"
                                                                    />
                                                                )}
                                                        </button>
                                                        <div className="hero__media__liner-gradien"></div>
                                                    </div>
                                                    <div className="hints__content-bottom">
                                              <span>
                                                {((item?.timeStart?.isBc && item?.timeEnd?.isBc) ||
                                                    (!item?.timeStart?.isBc && !item?.timeEnd?.isBc)) &&
                                                item.timeStart.year === item.timeEnd.year
                                                    ? item?.timeStart?.isBc
                                                        ? `Ք․ա. ${item.timeStart.year}`
                                                        : item.timeStart.year
                                                    : (item?.timeStart?.isBc
                                                        ? `Ք․ա. ${item.timeStart.year}`
                                                        : item.timeStart.year) +
                                                    " - " +
                                                    (item?.timeEnd?.isBc
                                                        ? `Ք․ա. ${item.timeEnd.year}`
                                                        : item.timeEnd.year)}
                                              </span>
                                                    </div>

                                                </div>
                                                <div className="hints__content-text">
                                                    <button
                                                        className="hints__content-name"
                                                        type="button"
                                                    >
                                                        <span>
                                                            {item?.content[0]?.mainTitle}
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                    );
                                })
                                : null}
                        </Splide>
                    )}
                </div>
            </div>)}
        </div>
    );
};

const mapStateTopProps = (state) => ({
    getCategories: getCategoriesData(state),
    timelineExtend: getTimeLineExpendData(state),
    filteredCategories: getFilteredCategoriesData(state),
    filteredCategoriesST: getFilteredCategoriesDataST(state),
    legends: getLegends(state),
    loadingLegends: getLegendsLoading(state),
    filteredLegends: getFilteredLegendsData(state),
    hints: getHintsData(state),
    selectedQuiz: getSelectedQuizData(state),
    quizPopup: getQuizPopupData(state),
    mapRequirement: getMapRequirementData(state),
    menuExpend: getMenuExpendData(state),
    lampData: getLampData(state),
    quizData: getQuizData(state),
    mapRequirements: getMapRequirementsData(state),
    sortedTooltipSelectItem: getSortedTooltipSelectItem(state),
    lampModalState: getLampModalState(state),
    lampModalData: getLampModalData(state),
    quizModalState: getQuizModalState(state),
    quizModalData: getQuizModalData(state),
    getOpenCategoryID: getOpenCategoryID(state),
    getLampDataFilteredScreenShot: getLampDataFilteredScreenShot(state),
    getLampSortedTooltipState: getLampSortedTooltipState(state),
    getTestFilteredCategories: getTestFilteredCategories(state),
    getLampSelectedId: getLampSelectedId(state),
    getLampModalStateIndex: getLampModalStateIndex(state),
    getScreenShotLoadingST: getScreenShotLoadingST(state),

});

const mapDispatchToProps = {
    setHints,
    setQuizPopup,
    setMenuExpend,
    setMapRequirement,
    getArticleFromAPI,
    setMapRequirements: changeMapRequirements,
    setSortedTooltipSelectItem,
    setTimeLineExpend,
    setLampModalState,
    setLampModalData,
    setQuizModalState,
    setQuizModalData,
    setSortedTooltipSelectItemQuiz,
    getSelectedQuizDataFromApi,
    setSelectedQuizData,
    setOpenCategoryID,
    setMapRequiredScreenShot,
    setCategoriesClickedArticleId,
    setLampSortedTooltipState,
    setLampSelectedId,
    setLampModalStateIndex,
    setLamp
};

export default connect(mapStateTopProps, mapDispatchToProps)(LeftSide);
