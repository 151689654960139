import React from "react"

const Logout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <path
        d="M0,4.648C.284,1.347,1.98,0,5.693,0h.119c4.1,0,5.738,1.641,5.738,5.738v5.977c0,4.1-1.641,5.738-5.738,5.738H5.693c-3.685,0-5.381-1.329-5.683-4.574"
        transform="translate(8.158 2.282)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M0,0H11.807"
        transform="translate(1.833 11)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M0,0,3.071,3.071,0,6.142"
        transform="translate(11.596 7.929)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M22,0V22H0V0Z"
        transform="translate(22 22) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  )
}
export default Logout
