import {SET_MAP_LOADING, SET_MAP_SELECTED_CLUSTER} from '../constants'

export const setMapLoading = (bool) => ({
    type: SET_MAP_LOADING,
    payload: bool
});

export const setMapSelectedCluster = (data) => ({
    type: SET_MAP_SELECTED_CLUSTER,
    payload: data
});


export const loadingMap = (bool) => (dispatch) => {
    dispatch(setMapLoading(bool))
}
