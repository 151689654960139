import { connect } from "react-redux";
import "./style.css";
import React from "react";
const SearchSection = (props) => {
    const { content = {}, handleSetSearchParams } = props;
    return (
        <div className="card-body__search date-search">
            <input type="text" placeholder="Փնտրել" onChange={handleSetSearchParams}/>
        </div>
    );
};
export default connect(null, null)(SearchSection);
