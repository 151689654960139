import axios from "axios";

const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items;
    }
  }
  return [];
};

const sanitizeSelectedResult = (items) =>
  items.map((value) => ({
    ...value,
    ...{ show: true,showInTimeLine: true },
    subCategories:
      value.subCategories !== null &&
      value.subCategories.map((elem) => ({
        ...elem,
        ...{
          show: true,
          showInTimeLine: true,
          articles: [],
        },
      })),
  }));

export const getCategoriesAPI = async (url, body) => {
  return axios
    .post(url, body, {
      headers: {
        Authorization: `Bearer ${await localStorage.getItem("accessToken")}`
      }
    })
    .then((response) => extractItems(response))
    .then((items) => sanitizeSelectedResult(items))
    .catch(console.log);
};

export const filterCategories = (data, categories = []) => {
  const filteredCategories = [];

  data.forEach((item) => {
    let findCat = (categories || []).find((x) => x.id === item.catID);
    if(findCat !== undefined){
      findCat.show = true
      if (findCat?.subCategories.length > 0) {
        findCat?.subCategories?.forEach((sub) => {
          sub.articles = [];
          filteredCategories.push(findCat);
        });
      }
    }
  });
  const uniqueIds = [];
  return new Promise((resolve, reject) =>
    resolve(
      filteredCategories
        .filter((element) => {
          const isDuplicate = uniqueIds.includes(element.id);
          if (isDuplicate) {
            return false;
          }
          if (isDuplicate === false) {
            uniqueIds.push(element.id);
            return true;
          }
        })
        .sort((a, b) => (a.id < b.id ? 1 : -1))
    )
  );
};

// export const addArticlesToCategories = (categories, articles) => {
//
//   articles.forEach((article) => {
//     let  mainId
//     article.subCategory.forEach((item) => {
//       const findCat = categories.find(
//           (category) => category.id === article.subCategory[0]?.categoryId
//       );
//       if (findCat) {
//         const subCategory = findCat.subCategories.find(
//             (subCat) => subCat.id === item.subCategoryId
//         );
//         let art = undefined;
//         if (subCategory) {
//           art = subCategory.articles.find((x) => x.id === article.id);
//         }
//         if (art === undefined) {
//           subCategory?.articles?.push({
//             id: article.id,
//             title: article.content[0].mainTitle,
//             start: article.startYear,
//           });
//         }
//       }
//     });
//   });
//   const uniqueIds = {}
//
//   categories.forEach((category) => {
//     let subCategories = [];
//     category?.subCategories.forEach((subCat) => {
//
//       subCat.articles.forEach((item,idx)=>{
//         // if(!uniqueIds[item.id]) {
//         //   uniqueIds[item.id] = true
//         // }else{
//         //   delete subCat.articles[idx]
//         // }
//         if(!uniqueIds[item.id]) {
//           uniqueIds[item.id] = {
//             iconPath: subCat.iconPath,
//             main: subCat.isMain
//           }
//           subCat.articles[idx].isUniq = true
//         }else if(!uniqueIds[item.id].main && subCat.isMain){
//           subCat.articles[idx].isUniq = false
//           uniqueIds[item.id] = {
//             iconPath: subCat.iconPath,
//             main: subCat.isMain
//           }
//         }else{
//           subCat.articles[idx].isUniq = false
//         }
//         subCat.articles[idx].show = false
//       })
//       if (subCat.articles.length > 0) {
//         subCategories.push(subCat);
//       }
//     });
//     category.subCategories = subCategories;
//     category.uniqueIds = uniqueIds;
//   });
//
//
//   return new Promise((resolve, reject) => resolve(categories));
// };
function updateChildFieldById(obj,  data, fieldToUpdate) {
  // Base case: if the object has an id and matches the provided id, update the field
  if (obj.id && data[obj.id]) {
    if(Array.isArray(fieldToUpdate)){
      fieldToUpdate.forEach((el)=>{
        obj[el] = data[obj.id][el];
      })
    }else{
      obj[fieldToUpdate] = !data[obj.id][fieldToUpdate];
    }
  }

  // Recursive case: if the object has children, recursively call the function on each child
  if (obj.categories && obj.categories.length > 0) {
    for (var i = 0; i < obj.categories.length; i++) {
      updateChildFieldById(obj.categories[i], data, fieldToUpdate);
    }
  }else if (obj.subCategories && obj.subCategories.length > 0) {
    for (var i = 0; i < obj.subCategories.length; i++) {
      updateChildFieldById(obj.subCategories[i], data, fieldToUpdate);
    }
  }else if (obj.articles && obj.articles.length > 0) {
    for (var i = 0; i < obj.articles.length; i++) {
      updateChildFieldById(obj.articles[i], data, fieldToUpdate);
    }
  }
}
export const arrayProxy = new Proxy(Array, {
  construct(target,[args]) {
    let index = {}
    return new Proxy(new target(...args),{
      get(arr,prop){
        switch (prop){
          case 'getData':
            return  ()=> index
          case 'getTarget':
            return  ()=> arr
          case 'getDataStates':
            return  ()=> {
              const newIndex = {}
              Object.keys(index).forEach(el=> {
                if (typeof index[el] == 'object'
                    && (!index[el].show || !index[el].showInTimeLine)
                ) {
                  newIndex[el] = {
                    id: index[el].id,
                    key: index[el].key,
                    show: index[el].show,
                    showInTimeLine: index[el].showInTimeLine,
                  }
                }
              })
              return newIndex
            }
          case 'updateIndex':
            return (data) => {
              index = data || {}
            }
         case 'updateDataStates':
            return  (data)=> {
              updateChildFieldById({categories:arr},data,['show','showInTimeLine'])
                Object.keys(data).forEach(el=> {
                  if (typeof index[el] === 'object') {
                    index[el] = {
                      ...index[el],
                      show: data[el].show,
                      showInTimeLine: data[el].showInTimeLine,
                    }
                  }
                })

            }
          case 'updateItems':
            return (data) => {
              const  newProxy = new arrayProxy(data || arr)
              newProxy.updateIndex(index)
              return newProxy
            }
          case 'updateArray':
            return (data) => {
              arr = data
              return
            }
          case 'addItem':
            return (data) => {
              Array.isArray(data)
                  ? data.forEach(item => (index[item.id] = {
                    showInTimeLine: true,
                    show: true,
                    ...data,
                  }))
                  : index[data.id] = {
                    showInTimeLine: true,
                    show: true,
                    ...data,
                  }
            }
          case 'getById':
            return (key) => index[key]
          case 'checkIsDisableInTimeLineItem':
            return (key) => index[key]?.showInTimeLine
          case 'checkIsHideFromTimeLineItem':
            return (key) => {
              return index[key]?.showInTimeLine
            }
          case 'updateshowInTimeLineById':
            return (id,bool) => {
              if(index[id]) index[id].showInTimeLine = bool
            }
          case 'updateShowById':
            return (id,bool) => {
              if(index[id]) index[id].show = bool
            }
          default:
            return  arr[prop]
        }
      }
    })
  }
});

export const addArticlesToCategories = (categories, articles) => {
  const newCategories = new arrayProxy(categories)
  articles?.forEach((article) => {
    newCategories.addItem(article)
    let  mainId
    const findCat = categories.find(
        (category) => category.id === article?.subcategory?.[0]?.categoryId
    );
    if (findCat) {
      const subCategory = findCat.subCategories.find(
          (subCat) => subCat.id === article?.subcategory?.[0]?.subCategoryId
      );
      let art = undefined;
      if (subCategory) {
        art = subCategory.articles?.find((x) => x.id === article.id);
      }
      if (art === undefined) {

        subCategory?.articles?.push({
          id: article.id,
          title: article.content[0].mainTitle,
          start: article.startYear,
          elementsIds:article.elementIds
        });
      }
    }
  });
  const uniqueIds = {}
  categories.forEach((category) => {
    newCategories.addItem(category)

    let subCategories = [];
    category?.subCategories.forEach((subCat) => {
      newCategories.addItem(subCat)
      subCat.articles.forEach((item,idx)=>{
        // if(!uniqueIds[item.id]) {
        //   uniqueIds[item.id] = true
        // }else{
        //   delete subCat.articles[idx]
        // }
        if(!uniqueIds[item.id]) {
          uniqueIds[item.id] = {
            iconPath: subCat.iconPath,
            main: subCat.isMain
          }
          subCat.articles[idx].isUniq = true
        }else if(!uniqueIds[item.id].main && subCat.isMain){
          subCat.articles[idx].isUniq = false
          uniqueIds[item.id] = {
            iconPath: subCat.iconPath,
            main: subCat.isMain
          }
        }else{
          subCat.articles[idx].isUniq = false
        }
        subCat.articles[idx].show = true
        subCat.articles[idx].showInTimeLine = true
      })
      if (subCat.articles.length > 0) {
        subCategories.push(subCat);
      }
    });
    category.subCategories = subCategories;
    category.uniqueIds = uniqueIds;
  });
  return new Promise((resolve, reject) => resolve(newCategories));
};
