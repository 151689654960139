import { LOCAL_STORAGE_DATA_ITEM_TEMPL } from "../../helpers";

const saveUserToLocalStorage =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (global.window && global.window.localStorage) {
      const { localStorage } = global.window;
      if(getState().user?.data){
          localStorage.setItem(
              LOCAL_STORAGE_DATA_ITEM_TEMPL.user,
              JSON.stringify(getState().user)
          );
      }
    }
    return next(action);
  };

export default saveUserToLocalStorage;
