import {CHANGE_MAP_STYLES, CHANGE_MAP_STYLES_DARK, CHANGE_MAP_STYLES_LIST} from '../constants'
import {changeMapStylesList, getMapStyles, getMapStylesDark} from '../../service/mapStyles'

const initialState = {
    data: [
        {
            id: 1,
            value: "physical",
            title: "Ֆիզիկական",
            light: 1,
            dark: 5 ,
            active: 'light',
            anchor:'center',
            size:16,
            'letter-spacing':0.1,
            'color':'#000000',
            'halo-color':'rgba(255, 255, 255, 0.3)',
            hide:false

        },
        {
            id: 2,
            value: "satellite",
            title: "Արբանյակային",
            light: 2,
            dark: false,
            active: false,
            anchor:'center',
            size:17,
            'letter-spacing':0.1,
            'color':'rgba(255, 255, 255, 1)',
            'halo-color':'rgba(0, 0, 0, 1)',
            hide:false
        },
        {
            id: 3,
            value: "politics",
            title: "Քաղաքական",
            light: 3,
            dark: 6,
            active: false,
            anchor:'center',
            size:16,
            'letter-spacing':0.1,
            'color':'#000000',
            'halo-color':'rgba(255, 255, 255, 0.3)',
            hide:false

        },
        {
            id: 4,
            value: "terrain",
            title: "Տեղանքային",
            light: 4,
            dark: false,
            active: false,
            anchor:'center',
            size:16,
            'letter-spacing':0.1,
            'color':'#000000',
            'halo-color':'rgba(255, 255, 255, 0.3)',
            hide:false

        },
        {
            id: 5,
            value: "geographyTerrain",
            title: "Տեղանքային",
            light: 7,
            dark: false,
            active: false,
            anchor:'center',
            size:16,
            'letter-spacing':0.1,
            'color':'#000000',
            'halo-color':'rgba(255, 255, 255, 0.3)',
            hide:false

        },
    ],
    list:[]
}

export const mapStylesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_MAP_STYLES:
            return {
                ...state,
                data: getMapStyles(action.payload, initialState.data, state.list),
            }
        case CHANGE_MAP_STYLES_DARK:
            return {
                ...state,
                data: getMapStylesDark(action.payload, initialState.data),
            }
        case CHANGE_MAP_STYLES_LIST:
            return {
            ...state,
            data: changeMapStylesList(action.payload, initialState.data,initialState.data.find(el=>el.active)?.id),
            list: action.payload,
        }
        default:
            return state
    }
}
