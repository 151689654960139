import { forwardRef } from "react";
import "./style.css";
const CardBody = (props, ref) => {
  const { children, hasIcon = true, styles, classN } = props;

  return (
    <article
      ref={ref}
      className={`custom-card-1__card-body card-body ${classN}`}
      data-has-icon={hasIcon}
      style={styles}
    >
      {children}
    </article>
  );
};
export default forwardRef(CardBody);
