import {
  SET_PAINTER_GEO_JSON,
  SET_PAINTER_PREV_DATA,
  SET_PAINTER_PREV_LINE_NAME_DATA,
  SET_PAINTER_LAYER_LINE_NAME_DATA,
} from "../constants";

const initialState = {
  data: [],
  prevData: [],
  prevLineName: [],
  layerLineName: [],
};

export const painterGeojsonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAINTER_GEO_JSON:
      return {
        ...state,
        data: action.payload,
      };
    case SET_PAINTER_PREV_DATA:
      return {
        ...state,
        prevData: action.payload,
      };
    case SET_PAINTER_PREV_LINE_NAME_DATA:
      return {
        ...state,
        prevLineName: action.payload,
      };
    case SET_PAINTER_LAYER_LINE_NAME_DATA:
      return {
        ...state,
        layerLineName: action.payload,
      };
    default:
      return state;
  }
};
