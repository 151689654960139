import axios from "axios";
export const extractResult = (res) => {
    const { data } = res;
    if (data.accepted) {
        if (data.data && data.data[0]) {
            return data.data[0];
        }
    }
    return [];
};

export const extractItems = (res) => {
    const { data } = res;
    if (data.accepted) {
        if (data.data && data.data[0]) {
            // data.data[0].items.__proto__.totalLength = data.data[0].filteredTotal
            return data.data[0].items;
        }
    }
    return [];
};

export const editPresentation = async (url, body) => {
    return axios
        .patch(url, body, {
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        })
        .then((response) => response)
        .catch((error) => console.log("error", error));
};
export const presentationPostMethod = async (url, body) => {
    return axios
        .post(url, body)
        .then((response) => response)
        .catch((error) => console.log("error", error));
};
export const presentationDeleteMethod = async (url) => {
    return axios
        .delete(url)
        .then((response) => response)
        .catch((error) => console.log("error", error));
};
export const presentationGetMethod = async (url) => {
    return axios
        .get(url)
        .then((response) => response)
        .catch((error) => console.log("error", error));
};
export const presentationPutMethod = async (url, body) => {
    return axios
        .put(url, body)
        .then((response) => response)
        .catch((error) => console.log("error", error));
};
export const presentationPatchMethod = async (url, body) => {
    return axios
        .patch(url, body,{
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        })
        .then((response) => response)
        .catch((error) => console.log("error", error));
};
