import { SET_SPRITE_DATA } from "../constants";
import { SET_SPRITE_URL } from "../constants";

const initialState = {
  data: {},
  url: null
};

export const spriteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPRITE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_SPRITE_URL:
      return {
        ...state,
        url: action.payload,
      };
    default:
      return state;
  }
};
