import React, { Component } from "react";
import {
  getErrorMessage,
  handleImageUpload,
  uploadFile,
} from "../../../helpers";
import Loader from "../../../components/UI/Loader";
import UploadPhotoIcon from "../../../components/UI/UploadPhotoIcon";
import ErrorAlert from "../../../components/errorAlert";
import { getUserData } from "../../../store/selectors";
import { connect } from "react-redux";
import { getUserFromAPI } from "../../../store/actions/user";
import PasswordSideBar from "./PasswordSideBar";
import SuccessAlert from "../../../components/successAlert";

class LeftSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fields: props.user,
      errors: {},
      apiErrorMsg: "",
      loadImage: false,
      showError: false,
      showSuccess:false,
      apiSuccessMsg:'',
      uploadedFile: {
        id: null,
        url: null,
      },
    };
  }

  uploadFile = (e) => {
    const handlerState = {
      loadImage: false,
      uploadedFile: {
        id: null,
        url: null,
      },
    };
    uploadFile(e.target.files[0])
      .then((res) => {
        if (res.data.accepted === false) {
          return Promise.reject(res.data.errorMessages);
        }
        return res.data;
      })
      .then(handleImageUpload)
      .then((data) => {
        this.props.setUploadFile(data);
        handlerState.uploadedFile = data;
        this.setState(handlerState);
        this.state.fields.avatar = data.url
      })
      .catch((error) => {
        this.setState({
          ...handlerState,
          showError: true,
          apiErrorMsg: getErrorMessage(error),
        });
      });
  };

  handleRemoveAvatar = (e) => {
    e.preventDefault();
    this.props.user.avatar = null;
    this.props.user.mediaId = null;
    this.props.setUploadFile({ id: null, url: null });
    this.setState({
      uploadedFile: { id: null, url: null },
    });
  };
  // updateAccount = () => {
  //   const { fields, uploadedFile } = this.state;
  //   const body = getUserUpdateApiParams(fields, this.props.user, uploadedFile);
  //   updateUserAccount(updateUserInfoURI, body)
  //     .then((res) => {
  //       if (res.data.accepted === false) {
  //         return Promise.reject(res.data.errorMessages);
  //       }
  //       return res.data;
  //     })
  //     .then(this.props.getUserFromAPI)
  //     .then(() => this.setState(resetState))
  //     .catch((error) => {
  //       this.setState({
  //         ...resetState,
  //         showError: true,
  //         apiErrorMsg: getErrorMessage(error),
  //       });
  //     });
  // };
  handleChangeSuccessAlert = (show,msg) =>{
    this.setState({showSuccess:show,apiSuccessMsg:msg})
  }
  render() {
    const {
      loading,
      showError,
      apiErrorMsg,
      uploadedFile,
      loadImage,
      showSuccess,
      apiSuccessMsg
    } = this.state;
    const { handleRemoveAvatar } = this;
    const { user, edit } = this.props;

    return (
      <div className="user-left">
        {edit ? (
          <>
            <form className="account-info-edit">
              {uploadedFile.id !== null ? (
                <div className="user-photo uploaded">
                  {loadImage && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "rgba(0,0,0,0.4)",
                        position: "absolute",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  <img src={uploadedFile.url} alt="user photo" />
                  <label className="photo-upload-label">
                    <UploadPhotoIcon />
                    <input
                      type="file"
                      accept={"image/jpeg"}
                      onInput={(e) => this.uploadFile(e)}
                    />
                  </label>
                  {uploadedFile.url !== null && (
                    <button
                      className="photo-remove-label"
                      onClick={(e) => handleRemoveAvatar(e)}
                    >
                      X
                    </button>
                  )}
                </div>
              ) : user.avatar !== null ? (
                <div className="user-photo ">
                  {loadImage && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "rgba(0,0,0,0.4)",
                        position: "absolute",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  <img src={user.avatar} alt="user photo" />
                  <label className="photo-upload-label">
                    <UploadPhotoIcon />
                    <input
                      type="file"
                      accept={"image/jpeg"}
                      onInput={(e) => this.uploadFile(e)}
                    />
                  </label>
                  {user.avatar !== null && (
                    <button
                      className="photo-remove-label"
                      onClick={(e) => handleRemoveAvatar(e)}
                    >
                      X
                    </button>
                  )}
                </div>
              ) : (
                <div className="user-photo">
                  {loadImage && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "rgba(0,0,0,0.4)",
                        position: "absolute",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  <label className="photo-upload-label">
                    <UploadPhotoIcon />
                    <input
                      type="file"
                      accept={"image/jpeg"}
                      onInput={(e) => this.uploadFile(e)}
                    />
                  </label>
                </div>
              )}
            </form>
            {loading && <Loader />}
            {showError && (
              <ErrorAlert
                text={apiErrorMsg}
                close={() => this.setState({ showError: false })}
              />
            )}
          </>
        ) : (
          <>
            <div className="user-photo">
              {user.avatar !== null ? (
                <div className="user-photo uploaded">
                  <img
                    src={user.avatar}
                    alt={user.firstName + " " + user.lastName}
                  />
                </div>
              ) : (
                <div className="user-photo" />
              )}
            </div>
            <PasswordSideBar handleChangeSuccessAlert={this.handleChangeSuccessAlert}/>
            {showSuccess && (
                    <SuccessAlert
                        text={apiSuccessMsg}
                        close={() => this.setState({ showSuccess: false })}
                    />
            )}
            {/*<div*/}
            {/*  className="user-description"*/}
            {/*  style={{*/}
            {/*    position: "relative",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <p className="user-name">*/}
            {/*    {user.firstName + " " + user.lastName}*/}
            {/*  </p>*/}
            {/*  <p className="user-role">*/}
            {/*    {*/}
            {/*      specialityOptions.find((x) => x.text === user.speciality.text)*/}
            {/*        ?.label*/}
            {/*    }*/}
            {/*  </p>*/}
            {/*  <a className="user-contacts" href="tel:+37494123456">*/}
            {/*    <PhoneNumberIcon />*/}
            {/*    {user.phone}*/}
            {/*  </a>*/}
            {/*  <a*/}
            {/*    className="user-contacts"*/}
            {/*    href="mailto:edgarsimonyanash@gmail.com"*/}
            {/*  >*/}
            {/*    <EmailIcon />*/}
            {/*    {user.email}*/}
            {/*  </a>*/}
            {/*  <div className="user-form-btns">*/}
            {/*    <Button*/}
            {/*      className="user-form-btn"*/}
            {/*      action={() => this.setState({ editAccountInfo: true })}*/}
            {/*      text="Խմբագրել"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => ({
  user: getUserData(state),
});

const mapDispatchToProps = {
  getUserFromAPI,
};

export default connect(mapStateTopProps, mapDispatchToProps)(LeftSideBar);
