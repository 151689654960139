export const checkValidationErrors = (fields) => {
  let errors = {};
  let hasPhoneError = false;
  let hasEmailError = false;

  //Email
  if (!fields["email"] && fields['type'] === 'Email') {
    hasEmailError = true;
    errors["email"] = "Պարտադիր դաշտ";
  }

  if (!fields["phoneNumber"]  && fields['type'] === 'Phone') {
    hasPhoneError = true;
    errors['phoneNumber'] = "Պարտադիր դաշտ";
  }

  if (typeof fields["email"] !== "undefined" && fields['type'] === 'Email') {
    const lastAtPos = fields["email"].lastIndexOf("@");
    const lastDotPos = fields["email"].lastIndexOf(".");
    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        fields["email"].indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        fields["email"].length - lastDotPos > 2 &&
        !/\s/.test(fields['email'])
      )
    ) {
      hasEmailError = true;
      errors["email"] =
        "Ձեր էլ․ հասցեն վավեր չէ։ Խնդրում ենք մուտքագրել վավեր էլ․ հասցե։";
    }
  }

  return hasEmailError || hasPhoneError ? errors : null;
};
