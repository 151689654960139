import {CHANGE_MAP_STYLES, CHANGE_MAP_STYLES_DARK, CHANGE_MAP_STYLES_LIST} from "../constants";

export const changeMapStyles = (id) => {
  return {
    type: CHANGE_MAP_STYLES,
    payload: id,
  };
};

export const setMapStylesDark = (id) => {
  return {
    type: CHANGE_MAP_STYLES_DARK,
    payload: id,
  };
};

export const setMapStylesList = (list) => {
  return {
    type: CHANGE_MAP_STYLES_LIST,
    payload: list,
  };
};
