import axios from "axios"

const santizeSearchResult = (items) =>
  items.map((value, key) => ({
    id: value.id,
    value: value.id,
    title: value.title,
  }))

const extractItems = (res) => {
  const { data } = res
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items
    }
  }
  return []
}

export const constructSearchApiParams = (
  type,
  val,
  selectedTopicID,
  selectedSubjectID,
  selectedGradeID
) => {
  const obj = {
    subject: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/Subject/DataProvider/1`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "order",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          title: val,
        },
      },
    },
    grade: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/Grade/DataProvider/1`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "order",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          title: val,
        },
      },
    },
    lesson: {
      url: `${process.env.REACT_APP_HEADING_URL}/api/Lesson/DataProvider/${selectedTopicID}/1`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "order",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          title: val,
        },
      },
    },
    topic: {
      url: `${process.env.REACT_APP_HEADING_URL}/api/Topic/DataProvider/1`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "order",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          subjectId: String(selectedSubjectID),
          gradeId: String(selectedGradeID),
          title: val,
        },
      },
    },
  }

  return obj[type]
}


export const constructSelectedItemApiParams = (
    type,
    id,
    selectedSubjectID
) => {

  const obj = {
    subject: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/Grade/DataProvider/1`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "order",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
        },
      },
    },
    grade: {
      url: `${process.env.REACT_APP_HEADING_URL}/api/Topic/DataProvider/1`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "order",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          subjectId: String (selectedSubjectID),
          gradeId: String (id),
        },
      }
    },
    topic: {
      url: `${process.env.REACT_APP_HEADING_URL}/api/Lesson/DataProvider/${id}/1`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "order",
            type: "asc",
          },
        ],
        columnFilters: {
          "lesson.status": "Active",
        },
      },
    }
  }

  return obj[type]
}

export const searchAPIRequest = (url, body) => {
  return axios.post(url, body).then(extractItems).then(santizeSearchResult)
}


export const filterBySelectedSubject = (id, type, items) => {
  if (type === 'subject') {
    return items.filter (item => item.subjects.includes (id))
  }
  return items
}

export const santizeSelectedResult = (items) =>
    items.map((value, key) => ({
      id: value.id,
      value: value.id,
      title: value.title,
    }))

export const filterByUserRole = (type, items, userInfo) => {
  if (type === "subject" && userInfo.userInfo.userType === "Student") {
    const userGradeId = userInfo.userInfo?.gradeId
    if (userGradeId) {
      return items.filter((x) => x.id === userGradeId)
    }
  }
  return items
}

export const selectAPIRequest = (url, body) => {
  return axios.post(url, body).then(extractItems)
}
