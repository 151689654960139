import React, { useState } from "react";
import PasswordShowBlack from "../../assets/imgs/icons/password-show-black.svg";
import PasswordHideBlack from "../../assets/imgs/icons/password-hide-black.svg";
import PasswordShow from "../../assets/imgs/icons/password-show-icon.svg";
import PasswordHide from "../../assets/imgs/icons/password-hide-icon.svg";

export const TextInput = ({
  contentClassName = "input-wrapper",
  className = "account-input",
  type = "text",
  placeholder,
  onChange,
  value,
  hasError,
  style,
  profileZone,
  black,
}) => {
  const [inputType, setInputType] = useState(true);

  const passwordIcon = !inputType
    ? PasswordShow
    : PasswordHide
  const passwordIconBlack =
      !inputType
          ? PasswordShowBlack
          : PasswordHideBlack

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px",
        width: '100%'
      }}
      className="error_zone"
    >
      <div
        className={`${contentClassName} ${hasError ? "error" : ""}`}
        style={style ? style : {
          margin: 0,
        }}
      >
        <input
          className={className}
          type={inputType ? type : "text"}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {/*{hasError ? (*/}
        {/*  <>*/}
        {/*    <button className="error-btn" type="button" />*/}
        {/*    <ErrorMsg text={hasError} />*/}
        {/*  </>*/}
        {/*) : null}*/}

        {type === "password" && (
          <div
            className={
              profileZone
                ? "profile_password_icon_container"
                : "password_icon_container"
            }
            onClick={() => setInputType(!inputType)}
          >
            <img src={black ? passwordIconBlack : passwordIcon} width="100%" alt="" />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {hasError && <span className={"error-text"}>{hasError} *</span>}
      </div>
    </div>
  );
};
